import React from 'react'
import FormError, { FormErrorProps } from './error'
import FormProgress, { FormProgessProps } from './progess'
import FormSuccess, { FormSuccessProps } from './success'

interface FormWrapperProps {
  status?: 'idle' | 'loading' | 'error' | 'success'
  successProps?: FormSuccessProps
  loadingProps?: FormProgessProps
  errorProps?: FormErrorProps
  reset?(): void
}

const RequestStatus: React.FunctionComponent<FormWrapperProps> = ({
  status,
  successProps,
  loadingProps,
  errorProps,
  reset,
}) => {
  switch (status) {
    case 'success':
      return <FormSuccess {...successProps} reset={reset} />
    case 'loading':
      return <FormProgress {...loadingProps} />
    case 'error':
      return <FormError {...errorProps} />
    default:
      return <></>
  }
}

export default RequestStatus
