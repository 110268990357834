import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const useSoftReload = (): ((path?: string) => void) => {
  const navigate = useNavigate()
  const location = useLocation()

  return React.useCallback(
    (path?: string) => {
      if (!path) path = location.pathname
      navigate(path)
      navigate(0)
    },
    [location.pathname, navigate],
  )
}

export default useSoftReload
