import { FormikErrors } from 'formik'
import React, { ReactNode } from 'react'
import { Element, Form } from 'react-bulma-components'

export interface FormFieldProps {
  label?: string
  error?: string
  help?: string
  children?: ReactNode
}

const FormField: React.FunctionComponent<FormFieldProps> = ({ help, label, children, error }) => {
  return (
    <Form.Field>
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control>{children}</Form.Control>
      {help && <Form.Help>{help}</Form.Help>}
      {error && <FormFieldErrorMessage error={error} />}
    </Form.Field>
  )
}

export default FormField

export const FormFieldErrorMessage: React.FunctionComponent<{
  error?: string | string[]
}> = ({ error }) => {
  if (error) {
    const errors = Array.isArray(error) ? error : [error]
    return (
      <Element textColor="danger" mt={1}>
        {errors.map((e, i) => (
          <Element renderAs="span" key={e + i}>
            {e} <br />
          </Element>
        ))}
      </Element>
    )
  }
  return <></>
}
