import { QueryKey, useMutation, UseQueryResult } from '@tanstack/react-query'
import { queryClient, QueryOptions, UseMutationResult, useResource, useResources } from '.'
import {
  create,
  fetch,
  get,
  WorkPeriodEvent,
  WorkPeriodEventCreationPayload,
  WorkPeriodEventUpdatePayload,
  update,
} from '../api/work-period-events'

//Queries
const useWorkPeriodEventQuery = (id?: string): UseQueryResult<WorkPeriodEvent, any> =>
  useResource<WorkPeriodEvent>('work-period-events', get, id)

export const useWorkPeriodEventsQuery = (
  filters?: any,
  options?: QueryOptions,
): UseQueryResult<WorkPeriodEvent[], any> =>
  useResources<WorkPeriodEvent[], any>('work-period-events', fetch, filters, options)

//Mutations
const onMutationSuccess = () => {
  queryClient.invalidateQueries(['work-period-events'])
}

export const useCreateMutationKey = (): QueryKey => ['work-period-events', 'create']
export const useCreateMutation = (): UseMutationResult<
  WorkPeriodEvent,
  WorkPeriodEventCreationPayload,
  any
> => useMutation(create, { mutationKey: useCreateMutationKey(), onSuccess: onMutationSuccess })

export const useUpdateMutationKey = (): QueryKey => ['work-period-events', 'update']
export const useUpdateMutation = (): UseMutationResult<
  WorkPeriodEvent,
  WorkPeriodEventUpdatePayload,
  any
> =>
  useMutation(update, {
    mutationKey: useUpdateMutationKey(),
    onSuccess: onMutationSuccess,
  })
