import React from 'react'
import { Button, Container, Element, Form, Icon, Section } from 'react-bulma-components'
import { Column, Row } from 'react-table'
import Layout from '../../components/layout/layout'
import Table from '../../components/table'
import PageHeading from '../../components/sections/page-heading'
import { Link } from 'react-router-dom'
import { DocumentAddIcon } from '@heroicons/react/solid'
import ComponentHeader from '../../components/sections/component-header'
import { useClockingRulesQuery } from '../../queries/clocking-rules'
import { ClockingRules } from '../../api/clocking-rules'
import ClockingRulesDescription from '../../components/clocking-rules/clocking-rules-description'
import { trimTextIfLongerThan } from '../../utils/texts'
import { formatDuration } from 'date-fns'
import ClockingRulesBreakDescription from '../../components/clocking-rules/clocking-rules-break-description'

const ClockingRulesList: React.FunctionComponent = () => {
  const query = useClockingRulesQuery()
  const tableColumns: Column<ClockingRules>[] = useListTableColumns(query?.data || [])
  return (
    <Layout>
      <PageHeading
        title="Liste des Règles de Pointage"
        subtitle="Liste de toutes les Règles de Pointage de votre entreprise"
      />
      <Section>
        <Container>
          <ComponentHeader>
            <ComponentHeader.Left></ComponentHeader.Left>
            <ComponentHeader.Right>
              <Form.Field>
                <Form.Label>&nbsp;</Form.Label>
                <Form.Control>
                  <Button renderAs={Link} to="/clocking-rules/new" outlined color="primary">
                    <Icon>
                      <DocumentAddIcon />
                    </Icon>
                    <span>Nouvelle Règle de Pointage</span>
                  </Button>
                  <Element></Element>
                </Form.Control>
              </Form.Field>
            </ComponentHeader.Right>
          </ComponentHeader>
          <Table<ClockingRules>
            columns={tableColumns}
            data={query.data || []}
            noDataMessage="Aucune Règle de Pointage à afficher"
          />
        </Container>
      </Section>
    </Layout>
  )
}

export default ClockingRulesList

const useListTableColumns = (clockingRules: ClockingRules[]): Column<ClockingRules>[] =>
  React.useMemo(
    () => [
      {
        Header: 'Nom',
        accessor: 'name',
        Cell: ({ row }: { row: Row<ClockingRules> }) => {
          const { original: clockingRules } = row
          return (
            <p>
              <b>
                <Link to={`/clocking-rules/${clockingRules._id}`}>{clockingRules.name}</Link>
              </b>
              {clockingRules?.description && (
                <>
                  <br />
                  {trimTextIfLongerThan(clockingRules.description, 120)}
                </>
              )}
            </p>
          )
        },
      },
      {
        Header: 'En Début de Journée',
        accessor: row => row.rules.start,
        Cell: ({ row }: { row: Row<ClockingRules> }) => {
          const { original: clockingRules } = row
          return <ClockingRulesDescription clockingRules={clockingRules} type="start" />
        },
      },
      {
        Header: 'En Fin de Journée',
        accessor: row => row.rules.start,
        Cell: ({ row }: { row: Row<ClockingRules> }) => {
          const { original: clockingRules } = row
          return <ClockingRulesDescription clockingRules={clockingRules} type="end" />
        },
      },
      {
        Header: 'Pause',
        accessor: row => row.break,
        Cell: ({ row }: { row: Row<ClockingRules> }) => {
          const { original: clockingRules } = row
          return <ClockingRulesBreakDescription clockingRules={clockingRules} />
        },
      },
    ],
    [clockingRules],
  )
