import { produce } from 'immer'
import { StoreSlice, StoreState } from '.'
import { endOfWeek, formatISODate, startOfWeek } from '../utils/date'
import { Organization } from '../api/organizations'
import { JobTitle } from '../api/job-titles'
import { IsoDate } from '../hooks/use-calendar'

export enum FiltersResourcesEnum {
  missions = 'missions',
  workersRequests = 'workersRequests',
  workPeriods = 'workPeriods',
  clockings = 'clockings',
  preBillings = 'preBillings',
  kPI = 'kPI',
}

export interface MissionsFilters {
  start?: Date
  end?: Date
  service?: string[]
  interimAgency?: Organization['_id'][]
  employer?: Organization['_id'][]
  displayDay?: Date
  jobTitle?: JobTitle['_id'][]
  summaryMinimalMode?: 'minimal'
  user?: string[]
  isCancelled?: boolean[]
  'workPeriods.preBilling'?: string[] | boolean[]
  preBilling?: string[] | boolean[]
}

export interface WorkPeriodsFilters {
  start: IsoDate
  end: IsoDate
  interimAgency?: Organization['_id'][]
  employer?: Organization['_id'][]
  jobTitle?: JobTitle['_id'][]
  user?: string[]
  isValidated?: boolean[]
  isAbandoned?: boolean[]
  isCancelled?: boolean[]
}

export interface WorkersRequestsFilters {
  service?: MissionsFilters['service']
  interimAgency?: MissionsFilters['interimAgency']
  jobTitle?: MissionsFilters['jobTitle']
  employer?: MissionsFilters['employer']
  isCancelled?: boolean[]
  isComplete?: boolean[]
  isExpired?: boolean[]
  start?: Date
  end?: Date
}

export interface KPIFilters {
  service?: MissionsFilters['service']
  interimAgency?: MissionsFilters['interimAgency']
  jobTitle?: MissionsFilters['jobTitle']
  employer?: MissionsFilters['employer']
  user?: MissionsFilters['user']
  start: IsoDate
  end: IsoDate
}

export interface ClockingsFilters {
  user?: MissionsFilters['user']
  start?: MissionsFilters['start']
  end?: MissionsFilters['end']
}

export interface PreBillingsFilters {
  start?: MissionsFilters['start']
  end?: MissionsFilters['end']
  interimAgency?: MissionsFilters['interimAgency']
  invoiceRef?: boolean[]
}

interface PaginationItem {
  currentPage: number
}

interface Pagination {
  summary: PaginationItem
}

export interface FiltersSlice {
  [FiltersResourcesEnum.missions]: MissionsFilters
  [FiltersResourcesEnum.workersRequests]: WorkersRequestsFilters
  [FiltersResourcesEnum.workPeriods]: WorkPeriodsFilters
  [FiltersResourcesEnum.clockings]: ClockingsFilters
  [FiltersResourcesEnum.preBillings]: PreBillingsFilters
  [FiltersResourcesEnum.kPI]: KPIFilters
  setFilters<Resource extends FiltersResourcesEnum>(
    resource: Resource,
    filters: FiltersSlice[Resource],
  ): void
  pagination: Pagination
  setPagination(item: keyof Pagination, value: PaginationItem): void
}

const now = new Date()
const initialStartEndisThisWeek = { start: startOfWeek(now), end: endOfWeek(now) }
const initialStartEndisToday = {
  start: formatISODate(now),
  end: formatISODate(now),
}

const filtersSlice: StoreSlice<FiltersSlice> = set => ({
  [FiltersResourcesEnum.missions]: {
    ...initialStartEndisThisWeek,
    preBilling: [false],
    isCancelled: [false],
    summaryMinimalMode: 'minimal',
  },
  [FiltersResourcesEnum.workPeriods]: {
    ...initialStartEndisToday,
  },
  [FiltersResourcesEnum.kPI]: {
    ...initialStartEndisToday,
  },
  [FiltersResourcesEnum.workersRequests]: {
    isExpired: [false],
    isComplete: [false],
    isCancelled: [false],
  },
  [FiltersResourcesEnum.clockings]: {
    ...initialStartEndisThisWeek,
  },
  [FiltersResourcesEnum.preBillings]: {
    invoiceRef: [false],
  },
  pagination: {
    summary: { currentPage: 1 },
  },
  setFilters: (resource, filters) => {
    set(
      produce(state => {
        state.filters[resource] = filters
      }),
    )
  },
  setPagination: (item, value) => {
    set(
      produce((state: StoreState) => {
        state.filters.pagination[item] = value
      }),
    )
  },
})

export default filtersSlice
