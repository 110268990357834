import React from 'react'
import Layout from '../../components/layout/layout'
import { Box, Button, Container, Heading, Icon, Level, Section } from 'react-bulma-components'
import { ViewListIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'
import useStore from '../../store'
import { Employer } from '../../api/organizations'
import useUrlId from '../../hooks/use-url-id'
import { useOrganizationQuery } from '../../queries/organizations'
import PageTitle from '../../components/pages/page-title'
import { useJobTitlesQuery } from '../../queries/job-titles'
import { getJobTitleLaborCoefficientByInterimAgency } from '../../utils/job-titles'
import { JobTitle } from '../../api/job-titles'
import { sortByIgnoreCase } from '../../utils/arrays'
import Table from '../../components/table'
import { Column } from 'react-table'

const EmployerDetailsForInterimAgency: React.FunctionComponent = () => {
  const currentOrganization = useStore(state => state.session.currentOrganization)
  const { id } = useUrlId()
  const { data: employer } = useOrganizationQuery<Employer>(id, 'employer')
  const jobTitlesQuery = useJobTitlesQuery({ employer: id })
  const jobTitles = sortByIgnoreCase<JobTitle>(jobTitlesQuery.data || [], 'name')

  const laborCostCoefficientsColumns = useLaborCostCoefficientsColumns()

  const laborCostCoefficientsItems = React.useMemo(() => {
    return jobTitles.map(jobTitle => {
      const jobTitleLaborCoefficient = getJobTitleLaborCoefficientByInterimAgency(
        jobTitle,
        currentOrganization!._id,
      )
      return {
        jobTitleName: jobTitle.name,
        delegationValue: jobTitleLaborCoefficient?.delegationValue || 'N/A',
        managementValue: jobTitleLaborCoefficient?.managementValue || 'N/A',
      }
    })
  }, [jobTitles, currentOrganization])

  if (!employer || !jobTitles || !currentOrganization) return <></>

  return (
    <Layout>
      <Section>
        <Container max={true} breakpoint="desktop">
          <Level alignItems="flex-start" mb={6}>
            <Level.Side>
              <PageTitle title={employer.name} subTitle={`Vous collaborez avec ${employer.name}`} />
            </Level.Side>
            <Level.Side>
              <Button renderAs={Link} to="/associations" outlined color="primary" size="small">
                <Icon>
                  <ViewListIcon />
                </Icon>
                <span>Liste des Entreprises Utilisatrices partenaires</span>
              </Button>
            </Level.Side>
          </Level>
          <Heading size={4}>Intitulés de Poste & Coefficients main d'oeuvre</Heading>
          <Box>
            <Table columns={laborCostCoefficientsColumns} data={laborCostCoefficientsItems} />
          </Box>
        </Container>
      </Section>
    </Layout>
  )
}

export default EmployerDetailsForInterimAgency

type ColumnData = {
  jobTitleName: string
  delegationValue: number
  managementValue: number
}

const useLaborCostCoefficientsColumns = (): Column<ColumnData>[] => {
  return React.useMemo(
    () =>
      [
        {
          Header: 'Intitulé de poste',
          accessor: 'jobTitleName',
        },
        {
          Header: 'Coef Délégation',
          accessor: 'delegationValue',
        },
        {
          Header: 'Coef Gestion',
          accessor: 'managementValue',
        },
      ] as Column<ColumnData>[],
    [],
  )
}
