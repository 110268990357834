import React from 'react'
import { Element } from 'react-bulma-components'

const WorkPeriodEventBenefitDescription: React.FC = () => {
  return (
    <>
      <Element renderAs="strong">Evénement Prestation</Element> : il va affecter positivement les
      Heures Totales Prestées pour un Jour, quand bien même l’ Employé n’a pas presté réellement ce
      Jour là.
      <br />
      <Element renderAs="i">Exemple : Un Congé Payé</Element>
    </>
  )
}

export default WorkPeriodEventBenefitDescription
