import React from 'react'
import { Button, Element, Modal, Form } from 'react-bulma-components'

export interface ConfirmationModalBaseProps {
  show?: boolean
  hide(): void
}

interface ConfirmationModalProps {
  onConfirm(): void
  content?: string | React.ReactElement
  onCancel?(): void
  confirmButtonLabel?: string
  // BulmaButtonProps is not an exported type (see: https://github.com/couds/react-bulma-components/issues/362)
  // HTMLButtonElement has a lot of props not compatible with BulmaButton
  // So we have to pick the ones we use, to satisfy TS
  confirmButtonProps?: Pick<HTMLButtonElement, 'disabled'>
  cancelButtonLabel?: string
  show?: boolean
  title?: string
  rememberStoreKey?: string
  children: React.ReactNode
}

const ConfirmationModal: React.FunctionComponent<ConfirmationModalProps> = ({
  content,
  children,
  onConfirm,
  onCancel,
  confirmButtonLabel,
  confirmButtonProps,
  cancelButtonLabel,
  show,
  title,
  rememberStoreKey,
}) => {
  const [remember, setRemember] = React.useState(false)

  const handlerConfirm = React.useCallback(() => {
    if (rememberStoreKey && remember) localStorage.setItem(rememberStoreKey, 'yes')
    onConfirm()
  }, [onConfirm, rememberStoreKey, remember])

  React.useEffect(() => {
    if (show && rememberStoreKey && localStorage.getItem(rememberStoreKey)) onConfirm()
  }, [show, rememberStoreKey])

  if (show && rememberStoreKey && localStorage.getItem(rememberStoreKey)) return <></>

  return (
    <Modal show={show} onClose={onCancel} closeOnEsc closeOnBlur>
      <Modal.Card>
        <Modal.Card.Header>
          <Modal.Card.Title>{title || 'Confirmation requise'}</Modal.Card.Title>
        </Modal.Card.Header>
        <Modal.Card.Body>
          {content || children}
          {rememberStoreKey && (
            <Element mt={5}>
              <Form.Field>
                <Form.Control>
                  <Form.Checkbox onChange={e => setRemember(e.target.checked)}>
                    Ne plus demander
                  </Form.Checkbox>
                </Form.Control>
              </Form.Field>
            </Element>
          )}
        </Modal.Card.Body>
        <Modal.Card.Footer justifyContent="space-between">
          <Button
            color="danger"
            textWeight="bold"
            onClick={handlerConfirm}
            {...confirmButtonProps}
            data-test="confirm-action"
          >
            {confirmButtonLabel || 'OK'}
          </Button>
          <Button onClick={onCancel}>{cancelButtonLabel || 'Annuler'}</Button>
        </Modal.Card.Footer>
      </Modal.Card>
    </Modal>
  )
}

export default ConfirmationModal
