import React from 'react'
import { Columns, Heading, Section } from 'react-bulma-components'
import FormWrapper from '../../components/form/form-wrapper'
import Layout from '../../components/layout/layout'
import RequestNewPasswordForm from '../../components/users/request-new-password-form'
import { useFormWrapperPropsWithMutationData } from '../../hooks/use-form-wrapper-props-with-mutation-data'
import { useMutationState } from '../../queries'
import { useRequestPasswordMutationKey } from '../../queries/users'
import { useNavigate } from 'react-router-dom'

const UserRequestNewPassword: React.FC = ({}) => {
  const navigate = useNavigate()
  const mutationState = useMutationState(useRequestPasswordMutationKey())
  const formWrapperProps = useFormWrapperPropsWithMutationData(
    {
      loadingProps: { body: 'Vérification en cours...' },
      errorProps: {
        title: 'Une erreur est survenue',
        message: 'Email inconnu',
      },
    },
    mutationState,
  )

  React.useEffect(() => {
    if (mutationState?.status === 'success' && mutationState?.data?.data)
      navigate(`/reset-password?token=${mutationState?.data?.data}`)
  }, [mutationState?.status, mutationState?.data?.data])

  return (
    <Layout>
      <Section size={'large'}>
        <Columns centered>
          <Columns.Column size={4}>
            <Heading>Demande de nouveau mot de passe</Heading>
            <FormWrapper {...formWrapperProps}>
              <RequestNewPasswordForm />
            </FormWrapper>
          </Columns.Column>
        </Columns>
      </Section>
    </Layout>
  )
}

export default UserRequestNewPassword
