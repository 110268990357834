import React from 'react'
import { ClockingsFilters, FiltersResourcesEnum } from '../../store/filters'
import useFilters from '../../hooks/filters'
import { Formik } from 'formik'
import { Columns } from 'react-bulma-components'
import AutoSubmit from '../form/utils/auto-submit'
import WorkersFilterField from '../filters/fields/workers'
import WeekFilterField from '../filters/fields/week'
import { dateInputValuesToWeekDates, dateToDateInputValue } from '../../utils/date'

const ClockingsFilters: React.FC = ({}) => {
  const { setFilters, filters } = useFilters(FiltersResourcesEnum.clockings)

  return (
    <Formik<ClockingsFilters & { week: string }>
      initialValues={{
        ...filters,
        week: dateToDateInputValue(filters.start ? new Date(filters.start) : new Date()),
      }}
      onSubmit={values => {
        if (values.week) {
          const dates = dateInputValuesToWeekDates(values.week)
          if (dates) values = { ...values, ...dates }
        }
        setFilters(FiltersResourcesEnum.clockings, values)
      }}
    >
      {props => {
        return (
          <>
            <Columns mb="0">
              <WeekFilterField />
              <WorkersFilterField />
            </Columns>
            <AutoSubmit values={props.values} submitForm={props.submitForm} />
          </>
        )
      }}
    </Formik>
  )
}

export default ClockingsFilters
