import React from 'react'
import Layout from '../../components/layout/layout'
import { Card, Container, Element, Section, Tabs } from 'react-bulma-components'
import PageTitle from '../../components/pages/page-title'
import useStore from '../../store'
import { User, UserRole } from '../../api/users'
import locales from '../../../../locales'
import usersLocales from '../../../../locales/users'
import NotificationsSettings from '../../components/notifications/notifications-settings'
import { useUpdateNotificationSettingsMutation } from '../../queries/users'
import { RequestMessage } from '../../components/request-components/request-components'

const UserNotificationsSettings: React.FC = ({}) => {
  const currentUser = useStore(state => state.session.currentUser as User)
  const mutation = useUpdateNotificationSettingsMutation()

  const [userRoleId, setUserRoleId] = React.useState<string>(
    (currentUser.roles[0] as UserRole)._id as string,
  )
  const [userRole, setUserRole] = React.useState<UserRole>(currentUser.roles[0] as UserRole)

  React.useEffect(() => {
    setUserRole(currentUser.roles.find(role => role._id === userRoleId) as UserRole)
  }, [userRoleId, currentUser])

  const mutationSuccessText =
    mutation.variables &&
    `Paramètre "${
      usersLocales.notifications.settings[mutation.variables?.notificationResource][
        mutation.variables?.notificationAction
      ]
    }" modifié avec succès`

  return (
    <Layout>
      <Section>
        <Container breakpoint="desktop" max={true}>
          <PageTitle
            title={`Paramètres de notifications`}
            subTitle={'Gérer les notifications TeamTim.'}
          />
          <Element mt={5}>
            {currentUser.roles[1] && (
              <Tabs>
                {currentUser.roles.map(role => (
                  <Tabs.Tab
                    onClick={() => setUserRoleId(role._id)}
                    key={role._id}
                    active={userRoleId === role._id}
                  >
                    {role.organization ? role.organization.name : locales.users.roles[role.kind]}
                  </Tabs.Tab>
                ))}
              </Tabs>
            )}
            <Element key={userRole._id} mt={3} mb={5}>
              {userRole.notificationsSettings ? (
                <Card mt={3}>
                  <Card.Content>
                    {Object.keys(userRole.notificationsSettings).map(resource => (
                      <Element mb={5} key={resource}>
                        <NotificationsSettings
                          key={resource}
                          title={locales.resources[resource]}
                          resource={resource}
                          //@ts-ignore
                          settings={userRole.notificationsSettings[resource]}
                          locales={usersLocales.notifications.settings[resource]}
                          disabledFields={
                            userRole.kind === 'worker' && resource === 'missions'
                              ? ['created', 'updated', 'abandoned', 'cancelled']
                              : []
                          }
                          onItemChange={(actionName, value, notificationType) => {
                            mutation.mutate({
                              roleId: userRole._id,
                              notificationType,
                              //@ts-ignore
                              notificationResource: resource,
                              notificationAction: actionName,
                              value,
                            })
                          }}
                        />
                      </Element>
                    ))}
                  </Card.Content>
                </Card>
              ) : (
                <Element>Aucun paramètre disponible pour ce profil</Element>
              )}
              <Element mt={3}>
                {mutation.variables?.roleId === userRoleId && (
                  <RequestMessage
                    mutation={mutation}
                    displayOn={{
                      success: mutationSuccessText,
                    }}
                  />
                )}
              </Element>
            </Element>
          </Element>
        </Container>
      </Section>
    </Layout>
  )
}

export default UserNotificationsSettings
