import { QueryKey, useMutation, UseQueryResult } from '@tanstack/react-query'
import { queryClient, QueryOptions, UseMutationResult, useResource, useResources } from '.'
import {
  create,
  fetch,
  get,
  PreBilling,
  PreBillingCreationPayload,
  PreBillingUpdatePayload,
  simulate,
  update,
} from '../api/pre-billings'
import { SimulateData } from '../../../backend/src/services/resources/pre-billings/pre-billings.business.service.types'

//Queries
export const usePreBillingQuery = (
  id?: string,
  options?: QueryOptions,
): UseQueryResult<PreBilling, any> => useResource<PreBilling>('pre-billings', get, id, options)

export const usePreBillingsQuery = (
  filters?: any,
  options?: QueryOptions,
): UseQueryResult<PreBilling[], any> =>
  useResources<PreBilling[], any>('pre-billings', fetch, filters, options)

export const useSimulatePreBillingQuery = (
  params: PreBillingCreationPayload,
  options?: QueryOptions,
): UseQueryResult<SimulateData, any> =>
  useResources<SimulateData, any>('pre-billings', simulate, params, options)

//Mutations
const onMutationSuccess = () => {
  queryClient.invalidateQueries(['pre-billings'])
}

const useCreateMutationKey = (): QueryKey => ['pre-billings', 'create']
export const useCreateMutation = (): UseMutationResult<
  PreBilling,
  PreBillingCreationPayload,
  any
> => useMutation(create, { mutationKey: useCreateMutationKey(), onSuccess: onMutationSuccess })

const useUpdateMutationKey = (): QueryKey => ['pre-billings', 'update']
export const useUpdateMutation = (): UseMutationResult<PreBilling, PreBillingUpdatePayload, any> =>
  useMutation(update, { mutationKey: useUpdateMutationKey(), onSuccess: onMutationSuccess })
