import React from 'react'
import ErrorLayout from '../../components/layout/error-layout'
import { Button, Element, Icon } from 'react-bulma-components'
import { ExclamationIcon } from '@heroicons/react/outline'
import useStore from '../../store'
import { useNavigate } from 'react-router-dom'

const NoActiveRole: React.FC = ({}) => {
  const logout = useStore(state => state.session.logout)
  const naviguate = useNavigate()
  return (
    <ErrorLayout>
      <Element textAlign="center">
        <Icon size={'large'}>
          <ExclamationIcon />
        </Icon>
        <Element textSize={3} textAlign="center">
          Vous ne faites partie d'aucune organisation
        </Element>
        Votre compte TeamTim n'est plus lié à aucune organisation. Veuillez contacter votre
        responsable pour en savoir plus.
      </Element>
      <Element
        onClick={() => {
          logout()
          naviguate('/login')
        }}
        mt={5}
        textAlign="center"
      >
        <Button>Se déconnecter</Button>
      </Element>
    </ErrorLayout>
  )
}

export default NoActiveRole
