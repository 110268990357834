
import { EmployerWeekRuleInterval, EmployerDayRuleInterval } from '../backend/src/services/resources/employer-rules/employer-rule.model'
const employerRulesLocales: {
  inputs: {
    week: {
      [KEY in EmployerWeekRuleInterval['input']]: string
    }
    day: {
      [KEY in EmployerDayRuleInterval['input']]: string
    }
  }
} = {
  inputs: {
    week: {
        'durations.worked.nights': 'Heures travaillées la nuit',
        'durations.worked.days': 'Heures travaillées la journée',
        'durations.worked.total': 'Heures totale travaillées (Jour & Nuit)',
    },
    day: {
      'durations.worked.night': 'Heures travaillées la nuit',
      'durations.worked.day': 'Heures travaillées la journée',
      'durations.worked.total': 'Heures totale travaillées (Jour & Nuit)',
    }
  }
}

export default employerRulesLocales