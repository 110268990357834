import React from 'react'
import { Icon } from 'react-bulma-components'
import Duration from './duration'
import { CheckIcon, WarningIcon } from '../icons'
import { Size } from 'react-bulma-components/src/components'

interface MissionWeekDurationProps {
  duration: number
  expectedDuration?: number
}

const MissionWeekDuration: React.FC<MissionWeekDurationProps> = ({
  duration,
  expectedDuration,
}) => {
  return (
    <>
      <Duration duration={duration} />
      {expectedDuration ? (
        <Icon.Text>
          &nbsp;/&nbsp;
          <Duration duration={expectedDuration} />
        </Icon.Text>
      ) : undefined}
    </>
  )
}

interface MissionWeekDurationStatus {
  duration?: number
  expectedDuration?: number
  size?: Size
}

export const MissionWeekDurationStatus: React.FC<MissionWeekDurationStatus> = ({
  duration,
  expectedDuration,
  size,
}) => {
  if (!expectedDuration || !duration) return <></>
  if (expectedDuration !== duration) {
    return (
      <Icon color="danger" data-tooltip={'La durée ne respecte pas le contrat.'} size={size}>
        <WarningIcon />
      </Icon>
    )
  }
  return (
    <Icon color="success" size={size}>
      <CheckIcon />
    </Icon>
  )
}
