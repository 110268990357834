import { WorkPeriodEventSchema } from '../../../backend/src/services/resources/work-period-events/work-period-event.model'
import { WorkPeriodEventRepositoryCreationPayload } from '../../../backend/src/services/resources/work-period-events/work-period-events.repository.service.types'
import api, { ApiResponse } from '.'

export interface WorkPeriodEvent extends WorkPeriodEventSchema {
  _id: string
  employer: string
}

export const fetch = async (params?: any): Promise<ApiResponse<WorkPeriodEvent[]>> =>
  await api({
    endpoint: '/work-period-events',
    method: 'get',
    params: {
      sort: 'code',
      ...(params || {}),
    },
  })

export const get = async (id: string): Promise<ApiResponse<WorkPeriodEvent>> =>
  await api({
    endpoint: `/work-period-events/${id}`,
    method: 'get',
  })

export type WorkPeriodEventCreationPayload = Omit<
  WorkPeriodEventRepositoryCreationPayload,
  'employer'
>

export const create = async (
  params: WorkPeriodEventCreationPayload,
): Promise<ApiResponse<WorkPeriodEvent>> =>
  await api({
    endpoint: '/work-period-events',
    method: 'post',
    params,
  })

export interface WorkPeriodEventUpdatePayload extends WorkPeriodEventCreationPayload {
  id: string
}

export const update = async (
  params: WorkPeriodEventUpdatePayload,
): Promise<ApiResponse<WorkPeriodEvent>> => {
  const { id, ...payload } = params
  return await api({
    endpoint: `/work-period-events/${id}`,
    method: 'put',
    params: payload,
  })
}
