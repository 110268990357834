import React from 'react'
import { Element } from 'react-bulma-components'
import Message, { CommonMessageProps } from '../../message/message'
import { WorkersRequestPost } from '../../../api/workers-requests'
import {
  WorkersRequestPostEmptyIcon,
  WorkersRequestPostFilledIcon,
  WorkersRequestPostReplaceableIcon,
} from '../../icons'

const WorkersRequestPostIsFilled: React.FC<
  CommonMessageProps & {
    post: {
      missions: WorkersRequestPost['missions']
      isReplaceable: WorkersRequestPost['isReplaceable']
    }
  }
> = ({ post, ...rest }) => {
  if (!post) return <></>
  if (post.missions.length === 0) return <></>
  if (post.missions.length > 0 && post.isReplaceable === true) return <></>
  return (
    <Message
      iconColor="success"
      text="Le poste est pourvu"
      icon={<WorkersRequestPostFilledIcon />}
      {...rest}
    />
  )
}

const WorkersRequestPostIsEmpty: React.FC<
  CommonMessageProps & {
    post: {
      missions: WorkersRequestPost['missions']
      isReplaceable: WorkersRequestPost['isReplaceable']
    }
  }
> = ({ post, ...rest }) => {
  if (!post) return <></>
  if (post.missions.length > 0 && post.isReplaceable === false) return <></>
  return (
    <Message
      iconColor="warning"
      text="Le poste est à pourvoir"
      icon={<WorkersRequestPostEmptyIcon />}
      {...rest}
    />
  )
}

/* export const WorkersRequestPostIsReplaceable: React.FC<
  CommonMessageProps & { post: { missions: WorkersRequestPost['missions'], isReplaceable: WorkersRequestPost['isReplaceable'] } }
> = ({ post, ...rest }) => {
  if (!post) return <></>
  if (post.isReplaceable === false) return <></>
  return (
    <Message
      iconColor="danger"
      text="Le poste est à remplacer"
      icon={<WorkersRequestPostReplaceableIcon />}
      {...rest}
    />
  )
} */

interface WorkersRequestPostsMessagesProps extends CommonMessageProps {
  post: WorkersRequestPost
}

const WorkersRequestPostMessages: React.FC<WorkersRequestPostsMessagesProps> = props => {
  if (!props.post) return <></>
  return (
    <Element display="flex" flexWrap="wrap">
      <WorkersRequestPostIsFilled {...props} />
      <WorkersRequestPostIsEmpty {...props} />
      {/* <WorkersRequestPostIsReplaceable {...props} /> */}
    </Element>
  )
}

export default WorkersRequestPostMessages
