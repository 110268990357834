import React from 'react'
import { OrganizationTypeEnum } from '../api/organizations'
import { UserRoleKind } from '../api/users'
import { StoreState } from '../store'

const allThemeNames = [
  'superAdmin-theme',
  'employerMember-theme',
  'worker-theme',
  'interimAgencyMember-theme',
  'guest-theme',
]

export type UserRoleKindThemeNames = { [key in UserRoleKind]: string }
export const userRoleKindThemeNames: UserRoleKindThemeNames = {
  interimAgencyMember: 'interim-agency',
  employerMember: 'employer',
  worker: 'worker',
  superAdmin: 'super-admin',
}

type OrganizationTypeThemeNames = { [key in OrganizationTypeEnum]: string }
export const organizationTypeThemeNames: OrganizationTypeThemeNames = {
  interimAgency: 'interim-agency',
  employer: 'employer',
}

// Set className on <html> according current user role

const useThemeSwitcher = (currentUserRole: StoreState['session']['currentUserRole']): void => {
  React.useEffect(() => {
    const currentThemeClassName = currentUserRole + '-theme'
    allThemeNames.forEach(themeName => {
      if (document.getElementsByTagName('html')[0].classList.contains(themeName)) {
        document.getElementsByTagName('html')[0].classList.remove(themeName)
      }
    })
    document.getElementsByTagName('html')[0].classList.add(currentThemeClassName)
  }, [currentUserRole])
}
export default useThemeSwitcher
