import { Field, Form, Formik } from 'formik'
import React from 'react'
import { User } from '../../api/users'
import _ from 'lodash'
import { Columns, Heading, Form as BulmaForm, Message } from 'react-bulma-components'
import InputField from '../form/fields/input'
import ComponentFooter from '../sections/component-footer'
import { RequestButton, RequestMessage } from '../request-components/request-components'
import { useUpdateMutation } from '../../queries/users'
import useStore from '../../store'

interface UpdateCommonInformationsProps {
  user: User
}

const UpdateCommonInformations: React.FC<UpdateCommonInformationsProps> = ({ user }) => {
  const mutation = useUpdateMutation()
  const currentUserRole = useStore(store => store.session.currentUserRole)
  const isDisabled = currentUserRole === 'worker'

  const initialValues = {
    firstName: user.firstName,
    lastName: user.lastName,
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => {
        mutation.mutate({ ...values, id: user._id })
      }}
    >
      {({ values }) => {
        const submitDisabled = _.isEqual(initialValues, values)
        return (
          <Form>
            <Columns>
              <Columns.Column>
                <Heading renderAs="h2" size={4}>
                  Informations de base
                </Heading>
                <Columns>
                  <Columns.Column>
                    <Field
                      label="Prénom"
                      name="firstName"
                      component={InputField}
                      required
                      disabled={isDisabled}
                    />
                  </Columns.Column>
                  <Columns.Column>
                    <Field
                      label="Nom de Naissance"
                      name="lastName"
                      component={InputField}
                      required
                      disabled={isDisabled}
                    />
                  </Columns.Column>
                  <Columns.Column>
                    <BulmaForm.Field>
                      <BulmaForm.Label>
                        <RequestMessage
                          mutation={mutation}
                          displayOn={{ success: 'Mise à jour effectuée' }}
                        />
                        &nbsp;
                      </BulmaForm.Label>
                      <BulmaForm.Control>
                        <RequestButton
                          color="primary"
                          type="submit"
                          mutation={mutation}
                          disabled={submitDisabled || isDisabled}
                        >
                          {'Mettre à jour les Informations'}
                        </RequestButton>
                      </BulmaForm.Control>
                    </BulmaForm.Field>
                  </Columns.Column>
                </Columns>
              </Columns.Column>
            </Columns>
            {isDisabled ? (
              <Message mb={6} color="warning">
                <Message.Body>
                  Contactez votre Employeur pour mettre à jour vos Informations de base
                </Message.Body>
              </Message>
            ) : undefined}
            <ComponentFooter></ComponentFooter>
          </Form>
        )
      }}
    </Formik>
  )
}
export default UpdateCommonInformations
