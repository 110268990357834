import React from 'react'
import PageTitle from '../../pages/page-title'
import { PreBilling } from '../../../api/pre-billings'
import { Icon } from 'react-bulma-components'
import { BillIcon, CalendarIcon, EmployerIcon, InterimAgencyIcon } from '../../icons'
import { UserIcon } from '@heroicons/react/outline'
import Avatar from '../../avatar/avatar'
import { localDate } from '../../../utils/date'
import Protected from '../../protected/protected'

interface PreBillingPageTitleProps {
  preBilling: PreBilling
  onInvoiceRefClick?(): void
}

const PreBillingPageTitle: React.FC<PreBillingPageTitleProps> = ({
  preBilling,
  onInvoiceRefClick,
}) => {
  return (
    <PageTitle
      title={preBilling.identifier}
      subTitle={
        <>
          <Icon.Text mr={5}>
            <Icon mr={1}>
              <EmployerIcon />
            </Icon>
            {preBilling.employer.name}
          </Icon.Text>
          <Icon.Text mr={5}>
            <Icon mr={1}>
              <InterimAgencyIcon />
            </Icon>
            {preBilling.interimAgency.name}
          </Icon.Text>
          <Icon.Text mr={5}>
            <Icon mr={1}>
              <CalendarIcon />
            </Icon>
            {localDate(preBilling.createdAt)}
          </Icon.Text>
          <Icon.Text mr={5}>
            <Icon mr={1}>
              <UserIcon />
            </Icon>
            <Avatar user={preBilling.createdBy} />
          </Icon.Text>
          <Protected
            roles={[
              { role: 'employerMember', action: { resource: 'preBillings', name: 'create' } },
            ]}
          >
            {preBilling.invoiceRef && (
              <Icon.Text
                mr={5}
                renderAs="a"
                textColor="link"
                onClick={() => onInvoiceRefClick && onInvoiceRefClick()}
                data-tooltip="Numéro de facture renseigné"
              >
                <Icon mr={1}>
                  <BillIcon />
                </Icon>
                {preBilling.invoiceRef}
              </Icon.Text>
            )}
          </Protected>
        </>
      }
    />
  )
}

export default PreBillingPageTitle
