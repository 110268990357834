import React from 'react'
import { Footer as BulmaFooter, Columns, Element, Icon, Message } from 'react-bulma-components'
import { useIsDevEnvironment } from '../../hooks/use-is-dev-environment'
import { Link } from 'react-router-dom'
import Protected from '../protected/protected'
import config from '../../config'
import { AndroidMobileAppIcon, AppleMobileAppIcon } from '../icons'

export const Footer: React.FC = () => {
  const isDevEnvironment = useIsDevEnvironment()
  return (
    <>
      <BulmaFooter textAlign={'center'} textSize={7}>
        <Element>
          <Icon.Text>
            <Columns centered py={3}>
              <Columns.Column narrow py={1}>
                <Link to="/help">Contactez le support</Link>
              </Columns.Column>
              <Columns.Column narrow py={1}>
                <Link to="/privacy-policy">Politique de Confidentialité</Link>
              </Columns.Column>
              <Columns.Column narrow py={1}>
                <Link to="/terms-of-use">Conditions Générales d'Utilisation</Link>
              </Columns.Column>
              <Protected roles={['worker']}>
                <Columns.Column narrow py={1}>
                  <a href={config.mobileApps.apple.store.url}>
                    <Icon>
                      <AppleMobileAppIcon />
                    </Icon>
                  </a>
                </Columns.Column>
                <Columns.Column narrow py={1}>
                  <a href={config.mobileApps.android.store.url}>
                    <Icon>
                      <AndroidMobileAppIcon />
                    </Icon>
                  </a>
                </Columns.Column>
              </Protected>
            </Columns>
          </Icon.Text>
        </Element>
        {isDevEnvironment && (
          <Message mt={3} color={'warning'} display="inline-block">
            <Message.Body textSize={7}>
              Environnement de développement {process.env.APP_VERSION}
            </Message.Body>
          </Message>
        )}
      </BulmaFooter>
    </>
  )
}
