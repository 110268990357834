import React from 'react'
import { Element, Icon } from 'react-bulma-components'
import { Mission } from '../../../api/missions'
import { useUpdateManyMutation } from '../../../queries/work-periods'
import { formatCompleteDateTime } from '../../../utils/date'
import Avatar from '../../avatar/avatar'
import ConfirmationModal, {
  ConfirmationModalBaseProps,
} from '../../confirmation-modal/confirmation-modal'
import { isAfter } from 'date-fns'
import { hasAllWorkPeriodsClocked } from '../../../utils/missions'
import { WarningIcon } from '../../icons'

interface MissionValidateWeekConfirmationProps extends ConfirmationModalBaseProps {
  mission: Mission
  week: number
  year: number
}

const MissionValidateWeekConfirmation: React.FunctionComponent<
  MissionValidateWeekConfirmationProps
> = ({ mission, hide, year, week }) => {
  const mutation = useUpdateManyMutation()

  const workPeriods = mission.workPeriods.filter(w => w.year === year && w.week === week)
  const lastWorkPeriod = workPeriods[workPeriods.length - 1]

  const onConfirm = async () => {
    const workPeriodsToValidate = workPeriods.filter(w => !w.isValidated)

    await mutation.mutateAsync({
      missionId: mission._id,
      workPeriodsIds: workPeriodsToValidate.map(w => w._id),
      isValidated: true,
    })

    hide()
  }

  const hasNotStartedWorkPeriod =
    lastWorkPeriod && isAfter(new Date(lastWorkPeriod.start.date), new Date())

  const hasNotAllWorkPeriodsClocked = !hasAllWorkPeriodsClocked({ ...mission, workPeriods })

  return (
    <ConfirmationModal
      show={Boolean(mission)}
      onConfirm={onConfirm}
      onCancel={hide}
      confirmButtonProps={{ disabled: mutation.isLoading }}
    >
      <Element>
        <Element renderAs="p" mb={3}>
          Valider la semaine {week} : <br />
        </Element>
        {workPeriods[0] && lastWorkPeriod && (
          <Element renderAs="strong">
            <Avatar user={mission.user} />
            <br />
            Du {formatCompleteDateTime(workPeriods[0].start.date)} au{' '}
            {formatCompleteDateTime(lastWorkPeriod.end.date)}
          </Element>
        )}
      </Element>
      {!mission.isCancelled && (hasNotStartedWorkPeriod || hasNotAllWorkPeriodsClocked) && (
        <Element mt={4}>
          <Icon.Text>
            <Icon>
              <WarningIcon />
            </Icon>
            <Element renderAs="span" textWeight="bold">
              {' '}
              Attention
            </Element>
          </Icon.Text>
          {hasNotStartedWorkPeriod && <Element>La semaine n'est pas encore terminée</Element>}
          {hasNotAllWorkPeriodsClocked && (
            <Element>Toutes les journées de travail n'ont pas été pointées</Element>
          )}
        </Element>
      )}
    </ConfirmationModal>
  )
}

export default MissionValidateWeekConfirmation
