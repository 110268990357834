import React, { useState } from 'react'
import { Element, Notification, Block, Modal, Button, Heading } from 'react-bulma-components'
import { ApiErrorData } from '../../../api'
import { CountBack } from '../../../hooks/use-count-back'

export interface FormErrorProps {
  data?: ApiErrorData
  message?: string
  title?: string
  autoClearTimeOut?: number
}

const FormError: React.FunctionComponent<FormErrorProps> = ({
  data,
  message,
  title,
  autoClearTimeOut,
}) => {
  const [isDisplayed, setIsDisplayed] = useState(true)

  return (
    <Modal show={isDisplayed} closeOnEsc closeOnBlur onClose={() => setIsDisplayed(false)}>
      <Modal.Content>
        <Block>
          <Notification color="danger">
            <Heading size={5}>{title || data?.message || 'Error'}</Heading>
            <Element mb={5}>
              {message ? (
                message
              ) : data?.code === 422 ? (
                <Element renderAs="ul">
                  {data?.data?.map((validationError: any, index: number) => (
                    <Element renderAs="li" key={index}>
                      {validationError.message}
                    </Element>
                  ))}
                </Element>
              ) : (
                data?.message
              )}
            </Element>
            <p>
              <Button onClick={() => setIsDisplayed(false)} size="small">
                Fermer{' '}
                {autoClearTimeOut !== undefined && (
                  <CountBack seconds={autoClearTimeOut} cb={() => setIsDisplayed(false)} />
                )}
              </Button>
            </p>
          </Notification>
        </Block>
      </Modal.Content>
    </Modal>
  )
}

export default FormError
