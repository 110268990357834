import { useMemo } from 'react'
import useStore from '../store'

// Calculate pending invitations (organization, worker) for current user

export const usePendingInvitationsCount = (): number => {
  const currentUser = useStore(state => state.session.currentUser)

  const pendingInvitationsCount = useMemo(() => {
    if (!currentUser) return 0
    return (
      currentUser.roles.reduce((acc, role) => {
        if (role.status === 'pending' && role.kind !== 'worker') return acc + 1
        return acc
      }, 0) +
      currentUser.associations.reduce((acc, role) => {
        if (role.status === 'pending') return acc + 1
        return acc
      }, 0)
    )
  }, [currentUser])
  return pendingInvitationsCount
}
