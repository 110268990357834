import React from 'react'
import { Button, Columns, Element, Heading } from 'react-bulma-components'
import { Link } from 'react-router-dom'
import { UserRoleKind } from '../../../../backend/src/services/resources/users/user.model'
import { Employer, InterimAgency } from '../../api/organizations'
import { useOrganizationsQuery } from '../../queries/organizations'
import useStore from '../../store'
import { Organization } from '../../api/organizations'

interface LoginAsProps {
  onSelect(): void
}

const LoginAs: React.FC<LoginAsProps> = ({ onSelect }) => {
  const employersQuery = useOrganizationsQuery<Employer>({ sort: 'name', type: 'employer' })
  const interimAgencyQyery = useOrganizationsQuery<InterimAgency>({
    sort: 'name',
    type: 'interimAgency',
  })

  return (
    <Element mx={3} my={3}>
      <Columns>
        <Columns.Column size={6}>
          <LoginAsList
            organizations={interimAgencyQyery.data || []}
            onSelect={onSelect}
            texts={{
              title: "Agences d'Intérim",
              createNew: "Créer une Agence d'Intérim",
              noResult: "Aucune Agence d'Intérim",
            }}
            createNewPath="/agencies/new"
            role="interimAgencyMember"
          />
        </Columns.Column>
        <Columns.Column size={6}>
          <LoginAsList
            organizations={employersQuery.data || []}
            onSelect={onSelect}
            texts={{
              title: 'Employeurs',
              createNew: 'Créer un Employeur',
              noResult: 'Aucun Employeur',
            }}
            createNewPath="/employer/new"
            role="employerMember"
          />
        </Columns.Column>
      </Columns>
    </Element>
  )
}

const LoginAsList: React.FC<{
  organizations: Organization[]
  onSelect: LoginAsProps['onSelect']
  texts: { noResult: string; createNew: string; title: string }
  createNewPath: string
  role: UserRoleKind
}> = ({ organizations, onSelect, texts, createNewPath, role }) => {
  const loginAs = useStore(state => state.session.loginAs)
  return (
    <>
      <Heading size={5} ml={4}>
        {texts.title}
      </Heading>
      <Element renderAs="ul">
        {organizations[0] ? (
          organizations.map(organization => (
            <Element renderAs="li" key={organization._id as string}>
              <Button
                color="ghost"
                onClick={() => {
                  onSelect()
                  loginAs(role, organization, 'admin')
                }}
              >
                {organization.name}
              </Button>
            </Element>
          ))
        ) : (
          <>
            <Element renderAs="li">
              <Button renderAs={Link} to={createNewPath}>
                {texts.createNew}
              </Button>
            </Element>
            <Element renderAs="li" textColor="grey" textSize={6} ml={4} mt={3}>
              {texts.noResult}
            </Element>
          </>
        )}
      </Element>
    </>
  )
}

export default LoginAs
