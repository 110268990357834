import React from 'react'
import {
  Button,
  Card,
  Columns,
  Container,
  Element,
  Heading,
  Level,
  Section,
  Tag,
} from 'react-bulma-components'
import { useParams } from 'react-router-dom'
import Layout from '../../components/layout/layout'
import { findWorkPeriodById } from '../../utils/missions'
import Error404 from '../../components/errors/404'
import { useMissionQuery } from '../../queries/missions'
import Breadcrumbs from '../../components/breadcrumbs/breadcrumbs'
import WorkPeriodDatesForm from '../../components/work-periods/work-period-dates-form'
import WorkPeriodMessages from '../../components/work-periods/work-period-messages'
import WorkPeriodActions from '../../components/work-periods/work-period-actions'
import { Link } from 'react-router-dom'
import WorkPeriodPageTitle from '../../components/work-periods/titles/work-period-page-title'
import { WorkPeriodPageNavigation } from '../../components/work-periods/pages/work-period-page-navigation'
import WorkPeriodEventsForm from '../../components/work-periods/work-period-events-form'
import Protected from '../../components/protected/protected'
import Modal, { useModal } from '../../components/modal'
import WorkPeriodDateToDateEventForm from '../../components/work-periods/work-period-date-to-date-event-form'
import { useMutationState } from '../../queries'
import { useUpdateManyMutationKey } from '../../queries/work-periods'
import ClockingRulesDescription from '../../components/clocking-rules/clocking-rules-description'
import ClockingRulesBreakDescription from '../../components/clocking-rules/clocking-rules-break-description'
import WorkPeriodClockings from '../../components/work-periods/work-period-clockings'

const WorkPeriodDetails: React.FunctionComponent = () => {
  //Get mission and work period
  const { missionId, workPeriodId } = useParams()
  const { data: mission, status } = useMissionQuery(missionId)
  const workPeriod = workPeriodId && mission && findWorkPeriodById(mission, workPeriodId)
  const [showClockingRules, setShowClockingRules] = React.useState(true)

  const workPeriodsEventsModal = useModal()
  const updateManyWorkPeriodsMutationState = useMutationState(useUpdateManyMutationKey())

  React.useEffect(() => {
    if (updateManyWorkPeriodsMutationState?.status === 'success')
      workPeriodsEventsModal.setIsDisplayed(false)
  }, [updateManyWorkPeriodsMutationState?.status])

  const breadcrumbs = React.useMemo(
    () => [
      {
        label: 'Récapitulatif',
        to: '/summary',
      },
      {
        label: 'Journée de travail',
      },
    ],
    [],
  )

  if (!workPeriod && status !== 'loading') return <Error404 />
  //TODO: Suspense?
  else if (!workPeriod) return <></>

  return (
    <Layout>
      <Section>
        <Container breakpoint="desktop" max={true}>
          <Level>
            <Level.Side>
              <Breadcrumbs items={breadcrumbs} />
            </Level.Side>
            <Level.Side style={{ minWidth: 250 }} display="block" align="right">
              <WorkPeriodPageNavigation mission={mission} workPeriodId={workPeriodId} />
            </Level.Side>
          </Level>
          <Level alignItems="flex-start" mb={6}>
            <Level.Side>
              <WorkPeriodPageTitle mission={mission} workPeriod={workPeriod}></WorkPeriodPageTitle>
            </Level.Side>
            <Level.Side align="right">
              <Tag.Group>
                <WorkPeriodMessages workPeriod={workPeriod} type="tag" />
              </Tag.Group>
            </Level.Side>
          </Level>
          <Element mb={5}></Element>
          {mission && workPeriod && (
            <Element mb={5}>
              <Heading size={4}>Horaire de la Journée de Travail</Heading>
              <Card>
                <Card.Content>
                  <WorkPeriodDatesForm mission={mission} workPeriod={workPeriod} />
                </Card.Content>
              </Card>
              <Element textAlign={'right'} mt={2}>
                <Link to={`/missions/${mission._id}`}>Voir la mission</Link>
              </Element>
              <Heading size={4} mt={2}>
                Pointages
              </Heading>
              {workPeriod.start.clocking && (
                <Card>
                  <Card.Content>
                    <Element px={3}>
                      <WorkPeriodClockings workPeriod={workPeriod} />
                    </Element>
                  </Card.Content>
                </Card>
              )}
              <Card mt={4}>
                <Card.Content>
                  <Element px={3}>
                    {showClockingRules && (
                      <Columns>
                        <Columns.Column size={6}>
                          <Heading size={6} mb={1}>
                            Entrée
                          </Heading>
                          <ClockingRulesDescription
                            clockingRules={workPeriod.clockingRules}
                            type="start"
                          />
                        </Columns.Column>
                        <Columns.Column size={6}>
                          <Heading size={6} mb={1}>
                            Sortie
                          </Heading>
                          <ClockingRulesDescription
                            clockingRules={workPeriod.clockingRules}
                            type="end"
                          />
                        </Columns.Column>
                        <Columns.Column>
                          <Heading size={6} mb={1}>
                            Pauses
                          </Heading>
                          <ClockingRulesBreakDescription clockingRules={workPeriod.clockingRules} />
                        </Columns.Column>
                      </Columns>
                    )}
                    {/*<Element
                      renderAs="a"
                      textSize="7"
                      onClick={() => setShowClockingRules(!showClockingRules)}
                    >
                      {showClockingRules ? 'Masquer' : 'Afficher'} les règles de pointage
                    </Element> */}
                  </Element>
                </Card.Content>
              </Card>
            </Element>
          )}
          <Protected
            roles={['employerMember']}
            action={{ resource: 'missions', name: 'updateWorkPeriodEvents' }}
          >
            <Element mb={5}>
              <Heading size={4}>Évènements</Heading>
              <Card>
                <Card.Content>
                  <WorkPeriodEventsForm mission={mission} workPeriod={workPeriod} />
                  <Button mt={4} onClick={() => workPeriodsEventsModal.setIsDisplayed(true)}>
                    Ajouter un évènement de date à date
                  </Button>
                </Card.Content>
              </Card>
              <Modal actions={workPeriodsEventsModal} title={'Ajouter un évènement de date à date'}>
                <WorkPeriodDateToDateEventForm
                  mission={mission}
                  defaultFromDate={new Date(workPeriod.start.date)}
                />
              </Modal>
            </Element>
          </Protected>
          <Element mt={5}>
            <Heading size={4}>Actions</Heading>
            {mission && workPeriod && (
              <Card>
                <Card.Content>
                  <Element>
                    <WorkPeriodActions mission={mission} workPeriod={workPeriod} />
                  </Element>
                </Card.Content>
              </Card>
            )}
          </Element>
        </Container>
      </Section>
    </Layout>
  )
}

export default WorkPeriodDetails
