import React from 'react'
import { format, isWithinInterval } from 'date-fns'
import { Heading } from 'react-bulma-components'
import { CalendarWeek, useCalendarContext } from '../../../../hooks/use-calendar'

export const MonthDelimiter = <T, M>({
  calendarWeek,
}: {
  calendarWeek: CalendarWeek<T, M>
}): JSX.Element => {
  const { startDate } = useCalendarContext()
  const firstDay = calendarWeek.find(d => d) // first occurence which is non null
  const lastDay = calendarWeek[calendarWeek.length - 1]
  if (!firstDay) return <></>
  if (!lastDay) return <></>

  const isFirstCalendarWeek = isWithinInterval(new Date(startDate), {
    start: new Date(firstDay.isoDate),
    end: new Date(lastDay.isoDate),
  })
  const isNextMonth = firstDay.month !== lastDay.month || firstDay.day === 1

  if (isNextMonth || isFirstCalendarWeek)
    return (
      <tr>
        <td colSpan={8}>
          <Heading my={3} size={3}>
            {' '}
            {format(new Date(lastDay.isoDate), 'MMMM yyyy')}
          </Heading>
        </td>
      </tr>
    )
  return <></>
}
