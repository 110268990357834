import React from 'react'
import { Element, Heading, Modal, Notification } from 'react-bulma-components'

export interface FormProgessProps {
  body?: string | React.ReactElement
}

const FormProgess: React.FunctionComponent<FormProgessProps> = ({ body }) => (
  <Modal show={true}>
    <Modal.Content>
      <Notification color="warning">
        <Heading size={5}>Envoi en cours...</Heading>
        {body && <Element mb={5}>{body}</Element>}
      </Notification>
    </Modal.Content>
  </Modal>
)

export default FormProgess
