import { useMemo } from 'react'
import useStore from '../store'
import { Employer, InterimAgency } from '../api/organizations'

// Calculate pending associations invitation for an Employer

export const usePendingEmployerInvitationsCount = (): number => {
  const currentOrganization = useStore(state => state.session.currentOrganization) as
    | Employer
    | InterimAgency

  const pendingEmployerInvitationsCount = useMemo(() => {
    if (!currentOrganization || currentOrganization.type !== 'employer') return 0
    const organization = currentOrganization as Employer
    return organization.associations.reduce((acc, association) => {
      if (association.status === 'pending') return acc + 1
      return acc
    }, 0)
  }, [currentOrganization])
  return pendingEmployerInvitationsCount
}
