import { isValid } from 'date-fns'
import { upperFirst } from 'lodash'
import React from 'react'
import { Element, Heading, Icon } from 'react-bulma-components'
import { formatCompleteDateTime } from '../../utils/date'
import { ArrowRightIcon } from '../icons'
import { HistoryItem, HistoryLocales } from './history'

interface HistoryChangeProps {
  change: HistoryItem['changes'][0]
  locales?: HistoryLocales
}

const displayChange = (value?: string) =>
  React.useMemo(() => {
    if (value && isNaN(parseInt(value)) && isValid(new Date(value)))
      return upperFirst(formatCompleteDateTime(value))
    switch (value) {
      case 'true':
        return 'Oui'
      case 'false':
        return 'Non'
      default:
        return value
    }
  }, [value])

const HistoryChange: React.FC<HistoryChangeProps> = ({ change, locales }) => {
  const iconParentElement = React.useMemo<'span' | 'div'>(() => {
    if ((change.oldValue?.length || 0) >= 30 || (change.newValue?.length || 0) >= 30) return 'div'
    return 'span'
  }, [change])

  return (
    <Element key={change._id} mt={3} ml={3}>
      <Heading size={6} mb={3}>
        {locales?.changesTitle?.[change.attribute] || change.attribute}
      </Heading>
      <Element style={{ whiteSpace: 'pre-line' }}>
        {change.oldValue && (
          <Element renderAs={iconParentElement}>
            {displayChange(change.oldValue)}{' '}
            {change.newValue && (
              <>
                <Icon style={{ verticalAlign: 'top' }}>
                  <ArrowRightIcon />
                </Icon>{' '}
              </>
            )}
          </Element>
        )}
        {displayChange(change.newValue)}
      </Element>
    </Element>
  )
}

export default HistoryChange
