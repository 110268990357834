import { cloneDeep } from 'lodash'
import { MissionFormValues } from '../../missions/mission-form'
import { addDays, differenceInDays, isBefore, subDays } from 'date-fns'
import { formatISODate } from '../../../utils/date'

export const updateWorkPeriod = (
  workPeriods: MissionFormValues['workPeriods'],
  isoDate: string,
  value: Partial<MissionFormValues['workPeriods'][0]>,
): MissionFormValues['workPeriods'] => {
  const existingWorkPeriodIndex = getExistingWorkPeriodIndex(workPeriods, isoDate)
  const existingWorkPeriod =
    existingWorkPeriodIndex === -1 ? undefined : cloneDeep(workPeriods[existingWorkPeriodIndex])

  const newWorkPeriod: Partial<MissionFormValues['workPeriods'][number]> = existingWorkPeriod || {}

  const startDate = value.start?.date
  const endDate = value.end?.date

  if (startDate) {
    newWorkPeriod.start = { date: startDate }
  }
  if (endDate) {
    newWorkPeriod.end = { date: endDate }
  }
  if (newWorkPeriod.end?.date && newWorkPeriod.start?.date) {
    if (isBefore(newWorkPeriod.end.date, newWorkPeriod.start.date)) {
      newWorkPeriod.end = { date: addDays(newWorkPeriod.end.date, 1) }
    }
    if (differenceInDays(newWorkPeriod.end.date, newWorkPeriod.start.date) > 0) {
      newWorkPeriod.end = { date: subDays(newWorkPeriod.end.date, 1) }
    }
  }
  const newWorkPeriods = cloneDeep(workPeriods)
  if (existingWorkPeriod) {
    newWorkPeriods[existingWorkPeriodIndex] = newWorkPeriod
  } else {
    newWorkPeriods.push(newWorkPeriod)
  }
  return newWorkPeriods.sort((a, b) => {
    const wP1Date = a.start?.date ?? a.end?.date
    const wP2Date = b.start?.date ?? b.end?.date

    return wP1Date!.getTime() - wP2Date!.getTime()
  })
}

export const removeWorkPeriod = (
  workPeriods: MissionFormValues['workPeriods'],
  isoDate: string,
): MissionFormValues['workPeriods'] => {
  const newWorkPeriods = cloneDeep(workPeriods)

  return newWorkPeriods
    .filter(wP => {
      const wPDate = wP.start?.date ?? wP.end?.date
      const wPDateISO = formatISODate(new Date(wPDate!))
      if (wPDateISO === isoDate) return false
      return true
    })
    .sort((a, b) => {
      const wP1Date = a.start?.date ?? a.end?.date
      const wP2Date = b.start?.date ?? b.end?.date
      return wP1Date!.getTime() - wP2Date!.getTime()
    })
}

export const updateWorkPeriods = (
  workPeriods: MissionFormValues['workPeriods'],
  isoDates: string[],
  values: Partial<MissionFormValues['workPeriods']>,
): MissionFormValues['workPeriods'] => {
  let newWorkPeriods: MissionFormValues['workPeriods'] = cloneDeep(workPeriods)
  let index = 0
  for (const isoDate of isoDates) {
    const value = values[index]
    if (value === undefined) {
      newWorkPeriods = removeWorkPeriod(newWorkPeriods, isoDate)
    } else {
      newWorkPeriods = updateWorkPeriod(newWorkPeriods, isoDate, value)
    }
    index++
  }
  return newWorkPeriods
}

const getExistingWorkPeriodIndex = (
  workPeriods: MissionFormValues['workPeriods'],
  isoDate: string,
): number => {
  const existingWorkPeriodIndex = workPeriods.findIndex(wP => {
    const date = wP.start?.date ?? wP.end?.date
    if (!date) return false
    const wPDateISO = formatISODate(new Date(date))
    return wPDateISO === isoDate
  })
  return existingWorkPeriodIndex
}

export const getExistingWorkPeriod = (
  workPeriods: MissionFormValues['workPeriods'],
  isoDate: string,
): MissionFormValues['workPeriods'][0] | undefined => {
  const index = getExistingWorkPeriodIndex(workPeriods, isoDate)
  if (index === undefined) return undefined
  return workPeriods[index]
}

const getExistingWorkPeriods = (
  workPeriods: MissionFormValues['workPeriods'],
  isoDates: string[],
): MissionFormValues['workPeriods'] => {
  const existingWorkPeriods = isoDates
    .map(isoDate => {
      const index = getExistingWorkPeriodIndex(workPeriods, isoDate)
      if (index === -1) return undefined
      return workPeriods[index]
    })
    .filter((wP): wP is MissionFormValues['workPeriods'][0] => !!wP)
  return existingWorkPeriods
}
