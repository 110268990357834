import React from 'react'
import Layout from '../../components/layout/layout'
import { Container, Heading, Section } from 'react-bulma-components'

export const HelpPage: React.FC = () => {
  return (
    <Layout>
      <Section size="medium">
        <Container max={true} breakpoint={'desktop'}>
          <Heading textColor="primary">Un problème ?</Heading>
          Contactez le support via <a href="mailto:support@team-tim.fr">support@team-tim.fr</a>
        </Container>
      </Section>
    </Layout>
  )
}
