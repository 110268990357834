import React from 'react'

const FormikObserver = <Values,>({
  onChange,
  values,
}: {
  onChange?(values: Values): void
  values: Values
}): null => {
  React.useEffect(() => {
    onChange && onChange(values)
  }, [values])
  return null
}

export default FormikObserver
