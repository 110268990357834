import React from 'react'
import { Column } from 'react-table'
import { Link } from 'react-router-dom'
import { Employer, InterimAgency } from '../../api/organizations'
import Table from '../table'
import RoleStatus from '../users/role-status'
import { formatCompleteDate } from '../../utils/date'
import { isAuthorizedFor } from '../protected/protected'
import { compareDocsById } from '../../utils/documents'

interface AssociationsTableProps {
  organizations: Employer[]
  interimAgency: InterimAgency
}

/**
 * Composant affichant la liste des associations entre entreprises utilisatrices et agences d'intérim
 */
const AssociationsTable: React.FC<AssociationsTableProps> = ({ organizations, interimAgency }) => {
  const tableColumns = useListTableColumns(organizations, interimAgency)

  return (
    <Table
      columns={tableColumns}
      data={organizations.filter(organization =>
        organization?.associations?.some(association => {
          const interimAgency = association.interimAgency as InterimAgency
          return interimAgency && interimAgency._id === interimAgency._id
        }),
      )}
      noDataMessage="Aucune EU à afficher"
    />
  )
}

export default AssociationsTable

// Hook déplacé dans le même fichier
const useListTableColumns = (organizations: Employer[], interimAgency: InterimAgency): Column[] => {
  const getAssociation = (employer: Employer): Employer['associations'][0] | undefined => {
    return employer.associations.find(association => {
      return compareDocsById(association.interimAgency, interimAgency)
    })
  }
  return React.useMemo(
    () =>
      [
        {
          Header: 'Nom',
          accessor: 'name',
          Cell: (data: any) => {
            const organization: Employer = data.cell.row.original
            return isAuthorizedFor(['interimAgencyMember']) ? (
              <Link to={`/employers/${organization._id}`}>{organization.name}</Link>
            ) : (
              organization.name
            )
          },
        },
        {
          Header: "Statut de l'invitation",
          accessor: 'status',
          Cell: (data: any) => {
            const association = getAssociation(data.cell.row.original)
            console.log('association', association)
            //TODO: refactor component and type

            return association ? <RoleStatus role={association} showDate /> : null
          },
        },
        {
          Header: 'Invité le',
          accessor: 'createdAt',
          Cell: (data: any) => {
            const association = getAssociation(data.cell.row.original)
            return formatCompleteDate(association?.createdAt)
          },
        },
      ] as Column[],
    [organizations],
  )
}
