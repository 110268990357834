import React from 'react'
import { WorkersRequest } from '../../../api/workers-requests'
import { formatCompleteDate } from '../../../utils/date'
import PageTitle from '../../pages/page-title'

interface WorkersRequestFormTitleProps {
  workersRequest?: WorkersRequest
  isNew?: boolean
}

const WorkersRequestFormTitle: React.FC<WorkersRequestFormTitleProps> = ({
  workersRequest,
  isNew,
}) => {
  return (
    <PageTitle
      title={isNew ? `Nouvelle Demande d'Intérimaires` : `Demande d'Intérimaires`}
      subTitle={workersRequest && workersRequest.employer.name}
      subTitleLight={
        workersRequest &&
        `du ${formatCompleteDate(workersRequest.missionsStart)} au ${formatCompleteDate(
          workersRequest.missionsEnd,
        )}`
      }
    />
  )
}

export default WorkersRequestFormTitle
