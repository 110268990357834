import produce from 'immer'
import { StoreSlice, StoreState } from '.'
import {
  UserEmployerMemberRole,
  UserOrganizationRole,
  UserRole,
  UserRoleKind,
} from '../../../backend/src/services/resources/users/user.model'
import { Organization } from '../api/organizations'
import { User } from '../api/users'

export type AppUserRole = UserRoleKind | 'guest'

export interface SessionSlice {
  isNetworkOnError: boolean
  isAuthenticated: boolean
  isInitialized: boolean
  isSuperAdmin: boolean
  currentUser?: User
  currentUserRole: AppUserRole
  currentOrganization?: Organization
  currentOrganizationRole?: UserOrganizationRole | UserEmployerMemberRole
  jwt?: string
  initializeSession(
    user?: User,
    jwt?: string,
    role?: SessionSlice['currentUserRole'],
    organization?: Organization,
    organizationRole?: UserOrganizationRole | UserEmployerMemberRole,
  ): void
  logout(): void
  changeCurrentRole(role: UserRole): void
  loginAs(
    role: UserRoleKind,
    organization?: Organization,
    organizationRole?: UserOrganizationRole,
  ): void
  loggedAs?: {
    role?: SessionSlice['currentUserRole']
    organization?: Organization
  }
}

const sessionSlice: StoreSlice<SessionSlice> = (set, get) => ({
  isNetworkOnError: false,
  isAuthenticated: false,
  isInitialized: false,
  isSuperAdmin: false,
  currentUser: undefined,
  currentUserRole: 'guest',
  currentOrganization: undefined,
  jwt: undefined,
  initializeSession: (
    user?: User,
    jwt?: string,
    role?: SessionSlice['currentUserRole'],
    organization?: Organization,
    organizationRole?: UserOrganizationRole,
  ) => {
    set(
      produce((state: StoreState) => {
        state.session.isInitialized = true
        state.session.isAuthenticated = Boolean(user && jwt)
        state.session.isSuperAdmin = Boolean(
          user && user.roles.some(role => role.kind === 'superAdmin'),
        )
        state.session.currentUser = user
        state.session.currentUserRole = role || 'guest'
        state.session.jwt = jwt
        state.session.currentOrganization = organization
        state.session.currentOrganizationRole = organizationRole
        if (!user) delete state.session.loggedAs
      }),
    )
  },
  logout: () => {
    get().session.initializeSession()
  },
  changeCurrentRole: role => {
    set(
      produce((state: StoreState) => {
        const { kind } = role
        state.session.currentUserRole = role.kind
        if (kind === 'superAdmin' || kind === 'worker') {
          state.session.currentOrganization = undefined
          state.session.currentOrganizationRole = undefined
        } else {
          state.session.currentOrganization = role.organization as Organization
          state.session.currentOrganizationRole = 'admin'
        }
        delete state.session.loggedAs
      }),
    )
  },
  loginAs: (role, organization, organizationRole) => {
    const { session } = get()
    if (session.isSuperAdmin) {
      get().session.initializeSession(
        session.currentUser,
        session.jwt,
        role,
        organization,
        organizationRole,
      )
      set(
        produce((state: StoreState) => {
          const { session } = get()
          state.session.loggedAs = {
            role: session.currentUserRole,
            organization: session.currentOrganization,
          }
        }),
      )
    }
  },
})

export default sessionSlice
