import { useConfigQuery } from '../queries/config'
import useStore from '../store'

type Status = 'upToDate' | 'outdated'

const useUserLegalConditionsVersionsStatus = (): {
  termsOfUse: Status
  privacyPolicy: Status
} => {
  const currentUser = useStore(state => state.session.currentUser)
  const { data: config } = useConfigQuery()

  const getStatus = (userValue?: string, configValue?: string) =>
    !userValue || !configValue || userValue === configValue ? 'upToDate' : 'outdated'

  return {
    termsOfUse: getStatus(currentUser?.lastTermsOfUseVersionRead, config?.versions.termsOfUse),
    privacyPolicy: getStatus(
      currentUser?.lastPrivacyPolicyVersionRead,
      config?.versions.privacyPolicy,
    ),
  }
}

export default useUserLegalConditionsVersionsStatus
