import React from 'react'
import useStore from '../../store'
import { SessionSlice } from '../../store/session'
import { CrudAction, CrudResource } from '../../api/users'

export interface ProtectedAction {
  resource: CrudResource
  name: CrudAction
}

interface ProtectedProps {
  roles: (
    | SessionSlice['currentUserRole']
    | {
        role: SessionSlice['currentUserRole']
        action: ProtectedAction
      }
  )[]
  action?: ProtectedAction
  atLeastOneAction?: ProtectedAction[]
  children: React.ReactNode
}

export const isAuthorizedFor = (
  roles: ProtectedProps['roles'],
  action?: ProtectedProps['action'],
  atLeastOneAction?: ProtectedProps['atLeastOneAction'],
): boolean => {
  const currentUserRole = useStore.getState().session.currentUserRole
  const currentOrganization = useStore.getState().session.currentOrganization
  const currentUser = useStore.getState().session.currentUser

  const userOrganizationRole =
    currentOrganization &&
    currentUser?.roles.find(r => r.organization?._id === currentOrganization._id)

  const atLeastOneAuthorizedAction =
    !atLeastOneAction ||
    !userOrganizationRole?.crudActions ||
    atLeastOneAction.some(
      action => userOrganizationRole.crudActions?.[action?.resource]?.[action.name],
    )
  const roleKinds: SessionSlice['currentUserRole'][] = roles.map(r =>
    typeof r === 'string' ? r : r.role,
  )

  return Boolean(
    roles &&
      roleKinds.includes(currentUserRole) &&
      (!action ||
        !userOrganizationRole?.crudActions ||
        userOrganizationRole.crudActions?.[action?.resource]?.[action.name]) &&
      atLeastOneAuthorizedAction,
  )
}

export const isProtectedFor = (
  roles: ProtectedProps['roles'],
  action?: ProtectedProps['action'],
  atLeastOneAction?: ProtectedProps['atLeastOneAction'],
): boolean => !isAuthorizedFor(roles, action, atLeastOneAction)

const Protected: React.FC<ProtectedProps> = ({ roles, children, action, atLeastOneAction }) => (
  <>{isAuthorizedFor(roles, action, atLeastOneAction) && children}</>
)

export default Protected
