import produce from 'immer'
import { StoreSlice, StoreState } from '.'

export interface AppSlice {
  appNeedsUpdate: boolean
  setAppNeedsUpdate(needsUpdate: boolean): void
}

const appSlice: StoreSlice<AppSlice> = set => ({
  appNeedsUpdate: false,
  setAppNeedsUpdate: needsUpdate => {
    set(
      produce((state: StoreState) => {
        state.app.appNeedsUpdate = needsUpdate
      }),
    )
  },
})

export default appSlice
