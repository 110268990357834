import { differenceInHours, getMinutes } from 'date-fns'
import React from 'react'
import { Element } from 'react-bulma-components'

interface DurationProps {
  duration?: number
}

const Duration: React.FC<DurationProps> = ({ duration }) => {
  return (
    <Element renderAs="span">
      {duration
        ? `${String(differenceInHours(duration, new Date(0))).padStart(2, '0')}h${String(
            getMinutes(duration),
          ).padStart(2, '0')}`
        : '--:--'}
    </Element>
  )
}

export default Duration
