import { cloneDeep } from 'lodash'
import { ClockingRules, ClockingRulesCreationPayload } from '../api/clocking-rules'

// TODO : Protect this from the backend too !
export const minTolerance = 1
export const maxTolerance = 60

export const convertClockingRulesTolerances = (
  clockingRules: ClockingRulesCreationPayload,
  converter: (tolerance?: number) => number,
): ClockingRulesCreationPayload => {
  // Immutability, we don't transform foreign data
  const converted = cloneDeep(clockingRules)
  const { start, end } = converted.rules
  start.early.tolerance = converter(start.early.tolerance)
  start.late.tolerance = converter(start.late.tolerance)
  end.early.tolerance = converter(end.early.tolerance)
  end.late.tolerance = converter(end.late.tolerance)
  return converted
}

export const toleranceInMillisecondsToMinutes = (tolerance?: number): number => {
  return tolerance ? tolerance / 60 / 1000 : 0
}
export const toleranceInMinutesToMilliseconds = (tolerance?: number): number =>
  tolerance ? tolerance * 60 * 1000 : 0

const defaultInitialValues: ClockingRulesCreationPayload = {
  name: '',
  description: '',
  rules: {
    start: {
      early: {
        mode: 'theoritical',
        tolerance: minTolerance,
      },
      late: {
        mode: 'theoritical',
        tolerance: minTolerance,
      },
    },
    end: {
      early: {
        mode: 'theoritical',
        tolerance: minTolerance,
      },
      late: {
        mode: 'theoritical',
        tolerance: minTolerance,
      },
    },
  },
  break: {
    breakAllowedAfter: 4 * 60,
    breakDuration: 30,
    clockingsTolerance: {
      early: 0,
      late: 0,
    },
  },
}

export const buildInitialValues = (clockingRules?: ClockingRules): ClockingRulesCreationPayload => {
  let existingClockingRules: ClockingRulesCreationPayload | undefined
  // We are editing an existing Clocking Rule
  if (clockingRules) {
    // No need for employer and _id
    const { employer, _id, ...rest } = clockingRules //eslint-disable-line
    // Immutability, we don't transform foreign data
    existingClockingRules = cloneDeep(rest)
    // Clocking Rule object has time in milliseconds, the forms works with minutes
    existingClockingRules = convertClockingRulesTolerances(
      existingClockingRules,
      toleranceInMillisecondsToMinutes,
    )
  }

  const initialValues: ClockingRulesCreationPayload = Object.assign(
    {},
    // Immutability, we don't transform foreign data
    cloneDeep(defaultInitialValues),
    existingClockingRules,
  )

  return initialValues as ClockingRulesCreationPayload
}
