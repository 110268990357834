import React, { useMemo } from 'react'
import { Column } from 'react-table'
import Table from '../table'
import { formatPreciseCost } from '../../utils/finance'
import { AppSubscriptionPeriod } from '../../api/app-subscription-periods'

interface TableRow {
  name: string
  unitPrice: number | undefined
  unitType: string
  quantity: number | undefined
  subtotal: number | undefined
  discountPercentage: number | undefined
  total: number
}

interface AppSubscriptionPeriodTableProps {
  period: AppSubscriptionPeriod
}

/**
 * Tableau détaillant les tarifs et coûts d'une période d'abonnement
 */
const AppSubscriptionPeriodTable: React.FC<AppSubscriptionPeriodTableProps> = ({ period }) => {
  const columns = useMemo<Column<TableRow>[]>(() => {
    const baseColumns: Column<TableRow>[] = [
      {
        Header: 'Tarifs',
        accessor: 'name',
      },
      {
        Header: 'Prix unitaire HT',
        accessor: 'unitPrice',
        Cell: ({ value }) => (value ? formatPreciseCost(value) : ''),
      },
      {
        Header: 'Unité',
        accessor: 'unitType',
      },
      {
        Header: 'Unité',
        accessor: 'quantity',
      },
      {
        Header: 'Total HT',
        accessor: 'subtotal',
        Cell: ({ value }) => (value ? formatPreciseCost(value) : ''),
      },
      {
        Header: 'Total HT',
        accessor: 'total',
        Cell: ({ value }) => (value ? formatPreciseCost(value) : '0€'),
      },
    ]

    // Vérifier si au moins une remise est attribuée
    const hasDiscount = Boolean(
      period.pricing.tiers.some(tier => tier.discountPercentage) ||
        period.pricing.discountPercentage,
    )

    // Ajouter la colonne de remise si nécessaire
    if (hasDiscount) {
      baseColumns.splice(5, 0, {
        Header: '% de remise',
        accessor: 'discountPercentage',
        Cell: ({ value }) => (value ? `${value}%` : ''),
      })
    }

    return baseColumns
  }, [period])

  const tableData = useMemo(() => {
    const rows: TableRow[] = [
      {
        name: "Licence d'utilisation par EU",
        unitPrice: period.pricing.licenseFee,
        unitType: 'Semaine',
        quantity: 4,
        subtotal: period.fees.totalLicenseFees,
        discountPercentage: period.pricing.discountPercentage,
        total: period.fees.discountedLicenseFees,
      },
      ...period.pricing.tiers.map((tier, index) => ({
        name: `Utilisation de ${tier.workersRange.min} à ${tier.workersRange.max || '∞'} salariés`,
        unitPrice: tier.workPeriodCost,
        unitType: 'Salarié / jour',
        quantity: period.fees.tierTotals[index]?.workPeriodsCount || 0,
        subtotal: period.fees.tierTotals[index]?.totalCost || 0,
        discountPercentage: period.pricing.tiers[index]?.discountPercentage,
        total: period.fees.tierTotals[index]?.discountedCost || 0,
      })),
      {
        name: 'Total HT',
        unitPrice: undefined,
        unitType: '',
        quantity: undefined,
        subtotal: period.fees.subtotal,
        discountPercentage: undefined,
        total: period.fees.total,
      },
      {
        name: 'Total TTC',
        unitPrice: undefined,
        unitType: '',
        quantity: undefined,
        subtotal: period.fees.subtotal * 1.2,
        discountPercentage: undefined,
        total: period.fees.total * 1.2,
      },
    ]

    return rows
  }, [period])

  return <Table columns={columns} data={tableData} className="is-bordered" />
}

export default AppSubscriptionPeriodTable
