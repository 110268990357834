import React from 'react'
import { Columns } from 'react-bulma-components'

interface FieldColumnProps {
  children: React.ReactNode
}

const FieldColumn: React.FC<FieldColumnProps> = ({ children }) => {
  return (
    <Columns.Column narrow style={{ minWidth: 220 }}>
      {children}
    </Columns.Column>
  )
}

export default FieldColumn
