import React, { ReactElement, ReactNode } from 'react'
import { Field } from 'formik'
import { MissionFormValues } from '../../missions/mission-form'

import {
  Clipboard as ClipboardType,
  ClipboardProvider,
  useClipboardContext,
} from '../../../hooks/use-clipboard'
import { UseCalendar, CalendarProvider, IsoTime, IsoDate } from '../../../hooks/use-calendar'
import { calculateWorkPeriodDuration } from '../../../utils/work-periods'
import { WeeksList } from './calendar/weeks-list'
import { Week } from './calendar/week'
import { Day } from './calendar/day'
import { Button, Columns, Element, Icon } from 'react-bulma-components'
import { dateToTimeInputValue, formatISODate, timeInputValueToDate } from '../../../utils/date'
import { PasteButton } from '../../clipboard/paste-button'
import { CopyButton } from '../../clipboard/copy-button'
import { ClipboardBaseButton } from '../../clipboard/clipboard-base-button'
import { RemoveIcon } from '../../icons'
import {
  getExistingWorkPeriod,
  removeWorkPeriod,
  updateWorkPeriod,
  updateWorkPeriods,
} from './utils'
import { WorkPeriodEditor, WorkPeriodEditorFormErrors } from './work-period-editor'
import InputField from '../../form/fields/input'
import { WorkPeriodsDuration } from '../work-periods-duration'
import { MissionWeekDurationStatus } from '../../missions/mission-week-duration'
import { Mission } from '../../../api/missions'
import { isBefore } from 'date-fns'
import { FormFieldErrorMessage } from '../../form/fields/form-field'
import { WorkPeriod } from '../../../api/work-periods'
import { WorkersRequest } from '../../../api/workers-requests'
import { useJobTitlesQuery } from '../../../queries/job-titles'

interface WorkPeriodsEditorMeta {
  meta: {
    startDate: string // YYYY-mm-dd
    endDate: string // YYYY-mm-dd
    resource?: Mission | WorkersRequest
  }
}

type WorkPeriodsEditorWorkPeriodsValue = Array<
  {
    start?: { date: Date }
    end?: { date: Date }
  } & Partial<Omit<WorkPeriod, 'start' | 'end'>>
>

type CalendarData = MissionFormValues['workPeriods'][0]
type CalendarMetaData = { duration: number; isDisabled: boolean }

/**
 * New Mission
 * FormState -> Internal State -> Form Input \
 *    ^---------------------- onChange ------|
 *
 * Update Mission
 * Mission -> FormState -> Internal State -> Form Input \
 *    ^                          ^----- onChange -------|
 *    |------------------------------------- Save ------|
 */

export const isResourceDisabled = <Resource extends Mission | WorkersRequest>(
  resource?: Resource,
): boolean => {
  if (!resource) return false
  if (resource.__actions.canBeUpdated) return false
  return true
}

export const isWorkPeriodDisabled = <Resource extends Mission | WorkersRequest>(
  isoDate: IsoDate | null,
  resource?: Resource,
  workPeriod?: WorkPeriodsEditorWorkPeriodsValue[number],
): boolean => {
  if (isoDate === null) return true // out of range cell
  // if no mission we are in a create state,
  // Nothing is disabled
  if (!resource) return false
  // Here starts update state
  if (!workPeriod) {
    // Cannot update in the past, at all
    if (isBefore(new Date(isoDate), new Date())) return true
  } else {
    if (workPeriod.__actions?.canBeUpdated === false) return true

    if (workPeriod.start?.date) {
      // All workPeriods started or in the past cannot be edited
      // Without this, days without work periods can be added
      // Because, by definition those work periods doesn't exist
      // And don't have a canBeUpdated status
      if (isBefore(new Date(workPeriod.start.date), new Date())) return true
    }
  }

  return false
}

export const WorkPeriodsEditor = ({
  workPeriods,
  jobTitle,
  initialWorkPeriods,
  resource,
  user,
  setWorkPeriods,
  errors,
  startDate,
  endDate,
}: {
  workPeriods: WorkPeriodsEditorWorkPeriodsValue
  jobTitle?: string
  initialWorkPeriods: WorkPeriodsEditorWorkPeriodsValue
  resource?: Mission | WorkersRequest
  user?: string
  setWorkPeriods: (wPs: WorkPeriodsEditorWorkPeriodsValue) => void
  errors: WorkPeriodEditorFormErrors
  startDate: WorkPeriodsEditorMeta['meta']['startDate']
  endDate: WorkPeriodsEditorMeta['meta']['endDate']
}): ReactNode => {
  const jobTitlesQuery = useJobTitlesQuery()
  const currentClockingRule = jobTitle
    ? jobTitlesQuery.data?.find(jT => jobTitle.toString() === jT._id.toString())?.clockingRule
    : undefined

  const dateRangeError = Boolean(errors.meta?.startDate) || Boolean(errors.meta?.endDate)
  const minEndDate = resource ? formatISODate(new Date()) : undefined

  return (
    <CalendarProvider<CalendarData, CalendarMetaData>
      startDateISO={startDate}
      endDateISO={endDate}
      select={{
        day: (wPs, isoDate) => getExistingWorkPeriod(wPs, isoDate) ?? null,
      }}
      metadata={{
        day: (wP, isoDate) => ({
          duration: calculateWorkPeriodDuration(wP?.start?.date, wP?.end?.date),
          isDisabled: isWorkPeriodDisabled(isoDate, resource, wP),
        }),
      }}
      data={workPeriods}
    >
      <ClipboardProvider>
        <Clipboard<ClipboardValues>>
          {clipboard => (
            <UseCalendar<CalendarData, CalendarMetaData>>
              {({ calendar, getWeekDays }) => (
                <>
                  <Columns>
                    <Columns.Column>
                      <Field
                        label="Date de début"
                        name="meta.startDate"
                        type="date"
                        component={InputField}
                        required
                      />
                      <FormFieldErrorMessage error={errors.meta?.startDate} />
                    </Columns.Column>
                    <Columns.Column>
                      <Field
                        label="Date de fin"
                        name="meta.endDate"
                        type="date"
                        component={InputField}
                        min={minEndDate}
                        required
                      />
                      <FormFieldErrorMessage error={errors.meta?.endDate} />
                    </Columns.Column>
                  </Columns>
                  {!dateRangeError && (
                    <WeeksList>
                      {errors.workPeriods && (
                        <tr>
                          <td colSpan={8}>
                            <FormFieldErrorMessage
                              error={errors.workPeriods as string | string[]}
                            />
                          </td>
                        </tr>
                      )}
                      <WeeksList.Header
                        right={isoWeekDay => (
                          <PasteButton
                            size="extra-small"
                            data-test={`weekday-paste-action-${isoWeekDay}`}
                            disabled={
                              clipboard.isEmpty ||
                              isResourceDisabled(resource) ||
                              !isClipboardDay(clipboard.value)
                            }
                            onClick={() => {
                              if (isoWeekDay === null) return
                              if (clipboard.isEmpty) return
                              if (!isClipboardDay(clipboard.value)) return
                              const [start, end] = clipboard.value ?? []
                              if (start === undefined && end === undefined) return
                              const days = getWeekDays(isoWeekDay).filter(
                                d => !d.metadata?.isDisabled,
                              )
                              const daysISO = days.map(({ isoDate }) => isoDate)
                              const values = days.map(({ isoDate }) => {
                                const startDate = start
                                  ? timeInputValueToDate(new Date(isoDate), start)
                                  : undefined
                                const endDate = end
                                  ? timeInputValueToDate(new Date(isoDate), end)
                                  : undefined
                                return {
                                  start: startDate ? { date: startDate } : undefined,
                                  end: endDate ? { date: endDate } : undefined,
                                }
                              })
                              const newWorkPeriods = updateWorkPeriods(workPeriods, daysISO, values)
                              setWorkPeriods(newWorkPeriods)
                            }}
                          />
                        )}
                      />
                      {Object.entries(calendar).map(([yearNumber, weeks]) =>
                        Object.entries(weeks).map(([weekNumber, week]) => {
                          return (
                            <Week
                              week={parseInt(weekNumber)}
                              weekYear={parseInt(yearNumber)}
                              key={yearNumber + '' + weekNumber}
                            >
                              <Week.Header
                                week={parseInt(weekNumber)}
                                weekYear={parseInt(yearNumber)}
                                right={
                                  <>
                                    <CopyButton
                                      size="extra-small"
                                      disabled={isResourceDisabled(resource)}
                                      data-test={`week-copy-action`}
                                      onClick={() => {
                                        const res = week.map(day => {
                                          if (day === null) return null
                                          if (day.data === null) return null
                                          if (day.data.start === undefined) return null
                                          if (day.data.end === undefined) return null
                                          const {
                                            start: { date: startDate },
                                            end: { date: endDate },
                                          } = day.data
                                          if (!startDate) return null
                                          if (!endDate) return null
                                          return [
                                            dateToTimeInputValue(startDate),
                                            dateToTimeInputValue(endDate),
                                          ] as const
                                        })
                                        //@ts-expect-error
                                        clipboard.copy(res)
                                      }}
                                    />
                                    <PasteButton
                                      size="extra-small"
                                      data-test={`week-paste-action`}
                                      disabled={
                                        clipboard.isEmpty ||
                                        isResourceDisabled(resource) ||
                                        isClipboardDay(clipboard.value) ||
                                        week.some(d => d?.metadata?.isDisabled)
                                      }
                                      onClick={() => {
                                        if (clipboard.isEmpty) return
                                        if (!clipboard.value) return
                                        if (isClipboardDay(clipboard.value)) return
                                        const clipboardWeek = clipboard.value
                                        if (!clipboardWeek) return

                                        const res = clipboard.value
                                          .map((clipboardDay, index) => {
                                            const dayInWeek = week[index]
                                            if (!dayInWeek) return undefined
                                            const { isoDate } = dayInWeek
                                            const returnValue: {
                                              isoDate: IsoDate
                                              value:
                                                | MissionFormValues['workPeriods'][number]
                                                | undefined
                                            } = {
                                              isoDate,
                                              value: undefined,
                                            }
                                            if (clipboardDay === null) return returnValue
                                            const [start, end] = clipboardDay
                                            if (!start) return returnValue
                                            if (!end) return returnValue
                                            const startDate = start
                                              ? timeInputValueToDate(new Date(isoDate), start)
                                              : undefined
                                            const endDate = end
                                              ? timeInputValueToDate(new Date(isoDate), end)
                                              : undefined
                                            if (!startDate) return returnValue
                                            if (!endDate) return returnValue
                                            returnValue.value = {
                                              start: { date: startDate },
                                              end: { date: endDate },
                                            }
                                            return returnValue
                                          })
                                          .filter(v => v)
                                        const daysISO = res.map(d => d!.isoDate)
                                        const values = res.map(d => d!.value)

                                        const newWorkPeriods = updateWorkPeriods(
                                          workPeriods,
                                          daysISO,
                                          values,
                                        )
                                        setWorkPeriods(newWorkPeriods)
                                      }}
                                    />
                                  </>
                                }
                              >
                                <Element textSize={7} mt={1}>
                                  <MissionWeekDurationStatus />
                                  <WorkPeriodsDuration
                                    workPeriods={workPeriods}
                                    breakRule={currentClockingRule?.break}
                                    userId={user}
                                    period={`${parseInt(yearNumber)}-W${parseInt(weekNumber)}`}
                                  />
                                </Element>
                              </Week.Header>
                              {week.map((day, index) => {
                                return (
                                  <Day
                                    isoDate={day?.isoDate ?? null}
                                    right={
                                      <Button.Group hasAddons>
                                        <CopyButton
                                          size="extra-small"
                                          data-test={`day-copy-action`}
                                          disabled={
                                            !day?.data?.start?.date ||
                                            !day?.data?.end?.date ||
                                            isResourceDisabled(resource)
                                          }
                                          onClick={() => {
                                            if (!day) return
                                            const { data } = day
                                            if (!data) return
                                            if (!data.start) return
                                            if (!data.end) return
                                            const {
                                              start: { date: startDate },
                                              end: { date: endDate },
                                            } = data
                                            if (!startDate) return
                                            if (!endDate) return
                                            clipboard.copy([
                                              dateToTimeInputValue(startDate),
                                              dateToTimeInputValue(endDate),
                                            ])
                                          }}
                                        />
                                        <PasteButton
                                          size="extra-small"
                                          data-test={`day-paste-action`}
                                          disabled={
                                            clipboard.isEmpty ||
                                            clipboard.isEqualTo([
                                              dateToTimeInputValue(day?.data?.start?.date),
                                              dateToTimeInputValue(day?.data?.end?.date),
                                            ]) ||
                                            isResourceDisabled(resource) ||
                                            day?.metadata?.isDisabled ||
                                            !isClipboardDay(clipboard.value)
                                          }
                                          onClick={() => {
                                            if (!clipboard.value) return
                                            if (!day?.isoDate) return
                                            if (!isClipboardDay(clipboard.value)) return
                                            const startDate = timeInputValueToDate(
                                              new Date(day?.isoDate),
                                              clipboard.value[0],
                                            )
                                            const endDate = timeInputValueToDate(
                                              new Date(day?.isoDate),
                                              clipboard.value[1],
                                            )
                                            const newWorkPeriods = updateWorkPeriod(
                                              workPeriods,
                                              day.isoDate,
                                              {
                                                ...(startDate
                                                  ? { start: { date: startDate } }
                                                  : {}),
                                                ...(endDate ? { end: { date: endDate } } : {}),
                                              },
                                            )
                                            setWorkPeriods(newWorkPeriods)
                                          }}
                                        />
                                        <ClipboardBaseButton
                                          size="extra-small"
                                          data-test="remove-work-period-action"
                                          disabled={
                                            isResourceDisabled(resource) ||
                                            day?.metadata?.isDisabled
                                          }
                                          onClick={() => {
                                            if (!day?.isoDate) return
                                            const newWorkPeriods = removeWorkPeriod(
                                              workPeriods,
                                              day.isoDate,
                                            )
                                            setWorkPeriods(newWorkPeriods)
                                          }}
                                        >
                                          <Icon>
                                            <RemoveIcon />
                                          </Icon>
                                        </ClipboardBaseButton>
                                      </Button.Group>
                                    }
                                  >
                                    <WorkPeriodEditor
                                      //@ts-expect-error
                                      isoDate={day?.isoDate}
                                      workPeriods={workPeriods}
                                      jobTitle={jobTitle}
                                      initialWorkPeriods={initialWorkPeriods}
                                      resource={resource}
                                      setWorkPeriods={setWorkPeriods}
                                      errors={errors}
                                    />
                                  </Day>
                                )
                              })}
                            </Week>
                          )
                        }),
                      )}
                      {errors.workPeriods && (
                        <tr>
                          <td colSpan={8}>
                            <FormFieldErrorMessage
                              error={errors.workPeriods as string | string[]}
                            />
                          </td>
                        </tr>
                      )}
                    </WeeksList>
                  )}
                </>
              )}
            </UseCalendar>
          )}
        </Clipboard>
      </ClipboardProvider>
    </CalendarProvider>
  )
}

const Clipboard = <T,>({
  children,
}: {
  children: (clipboard: ClipboardType<T>) => ReactElement<T>
}): ReactElement<T> => {
  const clipboard = useClipboardContext<T>()

  return children(clipboard)
}

type ClipboardDay = [IsoTime, IsoTime]
type ClipboardWeek = [
  ClipboardDay | null,
  ClipboardDay | null,
  ClipboardDay | null,
  ClipboardDay | null,
  ClipboardDay | null,
  ClipboardDay | null,
  ClipboardDay | null,
]
type ClipboardValues = ClipboardDay | ClipboardWeek

const isClipboardDay = (clipboardValue?: ClipboardValues): clipboardValue is ClipboardDay => {
  if (clipboardValue && clipboardValue.length === 2) return true
  return false
}
