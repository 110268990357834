import React, { useEffect, useState } from 'react'
import Layout from '../../components/layout/layout'
import { Button, Container, Element, Heading, Message, Modal, Section } from 'react-bulma-components'
import AcceptNewConditionForm from '../../components/legal-conditions/accept-new-conditions-form'
import useUserLegalConditionsVersionsStatus from '../../hooks/use-user-legal-conditions-status'

export const PrivacyPolicyPage: React.FC = () => {
  const legalConditionsState = useUserLegalConditionsVersionsStatus()
  const privacyPolicyIsOutdated = legalConditionsState.privacyPolicy === 'outdated'
  const [showPrivacyPolicyIsOutdatedBanner, setShowPrivacyPolicyIsOutdatedBanner] = useState(false)

  useEffect(
    () => {
      if (privacyPolicyIsOutdated === true) {
        setShowPrivacyPolicyIsOutdatedBanner(true)
      }
    },
    [privacyPolicyIsOutdated]
  )
  
  return (
    <Layout hideHeader={privacyPolicyIsOutdated}>
      <Section size="medium" mobile={{textSize: 7}}>
        <Container max={true} breakpoint={'desktop'}>
          {showPrivacyPolicyIsOutdatedBanner && (
            <Modal show={true}>
              <Modal.Content>
                <Message color={'warning'}>
                  <Message.Header>La Politique de Confidentialité de TeamTim a été mise à jour.</Message.Header>
                  <Message.Body p={5}>
                    <Element renderAs='p' >
                      Merci de la consulter et de l'accepter pour continuer à
                      utiliser TeamTim.
                      <Element textAlign={'center'}>
                        <Button data-test='continue' type='button' color={'primary'} mt={5} onClick={() => setShowPrivacyPolicyIsOutdatedBanner(false)}>Continuer</Button>
                      </Element>
                    </Element>
                  </Message.Body>
                </Message>
              </Modal.Content>
            </Modal>
          )}
          <Heading textColor="primary">Politique de confidentialité</Heading>
          <Element className="content" my={6}>
            <p>
              La présente Politique de confidentialité vous informe sur la manière dont{' '}
              <strong>FANDI</strong> collecte et traite via son site <strong>team-tim.fr</strong> et
              l'application iOS et Android <strong>TeamTIM</strong> les données personnelles vous
              concernant en qualité de responsable de traitement au sens des dispositions du
              Règlement Général sur la Protection des Données personnelles n°2016/679 du 27 avril
              2016 (« <strong>RGPD</strong> ») et la loi dite <i>informatique et libertés</i>{' '}
              n°78-17 du 6 janvier 1978 (ci-après dénommés ensemble la «{' '}
              <strong>Règlementation</strong> »).
            </p>
            <h2>Les Définitions issues du RGPD</h2>
            <p>
              Les définitions suivantes sont celles issues du Règlement Général sur la Protection
              des Données n°2016/679 du 27 avril 2016 (RGPD) et de la Loi dite « Informatique et
              Libertés » n°78-17 du 6 janvier 1978.
            </p>
            <p>
              <strong>DONNÉE PERSONNELLE</strong>
            </p>
            <p>
              Une donnée personnelle est toute information se rapportant à une personne physique
              identifiée ou identifiable. Cette personne peut être identifiée directement (exemple :
              nom et prénom) ou indirectement (exemple : par un numéro de téléphone ou de plaque
              d’immatriculation, un identifiant tel que le numéro de sécurité sociale, une adresse
              postale ou courriel, mais aussi la voix ou l’image), grâce à une seule donnée ou au
              croisement de plusieurs d’entre elles (exemple : une femme vivant à telle adresse, née
              tel jour et membre dans telle association).
            </p>
            <p>
              <strong>DESTINATAIRE</strong>
            </p>
            <p>
              La personne physique ou morale, l’autorité publique, le service ou tout autre
              organisme habilité à obtenir communication de données enregistrées dans un fichier ou
              un traitement en raison de ses fonctions.
            </p>
            <p> </p>
            <p>
              <strong>FICHIER</strong>
            </p>
            <p>
              Un fichier est un traitement de données qui s'organise dans un ensemble stable et
              structuré de données. Les données d'un fichier sont accessibles selon des critères
              déterminés.
            </p>
            <p> </p>
            <p>
              <strong>RESPONSABLE DE TRAITEMENT</strong>
            </p>
            <p>
              Le responsable de traitement est la personne morale (entreprise, commune, etc.) ou
              physique qui détermine les finalités et les moyens d’un traitement, c’est à dire
              l’objectif et la façon de le réaliser. En pratique et en général, il s’agit de la
              personne morale incarnée par son représentant légal.
            </p>
            <p>
              <strong>SOUS-TRAITANT</strong>
            </p>
            <p>
              Le sous-traitant est la personne physique ou morale (entreprise ou organisme public)
              qui traite des données pour le compte d’un autre organisme (« le responsable de
              traitement »), dans le cadre d’un service ou d’une prestation.
            </p>
            <p> </p>
            <p>
              <strong>TRAITEMENT</strong>
            </p>
            <p>
              Un traitement de données personnelles est une opération, ou ensemble d’opérations,
              portant sur des données personnelles, quel que soit le procédé utilisé (collecte,
              enregistrement organisation, conservation, adaptation, modification, extraction
              consultation, utilisation, communication par transmission ou diffusion ou toute autre
              forme de mise à disposition, rapprochement).
            </p>
            <p>
              Un traitement de données personnelles n’est pas nécessairement informatisé : les
              fichiers papier sont également concernés et doivent être protégés dans les mêmes
              conditions.
            </p>
            <p>
              Un traitement de données doit avoir un objectif, une finalité déterminée préalablement
              au recueil des données et à leur exploitation.
            </p>
            <p> </p>
            <h2>
              Qui est responsable des traitements des données personnelles effectués au moyen du
              Site ?
            </h2>
            <p>
              Il s’agit de la société <strong>FANDI</strong> (ci-après « <strong>FANDI</strong> » ou
              la « Société » ou « Nous »), est une société par actions simplifiée dont le siège
              social est situé : <strong>70 rue Elie CARTAN, 62220 CARVIN</strong>, représentée par{' '}
              <strong>Christophe FANDI</strong>.
            </p>
            <p>
              A chaque fois que <strong>FANDI</strong> aura déterminé les finalités de traitement de
              vos données personnelles ainsi que les moyens de traitement utilisés, elle sera
              qualifiée de responsable de traitement et endossera l’ensemble des obligations que la
              Règlementation met à sa charge.
            </p>
            <p>
              Un interlocuteur privilégié en matière de protection des données peut être contacter à
              l’adresse suivante : <strong>dpo@team-tim.fr</strong>.
            </p>
            <p> </p>
            <h2>Comment vos données personnelles sont-elles collectées ? </h2>
            <h3>Nous collectons les Données personnelles que vous Nous communiquez directement</h3>
            <p>
              Il s’agit des Données personnelles que vous Nous communiquez directement en répondant
              à nos formulaires de collecte, notamment ceux disponibles en ligne sur notre Site
              (notamment : nom, adresse mail…). Il s’agit de Données de contact ainsi que toute
              autre information que vous souhaitez porter à notre connaissance.
            </p>
            <p>
              Il peut également s’agir des données que vous nous communiquez spontanément en prenant
              contact avec nos équipes.
            </p>
            <p>
              Enfin, nous traitons également les données que vous Nous communiquez à l’occasion de
              nos échanges (au cours d’un échange téléphonique au lors d’une démonstration de notre
              logiciel par exemple).
            </p>
            <p>
              Les données ainsi collectées par <strong>FANDI</strong> sont soit obligatoires soit
              facultatives. Le caractère obligatoire est identifié au sein de chacun des formulaires
              par un astérisque pour chacun des champs concernés. Lorsque les données sont
              collectées oralement, nous vous préciserons le caractère obligatoire ou facultatif des
              informations. L’ensemble des données que vous Nous communiquez spontanément sont, par
              définition, facultatives.
            </p>
            <h3>Nous collectons certaines de vos Données personnelles de manière automatique :</h3>
            <p>
              Lorsque vous consultez le Site, des données sont enregistrées automatiquement via vos
              traces de navigation sur notre Site soit :
            </p>
            <ul>
              <li>
                <p>
                  Indirectement par le biais de cookies tiers,<strong> </strong>
                </p>
              </li>
              <li>
                <p>
                  Directement au moyen des journaux d’évènements du serveur (les fichiers log) par
                  les personnes en charge de la gestion du Site (soit au sein de la Société soit en
                  qualité de prestataire informatique). <strong> </strong>
                </p>
              </li>
            </ul>
            <p>Il s’agit de vos Données de navigation. </p>
            <p>
              Lorsque la collecte de ces Données de navigation a un caractère obligatoire, elles
              sont collectées afin d’assurer la mise à disposition du Site, son amélioration et le
              maintien d’un environnement sécurisé.
            </p>
            <p>
              Lorsque la collecte est facultative, votre accord est préalablement recueilli pour les
              Données de navigation utilisées{' '}
              <i>
                (liste à modifier en fonction des cookies effectivement utilisés sur le site et leur
                finalité)
              </i>{' '}
              pour mesurer l’audience de notre Site et la performance de nos campagnes, optimiser la
              gestion des prospects, analyser votre comportement d’internaute et/ou pour augmenter
              l’interactivité de notre Site et vous proposer de la publicité ciblée.
            </p>
            <p>Il peut s’agir notamment : </p>
            <ul>
              <li>
                <p>Des données relatives à votre connexion au Site :</p>
              </li>
            </ul>
            <p>
              Par exemple, votre adresse IP, l’adresse du site internet que vous visitez, votre
              moteur de recherche ainsi que ses paramètres, la date et l’heure de la connexion, la
              manière dont vous naviguez sur le Site ainsi que toutes les autres données de suivi de
              l’utilisation que vous faites du Site.
            </p>
            <ul>
              <li>
                <p>
                  Des données relatives à l’équipement qui vous permet d’accéder au Site :
                  <strong> </strong>
                </p>
              </li>
            </ul>
            <p>
              Selon l’équipement que vous utilisez et les paramètres activés, les données suivantes
              peuvent notamment être collectées : le type d’équipement (téléphone mobile, tablette,
              PC, MAC, etc.) et le système d’exploitation que vous utilisez ainsi que leurs
              paramètres.
            </p>
            <p> </p>
            <h2>
              Dans quel but et pendant combien de temps utilisons-nous vos données personnelles ?
            </h2>
            <p>
              Nous utilisons vos Données pour les finalités suivantes exposées au sein du tableau
              ci-dessous.
            </p>
            <p>
              Ces Données sont collectées dans l’un des cas prévus par la Réglementation à savoir
              les bases légales suivantes :
            </p>
            <ul>
              <li>
                <p>
                  L’exécution d’un contrat auquel vous êtes partie ou de mesures précontractuelles
                  vous concernant,
                </p>
              </li>
              <li>
                <p>Le respect d’une obligation légale, </p>
              </li>
              <li>
                <p>Votre consentement à l’utilisation de vos Données, </p>
              </li>
              <li>
                <p>Notre intérêt légitime à utiliser vos Données.</p>
              </li>
            </ul>
            <p>
              Vous trouverez ci-dessous les informations détaillées concernant les traitements
              réalisés via le Site
              <i>
                (il s’agit d’une liste indicative, à adapter en fonction des données collectées sur
                le site)
              </i>{' '}
              :
            </p>
            <p> </p>
            <div className='table-container'>
              <table className='table'>
                <tr>
                  <td>
                    <p>
                      <strong>Nom du traitement</strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>Finalités</strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>Base(s) légale(s)</strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>Données concernées</strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>Durée de conservation</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <i>Formulaires du Site </i>
                    </p>
                  </td>
                  <td>
                    <p>
                      Traiter votre demande et vous fournir les informations ou les services demandés.
                    </p>
                  </td>
                  <td>
                    <p>
                      Le traitement est nécessaire à la poursuite de l’intérêt légitime de{' '}
                      <strong>FANDI</strong> à gérer ses interactions avec le public (clients et
                      prospects) et lui permettre de répondre aux demandes qui lui sont adressées.
                    </p>
                  </td>
                  <td>
                    <p>Données d’identité et de contact ; </p>
                    <p>Données relatives à la vie professionnelle ; </p>
                    <p>Autre (contenu des correspondances par exemple). </p>
                  </td>
                  <td>
                    <p>
                      Les données sont conservées 3 ans à compter du dernier contact en base active
                      puis archivées à titre probatoire pendant 5 ans.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <i>Recrutement</i>
                      <i> </i>
                    </p>
                  </td>
                  <td>
                    <p>Étudier et gérer les candidatures.</p>
                  </td>
                  <td>
                    <p>
                      Le traitement est nécessaire à l’exécution des mesures précontractuelles et à
                      l’établissement de la relation contractuelle entre <strong>FANDI</strong> et les
                      candidats à un emploi ou à un stage.
                    </p>
                  </td>
                  <td>
                    <p>Données d’identité et de contact ;</p>
                    <p>
                      Informations figurant dans le curriculum vitae, courriers et courriels transmis
                      par les candidats, dates d’entretiens, positionnement salarial, suites données à
                      la candidature, type et durée de contrat proposé.
                    </p>
                  </td>
                  <td>
                    <p>Les données sont conservées 2 ans à compter du dernier contact. </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <i>Organisation d’évènements </i>
                    </p>
                  </td>
                  <td>
                    <p>
                      Gestion de l’organisation d’évènements en ce compris l’envoi d’invitations,
                      suivi des inscriptions, suivi de la satisfaction, gestion des questions et
                      demandes formulées au cours des évènements.
                    </p>
                  </td>
                  <td>
                    <p>
                      Le traitement est nécessaire à la poursuite de l’intérêt légitime de{' '}
                      <strong>FANDI</strong> à promouvoir et améliorer ses évènements auprès d’un
                      public de professionnels et d’organiser ces évènements.
                    </p>
                  </td>
                  <td>
                    <p>Données d’identité et de contact ;</p>
                    <p>Données relatives à l’inscription et à la participation aux évènements ;</p>
                    <p>Échanges relatifs à l’organisation des interventions et évènements.</p>
                  </td>
                  <td>
                    <p>
                      Les données sont conservées 3 ans à compter du dernier contact en base active
                      puis archivées à titre probatoire pendant 5 ans.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <i>
                        Gestion du site internet de <strong>FANDI</strong>
                      </i>
                    </p>
                  </td>
                  <td>
                    <p>Administration technique du Site (maintenance, hébergement,) ; </p>
                    <p>Sécurisation du Site ; </p>
                    <p>Amélioration de l’expérience utilisateur ;</p>
                    <p>Production de statistiques d’audience et d’utilisation des services.</p>
                    <p> </p>
                  </td>
                  <td>
                    <p>
                      Le traitement est nécessaire à la poursuite de l’intérêt légitime de{' '}
                      <strong>FANDI</strong> de gérer son site vitrine et le mettre à disposition de
                      manière sécurisée ;
                    </p>
                    <p>Consentement pour les cookies tiers.</p>
                  </td>
                  <td>
                    <p>Données d’identité et de contact ; </p>
                    <p>
                      Données relatives à la navigation sur le site (horodatage, adresse IP, données
                      techniques relatives à l’équipement et au navigateur utilisés, cookies) et sur
                      les plateformes numériques via des boutons de partage et des médias (cookies et
                      autres traceurs) ;
                    </p>
                    <p>
                      Données relatives à la gestion des contacts (horodatage et objet de la demande,
                      suivi, suites apportées, statistiques) ;
                    </p>
                    <p>Statistiques d’audience.</p>
                  </td>
                  <td>
                    <p>13 mois </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <i>Gestion de la prospection </i>
                    </p>
                  </td>
                  <td>
                    <p>Gestion d’opérations techniques de prospection ; </p>
                    <p>
                      Sélection de personnes pour réaliser des actions de fidélisation, de
                      prospection, de sondage, de test produit et de promotion ;
                    </p>
                    <p>Réalisation d’opérations de sollicitations ; </p>
                  </td>
                  <td>
                    <p>
                      Le traitement est nécessaire à la poursuite d’un intérêt légitime de{' '}
                      <strong>FANDI</strong> à promouvoir son activité et ses services auprès de
                      professionnels.
                    </p>
                  </td>
                  <td>
                    <p>Données d’identité et de contact ;</p>
                    <p>Données relatives à la vie professionnelle.</p>
                  </td>
                  <td>
                    <p>
                      Les données sont conservées 3 ans à compter du dernier contact en base active
                      puis archivées à titre probatoire pendant 5 ans.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <i>Gestion de la communication </i>
                    </p>
                  </td>
                  <td>
                    <p>
                      Réaliser les campagnes d’information au sujet des actualités de{' '}
                      <strong>FANDI</strong> (évolution des produits, mises à jour, évènements,
                      distinction).
                    </p>
                  </td>
                  <td>
                    <p>
                      Intérêt légitime (communiquer à propos des services et produits de{' '}
                      <strong>FANDI</strong>, étendre sa clientèle).
                    </p>
                    <p> </p>
                  </td>
                  <td>
                    <p>Données d’identité et de contact.</p>
                  </td>
                  <td>
                    <p>
                      Les données sont conservées 3 ans à compter du dernier contact en base active
                      puis archivées à titre probatoire pendant 5 ans.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <i>Fichier clients </i>
                    </p>
                  </td>
                  <td>
                    <p>
                      Gestion de la relation commerciale avec les clients concernant les contrats ;
                      les devis ; les commandes ; les factures ; la comptabilité et en particulier la
                      gestion des comptes clients ; le suivi de la relation client tel que la
                      réalisation d’enquêtes de satisfaction, la gestion des réclamations et du
                      service après-vente ; la sélection de clients pour réaliser des études, sondages
                      et tests produits.
                    </p>
                  </td>
                  <td>
                    <p>
                      Le traitement est nécessaire à la poursuite de l’intérêt légitime de{' '}
                      <strong>FANDI</strong> à gérer son activité et ses relations contractuelles et
                      commerciales.
                    </p>
                    <p>
                      Lorsque le professionnel est une personne physique assimilée à un consommateur
                      au regard du droit de la consommation, le traitement est fondé sur l’exécution
                      du contrat entre <strong>FANDI</strong> et ce professionnel ou à la prise de
                      mesures précontractuelles à sa demande.
                    </p>
                  </td>
                  <td>
                    <p>Données d’identité et de contact ;</p>
                    <p>Informations relatives à la vie professionnelles ;</p>
                    <p>Données économiques et financières.</p>
                  </td>
                  <td>
                    <p>
                      Les données sont conservées 3 ans à compter de la fin de la relation
                      contractuelle en base active puis archivées à titre probatoire pendant 5 ans.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <i>Gestion newsletter</i>
                    </p>
                  </td>
                  <td>
                    <p>Adresser régulièrement la newsletter aux inscrits.</p>
                  </td>
                  <td>
                    <p>Consentement</p>
                  </td>
                  <td>
                    <p>Adresse mail</p>
                  </td>
                  <td>
                    <p>
                      Le temps de l’inscription de la personne à la newsletter (désinscription
                      possible via le lien de désinscription intégré aux newsletters).
                    </p>
                  </td>
                </tr>
              </table>
            </div>
            <p>
              Si vous avez déposé une candidature spontanée sur le Site et que celle-ci n’a pas été
              retenue, nous vous contacterons afin de vous demander si vous souhaitez que nous
              conservions vos données personnelles en vue de vous proposer ultérieurement un autre
              poste ou si vous souhaitez que ces dernières soient détruites. En tout état de cause,
              vos données personnelles seront automatiquement détruites 2 ans après votre dernier
              contact avec <strong>FANDI</strong>.
            </p>
            <p>
              Les données concernant les pièces d’identité relatives à l’exercice des droits
              d’accès, de rectification, de suppression, de limitation, d’effacement et de
              portabilité sont conservées durant 1 an à compter de leur réception.
            </p>
            <p>
              Dans l’hypothèse d’une procédure judiciaire, les données personnelles vous concernant
              seront conservées afin de permettre à <strong>FANDI</strong> d’assurer la défense de
              ses intérêts.
            </p>
            <h2>A qui sont destinées vos données personnelles ? </h2>
            <p>
              <strong>Au sein de FANDI</strong>
            </p>
            <p>
              Que ce soit sous forme individuelle ou agrégée, vos Données personnelles sont traitées
              par les collaborateurs de <strong>FANDI</strong>.
            </p>
            <p>
              Dans tous les cas, seules les données strictement nécessaires à la réalisation du
              traitement visé sont transmises aux seuls collaborateurs habilités à en connaître du
              fait de leurs fonctions. Toutes les personnes susceptibles d’avoir accès à vos Données
              personnelles sont soumises par contrat à une obligation de confidentialité.
            </p>
            <p>Au cas particulier, ont seuls accès à vos Données personnelles : </p>
            <ul>
              <li>
                <p>
                  Les représentant légaux de <strong>FANDI</strong> ;
                </p>
              </li>
              <li>
                <p>
                  Le responsable commercial de <strong>FANDI</strong>
                </p>
              </li>
              <li>
                <p>Votre gestionnaire de compte client ; </p>
              </li>
              <li>
                <p>
                  Les membres de <strong>FANDI</strong> en charge d’opérations techniques liées à
                  l’exercice des droits des personnes.
                </p>
              </li>
            </ul>
            <p> </p>
            <p>
              <strong>Transferts hors FANDI</strong>
            </p>
            <p>
              Plusieurs catégories de tiers visés ci-dessous sont susceptibles d’avoir accès à vos
              Données personnelles. Seules les Données personnelles strictement nécessaires leurs
              sont transmises pour la ou les finalités poursuivies par le traitement envisagé.
            </p>
            <ul>
              <li>
                <p>Les sous-traitants dont notamment nos Prestataires : </p>
                <ul>
                  <li>
                    <p>Informatiques : </p>
                    <ul>
                      <li>
                        <p>
                          En charge de l’administration, de l’hébergement et de la maintenance du
                          Site
                        </p>
                      </li>
                      <li>
                        <p>
                          MICROSOFT pour l’utilisation d’Office 365 en ce compris les prestations de
                          messagerie informatique (
                          <a href="https://privacy.microsoft.com/fr-fr/privacystatement">
                            https://privacy.microsoft.com/fr-fr/privacystatement
                          </a>
                          ),
                        </p>
                      </li>
                      <li>
                        <p>De fourniture de solution CRM </p>
                      </li>
                      <li>
                        <p>De fourniture d’outils d’analyse et de publicité (Google Analytics)</p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p>
                      Prestataires <strong>marketing</strong> (agence marketing et gestion de
                      référencement, solution d’enrichissement et de validation de contacts
                      professionnels)
                    </p>
                  </li>
                </ul>
              </li>
            </ul>
            <p>
              Nous nous assurons qu’en matière de Données personnelles, ces sous-traitants
              s’engagent contractuellement à mettre en œuvre des niveaux de protection équivalents
              aux nôtres et en tout état de cause, conformes à la Réglementation.
            </p>
            <ul>
              <li>
                <p>
                  Les sociétés fournissant les cookies tiers auxquels vous donnez votre consentement
                  pour leur installation (cf politique de cookies).
                </p>
              </li>
              <li>
                <p>
                  De manière exceptionnelle, les autorités compétentes (administratives,
                  judiciaires, de police) en raison d’une obligation légale ou pour garantir les
                  droits, les biens ou la sécurité de <strong>FANDI</strong>.
                </p>
              </li>
            </ul>
            <p>
              <strong>FANDI</strong> s’engage à ne pas transmettre vos Données personnelles à des
              tiers sauf si :
            </p>
            <ol>
              <li>
                <p>
                  Vous avez donné votre accord préalable pour le partage de vos Données personnelles
                  avec des tiers,
                </p>
              </li>
            </ol>
            <ol>
              <li>
                <p>
                  Le partage de vos Données personnelles avec ledit tiers est nécessaire à la
                  fourniture des produits ou services sollicités,
                </p>
              </li>
            </ol>
            <ol>
              <li>
                <p>
                  Une autorité judiciaire ou administrative compétente exige que{' '}
                  <strong>FANDI</strong> lui communique lesdites Données personnelles.
                </p>
              </li>
            </ol>
            <p>
              <strong>Transfert hors UE</strong>
            </p>
            <p>
              A ce jour, Nous n’opérons aucun transfert de vos Données en dehors de l’Union
              européenne.
            </p>
            <p>
              Toutefois, soucieux de la sécurité et de la confidentialité de vos Données, Nous vous
              informons que dans l’éventualité où vos Données seraient transférées hors UE, nous
              nous engageons à soumettre tous nos prestataires à une obligation de confidentialité
              et à ce que tous les éventuels transferts s’effectuent à destination :
            </p>
            <ul>
              <li>
                <p>
                  Soit d’un pays reconnu par la Commission européenne comme assurant un niveau de
                  protection adéquat ;<strong> </strong>
                </p>
              </li>
              <li>
                <p>
                  Soit vers un pays n’offrant pas de protection adéquate mais dont le transfert est
                  permis en application de la réglementation applicable notamment car encadré par
                  les clauses contractuelles types de la Commission européenne dont la CNIL a
                  préalablement reconnu qu'elles permettent de garantir un niveau de protection
                  suffisant de la vie privée et des droits fondamentaux des personnes.
                  <strong> </strong>
                </p>
              </li>
            </ul>
            <p> </p>
            <h2>Quels sont vos droits concernant vos données personnelles ?</h2>
            <h3>Droit d’accès </h3>
            <p>
              Vous avez le droit d’obtenir de <strong>FANDI</strong> la confirmation que des données
              personnelles vous concernant sont ou ne sont pas traitées. Dans l’hypothèse, où les
              données sont traitées, vous avez un droit d’accès à ces données et aux informations
              suivantes :
            </p>
            <p>a. les finalités du traitement ; </p>
            <p>b. les catégories de données personnelles concernées ; </p>
            <p>
              c. les destinataires ou catégories de destinataires auxquels les données ont été
              communiquées ou vont être communiquées ;
            </p>
            <p>
              d. la durée de conservation envisagée des données si cela est possible, ou lorsque ce
              n’est pas possible, les critères utilisés pour déterminer cette durée ;
            </p>
            <p>
              e. le droit de demander à <strong>FANDI</strong> la rectification ou l’effacement des
              données vous concernant, la limitation du traitement ou le droit de vous opposer au
              traitement
            </p>
            <p>f. le droit d’introduire une réclamation auprès de la CNIL ; </p>
            <p>
              g. lorsque les données personnelles ne sont pas collectées auprès de vous directement,
              toute information disponible sur la source ;
            </p>
            <p>
              h. l’existence d’une prise de décision automatisée, y compris le profilage et, au
              moins en pareils cas, des informations utiles concernant la logique sous-jacente,
              ainsi que l’importance et les conséquences prévues de ce traitement pour vous.
            </p>
            <p> </p>
            <p>
              Enfin lorsque les données personnelles sont transférées vers un pays tiers ou une
              organisation internationale, vous avez le droit d’être informé des garanties
              appropriées concernant le transfert.
            </p>
            <p>
              <strong>FANDI</strong> vous fournira sur demande une copie des données faisant l’objet
              du traitement. Elle pourra exiger le paiement de frais raisonnables basés sur les
              coûts administratifs de toute copie supplémentaire que vous demanderiez.
            </p>
            <p>
              Si vous demandez une copie des données par la voie électronique,{' '}
              <strong>FANDI</strong> les fournira sous une forme électronique d’usage courant à
              moins que vous demandiez qu’il en soit autrement. Enfin, le droit d’obtenir une copie
              des données personnelles ne doit pas porter atteinte aux droits et libertés d’autrui.
            </p>
            <h3>Droit de rectification </h3>
            <p>
              Vous disposez de la faculté de demander à <strong>FANDI</strong> de rectifier vos
              données qui seraient inexactes, dans les meilleurs délais. Vous disposez aussi de la
              faculté d’obtenir que les données personnelles incomplètes vous concernant soient
              complétées y compris en fournissant une déclaration supplémentaire.
            </p>
            <p> </p>
            <h3>Droit à l’effacement </h3>
            <p>
              Vous disposez du droit à l’effacement de vos données personnelles dans les meilleurs
              délais lorsque l’un des motifs suivants s’applique :
            </p>
            <p>
              a. Les données personnelles ne sont plus nécessaires au regard des finalités pour
              lesquelles elles ont été collectées ou traitées d’une autre manière ;
            </p>
            <p>
              b. Vous retirez votre consentement sur lequel est fondé le traitement et il n’existe
              pas d’autre fondement juridique au traitement concerné. Le retrait du consentement
              vaut pour l’avenir ;
            </p>
            <p>
              c. Vous exercez votre droit d’opposition et il n’existe pas de motif légitime
              impérieux pour le traitement ;
            </p>
            <p>d. Les données personnelles ont fait l’objet d’un traitement illicite ; </p>
            <p>
              e. Les données personnelles doivent être effacées pour respecter une obligation
              légale ;
            </p>
            <p>f. Les données personnelles étaient facultatives. </p>
            <p> </p>
            <p>
              Vous êtes informé que le droit à l’effacement peut ne pas s’appliquer si le traitement
              est nécessaire :
            </p>
            <ul>
              <li>
                <p>
                  Pour respecter une obligation légale,<strong> </strong>
                </p>
              </li>
              <li>
                <p>
                  À la constatation, à l’exercice ou à la défense de droits en justice.
                  <strong> </strong>
                </p>
              </li>
            </ul>
            <p> </p>
            <h3>Droit à la limitation du traitement </h3>
            <p>
              Vous disposez de la faculté de demander la limitation du traitement de vos données
              personnelles lorsque l’un des éléments suivants s’applique :
            </p>
            <p>
              a. Une vérification de vos données est nécessaire après une contestation de votre part
              sur l’exactitude de vos données personnelles ;
            </p>
            <p>
              b. Le traitement est illicite et vous vous opposez à l’effacement de vos données
              personnelles et exigez la limitation de leur utilisation ;
            </p>
            <p>
              c. <strong>FANDI</strong> n’a plus besoin des données personnelles vous concernant
              pour le traitement mais les données sont nécessaires pour vous pour la constatation,
              l’exercice ou la défense de droits en justice ;
            </p>
            <p>
              d. Vous vous êtes opposés au traitement et <strong>FANDI</strong> vérifie si les
              motifs légitimes poursuivis par elle prévalent sur vos motifs légitimes.
            </p>
            <p> </p>
            <h3>Droit à la portabilité </h3>
            <p>
              Vous avez la faculté de recevoir les données personnelles que vous avez fournies à{' '}
              <strong>FANDI</strong> dans un format structuré, couramment utilisé et lisible par
              machine lorsque, de manière cumulative :
            </p>
            <p>a. Le traitement est fondé sur le consentement ou sur un contrat ; </p>
            <p>b. Le traitement est effectué à l’aide de procédés automatisés. </p>
            <p>
              Dans le cadre de l’exercice de ce droit, vous avez le droit d’obtenir de{' '}
              <strong>FANDI</strong> que vos données personnelles soient transmises directement par{' '}
              <strong>FANDI</strong> à un autre responsable de traitement lorsque cela est
              techniquement possible.
            </p>
            <p>
              Le droit à la portabilité ne doit pas porter atteinte aux droits et libertés de tiers.
            </p>
            <h3>Droit d’opposition </h3>
            <p>
              En application du droit d’opposition, vous disposez du droit de vous opposer à tout
              moment, pour des raisons tenant à votre situation particulière, à un traitement de
              données personnelles fondé sur les intérêts légitimes de <strong>FANDI</strong>.
            </p>
            <p>
              <strong>FANDI</strong> ne traitera plus vos données personnelles sauf si elle démontre
              que des motifs légitimes et impérieux pour le traitement existent et prévalent sur vos
              intérêts et vos droits et libertés ou pour la constatation, l’exercice ou la défense
              de vos droits en justice.
            </p>
            <p>
              Vous avez le droit de vous opposer à tout moment au traitement de vos données
              personnelles à des fins de prospection. Pour ce faire, vous pouvez de manière
              totalement gratuite soit :
            </p>
            <ul>
              <li>
                <p>
                  Cliquer sur le lien figurant dans le mail qui vous a été adressé par{' '}
                  <strong>FANDI</strong>,
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <p>
                  Envoyer à <strong>FANDI</strong> un courriel à l’adresse mail suivante :{' '}
                  <strong>dpo@team-tim.fr</strong>.
                </p>
              </li>
            </ul>
            <h3>Sort des données personnelles post-mortem </h3>
            <p>
              En application de la loi, vous disposez de la faculté de définir vos directives
              relatives à la conservation, à l’effacement et à la communication de vos données
              personnelles en cas de décès.
            </p>
            <p>
              Vous pouvez nous communiquer vos directives ou bien les enregistrer auprès d’un tiers
              de confiance numérique certifié. Vous pouvez désigner la personne de votre choix qui
              sera chargée d’exécuter vos directives. A défaut, il s’agira de vos héritiers.
            </p>
            <h3>En matière de consentement </h3>
            <p>
              Plusieurs traitements de vos données personnelles sont conditionnés par votre
              consentement. C’est le cas par exemple lorsque vous sollicitez des renseignements via
              le formulaire de contact du Site.
            </p>
            <p>
              Vous pouvez à tout moment retirer votre consentement. Il suffit pour cela d’envoyer un
              email à l’adresse suivante : <strong>dpo@team-tim.fr.</strong>
            </p>
            <p>
              Ce retrait de consentement est sans incidence sur la légalité d’un traitement de
              Données personnelles effectué sur la base de votre consentement avant son retrait.
            </p>
            <p>
              Veuillez noter par ailleurs que les Données personnelles dont le traitement est rendu
              obligatoire pour répondre à vos demandes ou fournir les services demandés sont
              assorties d’un astérisque sur le formulaire de collecte. Sans ces informations,{' '}
              <strong>FANDI</strong> ne pourra pas être en mesure de répondre à vos demandes ou de
              fournir les produits ou services sollicités.
            </p>
            <p> </p>
            <h2>Comment exercer ses droits ?</h2>
            <h3>Modalités d’exercice des droits </h3>
            <p>Vous pouvez adresser vos demandes aux adresses suivantes : </p>
            <ul>
              <li>
                <p>
                  <strong>FANDI</strong>, <strong>70 rue Elie CARTAN, 62220 CARVIN</strong>
                </p>
              </li>
              <li>
                <p>
                  <strong>dpo@team-tim.fr</strong>
                </p>
              </li>
            </ul>
            <p>
              Pour exercer vos droits sur les données vous concernant, merci de joindre la copie
              d'une pièce d'identité en cours de validité (carte d'identité ou passeport), sauf si
              les éléments communiqués dans le cadre de votre demande permettent de vous identifier
              de façon certaine.
            </p>
            <p>
              Pour vous aider dans votre démarche, vous trouverez en utilisant le lien suivant un
              modèle de courrier élaboré par la Commission Nationale de l’Informatique et des
              Libertés (la « CNIL ») :{' '}
              <a href="https://www.cnil.fr/fr/modele/courrier/exercer-son-droit-dacces">
                https://www.cnil.fr/fr/modele/courrier/exercer-son-droit-dacces
              </a>
              .
            </p>
            <p> </p>
            <h3>Délai de réponse </h3>
            <p>
              <strong>FANDI</strong> dispose d’un délai d’un (1) mois pour vous répondre à compter
              de la réception de votre demande. Ce délai peut être prolongé de deux mois en raison
              de la complexité de votre demande et du nombre de demandes qui lui sont faites.{' '}
              <strong>FANDI</strong> vous informera la personne dans le mois de sa demande.
            </p>
            <p>
              Concernant les offres commerciales adressées par voie électronique, vous disposez de
              la faculté de vous désabonner à tout moment en cliquant sur le lien hypertexte
              disponible dans chacun des mails adressés par <strong>FANDI</strong>.
            </p>
            <p>
              Vous disposez en outre du droit d’introduire une réclamation auprès de la CNIL,
              autorité de contrôle (<a href="http://www.cnil.fr/">www.cnil.fr</a>).
            </p>
            <h2>L’usage de vos données par des plateformes tierces </h2>
            <p>
              Le Site peut contenir des liens vers d’autres sites. De même d’autres sites peuvent
              faire référence ou contenir un lien vers notre Site. Nous n’avons aucun contrôle sur
              ces autres domaines et sites Web. Nous invitons nos utilisateurs à lire les politiques
              de confidentialité de chaque site Web et de chaque application avec lesquels ils sont
              susceptibles d’interagir. Nous n’adhérons, ne vérifions ni n’approuvons, ni ne sommes
              responsables des pratiques en matière de confidentialité ou du contenu de tels sites
              Web ou applications. Si vous visitez les autres sites Web ou applications concernées,
              vous le faites à vos propres risques et vous êtes soumis à leur politique de
              confidentialité.
            </p>
            <p> </p>
            <h3>Les plugs in réseaux sociaux</h3>
            <p>
              Notre Site utilise des plug-in (modules d’extension) édités par des sociétés tierces
              (par ex. Facebook, twitter, google + etc.).
            </p>
            <p>
              Si vous interagissez au moyen des plug-ins en cliquant sur les icônes disponibles sur
              nos pages, certaines de vos données personnelles et notamment vos données techniques
              et de navigation seront transmises et enregistrées sur un serveur de la société tierce
              exploitant le réseau social correspondant à l’icône sur lequel vous avez cliqué. Ces
              données seront ensuite traitées par cette société tierce conformément à ses conditions
              et au paramétrage de votre compte ouvert au sein de ce réseau social.
            </p>
            <p>
              Vos données sont à ce titre susceptibles d’être hébergées hors de l’Union Européenne
              et notamment aux États-Unis.
            </p>
            <p>
              Si vous ne souhaitez pas qu’une société tierce exploitant un réseau social relie les
              données personnelles collectées par l'intermédiaire du Site à votre compte utilisateur
              ouvert au sein du réseau social considéré, vous devez vous déconnecter de ce réseau
              social avant de visiter le Site.
            </p>
            <p>
              En tout état de cause, l'utilisation de ces plugs in est gérée par les sociétés
              tierces éditrices de réseaux sociaux et est exclusivement régie par les conditions
              vous liant au réseau social dont vous êtes membre. Nous vous invitons à prendre
              connaissance des conditions appliquées par ces sociétés tierces éditrices de réseaux
              sociaux.
            </p>
          </Element>
          <Element id={'accept'}>
            {privacyPolicyIsOutdated && (
              <AcceptNewConditionForm type="privacyPolicy" />
            )}
          </Element>
        </Container>
      </Section>
    </Layout>
  )
}
