import React from 'react'
import InputField from '../../form/fields/input'
import FieldColumn from '../field-column'
import { Field } from 'formik'

interface DateFilterFieldProps {
  name?: string
  label?: string
}

const DateFilterField: React.FC<DateFilterFieldProps> = ({ name, label }) => {
  return (
    <FieldColumn>
      <Field label={label} name={name || 'date'} type="date" component={InputField} />
    </FieldColumn>
  )
}

export default DateFilterField
