import React from 'react'
import { Field, FieldArray, useFormikContext } from 'formik'
import { useState } from 'react'
import { Button, Form as BulmaForm, Icon } from 'react-bulma-components'
import InputField from '../form/fields/input'
import { ArrowDownIcon, ArrowUpIcon, RemoveIcon } from '../icons'

export const AddressesForm: React.FC = () => {
  const {
    values: { addresses },
    errors,
    validate,
  } = useFormikContext<{ addresses: string[] }>()
  const [newAddress, setNewAddress] = useState<string>('')

  return (
    <>
      <BulmaForm.Field mt={6}>
        <BulmaForm.Label>Adresses</BulmaForm.Label>
        <BulmaForm.Help mb={2}>
          La première adresse de la liste est l'adresse par défaut
        </BulmaForm.Help>
        <BulmaForm.Field>
          <FieldArray name="addresses">
            {({ swap, push, remove }) => (
              <>
                {addresses.map((address, index) => {
                  return (
                    <BulmaForm.Field kind="addons" key={index}>
                      <BulmaForm.Control fullwidth>
                        <Field name={`addresses[${index}]`} component={InputField} required />
                      </BulmaForm.Control>
                      <BulmaForm.Control>
                        <Button
                          type="button"
                          disabled={index === 0}
                          onClick={() => swap(index - 1, index)}
                        >
                          <Icon>
                            <ArrowUpIcon />
                          </Icon>
                        </Button>
                      </BulmaForm.Control>
                      <BulmaForm.Control>
                        <Button
                          type="button"
                          disabled={index === addresses.length - 1}
                          onClick={() => swap(index, index + 1)}
                        >
                          <Icon>
                            <ArrowDownIcon />
                          </Icon>
                        </Button>
                      </BulmaForm.Control>
                      <BulmaForm.Control>
                        <Button type="button" onClick={() => remove(index)}>
                          <Icon>
                            <RemoveIcon />
                          </Icon>
                        </Button>
                      </BulmaForm.Control>
                    </BulmaForm.Field>
                  )
                })}
                <BulmaForm.Field kind="addons">
                  <BulmaForm.Control fullwidth>
                    <BulmaForm.Input
                      name="address"
                      value={newAddress}
                      onChange={e => setNewAddress(e.target.value)}
                    />
                  </BulmaForm.Control>
                  <BulmaForm.Control>
                    <Button
                      onClick={() => {
                        push(newAddress)
                        setNewAddress('')
                      }}
                      disabled={!newAddress}
                    >
                      <b>Ajouter</b>
                    </Button>
                  </BulmaForm.Control>
                </BulmaForm.Field>
              </>
            )}
          </FieldArray>
        </BulmaForm.Field>
      </BulmaForm.Field>
      <BulmaForm.Help textSize={6} color={'danger'}>
        {errors.addresses}
      </BulmaForm.Help>
    </>
  )
}
