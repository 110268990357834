import { QueryKey, useMutation, UseQueryResult } from '@tanstack/react-query'
import { queryClient, QueryOptions, UseMutationResult, useResource, useResources } from '.'
import {
  create,
  fetch,
  get,
  JobTitle,
  JobTitleCreationPayload,
  JobTitleUpdatePayload,
  update,
} from '../api/job-titles'

//Queries
export const useJobTitleQuery = (id?: string): UseQueryResult<JobTitle, any> =>
  useResource<JobTitle>('job-titles', get, id)

export const useJobTitlesQuery = (
  filters?: any,
  options?: QueryOptions,
): UseQueryResult<JobTitle[], any> =>
  useResources<JobTitle[], any>('job-titles', fetch, filters, options)

//Mutations
const onMutationSuccess = () => {
  queryClient.invalidateQueries(['job-titles'])
}

export const useCreateMutationKey = (): QueryKey => ['job-titles', 'create']
export const useCreateMutation = (): UseMutationResult<JobTitle, JobTitleCreationPayload, any> =>
  useMutation(create, { mutationKey: useCreateMutationKey(), onSuccess: onMutationSuccess })

export const useUpdateMutationKey = (): QueryKey => ['job-titles', 'update']
export const useUpdateMutation = (): UseMutationResult<JobTitle, JobTitleUpdatePayload, any> =>
  useMutation(update, {
    mutationKey: useUpdateMutationKey(),
    onSuccess: onMutationSuccess,
  })
