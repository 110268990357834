import React from 'react'
import Layout from '../../components/layout/layout'
import FormWrapper from '../../components/form/form-wrapper'
import { Box, Button, Container, Heading, Icon, Level, Section } from 'react-bulma-components'
import EmployerForm from '../../components/organizations/employer-form'
import { ViewListIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'
import Protected from '../../components/protected/protected'
import { Column } from 'react-table'
import Table from '../../components/table'
import { useMutationState } from '../../queries'
import { Employer } from '../../api/organizations'
import useUrlId from '../../hooks/use-url-id'
import {
  useCreateMutationKey,
  useOrganizationQuery,
  useUpdateMutationKey,
} from '../../queries/organizations'
import { useFormWrapperPropsWithMutationData } from '../../hooks/use-form-wrapper-props-with-mutation-data'
import PageTitle from '../../components/pages/page-title'
import { useEmployerRulesQuery } from '../../queries/employer-rules'
import { EmployerRule } from '../../api/employer-rules'
import { ResourceLinkCell } from '../../components/table/cells'
import { sortByIgnoreCase } from '../../utils/arrays'
import EmployerMissionsRulesListDescription from '../../components/organizations/employer-missions-rules-list-description'

const EmployerDetails: React.FunctionComponent = () => {
  const { id, isNew } = useUrlId()
  const { data: organization } = useOrganizationQuery<Employer>(id, 'employer')

  const employerRulesQuery = useEmployerRulesQuery({ employer: id }, { enabled: Boolean(id) })

  const mutationState = useMutationState(isNew ? useCreateMutationKey() : useUpdateMutationKey())

  const formWrapperProps = useFormWrapperPropsWithMutationData(
    formWrapperBaseProps,
    mutationState,
    !isNew,
  )

  const rulesTableColumn = useRulesListTableColumns(id)

  return (
    <Layout>
      <Section>
        <Container max={true} breakpoint="desktop">
          <Level alignItems="flex-start" mb={6}>
            <Level.Side>
              <PageTitle
                title={isNew ? 'Nouvel Employeur' : organization?.name || ''}
                subTitle={
                  isNew ? 'Créer un Nouvel Employeur' : "Paramètres et Relations de l'Employeur"
                }
              />
            </Level.Side>
            <Level.Side>
              <Protected roles={['superAdmin']}>
                <Button renderAs={Link} to="/employers" outlined color="primary" size="small">
                  <Icon>
                    <ViewListIcon />
                  </Icon>
                  <span>Liste des Employeurs</span>
                </Button>
              </Protected>
            </Level.Side>
          </Level>
          <Box>
            <FormWrapper {...formWrapperProps}>
              <EmployerForm organization={organization} />
            </FormWrapper>
          </Box>
          <Protected roles={['employerMember']}>
            <Box>
              <EmployerMissionsRulesListDescription rules={organization?.rules?.missions} />
            </Box>
          </Protected>
          <Protected roles={['superAdmin']}>
            <Heading size={4}>
              Règles Financières
              <Button
                size="small"
                color={'primary'}
                pull="right"
                renderAs={Link}
                to={`/employers/${organization?._id}/rules/new`}
              >
                Nouvelle règle
              </Button>
            </Heading>
            <Box>
              <Table
                columns={rulesTableColumn}
                data={sortByIgnoreCase<EmployerRule>(employerRulesQuery.data || [], 'name')}
                noDataMessage="Aucune règle à afficher"
              />
            </Box>
          </Protected>
        </Container>
      </Section>
    </Layout>
  )
}

export default EmployerDetails

const formWrapperBaseProps = {
  loadingProps: { body: "Enregistrement de l'Employeur..." },
  successProps: {
    title: 'Employeur créé',
    body: {
      description: "L'Employeur a été créé",
      actions: [
        {
          label: 'Nouvel Employeur',
        },
        {
          label: 'Retourner à la liste',
          to: '/employers',
        },
      ],
    },
  },
}

const useRulesListTableColumns = (employerId?: string): Column<EmployerRule>[] => {
  return React.useMemo(
    () => [
      {
        Header: 'Nom',
        accessor: 'name',
        Cell: data => (
          <ResourceLinkCell
            resource={data.row.original}
            path={`/employers/${employerId}/rules/`}
            label={data.row.original.name}
          />
        ),
      },
      {
        Header: 'Nom court',
        accessor: 'shortName',
      },
      {
        Header: 'Type',
        accessor: 'interval.period',
      },
    ],
    [],
  )
}
