import React from 'react'
import { Columns, Container, Section } from 'react-bulma-components'
import Layout from './layout'

const ErrorLayout: React.FC = ({ children }) => {
  return (
    <Layout>
      <Container breakpoint={'tablet'}>
        <Section>
          <Columns vCentered centered>
            <Columns.Column size={6}>{children}</Columns.Column>
          </Columns>
        </Section>
      </Container>
    </Layout>
  )
}

export default ErrorLayout
