import React from 'react'
import { Button } from 'react-bulma-components'
import { ButtonProps } from '../../types/bulma'
import { BulmaComponent } from 'react-bulma-components/src/components'

export type IClipboardBaseButton = BulmaComponent<
  ButtonProps & {
    /* dataTooltip?: string */
    size?: ButtonProps['size'] & 'extra-small'
  },
  'button'
>

export const ClipboardBaseButton: BulmaComponent<ButtonProps, 'button'> = props => {
  const extraSmall = props.size === 'extra-small' ? { fontSize: '0.6rem' } : {}
  const { style } = props

  return (
    <Button type="button" color="ghost" {...props} style={{ ...extraSmall, ...style }}>
      {props.children}
    </Button>
  )
}
