import React from 'react'
import { Breadcrumb, Element } from 'react-bulma-components'
import { Link } from 'react-router-dom'

interface BreadcrumbsProps {
  items: {
    label: string
    to?: string
  }[]
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ items }) => {
  return (
    <Breadcrumb>
      {items.map((item, index) => (
        <Breadcrumb.Item key={item.label}>
          {item.to ? (
            <Link to={item.to}>{item.label}</Link>
          ) : (
            <Element
              renderAs="span"
              px={3}
              textWeight={index === items.length - 1 ? 'bold' : 'semibold'}
            >
              {item.label}
            </Element>
          )}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  )
}

export default Breadcrumbs
