import api, { ApiResponse } from '.'
import { ApiConfig as _ApiConfig } from '../../../backend/src/services/api/api.service.types'

export type ApiConfig = _ApiConfig

export const get = async (): Promise<ApiResponse<ApiConfig>> =>
  await api({
    endpoint: `/config`,
    method: 'get',
  })
