import React from 'react'
import { WorkPeriodEvent } from '../../api/work-period-events'
import { Column } from 'react-table'
import { useWorkPeriodEventsQuery } from '../../queries/work-period-events'
import Layout from '../../components/layout/layout'
import PageHeading from '../../components/sections/page-heading'
import { Button, Container, Element, Form, Icon, Section } from 'react-bulma-components'
import ComponentHeader from '../../components/sections/component-header'
import { DocumentAddIcon } from '@heroicons/react/outline'
import Table from '../../components/table'
import WorkPeriodEventModalForm from '../../components/work-period-events/work-period-event-modal-form'
import { MutationState, useMutationState } from '../../queries'
import { useCreateMutationKey, useUpdateMutationKey } from '../../queries/work-period-events'
import { RequestButton } from '../../components/request-components/request-components'
import locales from '../../../../locales/work-period-events'

const WorkPeriodEventsList: React.FC = ({}) => {
  const query = useWorkPeriodEventsQuery()
  const createMutationState = useMutationState(useCreateMutationKey())
  const updateMutationState = useMutationState(useUpdateMutationKey())
  const [currentWorkPeriodEvent, setCurrentWorkPeriodEvent] = React.useState<
    WorkPeriodEvent | 'new' | undefined
  >()
  const tableColumns: Column<WorkPeriodEvent>[] = useListTableColumns(
    query?.data || [],
    workPeriodEvent => setCurrentWorkPeriodEvent(workPeriodEvent),
    createMutationState || updateMutationState,
  )

  React.useEffect(() => {
    if (createMutationState?.status === 'success') setCurrentWorkPeriodEvent(undefined)
  }, [createMutationState?.status])
  React.useEffect(() => {
    if (updateMutationState?.status === 'success') setCurrentWorkPeriodEvent(undefined)
  }, [updateMutationState?.status])

  return (
    <Layout>
      <PageHeading
        title="Liste des Évènements"
        subtitle="Liste de tous les Évènements qui peuvent être liés à des journées de travail"
      />
      <Section>
        <Container>
          <ComponentHeader>
            <ComponentHeader.Left></ComponentHeader.Left>
            <ComponentHeader.Right>
              <Form.Field>
                <Form.Label>&nbsp;</Form.Label>
                <Form.Control>
                  <Button
                    outlined
                    color="primary"
                    onClick={() => setCurrentWorkPeriodEvent('new')}
                    data-test="new-work-period-event"
                  >
                    <Icon>
                      <DocumentAddIcon />
                    </Icon>
                    <Element renderAs="span">Nouvel évènement</Element>
                  </Button>
                  <Element></Element>
                </Form.Control>
              </Form.Field>
            </ComponentHeader.Right>
          </ComponentHeader>
          <Table<WorkPeriodEvent>
            columns={tableColumns}
            data={query.data || []}
            noDataMessage="Aucune donnée"
          />
        </Container>
      </Section>
      <WorkPeriodEventModalForm
        isDisplayed={Boolean(currentWorkPeriodEvent)}
        setIsDisplayed={() => setCurrentWorkPeriodEvent(undefined)}
        workPeriodEvent={currentWorkPeriodEvent === 'new' ? undefined : currentWorkPeriodEvent}
      />
    </Layout>
  )
}

const useListTableColumns = (
  workPeriodEvents: WorkPeriodEvent[],
  onClick: (workPeriodEvent: WorkPeriodEvent) => void,
  mutation?: MutationState,
): Column<WorkPeriodEvent>[] =>
  React.useMemo(
    () => [
      {
        Header: 'Code',
        accessor: 'code',
      },
      {
        Header: 'Intitulé',
        accessor: 'title',
        Cell: (data: any) => {
          const workPeriodEvent: WorkPeriodEvent = data.cell.row.original
          return (
            <RequestButton
              color="ghost"
              onClick={() => onClick(workPeriodEvent)}
              size={'small'}
              mutation={mutation?.data?.data?._id === workPeriodEvent._id ? mutation : undefined}
            >
              {workPeriodEvent.title}
            </RequestButton>
          )
        },
      },
      {
        Header: 'Type',
        accessor: 'type',
        Cell: (data: any) => {
          const workPeriodEvent: WorkPeriodEvent = data.cell.row.original
          return locales.types[workPeriodEvent.type]
        },
      },
    ],
    [workPeriodEvents, mutation],
  )

export default WorkPeriodEventsList
