import {
  addWeeks,
  differenceInMilliseconds,
  differenceInWeeks,
  eachDayOfInterval,
  endOfWeek,
  getDay,
  isSameDay,
  setYear,
  startOfWeek,
} from 'date-fns'
import { WorkPeriod, WorkPeriodWithSummary } from '../api/work-periods'
import { dateInputValuesToWeekDates, getWeekdayMondayBased, setWeek, WeekDay } from './date'
import { fr } from 'date-fns/locale'
import { Mission } from '../api/missions'
import { buildWorkPeriodEventLabel } from './work-period-events'
import { sortBy } from 'lodash'
import { BreakRule } from '../../../backend/src/services/resources/clocking-rules/clocking-rule.model'
import { WorkPeriodEvent } from '../api/work-period-events'

const getWorkPeriodFromDay = (
  workPeriods: WorkPeriod[],
  day: WeekDay,
): WorkPeriod | WorkPeriodWithSummary | undefined =>
  workPeriods.find(
    workPeriod => getWeekdayMondayBased(getDay(new Date(workPeriod.start.date))) === day,
  )

export const getWorkPeriodFromDayDate = (
  workPeriods: WorkPeriod[],
  date: Date,
): WorkPeriod | WorkPeriodWithSummary | undefined =>
  workPeriods.find(workPeriod => isSameDay(new Date(workPeriod.start.date), date))

//TODO: temp break calculation (need to be calculated by a shared util or by backend)
export const calculateWorkPeriodDuration = (
  start?: Date,
  end?: Date,
  breakRule?: BreakRule,
): number => {
  if (!start || !end || !breakRule) return 0
  const oneHour = 1000 * 60 * 60
  const breakDuration = (oneHour * breakRule.breakDuration) / 60
  const breakGoalDuration = (oneHour * breakRule.breakAllowedAfter) / 60
  let diff = differenceInMilliseconds(new Date(end), new Date(start))
  if (diff >= breakGoalDuration) diff -= breakDuration
  return diff
}
export const calculateWorkPeriodsDuration = (
  workPeriods: { start?: { date: Date }; end?: { date: Date } }[],
  breakRule?: BreakRule,
): number => {
  const totalDuration = workPeriods.reduce((duration, workPeriod) => {
    if (workPeriod.start && workPeriod.end) {
      return (
        duration +
        calculateWorkPeriodDuration(
          new Date(workPeriod.start.date),
          new Date(workPeriod.end.date),
          breakRule,
        )
      )
    } else return duration
  }, 0)
  return totalDuration
}

const exportWorkPeriodsDates = (
  workPeriods: { start: { date: Date }; end: { date: Date } }[],
): { start: Date; end: Date }[] =>
  workPeriods.map(workPeriod => ({
    start: workPeriod.start.date,
    end: workPeriod.end.date,
  }))

const getWorkPeriodsWeeks = (
  workPeriods: WorkPeriod[],
): { weekNumber: number; year: number; dates: Date[] }[] =>
  workPeriods.reduce<{ weekNumber: number; year: number; dates: Date[] }[]>((weeks, workPeriod) => {
    if (!weeks.some(week => week.weekNumber === workPeriod.week && week.year === workPeriod.year))
      weeks.push({
        weekNumber: workPeriod.week,
        year: workPeriod.year,
        dates: eachDayOfInterval({
          start: startOfWeek(setWeek(setYear(new Date(), workPeriod.year), workPeriod.week), {
            locale: fr,
          }),
          end: endOfWeek(setWeek(setYear(new Date(), workPeriod.year), workPeriod.week), {
            locale: fr,
          }),
        }),
      })
    return weeks
  }, [])

export const isAllWeekWorkPeriodsPreBillied = (
  mission: Mission,
  week: number,
  year: number,
): string | undefined => {
  const weekWorkPeriods = mission.workPeriods.filter(w => w.year === year && w.week === week)
  return weekWorkPeriods[0]?.preBilling
}

const buildEventLabel = (event: WorkPeriod['events'][0]): string =>
  buildWorkPeriodEventLabel(event.item)

export const buildEventsLabels = (events: WorkPeriod['events']): string =>
  sortBy(events, 'item.code').map(buildEventLabel).join('\n')

export const getWorkPeriodsWithEvent = (workPeriods: WorkPeriod[], eventId: string): WorkPeriod[] =>
  workPeriods.filter(w => w.events.some(e => e.item._id === eventId))

export const hasBenefitEvent = (workPeriod: WorkPeriod): boolean =>
  workPeriod.events.some(e => e.item.type === 'benefit')

export const getEventsByType = (
  workPeriod: WorkPeriod,
  eventType: WorkPeriodEvent['type'],
): WorkPeriod['events'] => workPeriod.events.filter(e => e.item.type === eventType)
