import React, { useState } from 'react'
import { Modal as BulmaModal } from 'react-bulma-components'

export interface ModalActions {
  isDisplayed: boolean
  setIsDisplayed: React.Dispatch<React.SetStateAction<boolean>>
}

export interface ModalProps {
  title?: string
  footer?: string
  children: React.ReactNode
  actions: ModalActions
  maxWidth?: number
}

const Modal: React.FC<ModalProps> = ({ title, children, footer, actions, maxWidth }) => {
  const { isDisplayed, setIsDisplayed } = actions
  return (
    <BulmaModal
      showClose
      show={isDisplayed}
      onClose={() => setIsDisplayed(false)}
      closeOnBlur
      closeOnEsc
    >
      <BulmaModal.Card style={{ maxWidth: maxWidth, width: maxWidth }}>
        <BulmaModal.Card.Header>
          <BulmaModal.Card.Title>{title && title}</BulmaModal.Card.Title>
        </BulmaModal.Card.Header>
        <BulmaModal.Card.Body>{children}</BulmaModal.Card.Body>
        {footer && <BulmaModal.Card.Footer>{footer}</BulmaModal.Card.Footer>}
      </BulmaModal.Card>
    </BulmaModal>
  )
}
export default Modal

interface UseModalOptions {
  isDisplayedDefaultValue?: boolean
  autoClose?: { on: boolean; delay?: number }
}

export const useModal = (options?: UseModalOptions): ModalActions => {
  const [isDisplayed, setIsDisplayed] = useState(options?.isDisplayedDefaultValue || false)

  const [autoCloseIsActive, setAutoCloseIsActive] = React.useState(false)

  React.useEffect(() => {
    setAutoCloseIsActive(Boolean(options?.autoClose?.on))
  }, [options?.autoClose?.on])

  React.useEffect(() => {
    if (!options?.autoClose) return
    let timeout: NodeJS.Timeout
    if (autoCloseIsActive && options?.autoClose?.delay)
      timeout = setTimeout(
        () => autoCloseIsActive && setIsDisplayed(false),
        options.autoClose.delay || 0,
      )
    else if (autoCloseIsActive) setIsDisplayed(false)
    return () => timeout && clearTimeout(timeout)
  }, [options?.autoClose, autoCloseIsActive])

  React.useEffect(() => {
    if (options?.autoClose && isDisplayed) setAutoCloseIsActive(false)
  }, [isDisplayed])

  return { isDisplayed, setIsDisplayed }
}
