import { Field, Form, Formik } from 'formik'
import React from 'react'
import { Columns, Container, Element } from 'react-bulma-components'
import { useCreateAssociationMutation } from '../../queries/organizations'
import useStore from '../../store'
import {
  Employer,
  InterimAgency,
  OrganizationCreateAssociationPayload,
} from '../../api/organizations'
import SelectField from '../form/fields/select'
import { RequestButton, RequestMessage } from '../request-components/request-components'

interface AssociationFormProps {
  employers: Employer[]
  interimAgency: InterimAgency
}

const AssociationForm: React.FC<AssociationFormProps> = ({ employers, interimAgency }) => {
  const createAssociationMutation = useCreateAssociationMutation()
  const currentOrganization = useStore(state => state.session.currentOrganization)

  return (
    <Formik<OrganizationCreateAssociationPayload>
      initialValues={{
        employerId: '',
        interimAgencyId: interimAgency._id,
      }}
      enableReinitialize
      validate={values => {
        const errors: any = {}
        if (!values.employerId) errors.employerId = 'Champ requis'
        return errors
      }}
      onSubmit={values => {
        createAssociationMutation.mutate(values)
      }}
    >
      {() => {
        return (
          <Form>
            <Columns>
              <Columns.Column size={12}>
                <Field
                  label={<>Inviter un Employeur à collaborer avec {interimAgency.name}</>}
                  name="employerId"
                  component={SelectField}
                  type="number"
                  min={0}
                  step={0.001}
                  required
                  options={employers
                    .filter(
                      employer =>
                        !employer.associations.some(
                          association => association.interimAgency._id === currentOrganization?._id,
                        ),
                    )
                    .map(employer => ({
                      label: employer.name,
                      value: employer._id,
                    }))}
                />
              </Columns.Column>
            </Columns>
            <Container>
              <Element mt={6}>
                <Element mb={1}>
                  <RequestMessage mutation={createAssociationMutation} />
                </Element>
                <RequestButton
                  color="primary"
                  type="submit"
                  mutation={createAssociationMutation}
                  data-test="submit-new-association-form"
                >
                  Inviter la société
                </RequestButton>
              </Element>
            </Container>
          </Form>
        )
      }}
    </Formik>
  )
}

export default AssociationForm
