import React from 'react'
import { Mission } from '../../api/missions'
import { Size } from 'react-bulma-components/src/components'
import { Button, Element, Icon } from 'react-bulma-components'
import ReactTooltip from 'react-tooltip'
import { WorkPeriodCancelledIcon } from '../icons'
import { isAfter } from 'date-fns'
import { getLastWorkPeriod } from '../../utils/missions'

interface MissionCancelButtonProps {
  mission: Mission
  label?: string
  size?: Size | 'smallest'
  type?: 'button' | 'submit' | 'reset'
  onClick(mission: Mission): void
}

const MissionCancelButton: React.FC<MissionCancelButtonProps> = ({
  mission,
  size,
  type,
  onClick,
  label,
}) => {
  if (mission.isCancelled) return <></>
  const tooltipId = `cancel-mission-${mission._id}`
  const canBeCancelled = mission.workPeriods.some(workPeriod => workPeriod.__actions.canBeCancelled)
  const lastWorkPeriod = getLastWorkPeriod(mission)
  return (
    <Element renderAs="span" data-tip={!canBeCancelled} data-for={tooltipId}>
      {!canBeCancelled && (
        <ReactTooltip id={tooltipId} effect="solid">
          <Element textAlign={'center'} textWeight="bold">
            Interruption impossible
          </Element>
          {mission.isValidated && <Element textAlign={'center'}>Mission validée</Element>}
          {mission.isAbandoned && <Element textAlign={'center'}>Mission abandonée</Element>}
          {isAfter(new Date(), new Date(lastWorkPeriod.start.date)) && (
            <Element textAlign={'center'}>
              Toutes les journées de travail
              <br /> sont entamées
            </Element>
          )}
        </ReactTooltip>
      )}
      <Button
        style={size === 'smallest' ? { height: '1.5rem' } : {}}
        size={size === 'smallest' ? 'small' : size}
        type={type}
        color={canBeCancelled ? 'link' : ''}
        onClick={() => onClick(mission)}
        disabled={!canBeCancelled}
        data-test="cancel-mission"
      >
        <Icon>
          <WorkPeriodCancelledIcon />
        </Icon>
        {label && <span>{label}</span>}
      </Button>
    </Element>
  )
}

export default MissionCancelButton
