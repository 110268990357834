import React from 'react'
import useStore from '../../store'
import { Field, Form, Formik } from 'formik'
import { useUpdateMutation } from '../../queries/users'
import { UpdateUserPayload } from '../../api/users'
import { Element, Level } from 'react-bulma-components'
import CheckboxField from '../form/fields/checkbox'
import { RequestButton, RequestMessage } from '../request-components/request-components'
import { useConfigQuery } from '../../queries/config'
import { useNavigate } from 'react-router-dom'
import { queryClient } from '../../queries'

interface AcceptNewConditionFormProps {
  type: 'termsOfUse' | 'privacyPolicy'
}

const AcceptNewConditionForm: React.FC<AcceptNewConditionFormProps> = ({ type }) => {
  const mutation = useUpdateMutation()
  const navigate = useNavigate()
  const currentUser = useStore(state => state.session.currentUser)
  const { data: config } = useConfigQuery()

  const labels = {
    termsOfUse: {
      validation: "Merci d'accepter nos Conditions Générales d'Utilisation.",
      fieldText: "Accepter les Conditions Générales d'Utilisation.",
    },
    privacyPolicy: {
      validation: "Merci d'accepter notre Politique de Confidentialité",
      fieldText: 'Accepter la Politique de Confidentialité',
    },
  }

  const onSubmit = () => {
    if (!currentUser || !config) return
    const payload: UpdateUserPayload = { id: currentUser?._id }
    if (type === 'termsOfUse') payload.lastTermsOfUseVersionRead = config.versions.termsOfUse
    if (type === 'privacyPolicy')
      payload.lastPrivacyPolicyVersionRead = config.versions.privacyPolicy
    mutation.mutate(payload)
  }

  if (mutation.isSuccess) {
    queryClient.clear() 
    navigate('/')
  }

  if (!currentUser) return <></>
  return (
    <Formik
      initialValues={{
        accepted: '',
      }}
      enableReinitialize
      validate={values => {
        const errors: any = {}
        if (!values.accepted) errors.accepted = labels[type].validation
        return errors
      }}
      onSubmit={onSubmit}
    >
      {() => {
        return (
          <Form>
            <Element mb={5}>
              <Field
                text={labels[type].fieldText}
                name="accepted"
                component={CheckboxField}
                options={[{ label: 'Accepter' }]}
                data-test='accept'
                required
              />
            </Element>
            <Level mt={5}>
              <Level.Side>
                <RequestMessage mutation={mutation} />
                <RequestButton color="primary" type="submit" mutation={mutation}>
                  Accepter
                </RequestButton>
              </Level.Side>
            </Level>
          </Form>
        )
      }}
    </Formik>
  )
}

export default AcceptNewConditionForm
