import {
  WorkersRequestMissionDataSchema,
  WorkersRequestPostSchema,
  WorkersRequestSchema,
} from '../../../backend/src/services/resources/workers-requests/workers-request.model'
import {
  AssignWorkerToPostParams,
  CreatePostParams,
  UpdatePostParams,
  WorkersRequestBusinessCreationPayload,
  WorkersRequestBusinessUpdatePayload,
} from '../../../backend/src/services/resources/workers-requests/workers-requests.business.service.types'
import api, { ApiResponse } from '.'
import { Employer, InterimAgency } from './organizations'
import { FetchMissionsParams, Mission } from './missions'
import { JobTitle } from './job-titles'
import { ClockingRules } from './clocking-rules'
import { Filters, WorkersRequestsFilters } from '../store/filters'
import { UserSchema } from '../../../backend/src/services/resources/users/user.model'
import { User } from './users'

interface WorkersRequestMissionData extends WorkersRequestMissionDataSchema {
  jobTitle: JobTitle
  clockingRules: ClockingRules
}

export interface WorkersRequestPost extends Omit<WorkersRequestPostSchema, 'missions'> {
  _id: string
  missions: Mission[]
  jobTitle: JobTitle
  desiredWorkers: User[]
}

export interface WorkersRequestHistoryItem {
  _id: string
  by: UserSchema
  changes: {
    _id: string
    attribute: string
    oldValue?: string
    newValue?: string
  }[]
  missions: Mission[]
}

export interface WorkersRequest
  extends Omit<
    WorkersRequestSchema,
    'interimAgency' | 'employer' | 'posts' | 'history' | 'desiredWorkers'
  > {
  _id: string
  interimAgency: InterimAgency
  employer: Employer
  missionData: WorkersRequestMissionData
  posts: WorkersRequestPost[]
  history: WorkersRequestHistoryItem[]
  desiredWorkers: User[]
}

const POPULATE = [
  'employer',
  'interimAgency',
  'posts.missions',
  'posts.missions.user',
  'posts.desiredWorkers',
  'posts.jobTitle',
  'history.by',
  'history.missions',
  'history.missions.user',
]

type FetchWorkersRequestsParams = WorkersRequestsFilters

//TODO : review this
const buildFetchParams = (params: FetchWorkersRequestsParams) => {
  const { service, isExpired, jobTitle, start, end, ...rest } = params

  const expiredParams: { 'missionsEnd>'?: Date; 'missionsEnd<'?: Date } = {}

  if (isExpired?.length === 1) {
    if (isExpired[0] === false) expiredParams['missionsEnd>'] = new Date()
    else expiredParams['missionsEnd<'] = new Date()
  }

  return {
    sort: '-missionsStart',
    ...expiredParams,
    'missionsStart>': start,
    'missionsStart<': end,
    'missionData.service': service,
    'posts.jobTitle': jobTitle,
    ...rest,
  }
}

export const fetch = async (params?: any): Promise<ApiResponse<WorkersRequest[]>> => {
  return await api({
    endpoint: '/workers-requests',
    method: 'get',
    params: {
      populate: params.populate || POPULATE,
      ...(buildFetchParams(params) || {}),
    },
  })
}

export const count = async (params?: any): Promise<ApiResponse<number>> => {
  return await api({
    endpoint: '/workers-requests/count',
    method: 'get',
    params: {
      ...(buildFetchParams(params) || {}),
    },
  })
}

export const get = async (id: string): Promise<ApiResponse<WorkersRequest>> =>
  await api({
    endpoint: `/workers-requests/${id}`,
    params: {
      populate: POPULATE,
    },
    method: 'get',
  })

export const fetchServices = async (
  params: FetchMissionsParams,
): Promise<ApiResponse<string[]>> => {
  const { start, end, ...rest } = params
  return await api({
    endpoint: '/workers-requests/services',
    method: 'get',
    params: {
      ...rest,
      'missionsStart>': start,
      'missionsStart<': end,
    },
  })
}

export type WorkersRequestCreationPayload = Omit<
  WorkersRequestBusinessCreationPayload,
  'employer' | 'createdBy'
>
export const create = async (
  params: WorkersRequestCreationPayload,
): Promise<ApiResponse<WorkersRequest>> =>
  await api({
    endpoint: '/workers-requests',
    method: 'post',
    params,
  })

export interface WorkersRequestUpdatePayload
  extends Omit<WorkersRequestBusinessUpdatePayload, 'updatedBy'> {
  id: string
}

export const update = async (
  params: WorkersRequestUpdatePayload,
): Promise<ApiResponse<WorkersRequest>> => {
  const { id, ...payload } = params
  return await api({
    endpoint: `/workers-requests/${id}`,
    method: 'put',
    params: payload,
  })
}

type CreatePostParamsPayload = Omit<CreatePostParams['payload'], 'createdBy'>
export interface CreatePostPayload extends CreatePostParamsPayload {
  workersRequestId: string
}

export const createPost = async (
  params: CreatePostPayload,
): Promise<ApiResponse<WorkersRequest>> => {
  const { workersRequestId, ...payload } = params
  return await api({
    endpoint: `/workers-requests/${workersRequestId}/posts`,
    method: 'post',
    params: payload,
  })
}

type UpdatePostParamsPayload = Omit<UpdatePostParams['payload'], 'updatedBy' | 'jobTitle'>
export interface UpdatePostPayload extends UpdatePostParamsPayload {
  postId: string
  workersRequestId: string
  jobTitleId: string
  description?: string
}

export const updatePost = async (
  params: UpdatePostPayload,
): Promise<ApiResponse<WorkersRequest>> => {
  const { workersRequestId, postId, ...payload } = params
  return await api({
    endpoint: `/workers-requests/${workersRequestId}/posts/${postId}`,
    method: 'put',
    params: payload,
  })
}

export interface DeletePostPayload {
  postId: string
  workersRequestId: string
}

export const deletePost = async (
  params: DeletePostPayload,
): Promise<ApiResponse<WorkersRequest>> => {
  const { workersRequestId, postId, ...payload } = params
  return await api({
    endpoint: `/workers-requests/${workersRequestId}/posts/${postId}`,
    method: 'delete',
    params: payload,
  })
}

type AssignWorkerToPostParamsPayload = Omit<AssignWorkerToPostParams['payload'], 'updatedBy'>
export interface AssignWorkerToPostPayload extends AssignWorkerToPostParamsPayload {
  postId: string
  workersRequestId: string
}

export const assignWorkerToPost = async (
  params: AssignWorkerToPostPayload,
): Promise<ApiResponse<WorkersRequest>> => {
  const { workersRequestId, postId, ...payload } = params
  return await api({
    endpoint: `/workers-requests/${workersRequestId}/posts/${postId}/assign`,
    method: 'put',
    params: payload,
  })
}
