import React from 'react'
import { Card, Element, Heading, Icon } from 'react-bulma-components'
import { WorkersRequest } from '../../api/workers-requests'
import AssignWorkerForm from './assign-worker-form'
import { Mission } from '../../api/missions'
import { Link } from 'react-router-dom'
import { MissionAbandonedMessage, MissionIsCancelledMessage } from '../missions/mission-messages'
import WorkersRequestPostMessages from './posts/workers-requests-posts-messages'
import { useAvailableWorkersQuery } from '../../queries/users'
import useStore from '../../store'
import {
  WorkersRequestRecourseCaseJustification,
  WorkersRequestRecourseCaseReason,
} from './recourse-case'
import Avatar from '../avatar/avatar'
import WorkersRequestPostDesiredWorker from './desired-worker'
import { useAssignWorkerToPost } from '../../queries/workers-requests'
import { getUserLabel, getWorkerLaborCostCoefficientKind } from '../../utils/users'
import WorkerLaborCostCoefficientKindLabel from '../users/worker-labor-cost-coefficient-kind-label'

interface WorkersRequestPostListItemProps {
  post: WorkersRequest['posts'][0]
  workersRequest: WorkersRequest
}

const WorkersRequestPostListItem: React.FC<WorkersRequestPostListItemProps> = ({
  post,
  workersRequest,
}) => {
  const currentUserRole = useStore(state => state.session.currentUserRole)
  const assignWorkerToPostMutation = useAssignWorkerToPost()
  const availableWorkersQuery = useAvailableWorkersQuery(
    {
      workersRequestId: workersRequest._id,
    },
    { enabled: Boolean(workersRequest) && currentUserRole === 'interimAgencyMember' },
  )
  const activeMission = post?.missions.find(
    mission => !mission?.isCancelled && !mission?.isAbandoned, // TODO: better check. Last item in list if post is not replaceable?
  )

  const postMissions: (Mission | undefined)[] = [...(post?.missions || [])]
  if (!activeMission) postMissions.push(undefined)

  return (
    <Card mb={5}>
      <Card.Content pb={3}>
        <Element>
          <Heading size={5} display="flex">
            <Element renderAs="span" mr={3}>
              {post.jobTitle.name}
            </Element>{' '}
            <WorkersRequestPostMessages post={post} type="icon" />
          </Heading>
          <Element mb={3} textSize={6}>
            <Element mb={1} textWeight="bold" textSize={7}>
              Description de la Demande
            </Element>
            {post.description || 'N/A'}
            <Element mb={1} textWeight="bold" mt={3} textSize={7}>
              Informations complémentaires pour l'Intérimaire
            </Element>
            <ul>
              {post.jobTitle.complementaryInformations ? (
                <li>{post.jobTitle.complementaryInformations}</li>
              ) : undefined}
              {workersRequest.missionData.complementaryInformations ? (
                <li>{workersRequest.missionData.complementaryInformations}</li>
              ) : undefined}
              {!post.jobTitle.complementaryInformations &&
                !workersRequest.missionData.complementaryInformations && <li>N/A</li>}
            </ul>
          </Element>
        </Element>
        <Element mb={3} textSize={7} mt={1}>
          <Element mb={1} textWeight="bold">
            Cas de recours et justification
          </Element>
          <WorkersRequestRecourseCaseReason recourseCase={post.recourseCase} /> (
          <WorkersRequestRecourseCaseJustification recourseCase={post.recourseCase} />)
        </Element>
        <br />
        {postMissions.map((mission, index) => {
          return (
            <Element
              mb={index === postMissions.length - 1 ? undefined : 3}
              key={mission?._id || index}
            >
              <PostMission
                key={mission?._id || 'new'}
                post={post}
                mission={mission}
                workersRequest={workersRequest}
              />
            </Element>
          )
        })}
        <br />
        {!activeMission && post.desiredWorkers[0] && (
          <Element textSize={7} textAlign="right">
            <Element renderAs="span" textWeight="bold" mr={1}>
              Suggestions :
            </Element>
            {post.desiredWorkers.map(worker => (
              <Element renderAs="span" key={worker._id} ml={1}>
                <WorkersRequestPostDesiredWorker
                  worker={worker}
                  workersRequest={workersRequest}
                  post={post}
                  availableWorkers={
                    currentUserRole === 'interimAgencyMember'
                      ? availableWorkersQuery.data || []
                      : undefined
                  }
                  onClick={
                    currentUserRole === 'interimAgencyMember'
                      ? () =>
                          assignWorkerToPostMutation.mutate({
                            workersRequestId: workersRequest._id,
                            postId: post._id,
                            userId: worker._id,
                          })
                      : undefined
                  }
                />
              </Element>
            ))}
          </Element>
        )}
      </Card.Content>
    </Card>
  )
}

export default WorkersRequestPostListItem

interface WorkersRequestPostListMissionProps extends WorkersRequestPostListItemProps {
  mission?: Mission
}

const PostMission: React.FC<WorkersRequestPostListMissionProps> = ({
  mission,
  workersRequest,
  post,
}) => {
  const laborCostCoefficientKind =
    (mission?.user &&
      getWorkerLaborCostCoefficientKind(
        mission.user,
        workersRequest.interimAgency,
        workersRequest.employer,
      )) ||
    'delegation'
  return (
    <Element key={mission?._id || 'new'}>
      {mission?.user && (
        <Element mr={2} renderAs="span">
          <Avatar user={mission.user} small />
        </Element>
      )}
      {mission ? (
        <>
          <PostMissionLink mission={mission} />
          <Element renderAs="small">
            {' '}
            /{' '}
            <WorkerLaborCostCoefficientKindLabel
              laborCostCoefficientKind={laborCostCoefficientKind}
            />
          </Element>
        </>
      ) : (
        (post.missions.length === 0 || post.isReplaceable) && (
          <AssignWorkerForm workersRequest={workersRequest} postId={post?._id} />
        )
      )}
    </Element>
  )
}

const PostMissionLink: React.FC<{ mission: Mission }> = ({ mission }) => {
  return (
    <Element renderAs="span">
      <Link to={`/missions/${mission._id}`}>
        <Icon.Text>
          {mission.isCancelled && <MissionIsCancelledMessage mission={mission} type="icon" />}
          {mission.isAbandoned && <MissionAbandonedMessage mission={mission} type="icon" />}
          <span
            style={
              mission.isCancelled || mission.isAbandoned ? { textDecoration: 'line-through' } : {}
            }
          >
            {getUserLabel(mission.user)}
          </span>
        </Icon.Text>
      </Link>
    </Element>
  )
}
