import { FieldProps } from 'formik'
import React from 'react'
import FormField, { FormFieldProps } from './form-field'
import Select, { components } from 'react-select'

interface MultiSelectFieldOption {
  value: string
  label: string
  [key: string]: any
}

interface MultiSelectFieldProps extends FormFieldProps, FieldProps {
  options: MultiSelectFieldOption[]
  inputFieldName?: string
  onInputChange?(value: string): void
  disableDefaultFilter?: boolean
}

const TextOption = (props: any) => {
  props.innerProps['data-test'] = `${props.innerProps['data-test']}-${props.data.value}`
  return <components.Option {...props}>{props.data.label}</components.Option>
}

const ClearIndicator = (props: any) => {
  props.innerProps['data-test'] = `${props.innerProps['data-test']}`
  return <components.ClearIndicator {...props} />
}

const addDataTest = (Component: any, innerProps: any) => (props: any) =>
  <Component {...props} innerProps={Object.assign({}, props.innerProps, innerProps)} />

const MultiSelectField: React.FunctionComponent<MultiSelectFieldProps> = props => {
  const { field, form, options, ...rest } = props

  const defaultValue = React.useMemo(() => {
    return (field.value || []).map((v: string | boolean) => options.find(o => o.value === v))
  }, [options, field.value])

  return (
    <FormField label={props.label} error={form.errors[field.name] as string}>
      <Select
        defaultValue={defaultValue}
        isMulti
        name={field.name}
        options={props.options}
        className="basic-multi-select"
        classNamePrefix="select"
        placeholder="Choisir un ou plusieurs..."
        id={`multi-select-${field.name}`}
        components={{
          Option: addDataTest(TextOption, { 'data-test': `multi-select-option-${field.name}` }),
          ClearIndicator: addDataTest(ClearIndicator, {
            'data-test': `multi-select-clear-${field.name}`,
          }),
        }}
        closeMenuOnSelect={false}
        onInputChange={value => {
          props.inputFieldName && form.setFieldValue(props.inputFieldName, value)
          props.onInputChange && props.onInputChange(value)
        }}
        filterOption={props.disableDefaultFilter ? () => true : undefined}
        onChange={v =>
          form.setFieldValue(
            field.name,
            v.map(i => i.value),
          )
        }
      />
    </FormField>
  )
}

export default MultiSelectField
