import React, { useMemo } from 'react'
import { Column } from 'react-table'
import Table from '../table'
import { localDate } from '../../utils/date'
import { AppSubscriptionPeriod } from '../../api/app-subscription-periods'
import { Box, Heading } from 'react-bulma-components'

interface AppSubscriptionPeriodDailyDetailsProps {
  period: AppSubscriptionPeriod
}

type DailyMetricsWithTiers = AppSubscriptionPeriod['metrics']['dailyMetrics'][number] & {
  [key: string]: any // Pour permettre l'ajout dynamique des tiers
}

/**
 * Composant affichant les métriques journalières d'une période d'abonnement
 */
const AppSubscriptionPeriodDailyDetails: React.FC<AppSubscriptionPeriodDailyDetailsProps> = ({
  period,
}) => {
  const columns = useMemo<Column<DailyMetricsWithTiers>[]>(() => {
    const baseColumns: Column<DailyMetricsWithTiers>[] = [
      {
        Header: 'Date',
        accessor: 'date',
        Cell: ({ value }) => localDate(value),
      },
      {
        Header: 'Périodes de travail',
        accessor: 'workPeriodsCount',
      },
    ]

    // Ajout dynamique des colonnes pour chaque tier
    period.pricing.tiers.forEach((tier, index) => {
      baseColumns.push({
        Header: `Palier ${index + 1} (${tier.workersRange.min}-${tier.workersRange.max || '∞'})`,
        accessor: `tier${index}Count`,
      })
    })

    return baseColumns
  }, [period.pricing.tiers])

  const tableData = useMemo(() => {
    return period.metrics.dailyMetrics.map(metric => {
      const dailyPricing = period.pricing.dailyPricing.find(
        dp => dp.date.toString() === metric.date.toString(),
      )

      const tierCounts = period.pricing.tiers.reduce(
        (acc, _, index) => ({
          ...acc,
          [`tier${index}Count`]:
            dailyPricing?.workPeriodsDistribution[index]?.workPeriodsCount || 0,
        }),
        {},
      )

      return {
        ...metric,
        ...tierCounts,
      }
    })
  }, [period])

  return (
    <>
      <Heading size={6} className="mb-4">
        Détails journaliers
      </Heading>
      <Table
        columns={columns}
        data={tableData}
        className="is-bordered is-striped is-narrow"
        noDataMessage="Aucune donnée journalière disponible"
      />
    </>
  )
}

export default AppSubscriptionPeriodDailyDetails
