import { endOfDay, isAfter, parseISO, startOfDay } from 'date-fns'
import { WorkersRequest, WorkersRequestPost } from '../api/workers-requests'
import { useWorkersRequestsQuery } from '../queries/workers-requests'
import { KPIFilters } from '../store/filters'
import { WorkPeriod } from '../api/work-periods'
import { useWorkPeriodsQuery } from '../queries/work-periods'
import { IsoDate } from './use-calendar'
import { MongooseQuery, stringifyMongooseFilterQuery } from '../types/queries'

export type EmployerStats = {
  start: IsoDate
  end: IsoDate
  workPeriods: {
    combine: (
      stats: EmployerStats['workPeriods'],
      statIds: Array<Exclude<keyof EmployerStats['workPeriods'], 'combine'>>,
    ) => WorkPeriod[]
    total: WorkPeriod[]
    expected: WorkPeriod[] // Not abandoned not cancelled
    notExpected: WorkPeriod[] // Abandoned or cancelled
    cancelled: WorkPeriod[]
    notCancelled: WorkPeriod[]
    abandoned: WorkPeriod[]
    notAbandoned: WorkPeriod[]
    validated: WorkPeriod[]
    notValidated: WorkPeriod[]
    clocked: WorkPeriod[]
    notClocked: WorkPeriod[]
    clockedIn: WorkPeriod[]
    clockedInNotOut: WorkPeriod[]
    clockedOut: WorkPeriod[]
    late: WorkPeriod[]
    notLate: WorkPeriod[]
  }
  posts: {
    total: WorkersRequestPost[]
    filled: WorkersRequestPost[]
    missing: WorkersRequestPost[]
  }
}

// Uses day ranged queries for easier caching by react-query
export const useEmployerStats = (
  start: IsoDate,
  end: IsoDate,
  interimAgency?: KPIFilters['interimAgency'],
  employer?: KPIFilters['employer'],
  user?: KPIFilters['user'],
  service?: KPIFilters['service'],
): EmployerStats => {
  const startDate = startOfDay(parseISO(start))
  const endDate = endOfDay(parseISO(end))
  const workPeriodsQuery = useWorkPeriodsQuery(
    {
      populate: [],
      start: startDate,
      end: endDate,
      interimAgency,
      employer,
      user,
      fields: ['_id', 'workPeriods'],
    },
    { staleTime: 60 * 1000 },
  )

  const query: MongooseQuery<WorkersRequest> = {
    filter: stringifyMongooseFilterQuery<WorkersRequest>({
      'missionData.workPeriods': {
        $elemMatch: {
          'start.date': { $lte: endOfDay(parseISO(end)) },
          'end.date': { $gte: startOfDay(parseISO(start)) },
        },
      },
    }),
  }

  const workersRequestsQuery = useWorkersRequestsQuery(query, { staleTime: 60 * 1000 })

  const posts =
    workersRequestsQuery.data?.reduce((posts, current) => {
      for (const post of current.posts) {
        posts.push({ ...post, workersRequest: current })
      }
      return posts
    }, [] as Array<WorkersRequestPost & { workersRequest: WorkersRequest }>) ?? []

  const workPeriods =
    workPeriodsQuery.data?.filter(wP =>
      service?.[0] ? service.some(s => s === wP.mission.service) : true,
    ) ?? []

  const stats: EmployerStats = {
    start: start,
    end: end,
    workPeriods: {
      combine: (stats, statIds) => {
        const all = statIds.map(statId => stats[statId])
        const result = all.reduce((common, currentArray) => {
          const currentIds = currentArray.map(obj => obj._id)
          return common.filter(obj => currentIds.some(_id => _id === obj._id))
        }, all[0] ?? [])
        return result
      },
      total: workPeriods,
      expected: workPeriods.filter(wP => !wP.isAbandoned && !wP.isCancelled),
      notExpected: workPeriods.filter(wP => wP.isAbandoned || wP.isCancelled),
      cancelled: workPeriods.filter(wP => wP.isCancelled),
      notCancelled: workPeriods.filter(wP => !wP.isCancelled),
      abandoned: workPeriods.filter(wP => wP.isAbandoned),
      notAbandoned: workPeriods.filter(wP => !wP.isAbandoned),
      validated: workPeriods.filter(wP => wP.isValidated),
      notValidated: workPeriods.filter(wP => !wP.isValidated),
      clocked: workPeriods.filter(wP => wP.start.clocking?.date || wP.start.manualClocking),
      notClocked: workPeriods.filter(wP => !wP.start.clocking?.date && !wP.start.manualClocking),
      clockedIn: workPeriods.filter(wP => wP.start.clocking?.date || wP.start.manualClocking),
      clockedInNotOut: workPeriods.filter(
        wP =>
          (wP.start.clocking?.date || wP.start.manualClocking) &&
          !(wP.end.clocking?.date || wP.end.manualClocking),
      ),
      clockedOut: workPeriods.filter(wP => wP.end.clocking?.date || wP.end.manualClocking),
      late: workPeriods.filter(wP => {
        const isLate = isAfter(
          new Date(wP.start.clocking?.date || wP.start.manualClocking || new Date()),
          new Date(wP.start.date),
        )
        return isLate
      }),
      notLate: workPeriods.filter(
        wP =>
          !isAfter(
            new Date(wP.start.clocking?.date || wP.start.manualClocking || new Date()),
            new Date(wP.start.date),
          ),
      ),
    },
    posts: {
      total: posts,
      filled: posts.filter(p => p.missions.length > 0 && !p.__actions.canBeUpdated),
      missing: posts.filter(p => p.missions.length === 0 && p.__actions.canBeUpdated),
    },
  }

  return stats
}
