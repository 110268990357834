import React from 'react'
import Layout from '../../components/layout/layout'
import { Box, Container, Level, Section } from 'react-bulma-components'
import useUrlId from '../../hooks/use-url-id'
import PageTitle from '../../components/pages/page-title'
import { useOrganizationQuery } from '../../queries/organizations'
import { Employer } from '../../api/organizations'
import { useEmployerRuleQuery } from '../../queries/employer-rules'
import EmployerRulesForm from '../../components/employer-rules/employer-rules-form'

const EmployerRulesDetails: React.FC = ({}) => {
  const { id: employerId } = useUrlId({ idName: 'employerId' })
  const { id, isNew } = useUrlId()

  const { data: employer } = useOrganizationQuery<Employer>(employerId, 'employer')
  const { data: employerRule } = useEmployerRuleQuery(id, { enabled: Boolean(id && !isNew) })

  return (
    <Layout>
      <Section>
        <Container max={true} breakpoint="desktop">
          <Level alignItems="flex-start" mb={6}>
            <Level.Side>
              <PageTitle
                title={isNew ? 'Nouvel Règle Financière' : employerRule?.name || ''}
                subTitle={employer?.name}
              />
            </Level.Side>
            <Level.Side></Level.Side>
          </Level>
          <Box>
            <EmployerRulesForm employerRule={employerRule} employerId={employerId} />
          </Box>
        </Container>
      </Section>
    </Layout>
  )
}

export default EmployerRulesDetails
