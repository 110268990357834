import React from 'react'
import {
  AcademicCapIcon,
  ColorSwatchIcon,
  HandIcon,
  CalendarIcon as _CalendarIcon,
  EyeIcon,
  QuestionMarkCircleIcon,
  SwitchVerticalIcon,
  SearchIcon as _SearchIcon,
  CurrencyEuroIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  PlusIcon,
  FlagIcon,
  XCircleIcon,
  CreditCardIcon,
  DocumentDuplicateIcon,
} from '@heroicons/react/outline'
import {
  BanIcon as _BanIcon,
  CheckIcon as _CheckIcon,
  FolderOpenIcon,
  LockClosedIcon,
  OfficeBuildingIcon,
  UserIcon,
  ClockIcon,
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
  CheckCircleIcon,
  PencilIcon,
  XIcon,
  ArrowNarrowUpIcon,
  ArrowNarrowDownIcon,
  ThumbUpIcon as _ThumbUpIcon,
  ChartPieIcon,
} from '@heroicons/react/solid'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAndroid, faApple } from '@fortawesome/free-brands-svg-icons'

// For all FontAwesome imports
// 1. Use library to load icon with treeshaking
// 2. Use icon using the "string name" with <FontAwesomeIcon icon={[family, name]} />
library.add(faApple, faAndroid)

// Generics
export const ArrowLeftIcon = ArrowNarrowLeftIcon
export const ArrowRightIcon = ArrowNarrowRightIcon
export const ArrowUpIcon = ArrowNarrowUpIcon
export const ArrowDownIcon = ArrowNarrowDownIcon
export const CalendarIcon = _CalendarIcon
export const EditIcon = PencilIcon
export const DuplicateIcon = DocumentDuplicateIcon
export const ViewIcon = EyeIcon
export const RemoveIcon = XIcon
export const ThumbUpIcon = _ThumbUpIcon
export const CheckIcon = _CheckIcon
export const BanIcon = _BanIcon
export const HelpIcon = QuestionMarkCircleIcon
export const DraggableListIcon = SwitchVerticalIcon
export const SearchIcon = _SearchIcon
export const WarningIcon = ExclamationCircleIcon
export const AppleMobileAppIcon = (): JSX.Element => <FontAwesomeIcon icon={['fab', 'apple']} />
export const AndroidMobileAppIcon = (): JSX.Element => <FontAwesomeIcon icon={['fab', 'android']} />
export const InfoIcon = InformationCircleIcon
export const AddResourceIcon = PlusIcon
export const BillIcon = CreditCardIcon
export const KpiIcon = ChartPieIcon

// Employer
export const EmployerIcon = OfficeBuildingIcon
export const EmployerServiceIcon = ColorSwatchIcon
export const JobTitleIcon = AcademicCapIcon

// Interim Agency
export const InterimAgencyIcon = FolderOpenIcon

// Workers
export const WorkerIcon = UserIcon

// Missions
export const MissionNotEditableIcon = LockClosedIcon
export const MissionAbandonedIcon = BanIcon
export const MissionValidatedIcon = CheckCircleIcon
export const MissionNotValidatedIcon = XCircleIcon
export const MissionWeekValidatedIcon = CheckCircleIcon
export const MissionCancelledIcon = HandIcon
export const MissionAddIcon = AddResourceIcon

// WorkPeriods
export const WorkPeriodCancelledIcon = MissionCancelledIcon
export const WorkPeriodValidatedIcon = MissionValidatedIcon
export const WorkPeriodNotValidatedIcon = MissionNotValidatedIcon
export const WorkPeriodEventIcon = FlagIcon
export const WorkPeriodCannotBeValidatedIcon = WarningIcon

// Workers Requests
export const WorkersRequestClosedIcon = LockClosedIcon
export const WorkersRequestCancelIcon = BanIcon
export const WorkersRequestCompleteIcon = CheckIcon
export const WorkersRequestIcon = ClockIcon

// Workers Requests Post
export const WorkersRequestPostEmptyIcon = WarningIcon
export const WorkersRequestPostReplaceableIcon = BanIcon
export const WorkersRequestPostFilledIcon = CheckIcon

//Pre billings
export const PreBillingIcon = CurrencyEuroIcon
