import { Field, Form, Formik } from 'formik'
import React from 'react'
import InputField from '../form/fields/input'
import { useUpdateMutation } from '../../queries/pre-billings'
import { PreBilling } from '../../api/pre-billings'
import { RequestButton } from '../request-components/request-components'

interface PreBillingEditFormProps {
  preBilling: PreBilling
}

const PreBillingEditForm: React.FC<PreBillingEditFormProps> = ({ preBilling }) => {
  const updateMutation = useUpdateMutation()

  return (
    <Formik
      initialValues={preBilling}
      enableReinitialize
      onSubmit={values => {
        updateMutation.mutate({ invoiceRef: values.invoiceRef, id: preBilling._id.toString() })
      }}
    >
      {() => {
        return (
          <Form autoComplete="off">
            <Field label="Numéro de facture" name="invoiceRef" component={InputField} required />
            <RequestButton color="primary" type="submit" mutation={updateMutation}>
              Modifier
            </RequestButton>
          </Form>
        )
      }}
    </Formik>
  )
}
export default PreBillingEditForm
