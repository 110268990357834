import { MissionSchema } from 'src/services/resources/missions/mission.model'
import { hasClockingInProgress } from './work-periods'
import { MissionWithSummary } from '../services/resources/missions/missions.summary.service.types'
import { compareDocsById, getDocIdAsString } from './db'
import { WorkPeriodSchema } from '../services/resources/work-periods/work-period.model'
import { WorkPeriodWithSummary } from '../services/resources/work-period-summaries/work-period-summary.types'

export const isMissionAllWorkPeriodsValidated = (mission: MissionSchema): boolean =>
  mission.workPeriods.every(w => w.isValidated)

export const isMissionsAllWorkPeriodsValidated = (missions: MissionSchema[]): boolean =>
  missions.every(isMissionAllWorkPeriodsValidated)

export const hasWorkPeriodWithClockingInProgress = (mission: MissionSchema): boolean =>
  mission.workPeriods.some(w => hasClockingInProgress(w))

export const filtersWorkPeriodsByWeek = (
  workPeriods: WorkPeriodWithSummary[] | WorkPeriodSchema[],
  week: number,
  year: number,
): WorkPeriodWithSummary[] | WorkPeriodSchema[] =>
  workPeriods.filter(
    workPeriod =>
      workPeriod.year.toString() === year.toString() &&
      workPeriod.week.toString() === week.toString(),
  )

export const hasSameWorkerAndEmployer = (
  mission: MissionSchema | MissionWithSummary,
  worker: MissionSchema['user'],
  employer: MissionSchema['employer'] | MissionSchema['interimAgency'],
): boolean =>
  compareDocsById(mission.user, worker) &&
  (!mission.interimAgency
    ? compareDocsById(mission.employer, employer)
    : compareDocsById(mission.interimAgency, employer))

export const getAllMissionsWithSameWorkerAndEmployer = <T = MissionSchema | MissionWithSummary>(
  missions: T[],
  worker: MissionSchema['user'],
  employer: MissionSchema['employer'] | MissionSchema['interimAgency'],
): T[] => {
  //@ts-ignore
  return missions.filter(mission => hasSameWorkerAndEmployer(mission, worker, employer))
}

export const groupMissionsByWorkerAndEmployer = <T extends MissionSchema | MissionWithSummary>(
  missions: T[],
): { [key: string]: T[] } => {
  return missions.reduce<{ [key: string]: T[] }>((groupedMissions, mission) => {
    // Clé unique pour chaque combinaison worker/employer
    const employerId = getDocIdAsString(mission.interimAgency || mission.employer)
    const workerId = getDocIdAsString(mission.user)
    const groupKey = `${workerId}_${employerId}`

    if (!groupedMissions[groupKey]) {
      groupedMissions[groupKey] = []
    }

    groupedMissions[groupKey].push(mission)
    return groupedMissions
  }, {})
}
