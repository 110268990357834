import { useMemo } from 'react'
import { calculateWorkPeriodDuration, calculateWorkPeriodsDuration } from '../utils/work-periods'
import { BreakRule } from '../../../backend/src/services/resources/clocking-rules/clocking-rule.model'
import { User } from '../api/users'
import useStore from '../store'
import { Organization } from '../api/organizations'
import { useUsersQuery } from '../queries/users'
import { getWorkerAndOrganizationAssociation } from '../utils/users'
import { endOfMonth, endOfWeek, isWithinInterval, parse, startOfMonth, startOfWeek } from 'date-fns'
import { cloneDeep } from 'lodash'

export const useWorkPeriodDuration = (start?: Date, end?: Date, breakRule?: BreakRule): number => {
  const workPeriodDuration = useMemo(
    () => calculateWorkPeriodDuration(start, end, breakRule),
    [start, end, breakRule],
  )
  return workPeriodDuration
}

export const useWorkPeriodsDuration = (
  workPeriods: {
    start?:
      | {
          date: Date
        }
      | undefined
    end?:
      | {
          date: Date
        }
      | undefined
  }[],
  breakRule?: BreakRule | undefined,
  userId?: User['_id'],
  period?: string, // IsoWeek like 2024-W13 or IsoMonth like 2024-03
): { total: number; expected?: number } => {
  const currentOrganization = useStore(state => state.session.currentOrganization) as Organization
  const usersQuery = useUsersQuery({
    'roles.kind': 'worker',
    filter: JSON.stringify({
      associations: {
        $elemMatch: {
          organization: currentOrganization._id,
          status: ['accepted', 'pending'],
        },
      },
    }),
  })
  const currentWorker = userId && usersQuery.data?.find(u => u._id === userId)
  const currentWorkerAssociation =
    currentWorker && currentWorker.associations
      ? getWorkerAndOrganizationAssociation(currentWorker, currentOrganization)
      : undefined
  const currentWorkerSchedule = currentWorkerAssociation?.schedule

  const workPeriodsDuration = useMemo(() => {
    let weekDate: Date
    let monthDate: Date
    let startDate: Date
    let endDate: Date
    let expected: number | undefined
    let filteredWorkPeriods = cloneDeep(workPeriods)

    if (period) {
      try {
        weekDate = parse(period, "RRRR-'W'II", new Date())
        expected = (currentWorkerSchedule?.weeklyHoursWorked ?? 0) * 60 * 60 * 1000
        startDate = startOfWeek(weekDate)
        endDate = endOfWeek(weekDate)
      } catch (err) {}
      try {
        monthDate = parse(period, 'YYYY-MM', new Date())
        expected = (currentWorkerSchedule?.monthlyHoursWorked ?? 0) * 60 * 60 * 1000
        startDate = startOfMonth(monthDate)
        endDate = endOfMonth(monthDate)
      } catch (err) {}
      filteredWorkPeriods = filteredWorkPeriods.filter(wP => {
        if (!wP.start?.date || !wP.end?.date) return false
        return isWithinInterval(wP.start?.date, { start: startDate, end: endDate })
      })
    }

    const totalDuration = calculateWorkPeriodsDuration(filteredWorkPeriods, breakRule)

    return { total: totalDuration, expected }
  }, [workPeriods, breakRule, userId, period])

  return workPeriodsDuration
}
