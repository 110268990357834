import React from 'react'
import { EmployerRule } from '../../api/employer-rules'
import { Element } from 'react-bulma-components'
import locales from '../../../../locales/employer-rules'

interface EmployerRuleDescriptionProps {
  employerRule: EmployerRule
}

const EmployerRuleDescription: React.FC<EmployerRuleDescriptionProps> = ({ employerRule }) => {
  const periodLabel =
    locales.inputs[employerRule.interval?.period]?.[employerRule.interval?.input]?.toLowerCase()

  const { min: intervalMin, max: intervalMax, period } = employerRule.interval

  return (
    <Element>
      {employerRule.type === 'goal' ? (
        <>Une prime fixe de {employerRule.value}€ est attribuée</>
      ) : (
        <>
          Le cout horaire est {employerRule.kind === 'add' ? 'augmenter de' : 'multiplé par'}{' '}
          {employerRule.value}
        </>
      )}{' '}
      {period === 'day' && 'pour chaque jour de la semaine '}
      {employerRule.type === 'goal' ? (
        <>
          lorsque les {periodLabel}{' '}
          {employerRule.interval?.period === 'week' && <>durant la semaine</>}
          {intervalMin ? <>sont</> : null}
        </>
      ) : (
        <>
          pour les {!intervalMin && 'toutes'} {periodLabel}{' '}
          {employerRule.interval?.period === 'week' && <>durant la semaine</>}
        </>
      )}
      {intervalMin ? (
        <>
          {' '}
          {intervalMax ? 'comprisent entre' : 'à partir de'}{' '}
          {employerRule.interval?.min / 1000 / 60} minutes
          {intervalMax ? <> et {intervalMax / 1000 / 60} minutes</> : null}
        </>
      ) : null}
      .
    </Element>
  )
}

export default EmployerRuleDescription
