import React from 'react'
import FieldColumn from '../field-column'
import { Field } from 'formik'
import SelectField from '../../form/fields/select'
import {
  allDaysOfCurrentWeek,
  allDaysOfInterval,
  weekDaysAsInputOptions,
} from '../../../utils/date'

interface DisplayedDaysFilterFieldProps {
  start?: Date
  end?: Date
}

const DisplayedDaysFilterField: React.FC<DisplayedDaysFilterFieldProps> = ({ start, end }) => {
  return (
    <FieldColumn>
      <Field
        label="Jour affiché"
        name="day"
        component={SelectField}
        options={weekDaysAsInputOptions(
          start && end ? allDaysOfInterval(start, end) : allDaysOfCurrentWeek,
        )}
      />
    </FieldColumn>
  )
}

export default DisplayedDaysFilterField
