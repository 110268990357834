import React from 'react'
import { Outlet, useParams, useLocation, Link } from 'react-router-dom'
import Layout from '../../components/layout/layout'
import { Section, Container, Box, Heading, Columns, Menu, Icon } from 'react-bulma-components'
import { useOrganizationQuery } from '../../queries/organizations'
import { InterimAgency } from '../../api/organizations'
import { CurrencyEuroIcon, UserGroupIcon } from '@heroicons/react/outline'
import { EmployerIcon, PreBillingIcon } from '../../components/icons'
import { ViewListIcon } from '@heroicons/react/solid'

interface MenuSection {
  path: string
  label: string
  icon: React.ComponentType
}

export interface OutletContext {
  interimAgency: InterimAgency
}

const InterimAgencyWrapper: React.FC = () => {
  const { id } = useParams()
  const location = useLocation()
  const { data: interimAgency } = useOrganizationQuery<InterimAgency>(id, 'interimAgency')

  const settingsSections: MenuSection[] = [
    {
      path: 'info',
      label: "Détails de l'Agence",
      icon: UserGroupIcon,
    },
    {
      path: 'app-subscription-options',
      label: "Couts d'abonnement",
      icon: CurrencyEuroIcon,
    },
    {
      path: 'partner-employers',
      label: 'Entreprises Partenaires',
      icon: EmployerIcon,
    },
  ]

  const appSubscriptionSections: MenuSection[] = [
    {
      path: 'app-subscription-periods/new',
      label: 'En cours',
      icon: PreBillingIcon,
    },
    {
      path: 'app-subscription-periods',
      label: 'Historique',
      icon: ViewListIcon,
    },
  ]

  const currentSection = [...settingsSections, ...appSubscriptionSections].find(section =>
    location.pathname.includes(section.path),
  )

  if (!interimAgency) {
    return <></>
  }

  return (
    <Layout>
      <Section>
        <Container>
          <Columns>
            <Columns.Column size={3}>
              <Box>
                <Menu>
                  <Menu.List title="Paramètres">
                    <MenuSection
                      sections={settingsSections}
                      currentSection={currentSection}
                      agencyId={id as string}
                    />
                  </Menu.List>
                  <Menu.List title="Abonnements et consommations">
                    <MenuSection
                      sections={appSubscriptionSections}
                      currentSection={currentSection}
                      agencyId={id as string}
                    />
                  </Menu.List>
                </Menu>
              </Box>
            </Columns.Column>

            <Columns.Column>
              <Heading size={5} mt={4}>
                {currentSection?.label}
              </Heading>
              <Box>
                <Outlet context={{ interimAgency }} />
              </Box>
            </Columns.Column>
          </Columns>
        </Container>
      </Section>
    </Layout>
  )
}

export default InterimAgencyWrapper

interface MenuSectionProps {
  sections: Array<{
    path: string
    label: string
    icon: React.ComponentType
  }>
  currentSection?: { path: string }
  agencyId: string
}

const MenuSection: React.FC<MenuSectionProps> = ({ sections, currentSection, agencyId }) => {
  return sections.map(({ path, label, icon: IconComponent }) => {
    const fullPath = `/agencies/${agencyId}/${path}`

    return (
      <Menu.List.Item
        key={path}
        active={Boolean(currentSection?.path === path)}
        renderAs={Link}
        to={fullPath}
      >
        <span className="is-flex is-align-items-center">
          <Icon size="small" className="mr-2">
            <IconComponent />
          </Icon>
          <span>{label}</span>
        </span>
      </Menu.List.Item>
    )
  })
}
