import React from 'react'
import { Button, Container, Element, Heading, Icon, Message, Section } from 'react-bulma-components'
import Layout from '../../components/layout/layout'
import useStore from '../../store'
import ComponentHeader from '../../components/sections/component-header'
import { usePendingInvitationsCount } from '../../hooks/use-pending-invitations-count'
import { NavLink } from 'react-router-dom'
import { AndroidMobileAppIcon, AppleMobileAppIcon } from '../../components/icons'
import config from '../../../../backend/src/config'

const WorkerDashboard: React.FC = () => {
  const currentUser = useStore(state => state.session.currentUser)
  const pendingInvitationsCount = usePendingInvitationsCount()

  return (
    <Layout>
      <Section>
        {pendingInvitationsCount > 0 && (
          <Container mb={5}>
            <Message color={'worker'}>
              <Message.Header>Invitations en attente</Message.Header>
              <Message.Body>
                <Element renderAs="p" mb={3} textColor="dark">
                  Vous avez une ou plusieurs invitations en attente de validation
                </Element>
                <Button renderAs={NavLink} to="/me/organizations">
                  Voir les invitations
                </Button>
              </Message.Body>
            </Message>
          </Container>
        )}
        <Container>
          <ComponentHeader>
            <ComponentHeader.Left>
              <Heading>
                <Element renderAs="span" textWeight="light">
                  Bonjour{' '}
                </Element>
                <Element renderAs="span" textWeight="bold">
                  {currentUser?.firstName} {currentUser?.lastName}
                </Element>
              </Heading>
              <br />
              <Heading subtitle spaced>
                Installez et connectez-vous via l'app mobile :
              </Heading>
              <Button.Group>
                <Button renderAs="a" href={config.mobileApps.apple.store.url}>
                  <Icon size={'large'}>
                    <AppleMobileAppIcon />
                  </Icon>
                  <span>Apple Store</span>
                </Button>
                <Button renderAs="a" href={config.mobileApps.apple.store.url}>
                  <Icon size={'large'}>
                    <AndroidMobileAppIcon />
                  </Icon>
                  <span>Android Store</span>
                </Button>
              </Button.Group>
              <ul>
                <li>
                  <Icon text></Icon>
                </li>
              </ul>
            </ComponentHeader.Left>
            <ComponentHeader.Right>
              <></>
            </ComponentHeader.Right>
          </ComponentHeader>
        </Container>
      </Section>
      {/* <Section>
        <Container>
          <Columns centered>
            <Columns.Column size={4} textAlign="center">
              <Heading textAlign={'center'} textWeight="bold" size={4}>
                Mon QR Code
              </Heading>
              {currentUser && <UserQrCode user={currentUser} print />}
            </Columns.Column>
          </Columns>
        </Container>
      </Section> */}
    </Layout>
  )
}

export default WorkerDashboard
