import React, { ReactNode } from 'react'
import { Element, Table } from 'react-bulma-components'
import { Calendar } from '../../../../hooks/use-calendar'

interface WeeksListProps<T, M> {
  calendar: Calendar<T, M>
}

const weekDays = [1, 2, 3, 4, 5, 6, 0] as const
const weekDayNames = ['Di.', 'Lu.', 'Ma.', 'Me.', 'Je.', 'Ve.', 'Sa.'] as const

export const WeeksList = <T, M>({ children }: { children: ReactNode }): JSX.Element => {
  return (
    <Table bordered size="fullwidth">
      <tbody>
        <>{children}</>
      </tbody>
    </Table>
  )
}

interface WeeksListHeaderProps<T, M> {
  left?: (isoWeekDay: 0 | 1 | 2 | 3 | 4 | 5 | 6 | null) => ReactNode | string
  right?: (isoWeekDay: 0 | 1 | 2 | 3 | 4 | 5 | 6 | null) => ReactNode | string
}

const Header = <T, M>({ left, right }: WeeksListHeaderProps<T, M>) => {
  return (
    <>
      <tr>
        <Element renderAs="td" textWeight="bold">
          Semaine
        </Element>
        {weekDays.map(wD => (
          <td key={wD}>
            <Element display="flex" justifyContent="space-between">
              {left ? (
                left(wD)
              ) : (
                <Element textWeight="bold">
                  <span>{weekDayNames[wD]}</span>
                </Element>
              )}
              <Element>{right ? right(wD) : undefined}</Element>
            </Element>
          </td>
        ))}
      </tr>
    </>
  )
}
WeeksList.Header = Header
