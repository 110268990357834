import React from 'react'
import { ModalProps } from '../modal'
import Modal from '../modal'
import WorkPeriodEventForm, { WorkPeriodEventFormProps } from './work-period-event-form'

interface WorkPeriodEventModalFormProps extends WorkPeriodEventFormProps {
  isDisplayed: ModalProps['actions']['isDisplayed']
  setIsDisplayed: ModalProps['actions']['setIsDisplayed']
}

const WorkPeriodEventModalForm: React.FC<WorkPeriodEventModalFormProps> = ({
  isDisplayed,
  setIsDisplayed,
  workPeriodEvent,
}) => {
  return (
    <Modal
      actions={{ isDisplayed, setIsDisplayed }}
      title={workPeriodEvent ? 'Modifier un Évènement' : 'Créer un nouvel Évènement'}
    >
      <WorkPeriodEventForm workPeriodEvent={workPeriodEvent} />
    </Modal>
  )
}

export default WorkPeriodEventModalForm
