import React from 'react'
import { Button, Icon } from 'react-bulma-components'
import { Link } from 'react-router-dom'
import { Mission } from '../../../api/missions'
import {
  DuplicateIcon,
  EditIcon,
  EmployerServiceIcon,
  InterimAgencyIcon,
  JobTitleIcon,
} from '../../icons'
import PageTitle from '../../pages/page-title'
import Protected from '../../protected/protected'
import { getUserLabel } from '../../../utils/users'

interface MissionPageTitleProps {
  mission?: Mission
  organizationType: 'interimAgency' | 'employer'
  children?: React.ReactNode
}

const MissionPageTitle: React.FC<MissionPageTitleProps> = ({
  mission,
  organizationType,
  children,
}) => {
  if (!mission) {
    return <PageTitle title="Nouvelle Mission Interne" />
  } else
    return (
      <PageTitle
        title={getUserLabel(mission.user)}
        subTitle={
          <>
            <Icon.Text mr={5}>
              <Icon mr={1}>
                <EmployerServiceIcon />
              </Icon>
              {mission.service}
            </Icon.Text>
            <Icon.Text mr={5}>
              <Icon mr={1}>
                <JobTitleIcon />
              </Icon>
              {mission.jobTitle.name}
            </Icon.Text>
            <Icon.Text mr={5}>
              <Icon mr={1}>
                <InterimAgencyIcon />
              </Icon>
              {mission?.[organizationType]?.name}
            </Icon.Text>
          </>
        }
      >
        {children}
      </PageTitle>
    )
}

export default MissionPageTitle

export const EditActions: React.FC<Pick<MissionPageTitleProps, 'mission'>> = ({ mission }) => {
  // const modal = useModal()

  if (!mission) return
  return (
    <>
      <Protected roles={['employerMember']} action={{ resource: 'missions', name: 'update' }}>
        <Button
          color={'link'}
          outlined
          renderAs={Link}
          size="small"
          to={`/missions/${mission._id}/edit`}
        >
          <Icon mr={1}>
            <EditIcon />
          </Icon>
          Modifier la mission
        </Button>
      </Protected>
      {mission.interimAgency ? (
        <Protected
          roles={['employerMember']}
          action={{ resource: 'workersRequests', name: 'create' }}
        >
          <Button
            color={'link'}
            outlined
            renderAs={Link}
            size="small"
            to={`/workers-requests/new?missionTemplate=${mission._id}`}
          >
            <Icon mr={1}>
              <DuplicateIcon />
            </Icon>
            Utiliser pour une nouvelle Demande
          </Button>
        </Protected>
      ) : null}
    </>
  )
}
