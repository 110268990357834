import { Field } from 'formik'
import React from 'react'
import FieldColumn from '../field-column'
import MultiSelectField from '../../form/fields/multi-select'
import useWorkersSearch from '../../../hooks/use-workers-search'
import WorkerLabel from '../../texts/worker-label'

interface WorkersFilterFieldProps {
  disabledQueries?: boolean
}

const WorkersFilterField: React.FC<WorkersFilterFieldProps> = ({ disabledQueries }) => {
  const [workersSearchString, setWorkersSearchString] = React.useState('')
  const workers = useWorkersSearch(workersSearchString, { enabled: !disabledQueries })

  return (
    <FieldColumn>
      <Field
        label={<WorkerLabel plural />}
        component={MultiSelectField}
        name="user"
        onInputChange={setWorkersSearchString}
        options={workers}
        disableDefaultFilter
      />
    </FieldColumn>
  )
}

export default WorkersFilterField
