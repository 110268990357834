import { Field, Form, Formik } from 'formik'
import React from 'react'
import { Columns } from 'react-bulma-components'
import AutocompleteField from '../form/fields/autocomplete'
import { buildListAsInputOptions } from '../../utils/forms'
import { getUserLabel } from '../../utils/users'
import { UserInputLabel } from '../missions/mission-form'
import { User } from '../../api/users'
import { useUsersQuery } from '../../queries/users'
import useStore from '../../store'
import { Organization } from '../../api/organizations'
import InputField from '../form/fields/input'
import { dateInputToDateValue, startOfWeek } from '../../utils/date'
import AutoSubmit from '../form/utils/auto-submit'
import WorkerLabel from '../texts/worker-label'

interface GeneratePayrollFormProps {
  onSubmit(data: { from: Date; to: Date; user: User }): void
}

const GeneratePayrollForm: React.FC<GeneratePayrollFormProps> = ({ onSubmit }) => {
  const currentOrganization = useStore(state => state.session.currentOrganization) as Organization
  const usersQuery = useUsersQuery({
    'roles.kind': 'worker',
    filter: JSON.stringify({
      associations: {
        $elemMatch: {
          organization: currentOrganization._id,
          status: ['accepted', 'pending'],
        },
      },
    }),
  })

  return (
    <Formik<{ userId?: string; from?: string; to?: string }>
      initialValues={{ userId: '' }}
      enableReinitialize
      validate={values => {
        const errors: any = {}
        if (!values.userId) errors.name = 'Champ requis'
        if (!values.from) errors.clockingRule = 'Champ requis'
        if (!values.to) errors.clockingRule = 'Champ requis'
        return errors
      }}
      onSubmit={values => {
        onSubmit({
          from: startOfWeek(dateInputToDateValue(values.from!)),
          to: startOfWeek(dateInputToDateValue(values.to!)),
          user: (usersQuery.data || []).find(u => u._id === values.userId)!,
        })
      }}
    >
      {({ values, submitForm }) => {
        return (
          <Form autoComplete="off">
            <Columns>
              <Columns.Column>
                <Field
                  label={<WorkerLabel />}
                  name="userId"
                  component={AutocompleteField}
                  required
                  fullwidth
                  onlySelect
                  items={buildListAsInputOptions(usersQuery?.data || [], {
                    labelBuilder: (user: User) => getUserLabel(user),
                    renderLabel: user =>
                      UserInputLabel({ user, organization: currentOrganization }),
                  })}
                  selectDefaultChooseOptionLabel={
                    <>
                      Choisir le <WorkerLabel />
                    </>
                  }
                />
              </Columns.Column>
              <Columns.Column>
                <Field
                  label="Date de début"
                  name="from"
                  type="date"
                  component={InputField}
                  max={values.to}
                  required
                />
              </Columns.Column>
              <Columns.Column>
                <Field
                  label="Date de fin"
                  name="to"
                  type="date"
                  component={InputField}
                  required
                  min={values.from}
                />
              </Columns.Column>
            </Columns>
            <AutoSubmit values={values} submitForm={submitForm} />
          </Form>
        )
      }}
    </Formik>
  )
}

export default GeneratePayrollForm
