import React from 'react'
import { NavLink } from 'react-router-dom'

export const ResourceLinkCell = <ResourceSchema extends { _id: string }>({
  resource,
  path,
  label,
}: {
  resource: ResourceSchema
  path: string
  label: string
}): React.ReactElement => {
  return <NavLink to={`${path}${resource._id}`}>{label}</NavLink>
}
