import { startOfDay, subDays } from 'date-fns'
import api, { ApiResponse } from '.'
import { User } from './users'
import { Mission } from './missions'
import { Employer } from './organizations'
import { WorkPeriod } from './work-periods'
import { WorkPeriodClocking } from '../../../backend/src/services/resources/missions/mission.model'

const POPULATE = [
  'mission',
  'user',
  'mission.interimAgency',
  'mission.employer',
  'employer',
  'workPeriod',
]

//TODO: use backend types

export enum ClockingTypeEnum {
  start = 'start',
  end = 'end',
}

interface ClockingWorkPeriod extends Omit<Omit<WorkPeriod, 'start'>, 'end'> {
  start: { date: Date; clocking?: WorkPeriodClocking }
  end: { date: Date; clocking?: WorkPeriodClocking }
}

export interface ClockingMission extends Omit<Mission, 'workPeriods'> {
  workPeriods: ClockingWorkPeriod[]
}

export interface Clocking {
  createdAt: Date
  user: User['_id'] | User
  date: Date
  _id: string
  mission?: ClockingMission
  employer?: Employer
  workPeriod?: WorkPeriod
  type?: ClockingTypeEnum
}

export interface FetchClockingsParams {
  limit?: number
  start?: Date
  end?: Date
  user?: string[]
  workPeriod?: string
}

export const fetch = async (params: FetchClockingsParams): Promise<ApiResponse<Clocking>> => {
  const { start, end, user, workPeriod, ...rest } = params

  return await api({
    endpoint: '/clockings',
    method: 'get',
    params: {
      sort: '-createdAt',
      'date>': start || subDays(startOfDay(new Date()), 7),
      'date<': end,
      populate: POPULATE,
      limit: params?.limit,
      workPeriod,
      user,
    },
  })
}

export interface ClockingCreationPayload {
  hash: { content: string; iv: string }
}

export const create = async (params: ClockingCreationPayload): Promise<ApiResponse<Clocking>> => {
  return await api({
    endpoint: '/clockings',
    method: 'post',
    params,
  })
}
