import React from 'react'
import useUrlId from '../../hooks/use-url-id'
import { useUserQrQuery, useUsersQuery } from '../../queries/users'
import { getUserLabel } from '../../utils/users'
import useStore from '../../store'
import { Element } from 'react-bulma-components'

const QrPrintPage: React.FC = () => {
  const { id } = useUrlId({ idName: 'userId' })
  const currentUser = useStore(state => state.session.currentUser)
  const isCurrentUserQr = currentUser?._id === id
  const qrQuery = useUserQrQuery(id)
  const usersQuery = useUsersQuery(
    {
      _id: id,
      'roles.kind': 'worker',
    },
    { enabled: !isCurrentUserQr },
  )
  const user = isCurrentUserQr ? currentUser : usersQuery.data?.[0]

  return (
    <>
      {qrQuery.data && user && (
        <Element>
          <img
            data-test="user-qr-code"
            src={`data:image/png;base64, ${qrQuery.data}`}
            onLoad={() => window.print()}
          />
          <Element style={{ width: 500, textAlign: 'center', fontSize: 28 }}>
            {getUserLabel(user, { hideOfflineInfo: true })}
          </Element>
        </Element>
      )}
    </>
  )
}

export default QrPrintPage
