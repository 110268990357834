import React from 'react'
import { Element } from 'react-bulma-components'
import { WorkersRequestPost } from '../../api/workers-requests'
import config from '../../../../backend/src/config'

const allRecourseCasesReasons = config.workersRequests.recourseCases.reasons

interface WorkersRequestRecourseCaseProps {
  recourseCase: WorkersRequestPost['recourseCase']
}

export const WorkersRequestRecourseCaseReason: React.FC<WorkersRequestRecourseCaseProps> = ({
  recourseCase,
}) => {
  return <Element renderAs="span">{allRecourseCasesReasons[recourseCase.reason]} </Element>
}

export const WorkersRequestRecourseCaseJustification: React.FC<WorkersRequestRecourseCaseProps> = ({
  recourseCase,
}) => {
  return <Element renderAs="span">{recourseCase.justification} </Element>
}
