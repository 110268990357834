import React from 'react'

interface WorkerLaborCostCoefficientKindLabelProps {
  laborCostCoefficientKind: 'management' | 'delegation'
}

const WorkerLaborCostCoefficientKindLabel: React.FC<WorkerLaborCostCoefficientKindLabelProps> = ({
  laborCostCoefficientKind,
}) => {
  if (laborCostCoefficientKind === 'delegation') return 'Délégation'
  return 'Gestion'
}

export default WorkerLaborCostCoefficientKindLabel
