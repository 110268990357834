import { ResourceRefOrSchema } from '../../../backend/src/types/common.types'
import { JobTitle } from '../api/job-titles'
import { InterimAgency } from '../api/organizations'
import { compareDocsById } from './documents'

export const getJobTitleLaborCoefficientByInterimAgency = (
  jobTilte: JobTitle,
  interimAgency: ResourceRefOrSchema<InterimAgency>,
): JobTitle['laborCostCoefficients'][0] | undefined =>
  jobTilte.laborCostCoefficients.find(l => compareDocsById(l.interimAgency, interimAgency))
