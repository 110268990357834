import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
  Method,
} from 'axios'
import useStore from '../store'

const baseURLHostname = process.env.API_HOSTNAME
  ? process.env.API_HOSTNAME
  : process.env.NODE_ENV === 'development'
  ? 'http://localhost:3000'
  : ''
const currentClientAppVersion = process.env.APP_VERSION

const axiosApi = axios.create({
  // If NODE_ENV is not set or set to something else than 'development'
  // We then consider to be in production
  baseURL: baseURLHostname ? new URL('/api/', baseURLHostname).href : '/api',
})

axiosApi.interceptors.response.use(
  res => res,
  err => {
    if (axios.isAxiosError(err)) {
      const error = (err as AxiosError).toJSON()
      // If the server tells the client that its app version is unsupported
      // Server sends a 406 Not Acceptable
      // @ts-expect-error
      if (error.status === 406) {
        useStore.getState().app.setAppNeedsUpdate(true)
      }
    }
    return Promise.reject(err)
  },
)

axiosApi.interceptors.request.use(req => {
  if (useStore.getState().app.appNeedsUpdate) {
    // If app needs update, we cancel subsequent requests until the browser is (force) refreshed
    throw new axios.Cancel('Version Not Supported')
  }
  return req
})

interface ApiCallOptions<
  Params = {
    [key: string]: any
  },
> {
  endpoint?: string
  params?: Params
  method: Method
  headers?: AxiosRequestHeaders
}

export interface ApiErrorData {
  code: number
  data: { actual: string; field: string; message: string; type: string }[]
  message?: string
  name: string
  type: string
}

export type ApiResponse<T = any> = AxiosResponse<T>

const api = async <Data, Params>({
  endpoint,
  params,
  method,
  headers,
}: ApiCallOptions<Params>): Promise<ApiResponse<Data>> => {
  if (!headers) headers = {}

  const { session } = useStore.getState()
  headers.role = session.currentUserRole
  if (session.jwt) headers.authorization = session.jwt
  if (session.currentOrganization?._id)
    headers.organization = session.currentOrganization._id.toString()

  // We set the current app version as header for all outgoing requests
  if (currentClientAppVersion) headers['x-app-version'] = currentClientAppVersion

  const config: AxiosRequestConfig = {
    method: method || 'get',
    url: endpoint,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
  }

  if (params && method.toLocaleLowerCase() === 'get') config.params = params
  else config.data = params

  return await axiosApi.request(config)
}

export default api
