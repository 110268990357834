import React from 'react'
import { Element, Form } from 'react-bulma-components'
import { User } from '../../../api/users'
import { useRegenerateUserQrCodeMutation } from '../../../queries/users'
import Avatar from '../../avatar/avatar'
import ConfirmationModal, {
  ConfirmationModalBaseProps,
} from '../../confirmation-modal/confirmation-modal'

interface RegenerateQrConfirmationProps extends ConfirmationModalBaseProps {
  user: User
}

const RegenerateQrConfirmation: React.FC<RegenerateQrConfirmationProps> = ({
  show,
  hide,
  user,
}) => {
  const regenerateUserQrCodeMutation = useRegenerateUserQrCodeMutation()

  const onConfirm = () => {
    regenerateUserQrCodeMutation.mutate(user._id)
    hide()
  }

  return (
    <ConfirmationModal
      onConfirm={onConfirm}
      show={show}
      onCancel={hide}
      confirmButtonLabel="Générer un nouveau QR Code"
    >
      <Element renderAs="p" mb={3}>
        Si vous générez un nouveau QR Code pour{' '}
        <strong>
          <Avatar user={user} />
        </strong>{' '}
        : <br />
      </Element>
      <Form.Checkbox checked>Le QR code généré précédemment ne sera plus utilisable.</Form.Checkbox>
      <Form.Checkbox checked>Cette opération est irréversible !</Form.Checkbox>
    </ConfirmationModal>
  )
}

export default RegenerateQrConfirmation
