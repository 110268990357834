import React from 'react'
import { Element } from 'react-bulma-components'
import { TermsOfUseTitle2, TermsOfUseTitle3, TermsOfUseTitle4 } from '.'

export const WorkerTermsOfUse: React.FC = () => {
  return (
    <Element className="content">
      <TermsOfUseTitle2>Conditions Générales d'Utilisation pour les Intérimaires</TermsOfUseTitle2>
      <TermsOfUseTitle3>1. Acceptation des Conditions Générales d'Utilisation</TermsOfUseTitle3>
      <TermsOfUseTitle4>1.1 Acceptation des CGU :</TermsOfUseTitle4> En utilisant l'application
      TeamTIM, vous acceptez les présentes Conditions Générales d'Utilisation et vous vous engagez à
      les respecter.
      <TermsOfUseTitle3>2. Inscription et Utilisation de l'Application</TermsOfUseTitle3>
      <TermsOfUseTitle4>2.1 Processus d'Inscription :</TermsOfUseTitle4>
      Les intérimaires doivent être inscrits par une agence d'intérim associée pour accéder à
      l'application. Un e-mail d'inscription sera envoyé à l'intérimaire pour créer son compte.
      <TermsOfUseTitle4>2.2 Accès à l'Application :</TermsOfUseTitle4> Les intérimaires peuvent
      télécharger l'application depuis l'App Store (iOS) ou le Play Store (Android) et se connecter
      à leur compte en utilisant les identifiants fournis lors de l'inscription..
      <TermsOfUseTitle3>3. Pointage des Heures de Travail</TermsOfUseTitle3>
      <TermsOfUseTitle4>3.1 QR-Code de Pointage :</TermsOfUseTitle4> Chaque intérimaire dispose d'un
      QR-code unique dans son profil. Ce QR-code doit être scanné sur les bornes de pointage des
      entreprises pour enregistrer les heures de travail lorsqu'une mission a été créée et validée
      par l'employeur, l'agence d'intérim et l'intérimaire. .{' '}
      <TermsOfUseTitle4>3.2 Création des Demandes de Mission :</TermsOfUseTitle4> Les employeurs
      peuvent utiliser l'application pour créer des demandes de mission en remplissant un
      formulaire. Ces demandes sont ensuite soumises à l'agence d'intérim associée sélectionnée par
      l'employeur.
      <TermsOfUseTitle3>4. Gestion du Profil et des Données Personnelles</TermsOfUseTitle3>
      <TermsOfUseTitle4>4.1 Sélection des Intérimaires :</TermsOfUseTitle4> Les intérimaires peuvent
      accéder à leur profil et modifier leur mot de passe, leur adresse e-mail et d'autres
      informations personnelles.
      <TermsOfUseTitle4>4.2 Suppression du Compte :</TermsOfUseTitle4>Les intérimaires ont la
      possibilité de supprimer leur compte à tout moment. Cela entraînera la suppression de toutes
      les données de pointage et ils cesseront de recevoir des e-mails liés à l'application. Il est
      possible de revenir sur la suppression d'un compte pendant une durée 1 mois si l'utilisateur
      le souhaite en contactant le Support.
      <TermsOfUseTitle3>5. Support Client et Assistance</TermsOfUseTitle3>
      <TermsOfUseTitle4>5.1 Contact du Support :</TermsOfUseTitle4> Pour toute question ou problème
      lié à l'application, les intérimaires peuvent contacter le support client par e-mail à
      support@team-tim.fr
      <TermsOfUseTitle3>6. Politique de Confidentialité</TermsOfUseTitle3>
      <TermsOfUseTitle4>6.1 Protection des Données :</TermsOfUseTitle4> Les informations
      personnelles des intérimaires sont traitées conformément aux lois applicables en matière de
      protection des données. Les détails sur la collecte, l'utilisation et le partage des données
      sont décrits en détail dans la politique de confidentialité disponible sur l'application.
    </Element>
  )
}
