import { useRef, useEffect } from 'react'

// Return
// false when the component is first rendered
// true in other cases

export function useFirstRender(): boolean {
  const firstRender = useRef(true)

  useEffect(() => {
    firstRender.current = false
  }, [])

  return firstRender.current
}
