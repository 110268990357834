import React from 'react'
import { formatTime } from '../../utils/date'

const Clock: React.FunctionComponent = () => {
  const [currentHour, setCurrentHour] = React.useState<number>(new Date().getTime())

  React.useEffect(() => {
    const interval = setInterval(() => {
      setCurrentHour(new Date().getTime())
    }, 1000)
    return () => clearInterval(interval)
  }, [currentHour])

  return <>{formatTime(new Date(currentHour))}</>
}

export default Clock
