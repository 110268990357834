import React from 'react'
import { Panel } from 'react-bulma-components'
import HistoryComponent from './history-item'
import { User } from '../../api/users'

export interface HistoryItem {
  _id: string
  date: Date
  action: string
  by: User
  changes: {
    _id: string
    attribute: string
    oldValue?: string
    newValue?: string
  }[]
}

export interface HistoryLocales {
  title?: { [key: string]: string }
  changesTitle: { [key: string]: string }
}

interface HistoryProps {
  items: HistoryItem[]
  locales?: HistoryLocales
  renderCustomContent?(historyItem: HistoryItem, locales?: HistoryLocales): JSX.Element
}

const History: React.FC<HistoryProps> = ({ items, locales, renderCustomContent }) => {
  return (
    <Panel>
      {items.length === 0 && <Panel.Block justifyContent="center">Rien à afficher</Panel.Block>}
      {items.map(historyItem => (
        <HistoryComponent
          item={historyItem}
          locales={locales}
          renderCustomContent={renderCustomContent}
          key={historyItem._id.toString()}
        />
      ))}
    </Panel>
  )
}

export default History
