import React from 'react'
import { Container, Heading, Section } from 'react-bulma-components'
import Layout from '../../components/layout/layout'
import KPIFilters from '../../components/kpi/filters'
import useFilters from '../../hooks/filters'
import { FiltersResourcesEnum } from '../../store/filters'
import { useEmployerStats } from '../../hooks/use-employer-stats'
import { EmployerKpis } from '../../components/kpi/employer-kpis'
import { dateRangeDisplayHelper } from '../../utils/date'
import { useOrganizationsQuery } from '../../queries/organizations'
import { useWorkersQuery } from '../../queries/users'
import { getUserLabel } from '../../utils/users'
import useStore from '../../store'

export const EmployerKPIsPage = () => {
  const {
    filters: { start, end, user, interimAgency, employer, service },
  } = useFilters(FiltersResourcesEnum.kPI)
  const stats = useEmployerStats(start, end, interimAgency, employer, user, service)
  const interimAgenciesQuery = useOrganizationsQuery({ type: 'interimAgency' }).data ?? []
  const employersQuery = useOrganizationsQuery({ type: 'employer' }).data ?? []
  const workersQuery = useWorkersQuery().data ?? []
  const currentOrganization = useStore(s => s.session.currentOrganization)

  const contextualDateRange = dateRangeDisplayHelper(start, end)
  const dateRange = dateRangeDisplayHelper(start, end, false)

  return (
    <Layout>
      <Section>
        <Container textAlign={'center'}>
          <KPIFilters />
        </Container>
      </Section>
      <Section>
        <Container>
          <Heading size={3} mt={5} textAlign={'center'}>
            Statistiques de {currentOrganization?.name}
          </Heading>
          <Heading size={5} textAlign={'center'} textColor="primary">
            {contextualDateRange} <br />
            {contextualDateRange !== dateRange && dateRange}
            {interimAgency && (
              <>
                <br />
                <ul>
                  {interimAgency.map(iA => (
                    <li key={iA}>{interimAgenciesQuery.find(o => o._id === iA)?.name ?? ''}</li>
                  ))}
                </ul>
              </>
            )}
            {employer && (
              <>
                <br />
                <ul>
                  {employer.map(e => (
                    <li key={e}>{employersQuery.find(o => o._id === e)?.name ?? ''}</li>
                  ))}
                </ul>
              </>
            )}
            {user && (
              <>
                <br />
                <ul>
                  {user.map(u => {
                    const worker = workersQuery.find(w => w._id === u)
                    if (worker) {
                      return <li key={u}>{getUserLabel(worker)}</li>
                    } else return ''
                  })}
                </ul>
              </>
            )}
          </Heading>
        </Container>
      </Section>
      <Section>
        <Container>
          <EmployerKpis stats={stats} />
        </Container>
      </Section>
    </Layout>
  )
}
