import React from 'react'
import { Employer } from '../../api/organizations'
import useStore from '../../store'
import { get } from 'lodash'

export interface OrganizationOptionProtectedProps {
  options: {
    organizationType: 'employer' | 'interimAgency'
    option: keyof Employer['appOptions']
  }[]
  children: React.ReactNode
}

export const isAuthorizedForOption = (
  options: OrganizationOptionProtectedProps['options'],
): boolean => {
  const currentOrganization = useStore.getState().session.currentOrganization
  const optionToCheck =
    currentOrganization && options.find(o => o.organizationType === currentOrganization?.type)
  //@ts-ignore
  return !optionToCheck || get(currentOrganization.appOptions, optionToCheck.option)
}

const OrganizationOptionProtected: React.FC<OrganizationOptionProtectedProps> = ({
  children,
  options,
}) => {
  if (isAuthorizedForOption(options)) return children
  return <></>
}

export default OrganizationOptionProtected
