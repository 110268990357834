import api, { ApiResponse } from '.'
import {
  UserRoleKind as UserSchemaRoleKind,
  UserSchema,
  UserRole as UserRoleSchema,
  UserAssociation as UserAssociationSchema,
  InterimAgencyMemberRole,
  EmployerMemberRole,
  WorkerRole,
  UserEmployerMemberRole,
  MissionActions,
  ClockingRulesActions,
  JobTitlesActions,
  ClockingsActions,
  PreBillingsActions,
  OrganizationsActions,
  WorkPeriodEventsActions,
  WorkersRequestsActions,
  UserActions,
} from '../../../backend/src/services/resources/users/user.model'
import { UpdateNotificationSettingsParams } from '../../../backend/src/services/resources/users/users.business.service.types'
import { CreateUserParams } from '../../../backend/src/services/resources/users/users.controller.service.types'
import { AvailableWorker as BaAvailableWorker } from '../../../backend/src/services/resources/users/users.repository.service.types'
import { Employer, InterimAgency } from './organizations'
import { EmployerMemberActions } from '../../../backend/src/config/user-profiles'

export interface UserRole extends UserRoleSchema {
  _id: string
  organization: InterimAgency | Employer
  invitedBy: User
  notificationsSettings:
    | undefined
    | InterimAgencyMemberRole['notificationsSettings']
    | EmployerMemberRole['notificationsSettings']
    | WorkerRole['notificationsSettings'] // TODO: improve types
  crudActions?: EmployerMemberActions
}

export type CrudResource = keyof EmployerMemberActions

export type CrudAction =
  | keyof typeof MissionActions
  | keyof typeof ClockingsActions
  | keyof typeof JobTitlesActions
  | keyof typeof ClockingRulesActions
  | keyof typeof PreBillingsActions
  | keyof typeof OrganizationsActions
  | keyof typeof WorkPeriodEventsActions
  | keyof typeof WorkersRequestsActions
  | keyof typeof UserActions

export interface UserAssociation extends UserAssociationSchema {
  _id: string
  organization: InterimAgency
  invitedBy: User
}

export interface User extends UserSchema {
  _id: string
  roles: UserRole[]
  associations: UserAssociation[]
}

export type AvailableWorker = BaAvailableWorker

export type UserRoleKind = UserSchemaRoleKind

export interface FetchUsersParams {
  _id?: string | string[]
  'roles.kind'?: UserRoleKind
  filter?: string
  appAccess?: 'none' | 'full' // TODO: type
}

export const fetch = async (params: FetchUsersParams): Promise<ApiResponse<User[]>> => {
  return await api({
    endpoint: '/users',
    method: 'get',
    params: {
      sort: '-isCompleted lastName email',
      populate: ['roles.invitedBy'],
      ...params,
    },
  })
}

export const get = async (id: string): Promise<ApiResponse<User>> => {
  return await api({
    endpoint: `/users/${id}`,
    method: 'GET',
    params: {
      populate: [
        'roles.invitedBy',
        'associations.invitedBy',
        'associations.organization',
        'roles.organization',
      ],
    },
  })
}

export interface FetchAvailableParams {
  dates?: { start: Date; end: Date }[]
  workersRequestId?: string
  missionId?: string
  start?: Date
  end?: Date
}

export const fetchAvailableWorkers = async (
  params: FetchAvailableParams,
): Promise<ApiResponse<AvailableWorker[]>> =>
  await api({
    endpoint: `/workers/available`,
    method: 'get',
    params,
  })

export interface FetchWorkersParams {
  interimAgency?: string
}

export const fetchWorkers = async (params: FetchWorkersParams): Promise<ApiResponse<User[]>> =>
  await api({
    endpoint: `/workers`,
    method: 'get',
    params,
  })

type UserCreationPayload = CreateUserParams

export const create = async (params: UserCreationPayload): Promise<ApiResponse<User>> => {
  return await api({
    endpoint: '/users',
    method: 'post',
    params,
  })
}
export interface UpdateUserPayload {
  id: string
  email?: string
  oldPassword?: string
  password?: string
  firstName?: string
  lastName?: string
  lastTermsOfUseVersionRead?: string
  lastPrivacyPolicyVersionRead?: string
}

export const update = async (params: UpdateUserPayload): Promise<ApiResponse<User>> => {
  const { id, ...payload } = params
  return await api({
    endpoint: `/users/${id}`,
    method: 'put',
    params: payload,
  })
}

export interface UpdateWorkerPayload {
  id: string
  schedule?: UserAssociation['schedule']
  firstName?: string
  lastName?: string
  internalRef?: UserAssociation['internalRef']
  internalInformation?: UserAssociation['internalInformation']
  internalComment?: UserAssociation['internalComment']
  laborCostCoefficients?: UserAssociation['laborCostCoefficients']
}

export const updateWorker = async (params: UpdateUserPayload): Promise<ApiResponse<User>> => {
  const { id, ...payload } = params
  return await api({
    endpoint: `/users/workers/${id}`,
    method: 'put',
    params: payload,
  })
}

export interface UpdateUserRolePayload {
  roleId: string
  status: 'accepted' | 'rejected'
}

export const updateRole = async (params: UpdateUserRolePayload): Promise<ApiResponse<User>> => {
  return await api({
    endpoint: `/users/me/roles/${params.roleId}`,
    method: 'put',
    params,
  })
}

export interface UpdateUserAssociationPayload {
  associationId: string
  status: 'accepted' | 'rejected' | 'discontinued'
}
export const updateAssociation = async (
  params: UpdateUserAssociationPayload,
): Promise<ApiResponse<User>> => {
  return await api({
    endpoint: `/users/me/associations/${params.associationId}`,
    method: 'put',
    params,
  })
}

export const discontinueUserOrganizationRole = async (params: {
  userId: string
}): Promise<ApiResponse<User>> => {
  return await api({
    endpoint: `/users/${params.userId}/roles/organizations/discontinue`,
    method: 'put',
  })
}

export interface UpdateUserOrganizationRolePayload {
  userId: string
  organizationRole: UserEmployerMemberRole | 'admin'
}

export const updateUserOrganizationRole = async (
  params: UpdateUserOrganizationRolePayload,
): Promise<ApiResponse<User>> => {
  return await api({
    endpoint: `/users/${params.userId}/roles/organizations`,
    method: 'put',
    params,
  })
}

export const discontinueWorkerAssociation = async (params: {
  workerId: string
}): Promise<ApiResponse<User>> => {
  return await api({
    endpoint: `/workers/${params.workerId}/associations/discontinue`,
    method: 'put',
  })
}

export const deleteWorkerAssociation = async (params: {
  workerId: string
}): Promise<ApiResponse<User>> => {
  return await api({
    endpoint: `/workers/${params.workerId}/associations/delete`,
    method: 'put',
  })
}

export interface CompleteUserProfilePayload {
  id: string
  token: string
  firstName: string
  lastName: string
  password: string
}

export const completeProfile = async (
  params: CompleteUserProfilePayload,
): Promise<ApiResponse<User>> => {
  return await api({
    endpoint: `/users/${params.id}`,
    method: 'put',
    params,
  })
}

export const validateNewEmail = async (token: string): Promise<ApiResponse<User>> => {
  return await api({
    endpoint: `/users/me/validate-new-email`,
    method: 'put',
    params: { token },
  })
}

export const updateUserEmail = async (email: string): Promise<ApiResponse<User>> => {
  return await api({
    endpoint: `/users/me/email`,
    method: 'put',
    params: { email },
  })
}

export type UpdateNotificationSettingsPayload = Omit<UpdateNotificationSettingsParams, 'userId'>

export const updateNotificationSettings = async (
  params: UpdateNotificationSettingsPayload,
): Promise<ApiResponse<User>> =>
  await api({
    endpoint: `/users/me/roles/${params.roleId}/notifications-settings`,
    params,
    method: 'put',
  })

export interface RequestNewPasswordPayload {
  email: string
}

export const requestNewPassword = async (
  params: RequestNewPasswordPayload,
): Promise<ApiResponse<User>> => {
  return await api({
    endpoint: '/users/request-new-password',
    method: 'post',
    params,
  })
}

export interface ResetUserPasswordPayload {
  password: string
  token: string
  validationCode: number
}

export const resetPassword = async (
  params: ResetUserPasswordPayload,
): Promise<ApiResponse<User>> => {
  return await api({
    endpoint: '/users/reset-password',
    method: 'post',
    params,
  })
}

export const deleteAccount = async (userId: User['_id']): Promise<ApiResponse<User>> => {
  return await api({
    endpoint: `/users/${userId}`,
    method: 'delete',
  })
}

export const getUserQrCode = async (userId: string): Promise<ApiResponse<string>> => {
  return await api({
    endpoint: `/users/${userId}/qr`,
    method: 'get',
  })
}

export const regenerateQrCode = async (userId: string): Promise<ApiResponse<string>> =>
  await api({
    endpoint: `/users/${userId}/qr`,
    method: 'put',
  })

export interface AuthPayload {
  email: string
  password: string
  loginAsQrScannerForEmployerId?: string
}

export interface AuthResponse {
  user: User
  jwt: string
}

export const auth = async (params: AuthPayload): Promise<ApiResponse<AuthResponse>> =>
  await api({
    endpoint: '/auth',
    method: 'post',
    params,
  })

export const isAuth = async (): Promise<ApiResponse<AuthResponse>> =>
  await api({
    endpoint: '/is-auth',
    method: 'get',
  })
