import React from 'react'
import { Container, Heading, Section } from 'react-bulma-components'

interface PageHeadingProps {
  title: string | JSX.Element
  subtitle?: string
  [key: string]: any
}

const PageHeading: React.FunctionComponent<PageHeadingProps> = ({ title, subtitle, ...rest }) => {
  return (
    <Section size={'small'} {...rest}>
      <Container>
        <Heading textColor="primary">{title}</Heading>
        {subtitle && (
          <Heading subtitle renderAs="p">
            {subtitle}
          </Heading>
        )}
      </Container>
    </Section>
  )
}
export default PageHeading
