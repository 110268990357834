import React from 'react'
import FieldColumn from '../field-column'
import { Field } from 'formik'
import MultiSelectField from '../../form/fields/multi-select'
import { buildListAsInputOptions } from '../../../utils/forms'
import useStore from '../../../store'
import { useWorkersRequestsServicesQuery } from '../../../queries/workers-requests'

interface ServiceFilterFieldProps {
  disabledQueries?: boolean
  queriesStaleTime?: number
  employerIds?: string[]
}

const ServiceFilterField: React.FC<ServiceFilterFieldProps> = ({
  disabledQueries,
  queriesStaleTime,
  employerIds,
}) => {
  const currentUserRole = useStore(state => state.session.currentUserRole)
  const currentOrganization = useStore(state => state.session.currentOrganization)

  //Build missions services query params
  const missionsServicesQueryParams = React.useMemo(() => {
    if (currentOrganization && currentUserRole === 'employerMember')
      return { employer: [currentOrganization._id] }
    else if (employerIds && currentUserRole === 'interimAgencyMember')
      return { employer: employerIds }
    return {}
  }, [currentUserRole, employerIds])

  //Missions services query
  const missionsServicesQuery = useWorkersRequestsServicesQuery(missionsServicesQueryParams, {
    enabled: !disabledQueries && Boolean(Object.keys(missionsServicesQueryParams)[0]),
    staleTime: queriesStaleTime,
  })
  return (
    <FieldColumn>
      <Field
        label="Service"
        name="service"
        component={MultiSelectField}
        options={buildListAsInputOptions(missionsServicesQuery.data || [])}
      />
    </FieldColumn>
  )
}

export default ServiceFilterField
