import React from 'react'
import { Element } from 'react-bulma-components'
import { TermsOfUseTitle2, TermsOfUseTitle3, TermsOfUseTitle4 } from '.'

export const EmployerTermsOfUse: React.FC = () => {
  return (
    <Element className="content">
      <TermsOfUseTitle2>Conditions Générales d'Utilisation pour les Employeurs</TermsOfUseTitle2>
      <TermsOfUseTitle3>1. Acceptation des Conditions Générales d'Utilisation</TermsOfUseTitle3>
      <TermsOfUseTitle4>1.1 Acceptation des CGU :</TermsOfUseTitle4> En utilisant l'application
      TeamTIM, vous acceptez les présentes Conditions Générales d'Utilisation et vous vous engagez à
      les respecter.
      <TermsOfUseTitle3>2. Inscription et Utilisation de l'Application</TermsOfUseTitle3>
      <TermsOfUseTitle4>2.1 Processus d'Inscription :</TermsOfUseTitle4> Les employeurs peuvent
      s'inscrire sur l'application par invitation d'une agence d'intérim ou en envoyant une demande
      de création de compte à l'équipe de support de TeamTIM via l'adresse e-mail
      support@team-tim.fr. <TermsOfUseTitle4>2.2 Informations d'Inscription :</TermsOfUseTitle4> Les
      employeurs doivent fournir leurs nom, prénom et adresse e-mail lors de leur inscription. Ils
      ont également la possibilité d'inviter d'autres personnes au sein de leur entité employeur.
      <TermsOfUseTitle3>3. Relations avec les Agences d'Intérim</TermsOfUseTitle3>
      <TermsOfUseTitle4>3.1 Mise en Relation avec une Agence :</TermsOfUseTitle4> Si un employeur
      est invité par une agence d'intérim, il doit accepter la mise en relation pour pouvoir
      interagir avec cette agence. L'employeur peut révoquer cette relation à tout moment, ce qui
      empêchera la possibilité de faire des demandes de mission à cette agence.{' '}
      <TermsOfUseTitle4>3.2 Création des Demandes de Mission :</TermsOfUseTitle4> Les employeurs
      peuvent utiliser l'application pour créer des demandes de mission en remplissant un
      formulaire. Ces demandes sont ensuite soumises à l'agence d'intérim associée sélectionnée par
      l'employeur.
      <TermsOfUseTitle3>4. Gestion des Missions et des Intérimaires</TermsOfUseTitle3>
      <TermsOfUseTitle4>4.1 Sélection des Intérimaires :</TermsOfUseTitle4> Les agences d'intérim
      sont responsables de la sélection des intérimaires de leur agence pour répondre aux demandes
      de personnel des missions créées par les employeurs. Les employeurs peuvent vérifier les
      intérimaires affectés à la mission une fois que ceux-ci ont été sélectionnés et validés par
      l'agence.
      <TermsOfUseTitle3>5. Communication et Notifications</TermsOfUseTitle3>
      <TermsOfUseTitle4>5.1 Communication Hors de l'Application :</TermsOfUseTitle4> Les employeurs
      et les agences d'intérim communiquent en dehors de l'application. Les modifications apportées
      aux missions ou aux contrats d'intérimaires sont notifiées par e-mail. Les employeurs doivent
      consulter leurs e-mails pour être informés des changements importants.
      <TermsOfUseTitle3>6. Politique de Confidentialité</TermsOfUseTitle3>
      <TermsOfUseTitle4>6.1 Protection des Données :</TermsOfUseTitle4> Les employeurs sont invités
      à consulter la politique de confidentialité disponible sur l'application pour comprendre
      comment leurs données personnelles sont collectées, utilisées et partagées dans le cadre de
      l'utilisation de l'application.
      <TermsOfUseTitle3>7. Suppression du Compte</TermsOfUseTitle3>
      <TermsOfUseTitle4>7.1 Suppression du Compte :</TermsOfUseTitle4> Les employeurs peuvent
      supprimer leur compte à tout moment. Lors de la suppression du compte, toutes les informations
      personnelles de l'employeur sont supprimées, à l'exception de l'historique de l'activité qui
      est conservé pendant 1 an à des fins de suivi et de sécurité.
    </Element>
  )
}
