import React from 'react'
import { RequestButton, RequestMessage } from '../../request-components/request-components'
import ConfirmationModal from '../../confirmation-modal/confirmation-modal'
import { User } from '../../../api/users'
import { useModal } from '../../modal'
import {
  //useDeleteUserOrganizationRoleMutation,
  useDeleteWorkerAssociationMutation,
} from '../../../queries/users'
import { Element } from 'react-bulma-components'
import { getUserLabel } from '../../../utils/users'

interface DeleteAssociationButtonProps {
  user: User
  roleKind: 'worker'
}

const DeleteAssociationButton: React.FC<DeleteAssociationButtonProps> = ({ user, roleKind }) => {
  const modal = useModal()
  //const deleteUserOrganizationRoleMutation = useDeleteUserOrganizationRoleMutation()
  const deleteWorkerAssociationMutation = useDeleteWorkerAssociationMutation(user._id)

  return (
    <>
      <RequestButton
        size={'small'}
        variant="danger"
        onClick={() => modal.setIsDisplayed(true)}
        mutation={deleteWorkerAssociationMutation}
      >
        <Element renderAs="span" textColor="danger" textWeight="bold">
          Supprimer
        </Element>
      </RequestButton>
      <ConfirmationModal
        show={modal.isDisplayed}
        onCancel={() => modal.setIsDisplayed(false)}
        onConfirm={async () => {
          if (roleKind === 'worker') {
            await deleteWorkerAssociationMutation.mutateAsync({ workerId: user._id }).catch(e => e)
            if (deleteWorkerAssociationMutation.isSuccess) {
              modal.setIsDisplayed(false)
            }
            //else discontinueUserOrganizationRoleMutation.mutate({ userId: user._id })
          }
        }}
      >
        <Element renderAs="p" mb={3}>
          Êtes-vous certain de vouloir supprimer complètement votre association avec{' '}
          <Element renderAs="strong">
            {user.firstName && user.lastName ? getUserLabel(user) : user.email}
          </Element>
          ?
        </Element>
        <Element renderAs="p" mb={3}>
          Cette opération ne fonctionnera que si vous n'avez aucun historique de Mission ou Demande
          avec cette personne
        </Element>
        <RequestMessage mutation={deleteWorkerAssociationMutation} />
      </ConfirmationModal>
    </>
  )
}

export default DeleteAssociationButton
