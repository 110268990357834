import React from 'react'
import { ClockingRules } from '../../api/clocking-rules'
import { formatDuration } from 'date-fns'

interface ClockingRulesBreakDescriptionProps {
  clockingRules: ClockingRules
}

const ClockingRulesBreakDescription: React.FC<ClockingRulesBreakDescriptionProps> = ({
  clockingRules,
}) => {
  if (!clockingRules.break.clocked)
    return (
      <>
        {formatDuration({ minutes: clockingRules.break.breakDuration })} après{' '}
        {formatDuration({ minutes: clockingRules.break.breakAllowedAfter })}
      </>
    )
  return <>Les pauses sont pointées</>
}

export default ClockingRulesBreakDescription
