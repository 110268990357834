export default {
  roles: {
    status: {
      accepted: 'Accepté',
      pending: 'En attente',
      rejected: 'Refusé',
      discontinued: 'Interrompu',
    },
  },
  notifications: {
    settings: {
      missions: {
        updated: 'Mission mise à jour',
        abandoned: 'Mission abandonée',
        created: 'Mission créée',
        cancelled: 'Mission annulée',
      },
      clockings: {
        created: 'Confirmation pointage',
      },
      workersRequests: {
        completed: 'Demande complète',
        postReplaced: 'Remplacement effectué',
        created: 'Nouvelle demande',
        cancelled: 'Demande annulée',
        updated: 'Demande mise à jour',
        postNeedsReplacement: 'Remplacement demandé',
      },
      preBillings: {
        created: 'Nouvelle pré-facturation',
      },
    },
  },
  organizationRoles: {
    'admin': 'Admin',
    'teamLeader': "Chef d'équipe",
    'clocker': 'Pointage uniquement',
  }
}
