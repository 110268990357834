import React from 'react'
import { Container, Section } from 'react-bulma-components'
import { Column } from 'react-table'
import { EmployerAssociation } from '../../../../backend/src/services/resources/organizations/organization.model'
import Layout from '../../components/layout/layout'
import PageHeading from '../../components/sections/page-heading'
import Table from '../../components/table'
import RoleStatus from '../../components/users/role-status'
import { useOrganizationsQuery } from '../../queries/organizations'
import useStore from '../../store'
import { Employer, InterimAgency } from '../../api/organizations'
import { formatCompleteDate } from '../../utils/date'
import { isAuthorizedFor } from '../../components/protected/protected'
import { Link } from 'react-router-dom'

const AssociationsList: React.FC = () => {
  const organizationsQuery = useOrganizationsQuery<Employer>({ type: 'employer' })
  const currentOrganization = useStore(state => state.session.currentOrganization) as InterimAgency
  const tableColumns = useListTableColumns(organizationsQuery.data || [], currentOrganization)

  return (
    <Layout>
      <PageHeading title="Liste de mes Entreprises Utilisatrices partenaires" />
      <Section>
        <Container>
          <Table
            columns={tableColumns}
            data={(organizationsQuery.data || []).filter(organization =>
              organization?.associations?.some(association => {
                const interimAgency = association.interimAgency as InterimAgency
                return currentOrganization && interimAgency._id === currentOrganization._id
              }),
            )}
            noDataMessage="Aucune EU à afficher"
          />
        </Container>
      </Section>
    </Layout>
  )
}

export default AssociationsList

const useListTableColumns = (
  organizations: Employer[],
  currentOrganization: InterimAgency,
): Column[] => {
  const getAssociation = (employer: Employer): EmployerAssociation | undefined => {
    return employer.associations.find(association => {
      const interimAgency = association.interimAgency as InterimAgency
      return interimAgency._id === currentOrganization._id
    })
  }
  return React.useMemo(
    () =>
      [
        {
          Header: 'Nom',
          accessor: 'name',
          Cell: (data: any) => {
            const organization: Employer = data.cell.row.original
            return isAuthorizedFor(['interimAgencyMember']) ? (
              <Link to={`/employers/${organization._id}`}>{organization.name}</Link>
            ) : (
              organization.name
            )
          },
        },
        {
          Header: "Statut de l'invitation",
          accessor: 'status',
          Cell: (data: any) => {
            const association = getAssociation(data.cell.row.original)
            //TODO: refactor component and type

            return <RoleStatus role={association} showDate />
          },
        },
        {
          Header: 'Invité le',
          accessor: 'createdAt',
          Cell: (data: any) => {
            const association = getAssociation(data.cell.row.original)
            return formatCompleteDate(association?.createdAt)
          },
        },
      ] as Column[],
    [organizations],
  )
}
