import React from 'react'
import { Icon } from 'react-bulma-components'
import { ClipboardCopyIcon } from '@heroicons/react/outline'
import { ClipboardBaseButton, IClipboardBaseButton } from './clipboard-base-button'

type ICopyButton = IClipboardBaseButton

export const CopyButton: ICopyButton = props => {
  return (
    <ClipboardBaseButton data-tooltip="Copier" {...props}>
      <Icon color="primary">
        <ClipboardCopyIcon />
      </Icon>
    </ClipboardBaseButton>
  )
}
