import React from 'react'
import FieldColumn from '../field-column'
import { Field } from 'formik'
import MultiSelectField from '../../form/fields/multi-select'

const IsExpiredFilterField: React.FC = ({}) => {
  return (
    <FieldColumn>
      <Field
        label="Expirée (date de fin dépassée)"
        name="isExpired"
        component={MultiSelectField}
        options={[
          {
            label: 'Oui',
            value: true,
          },
          {
            label: 'Non',
            value: false,
          },
        ]}
      />
    </FieldColumn>
  )
}

export default IsExpiredFilterField
