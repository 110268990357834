import React from 'react'
import Layout from '../../components/layout/layout'
import PageHeading from '../../components/sections/page-heading'
import FormWrapper from '../../components/form/form-wrapper'
import { Button, Columns, Container, Form, Icon, Section } from 'react-bulma-components'
import AgencyForm from '../../components/organizations/agency-form'
import ComponentHeader from '../../components/sections/component-header'
import { ViewListIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'
import Protected from '../../components/protected/protected'
import useUrlId from '../../hooks/use-url-id'
import { useMutationState } from '../../queries'
import {
  useCreateMutationKey,
  useOrganizationQuery,
  useOrganizationsQuery,
  useUpdateMutationKey,
} from '../../queries/organizations'
import { Employer, InterimAgency } from '../../api/organizations'
import { useFormWrapperPropsWithMutationData } from '../../hooks/use-form-wrapper-props-with-mutation-data'
import { DocumentAddIcon } from '@heroicons/react/outline'
import Modal, { useModal } from '../../components/modal'
import AssociationForm from '../../components/organizations/association-form'

const AgencyDetails: React.FunctionComponent = () => {
  const { id, isNew } = useUrlId()
  const { data: organization } = useOrganizationQuery<InterimAgency>(id, 'interimAgency')
  const mutationState = useMutationState(isNew ? useCreateMutationKey() : useUpdateMutationKey())

  const employersQuery = useOrganizationsQuery<Employer>({ type: 'employer' })

  const employers = (employersQuery.data || []).filter(
    e => !e.associations.some(a => organization && a.interimAgency._id === organization._id),
  )

  const formWrapperProps = useFormWrapperPropsWithMutationData(
    formWrapperBaseProps,
    mutationState,
    !isNew,
  )

  const inviteEmployerModal = useModal()

  return (
    <Layout>
      <PageHeading
        title={isNew ? "Nouvelle Agence d'Intérim" : organization?.name || ''}
        subtitle={isNew ? 'Créer une Nouvelle Agence' : "Editer l'Agence"}
      />
      <Section>
        <Container>
          <ComponentHeader>
            <ComponentHeader.Left>{''}</ComponentHeader.Left>
            <ComponentHeader.Right>
              <Form.Field>
                <Form.Label>&nbsp;</Form.Label>
                <Form.Control>
                  <Protected roles={['superAdmin']}>
                    {!isNew && (
                      <Button
                        outlined
                        color="primary"
                        onClick={() => inviteEmployerModal.setIsDisplayed(true)}
                        mr={2}
                      >
                        <Icon>
                          <DocumentAddIcon />
                        </Icon>
                        <span>Ajouter un Employeur Paternaire</span>
                      </Button>
                    )}
                    <Button renderAs={Link} to="/agencies" outlined color="primary">
                      <Icon>
                        <ViewListIcon />
                      </Icon>
                      <span>Liste des Agences</span>
                    </Button>
                  </Protected>
                </Form.Control>
              </Form.Field>
            </ComponentHeader.Right>
          </ComponentHeader>
          <FormWrapper {...formWrapperProps}>
            <Columns>
              <Columns.Column size={6}>
                <AgencyForm organization={organization} />
              </Columns.Column>
            </Columns>
          </FormWrapper>
        </Container>
        {organization && (
          <Modal title="Inviter un Nouvel Employeur" actions={inviteEmployerModal}>
            <AssociationForm employers={employers} interimAgency={organization} />
          </Modal>
        )}
      </Section>
    </Layout>
  )
}

export default AgencyDetails

const formWrapperBaseProps = {
  loadingProps: { body: "Enregistrement de l'Agence..." },
  successProps: {
    title: 'Agence créée',
    body: {
      description: "L'Agence a été créée",
      actions: [
        {
          label: 'Nouvelle Agence',
        },
        {
          label: 'Retourner à la liste',
          to: '/agencies',
        },
      ],
    },
  },
}
