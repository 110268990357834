import React from 'react'
import { MissionWithSummary } from '../../api/missions'
import { Element, Message } from 'react-bulma-components'
import { localDate } from '../../utils/date'
import WorkerLabel from '../../components/texts/worker-label'

interface MultipleMissionsInWeekMessageProps {
  missions?: MissionWithSummary[]
}

const MultipleMissionsInWeekMessage: React.FC<MultipleMissionsInWeekMessageProps> = ({
  missions,
}) => {
  if (!missions || !missions[1]) return <></>
  return (
    <Message color={'danger'} my={2} p={3}>
      Attention: plusieurs missions pour ce <WorkerLabel /> dans cette semaine
      {missions.map(mission => (
        <Element key={mission._id}>
          {mission.jobTitle.name} - Du {localDate(mission.start)} au {localDate(mission.end)}
        </Element>
      ))}
    </Message>
  )
}

export default MultipleMissionsInWeekMessage
