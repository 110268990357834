import React from 'react'
import { useOutletContext } from 'react-router-dom'
import { OutletContext } from './interim-agency-wrapper'
import {
  useAppSubscriptionPeriodByPeriodQuery,
  useAppSubscriptionPeriodTotalsQuery,
  useCreateAppSubscriptionPeriodMutation,
  useCreateAppSubscriptionPeriodMutationFromTotal,
} from '../../queries/app-subscription-periods'
import useUrlId from '../../hooks/use-url-id'
import { getWeek, localDate } from '../../utils/date'
import { getYear, isWithinInterval } from 'date-fns'
import { Employer } from '../../api/organizations'
import { Columns, Element, Heading, Button, Loader } from 'react-bulma-components'
import AppSubscriptionPeriodTable from '../../components/app-subscription-periods/app-subscription-period-table'
import AppSubscriptionPeriodTotalsTable from '../../components/app-subscription-periods/app-subscription-period-totals-table'
import AppSubscriptionPeriodDailyDetails from '../../components/app-subscription-periods/app-subscription-period-daily-details'
import { ArrowUpIcon, ArrowDownIcon } from '../../components/icons'
import { parsePeriodString } from '../../utils/app-subscription-periods'
import { AppSubscriptionPeriodsInterimAgencyTotal } from '../../api/app-subscription-periods'

/**
 * Component for managing agency subscription settings
 * Receives interimAgency from outlet context and passes it to the form
 */
type AppSubscriptionPeriodProps = OutletContext

const AppSubscriptionPeriod: React.FC = () => {
  const { id, isNew } = useUrlId({ idName: 'appSubscriptionPeriodId' })
  const { interimAgency } = useOutletContext<AppSubscriptionPeriodProps>()
  const [activeEmployer, setActiveEmployer] = React.useState<Employer | null>(null)
  const [showDailyDetails, setShowDailyDetails] = React.useState(false)

  const { data: period } = useAppSubscriptionPeriodByPeriodQuery(
    {
      week: getWeek(new Date()),
      year: getYear(new Date()),
      interimAgencyId: interimAgency._id,
      employerId: activeEmployer?._id || '',
    },
    { enabled: Boolean(activeEmployer) },
  )

  let periodYear: number | undefined
  let periodNumber: number | undefined
  if (id && !isNew) {
    const parsedPeriod = parsePeriodString(id)
    periodYear = parsedPeriod.periodYear
    periodNumber = parsedPeriod.periodNumber
  }

  const appSubscriptionPeriodTotalsQuery = useAppSubscriptionPeriodTotalsQuery({
    interimAgencyId: interimAgency._id,
    periodNumber,
    periodYear,
  })

  const createAppSubscriptionPeriodsMutation = useCreateAppSubscriptionPeriodMutationFromTotal(
    appSubscriptionPeriodTotalsQuery.data as AppSubscriptionPeriodsInterimAgencyTotal,
  )

  React.useEffect(() => {
    appSubscriptionPeriodTotalsQuery.refetch()
  }, [id, isNew])

  const appSubscriptionPeriodTotal = appSubscriptionPeriodTotalsQuery.data

  const isCurrentPeriod =
    appSubscriptionPeriodTotal &&
    isWithinInterval(new Date(), {
      start: new Date(appSubscriptionPeriodTotal.period.from),
      end: new Date(appSubscriptionPeriodTotal.period.to),
    })

  const employer = period?.employer as Employer

  if (!appSubscriptionPeriodTotal) return <></>

  return (
    <>
      <Element mt={5}>
        <Columns mb={1}>
          <Columns.Column size={6}>
            <strong>Ref Client:</strong> {interimAgency?.name}
          </Columns.Column>
          <Columns.Column size={6}>
            <strong>N° de Période:</strong> {appSubscriptionPeriodTotal.period.identifier}
          </Columns.Column>
        </Columns>
        <Columns mb={1}>
          <Columns.Column size={6}>
            <strong>Période:</strong> du {localDate(appSubscriptionPeriodTotal.period?.from)} au{' '}
            {localDate(appSubscriptionPeriodTotal.period?.to)}
            {isCurrentPeriod && (
              <>
                <br />
                <Element renderAs="i" className="text-muted">
                  Periode en cours
                </Element>
              </>
            )}
          </Columns.Column>
          <Columns.Column size={6}>
            <div className="d-flex align-items-center gap-2">
              <strong>Status:</strong> {appSubscriptionPeriodTotal.status}
              {appSubscriptionPeriodTotal.status === 'draft' && (
                <Button
                  onClick={() => createAppSubscriptionPeriodsMutation()}
                  size="small"
                  ml={5}
                  pull="right"
                  color={'success'}
                  textColor="white"
                >
                  Valider la période
                </Button>
              )}
            </div>
          </Columns.Column>
        </Columns>
        <AppSubscriptionPeriodTotalsTable
          totals={appSubscriptionPeriodTotal}
          onEmployerClick={employer => setActiveEmployer(employer)}
        />
        {activeEmployer && period && (
          <>
            <Element className="is-flex is-justify-content-space-between is-align-items-center mb-4">
              <Heading size={5}>Détails coûts {activeEmployer.name}</Heading>
              <Button remove onClick={() => setActiveEmployer(null)} aria-label="close" />
            </Element>
            <Columns mb={1}>
              <Columns.Column size={6}>
                <strong>Ref Client:</strong> {interimAgency?.name}
              </Columns.Column>
              <Columns.Column size={6}>
                <strong>N° de Période:</strong> {period.identifier || 'Période en cours'}
              </Columns.Column>
            </Columns>
            <Columns mb={1}>
              <Columns.Column size={6}>
                <strong>Entreprise Utilisatrice:</strong> {employer?.name}
              </Columns.Column>
              <Columns.Column size={6}>
                <strong>Période:</strong> du {localDate(period.periodStart)} au{' '}
                {localDate(period.periodEnd)}
              </Columns.Column>
            </Columns>
            <AppSubscriptionPeriodTable period={period} />

            <Element className="is-flex is-justify-content-flex-end" style={{ marginTop: -30 }}>
              <Button
                onClick={() => setShowDailyDetails(!showDailyDetails)}
                size="small"
                color="ghost"
                renderAs="a"
                className="mt-n4"
              >
                Détails journaliers -&nbsp;
                {showDailyDetails ? (
                  <>
                    Masquer <ArrowUpIcon className="ml-1" width={16} height={16} />
                  </>
                ) : (
                  <>
                    Afficher <ArrowDownIcon className="ml-1" width={16} height={16} />
                  </>
                )}
              </Button>
            </Element>

            {showDailyDetails && (
              <Element mt={2}>
                <AppSubscriptionPeriodDailyDetails period={period} />
              </Element>
            )}
          </>
        )}
      </Element>
    </>
  )
}

export default AppSubscriptionPeriod
