import React, { ReactNode, useMemo } from 'react'
import { Button, Element, Form } from 'react-bulma-components'
import { getExistingWorkPeriod, removeWorkPeriod, updateWorkPeriod } from './utils'
import { dateToTimeInputValue, timeInputValueToDate } from '../../../utils/date'
import Duration from '../../missions/duration'
import { useJobTitlesQuery } from '../../../queries/job-titles'
import { useWorkPeriodDuration } from '../../../hooks/use-work-period-duration'
import { isResourceDisabled, isWorkPeriodDisabled } from './work-periods-editor'
import { FormFieldErrorMessage } from '../../form/fields/form-field'
import ReactTooltip from 'react-tooltip'
import { WorkersRequest } from '../../../api/workers-requests'
import { Mission } from '../../../api/missions'
import { IsoDate } from '../../../hooks/use-calendar'
import { WorkPeriod } from '../../../api/work-periods'

export type WorkPeriodEditorFormErrors = {
  [key: `workPeriod-${IsoDate}`]: string[] | any | undefined
  workPeriods?: string[] | any
  meta?: {
    startDate?: string[] | any
    endDate?: string[] | any
  }
}

export const WorkPeriodEditor = ({
  isoDate,
  workPeriods,
  jobTitle,
  initialWorkPeriods,
  resource,
  setWorkPeriods,
  errors,
}: {
  isoDate: string
  workPeriods: Array<
    {
      start?: { date: Date }
      end?: { date: Date }
    } & Partial<Omit<WorkPeriod, 'start' | 'end'>>
  >
  jobTitle?: string
  initialWorkPeriods: Array<
    {
      start?: { date: Date }
      end?: { date: Date }
    } & Partial<Omit<WorkPeriod, 'start' | 'end'>>
  >
  resource?: Mission | WorkersRequest
  setWorkPeriods: (
    wPs: Array<
      {
        start?: { date: Date }
        end?: { date: Date }
      } & Partial<Omit<WorkPeriod, 'start' | 'end'>>
    >,
  ) => void
  errors: WorkPeriodEditorFormErrors
}): ReactNode => {
  const jobTitlesQuery = useJobTitlesQuery()
  const currentClockingRule = jobTitle
    ? jobTitlesQuery.data?.find(jT => jobTitle.toString() === jT._id.toString())?.clockingRule
    : undefined

  const existingWorkPeriod = getExistingWorkPeriod(workPeriods, isoDate)

  const workPeriodDuration = useWorkPeriodDuration(
    existingWorkPeriod?.start?.date,
    existingWorkPeriod?.end?.date,
    currentClockingRule?.break,
  )
  const startValue = dateToTimeInputValue(existingWorkPeriod?.start?.date)
  const endValue = dateToTimeInputValue(existingWorkPeriod?.end?.date)

  const [initialStartValue, initialEndValue, initialWorkPeriodValue] = useMemo(() => {
    const initialExistingWorkPeriod = getExistingWorkPeriod(initialWorkPeriods, isoDate)
    return [
      dateToTimeInputValue(initialExistingWorkPeriod?.start?.date),
      dateToTimeInputValue(initialExistingWorkPeriod?.end?.date),
      initialExistingWorkPeriod,
    ]
  }, [initialWorkPeriods, isoDate])

  const workPeriodErrorFieldIdentifier = `workPeriod-${isoDate}` as const
  const formErrors = errors[workPeriodErrorFieldIdentifier]

  const resourceIsDisabled = isResourceDisabled(resource)
  const workPeriodIsDisabled = isWorkPeriodDisabled(isoDate, resource, existingWorkPeriod)

  const isDisabled = resourceIsDisabled || workPeriodIsDisabled

  return (
    <Element>
      {formErrors && (
        <ReactTooltip id={isoDate} type="light" border>
          <FormFieldErrorMessage error={formErrors} />
        </ReactTooltip>
      )}
      <Element
        renderAs="span"
        textColor={workPeriodDuration === 0 ? 'grey' : undefined}
        textSize={7}
      >
        Durée {<Duration duration={workPeriodDuration} />}
      </Element>
      <Form.Field mt={2}>
        <Form.Control>
          <Form.Input
            data-tip
            data-for={isoDate}
            type="time"
            size="small"
            name="start"
            value={startValue}
            style={{
              borderColor: formErrors?.[0]
                ? 'red'
                : startValue !== initialStartValue
                ? 'green'
                : startValue === ''
                ? '#ccc'
                : undefined,
            }}
            textWeight={startValue !== '' ? 'bold' : undefined}
            textColor={startValue === '' ? 'grey-light' : undefined}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const startValue = e.target.value
              const datetime = timeInputValueToDate(new Date(isoDate), startValue)
              if (!datetime) return

              const newWorkPeriods = updateWorkPeriod(workPeriods, isoDate, {
                start: { date: datetime },
              })

              setWorkPeriods(newWorkPeriods)
            }}
            disabled={isDisabled}
          />
        </Form.Control>
      </Form.Field>
      <Form.Field>
        <Form.Control>
          <Form.Input
            data-tip
            data-for={isoDate}
            type="time"
            size="small"
            name="end"
            value={endValue}
            style={{
              borderColor: formErrors?.[0]
                ? 'red'
                : endValue !== initialEndValue
                ? 'green'
                : endValue === ''
                ? '#ccc'
                : undefined,
            }}
            textWeight={endValue !== '' ? 'bold' : undefined}
            textColor={endValue === '' ? 'grey-light' : undefined}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const endValue = e.target.value

              const datetime = timeInputValueToDate(new Date(isoDate), endValue)
              if (!datetime) return
              const newWorkPeriods = updateWorkPeriod(workPeriods, isoDate, {
                end: { date: datetime },
              })
              setWorkPeriods(newWorkPeriods)
            }}
            disabled={isDisabled}
          />
        </Form.Control>
      </Form.Field>

      {resource && (startValue !== initialStartValue || endValue !== initialEndValue) && (
        <Button
          color="link"
          type="button"
          size="small"
          data-test="cancel-edit-action"
          onClick={() => {
            if (initialWorkPeriodValue) {
              const newWorkPeriods = updateWorkPeriod(workPeriods, isoDate, initialWorkPeriodValue)
              setWorkPeriods(newWorkPeriods)
            } else {
              const newWorkPeriods = removeWorkPeriod(workPeriods, isoDate)
              setWorkPeriods(newWorkPeriods)
            }
          }}
        >
          Annuler
        </Button>
      )}
    </Element>
  )
}
