import React from 'react'
import { Columns } from 'react-bulma-components'

const ComponentHeader = ({
  children,
  ...rest
}: {
  children: React.ReactNode
  [key: string]: any
}): JSX.Element => (
  <Columns mb={5} {...rest}>
    {children}
  </Columns>
)
const Left: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => (
  <Columns.Column>{children}</Columns.Column>
)
const Right: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => (
  <Columns.Column narrow>{children}</Columns.Column>
)
ComponentHeader.Left = Left
ComponentHeader.Right = Right
export default ComponentHeader
