import React from 'react'
import { Element } from 'react-bulma-components'
import { WorkersRequest } from '../../../api/workers-requests'
import { useUpdateMutation } from '../../../queries/workers-requests'
import ConfirmationModal, {
  ConfirmationModalBaseProps,
} from '../../confirmation-modal/confirmation-modal'
import WorkerLabel from '../../texts/worker-label'

interface WorkersRequestCancelConfirmationModalProps extends ConfirmationModalBaseProps {
  workersRequest: WorkersRequest
}

const WorkersRequestCancelConfirmationModal: React.FC<
  WorkersRequestCancelConfirmationModalProps
> = ({ show, hide, workersRequest }) => {
  const mutation = useUpdateMutation()

  const onConfirm = () => {
    mutation.mutate({
      id: workersRequest._id,
      isCancelled: true,
    })
    hide()
  }

  return (
    <ConfirmationModal show={show} onConfirm={onConfirm} onCancel={hide}>
      <Element>
        Êtes vous certain de vouloir annuler cette Demande de Personnel ?
        <br />
        <Element renderAs="strong">
          L'Agence d'Intérim ne pourra plus ajouter de <WorkerLabel /> à cette demande et toutes les
          missions déjà assignées seront interrompues
        </Element>{' '}
      </Element>
    </ConfirmationModal>
  )
}

export default WorkersRequestCancelConfirmationModal
