import React from 'react'
import { FormErrorProps } from './request-status/error'
import { FormProgessProps } from './request-status/progess'
import RequestStatus from './request-status/request-status'
import { FormSuccessProps } from './request-status/success'

export interface FormWrapperProps {
  status?: 'idle' | 'loading' | 'error' | 'success'
  successProps?: FormSuccessProps
  loadingProps?: FormProgessProps
  errorProps?: FormErrorProps
  disabled?: boolean
}

const FormWrapper: React.FunctionComponent<FormWrapperProps> = props => {
  const [showForm, setShowForm] = React.useState(true)
  const [showStatus, setShowStatus] = React.useState(false)

  React.useEffect(() => {
    if (props.status === 'success') setShowForm(false)
    if (props.status && props.status !== 'idle') setShowStatus(true)
  }, [props.status])

  if (props.disabled) return <>{props.children}</>

  return (
    <>
      {showStatus && (
        <RequestStatus
          {...props}
          reset={() => {
            setShowForm(true)
            setShowStatus(false)
          }}
        />
      )}
      {showForm && props.children}
    </>
  )
}

export default FormWrapper
