import React from 'react'
import { Element, Table } from 'react-bulma-components'

export interface DescriptionListProps {
  items: { label: string | React.ReactNode; value: string | React.ReactNode }[]
  labelColumnWidth?: number
  valueColumnWidth?: number
}

const DescriptionList: React.FC<DescriptionListProps> = ({
  items,
  labelColumnWidth,
  valueColumnWidth,
}) => {
  return (
    <Table className="is-fullwidth" bordered={false}>
      <Element renderAs="tbody">
        {items.map(item => (
          <Element renderAs="tr" key={`${item.value}_${item.label}`}>
            <Element renderAs="td" style={{ border: 'none', width: labelColumnWidth }}>
              {item.label}
            </Element>
            <Element
              renderAs="td"
              style={{ border: 'none', width: valueColumnWidth }}
              textWeight="bold"
            >
              {item.value}
            </Element>
          </Element>
        ))}
      </Element>
    </Table>
  )
}

export default DescriptionList
