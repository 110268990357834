import React from 'react'
import Layout from '../../components/layout/layout'
import PageHeading from '../../components/sections/page-heading'
import useStore from '../../store'
import locales from '../../../../locales'
import { Columns, Container, Section } from 'react-bulma-components'
import { MissionsDashboardCard } from '../../components/dashboards/missions-dashboard-card'
import { WorkersRequestsDashboardCard } from '../../components/dashboards/workers-request-card'
import { UsersDashBoardCard } from '../../components/dashboards/users-dashboard-card'

const InterimAgencyDashboard: React.FC = () => {
  const currentOrganization = useStore(state => state.session.currentOrganization)

  return (
    <Layout>
      <PageHeading
        title={currentOrganization?.name ? currentOrganization.name : ''}
        subtitle={
          currentOrganization?.type ? locales.organizations.type[currentOrganization?.type] : ''
        }
      ></PageHeading>
      <Section>
        <Container>
          <Columns>
            <Columns.Column size={3}>
              <MissionsDashboardCard />
            </Columns.Column>
            <Columns.Column size={3}>
              <WorkersRequestsDashboardCard />
            </Columns.Column>
            <Columns.Column size={3}>
              <UsersDashBoardCard userRoleKind="worker" />
            </Columns.Column>
          </Columns>
        </Container>
      </Section>
    </Layout>
  )
}
export default InterimAgencyDashboard
