import React from 'react'
import Layout from '../../components/layout/layout'
import { Card, Container, Element, Heading, Level, Section } from 'react-bulma-components'
import PageTitle from '../../components/pages/page-title'
import GeneratePayrollForm from '../../components/payroll/generate-payroll-form'
import { User } from '../../api/users'
import { useWeeksSummaryQuery } from '../../queries/missions'
import DescriptionList, {
  DescriptionListProps,
} from '../../components/description-list/description-list'
import { getUserLabel, getWorkerAndOrganizationAssociation } from '../../utils/users'
import useStore from '../../store'
import PayrollWeekTable, { DurationDiff } from '../../components/payroll/week-table'
import { endOfWeek, formatDuration, getWeek, getWeekYear, localDate } from '../../utils/date'
import { eachWeekOfInterval, isSameWeek, startOfWeek } from 'date-fns'
import { fr } from 'date-fns/locale'
import { Organization } from '../../api/organizations'
import MultipleMissionsInWeekMessage from './multiple-missions-in-week-message'
import { UserSummaries } from '../../api/missions'
import { useWorkPeriodEventsQuery } from '../../queries/work-period-events'
import WorkerLabel from '../../components/texts/worker-label'

interface PayrollData {
  from: Date
  to: Date
  user: User
}

const PayrollDetails: React.FC = () => {
  const [payrollData, setPayrollData] = React.useState<PayrollData>()
  const currentOrganization = useStore(state => state.session.currentOrganization) as Organization
  const allWorkPeriodEvents = useWorkPeriodEventsQuery()
  const workerAssociation =
    payrollData?.user && getWorkerAndOrganizationAssociation(payrollData.user, currentOrganization)

  const weeksSummaryQuery = useWeeksSummaryQuery(
    {
      from: payrollData?.from as Date,
      to: payrollData?.to as Date,
      user: [payrollData?.user._id] as string[],
    },
    { enabled: Boolean(payrollData) },
  )

  const userWeeksSummary = weeksSummaryQuery.data?.[0]
  const descriptionListItems = useDescriptionListItems(payrollData, userWeeksSummary)

  const weeks =
    payrollData?.from && payrollData?.to
      ? eachWeekOfInterval(
          { start: payrollData.from, end: payrollData.to },
          { locale: fr, weekStartsOn: 1 },
        )
      : undefined

  return (
    <Layout>
      <Section>
        <Container max={true} breakpoint={'fluid'}>
          <Level alignItems="flex-start" mb={6}>
            <Level.Side>
              <PageTitle
                title="Données pour paie"
                subTitle={
                  <>
                    Visualisez les données des pointages pour un <WorkerLabel /> interne sur une
                    période
                  </>
                }
              />
            </Level.Side>
          </Level>
          <GeneratePayrollForm onSubmit={setPayrollData} />
          {payrollData && allWorkPeriodEvents.data && (
            <Element>
              <Card my={2}>
                <Card.Content>
                  <DescriptionList items={descriptionListItems} labelColumnWidth={580} />
                </Card.Content>
              </Card>
              {weeks?.map((week, index) => {
                const weekNumber = getWeek(week)
                const year = getWeekYear(week)
                const weekSummary = weeksSummaryQuery.data?.[0]?.weeks.find(
                  w =>
                    w.missions[0]?.workPeriods[0] &&
                    isSameWeek(week, new Date(w.missions[0]?.workPeriods[0].start.date), {
                      locale: fr,
                      weekStartsOn: 1,
                    }),
                )
                return (
                  <Element key={index}>
                    <Card my={3}>
                      <Card.Content>
                        <Heading size={4} mb={1}>
                          Semaine {weekNumber} - {year}{' '}
                          <Heading size={6} subtitle>
                            <Element renderAs="small">
                              {localDate(week)} - {localDate(endOfWeek(week))}
                            </Element>
                            <MultipleMissionsInWeekMessage missions={weekSummary?.missions} />
                          </Heading>
                        </Heading>
                        {weekSummary ? (
                          <PayrollWeekTable
                            weekSummary={weekSummary}
                            weekNumber={weekNumber}
                            year={year}
                            weeklyHoursWorked={workerAssociation?.schedule.weeklyHoursWorked}
                            events={allWorkPeriodEvents.data}
                          />
                        ) : (
                          <Element mt={2}>Aucune journée travaillée cette semaine</Element>
                        )}
                      </Card.Content>
                    </Card>
                  </Element>
                )
              })}
            </Element>
          )}
        </Container>
      </Section>
    </Layout>
  )
}

export default PayrollDetails

const useDescriptionListItems = (
  payrollData?: PayrollData,
  userWeeksSummary?: UserSummaries,
): DescriptionListProps['items'] => {
  const currentOrganization = useStore(state => state.session.currentOrganization) as Organization
  const workerAssociation =
    payrollData?.user && getWorkerAndOrganizationAssociation(payrollData.user, currentOrganization)
  const descriptionListItems: DescriptionListProps['items'] = !payrollData
    ? []
    : [
        {
          label: <WorkerLabel />,
          value: getUserLabel(payrollData.user),
        },
        {
          label: 'Durée contrat',
          value: `${workerAssociation?.schedule.weeklyHoursWorked} h / semaine`,
        },
        {
          label: 'Période',
          value: `${localDate(startOfWeek(payrollData.from))} au ${localDate(
            endOfWeek(payrollData.to),
          )}`,
        },
      ]

  if (userWeeksSummary) {
    descriptionListItems.push({
      label: 'Heures Contractuelles de Base à prester sur la période / Ecart',
      value: (
        <>
          {formatDuration(userWeeksSummary.totals.durations.contract.legal)} /{' '}
          <DurationDiff
            duration={userWeeksSummary.totals.durations.worked.contract.legalDiff}
            showZero
          />
        </>
      ),
    })
    descriptionListItems.push({
      label: 'Heures Contractuelles Supplémentaires à prester sur la période / Ecart',
      value: (
        <>
          {formatDuration(userWeeksSummary.totals.durations.contract.extraLegal)} /{' '}
          <DurationDiff
            duration={userWeeksSummary.totals.durations.worked.contract.extraLegalDiff}
            showZero
          />
        </>
      ),
    })
    descriptionListItems.push({
      label: 'Heures Supplémentaires Non-Contractuelles',
      value: (
        <DurationDiff
          duration={userWeeksSummary.totals.durations.overtime.nonContractual || 0}
          showZero
        />
      ),
    })
  }
  return descriptionListItems
}
