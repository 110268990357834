import React from 'react'
import { FieldProps } from 'formik'
import FormField, { FormFieldProps } from './form-field'
import { AutocompleteFieldItem } from './autocomplete'
import { Button, Element, Icon } from 'react-bulma-components'
import { DraggableListIcon, RemoveIcon } from '../../icons'
import { moveItemInArray } from '../../../utils/arrays'
import { List } from 'react-movable'
import { WorkersRequestCreateUpdateFormValues } from '../../workers-requests/workers-request-form'

interface ListFieldProps
  extends FormFieldProps,
    FieldProps<WorkersRequestCreateUpdateFormValues['posts'][number]['desiredWorkers']> {
  items: AutocompleteFieldItem[]
}

const ListField = ({ label, form, field, items }: ListFieldProps): React.ReactNode => {
  const { name: fieldName } = field
  const fieldValue = field.value
  const setFieldValue = form.setFieldValue

  const removeItemFromList = (itemValue: string) => {
    setFieldValue(
      fieldName,
      fieldValue.filter(fV => fV !== itemValue),
    )
  }

  const moveItemInList = (itemIndex: number, moveTo: number) => {
    setFieldValue(fieldName, moveItemInArray([...fieldValue], itemIndex, moveTo))
  }
  return (
    <FormField label={label} error={form.errors[fieldName] as string}>
      <Element renderAs="ul" mb={3}>
        <List
          values={items}
          onChange={({ oldIndex, newIndex }) => {
            moveItemInList(oldIndex, newIndex)
          }}
          renderList={({ children, props }) => <div {...props}>{children}</div>}
          renderItem={({ value, props, isDragged }) => (
            <div {...props}>
              <Element p={1} py={2} backgroundColor={isDragged ? 'light' : undefined}>
                <Button
                  color="primary"
                  outlined
                  type="button"
                  onClick={() => removeItemFromList(value.value)}
                  size="small"
                  data-tooltip="Supprimer de la liste"
                  style={{ marginTop: -3 }}
                  mr={3}
                >
                  <Icon>
                    <RemoveIcon />
                  </Icon>
                </Button>
                <Icon.Text style={{ cursor: isDragged ? 'grabbing' : 'grab' }}>
                  <Icon
                    data-tooltip="Réordonnez la liste par glisser-déposer"
                    color="grey-light"
                    style={{ cursor: isDragged ? 'grabbing' : 'grab' }}
                  >
                    <DraggableListIcon />
                  </Icon>
                  <Element renderAs="span" ml={2}>
                    {value.renderedLabel || value.label}
                  </Element>
                </Icon.Text>
              </Element>
            </div>
          )}
        />
      </Element>
    </FormField>
  )
}

export default ListField
