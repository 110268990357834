import useStore from '../store/index'
import { FiltersResourcesEnum, FiltersSlice } from '../store/filters'

interface UseFiltersHook<Resource extends FiltersResourcesEnum> {
  filters: FiltersSlice[Resource]
  setFilters: FiltersSlice['setFilters']
}

function useFilters<Resource extends FiltersResourcesEnum>(
  resource: Resource,
): UseFiltersHook<Resource> {
  const filters = useStore(state => state.filters[resource])
  const setFilters = useStore(state => state.filters.setFilters)

  return { filters, setFilters }
}

export default useFilters
