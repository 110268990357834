import api, { ApiResponse } from '.'
import { SmicSchema } from '../../../backend/src/services/providers/insee/smic.model'

export const get = async (date: Date): Promise<ApiResponse<SmicSchema | undefined>> => {
  return await api({
    endpoint: `/smic`,
    method: 'GET',
    params: {
      date,
    },
  })
}
