type AssertPositive<N extends number> = number extends N
  ? N
  : `${N}` extends `-${string}`
  ? never
  : N

export const trimTextIfLongerThan = (
  text: string,
  trimIfLongerThan: AssertPositive<number>,
): string => {
  return text && text.length > trimIfLongerThan ? text.substring(0, trimIfLongerThan) + '...' : text
}
