import api, { ApiResponse } from '.'
import { WorkersRequestEventLogSchema } from '../../../backend/src/services/resources/event-logs/event-log.model'
import { MissionsFilters } from '../store/filters'
import { JobTitle } from './job-titles'
import { Employer, InterimAgency } from './organizations'
import { WorkPeriod, WorkPeriodCreationPayload, WorkPeriodWithSummary } from './work-periods'
import { getWeek, getWeekYear } from '../utils/date'
import { User } from './users'
import {
  MissionWeekSummary,
  UserSummaries as _UserSummaries,
  UserWeekSummary,
} from '../../../backend/src/services/resources/missions/missions.summary.service.types'

export type FetchMissionsParams = MissionsFilters

export interface ExportSummaryParams extends FetchMissionsParams {
  type: 'csv' | 'xls'
}

export interface SummaryFilters extends MissionsFilters {
  week?: number
  year?: number
}

export interface WeeksSummaryParams extends Omit<MissionsFilters, 'start' | 'end'> {
  from: Date
  to: Date
}

type FetchSummaryParams = {
  year: number
  week: number
  [key: string]: any
}

//TODO: use backend types
export type MissionSummary = {
  weeks: {
    [key: string]: MissionWeekSummary
  }
}

export interface MissionWithSummary extends Mission {
  summary: MissionSummary
  workPeriods: WorkPeriodWithSummary[]
  __actions: Mission['__actions'] & { weekCanBeValidated?: boolean }
  weekIsValidated?: boolean
}

export interface MissionWithSummaries extends MissionWithSummary {
  // TEMP: used by summary table
  //  to be deleted when the summary table will be refactored and use directly query data
  weekSummary: UserWeekSummary
}

type WorkersRequestEventLog = WorkersRequestEventLogSchema

export interface Mission {
  //TODO Import from backend!
  _id: string
  start: Date
  end: Date
  effectiveStart: Date
  effectiveEnd: Date
  isEnded: boolean
  isValidated: boolean
  isAbandoned: boolean
  isCancelled: boolean
  workPeriods: WorkPeriod[]
  service: string
  user: User
  interimAgency?: InterimAgency
  jobTitle: JobTitle
  employer: Employer
  rules: {
    abandonmentPayment: boolean
  }
  costs: {
    officialHourlyCost: number
    interimAgencyCostCoefficient: number
  }
  __actions: {
    canBeValidated: boolean
    canBeUpdated: boolean
    canBeUnAbandoned: boolean
  }
  createdAt: Date
  history: WorkersRequestEventLog[]
  preBilling?: string
  address: string
  complementaryInformations?: string
}

export type UserSummaries = _UserSummaries

export type WeeksSummary = UserSummaries[]

const POPULATE = [
  'workPeriods.events.item',
  'workPeriods.start.clocking',
  'workPeriods.start.comments.user',
  'workPeriods.end.comments.user',
  'workPeriods.end.clocking',
  'user',
  'employer',
  'interimAgency',
  'jobTitle',
]

const buildFetchParams = (params: FetchMissionsParams | SummaryFilters) => {
  const { start, end, 'workPeriods.preBilling': preBilling, ...rest } = params
  const res: any = {
    sort: 'user.lastName',
    'end>': start,
    'start<': end,
    summary: true,
    populate: POPULATE,
    ...rest,
  }
  if (preBilling?.length === 1 && (preBilling[0] === 'true' || preBilling[0] === true))
    res['workPeriods.preBilling'] = ''
  // Quickfix: refactor this when we refactor the filters
  else if (preBilling?.length === 1 && (preBilling[0] === 'false' || preBilling[0] === false))
    res['!workPeriods.preBilling'] = ''
  // Quickfix: refactor this when we refactor the filters
  else if (preBilling?.length === 1) res['workPeriods.preBilling'] = preBilling[0]

  return res
}

const buildFetchSummaryParams = (params: SummaryFilters): FetchSummaryParams => {
  const res = buildFetchParams(params)
  delete res['start>']
  delete res['start<']
  res.week = params.week || getWeek(new Date(params.start as Date))
  res.year = params.year || getWeekYear(new Date(params.start as Date))
  return res
}

export const fetch = async (params: FetchMissionsParams): Promise<ApiResponse<Mission[]>> =>
  await api({
    endpoint: '/missions',
    method: 'get',
    params: buildFetchParams(params),
  })

export const count = async (params: FetchMissionsParams): Promise<ApiResponse<number>> =>
  await api({
    endpoint: '/missions/count',
    method: 'get',
    params: buildFetchParams(params),
  })

export const fetchSummary = async (
  params: MissionsFilters,
): Promise<ApiResponse<UserWeekSummary[]>> =>
  await api({
    endpoint: '/missions/summary',
    method: 'get',
    params: buildFetchSummaryParams(params),
  })

export const fetchWeeksSummary = async (
  params: WeeksSummaryParams,
): Promise<ApiResponse<WeeksSummary>> =>
  await api({
    endpoint: '/missions/weeks-summary',
    method: 'get',
    params: buildFetchSummaryParams(params),
  })

export const get = async (id: string): Promise<ApiResponse<Mission>> =>
  await api({
    endpoint: `/missions/${id}`,
    method: 'get',
    params: {
      populate: POPULATE,
      summary: true,
    },
  })

export const exportSummary = async (params: ExportSummaryParams): Promise<ApiResponse<string>> => {
  const { start, end, type, ...rest } = params
  const res = await api({
    endpoint: '/missions/summary/export',
    method: 'get',
    params: {
      sort: 'user.lastName',
      ...buildFetchParams(rest),
      from: start,
      to: end,
      type,
    },
  })

  return res
}

export const fetchServices = async (
  //TODO: (TEMP) remove when "service" feature exists
  params: FetchMissionsParams,
): Promise<ApiResponse<string[]>> => {
  const { start, end, ...rest } = params
  return await api({
    endpoint: '/missions/services',
    method: 'get',
    params: {
      ...rest,
      'start>': start,
      'start<': end,
    },
  })
}

export type MissionCreationPayload = {
  user: User['_id']
  jobTitle: JobTitle['_id']
  service: Mission['_id']
  workPeriods: Array<Omit<WorkPeriodCreationPayload, 'missionId'>>
  address: Mission['address']
}

export const create = async (params: MissionCreationPayload): Promise<ApiResponse<Mission>> => {
  params.workPeriods = params.workPeriods.filter(w => w)
  return await api({
    endpoint: '/missions',
    method: 'post',
    params,
  })
}

export type MissionUpdatePayload = Partial<
  Pick<MissionCreationPayload, 'service' | 'workPeriods' | 'address'>
> &
  Pick<Mission, '_id'>

export const update = async (params: MissionUpdatePayload): Promise<ApiResponse<Mission>> => {
  const { _id, ...payload } = params
  return await api({
    endpoint: `/missions/${_id}`,
    method: 'put',
    params: payload,
  })
}
