import React from 'react'
import { Button, Icon } from 'react-bulma-components'
import { useModal } from '../../modal'
import { QrcodeIcon } from '@heroicons/react/outline'
import { User } from '../../../api/users'
import UserQrCodeModal from '../modals/user-qr-code'

interface ShowQrCodeButtonProps {
  user: User
}

const ShowQrCodeButton: React.FC<ShowQrCodeButtonProps> = ({ user }) => {
  const modal = useModal()
  return (
    <>
      <Button
        size="small"
        onClick={() => {
          modal.setIsDisplayed(true)
        }}
      >
        <Icon>
          <QrcodeIcon />
        </Icon>
      </Button>
      <UserQrCodeModal modalActions={modal} user={user} />
    </>
  )
}

export default ShowQrCodeButton
