import React from 'react'
import { RequestButton } from '../../request-components/request-components'
import ConfirmationModal from '../../confirmation-modal/confirmation-modal'
import { User, UserRoleKind } from '../../../api/users'
import { useModal } from '../../modal'
import {
  useDiscontinueUserOrganizationRoleMutation,
  useDiscontinueWorkerAssociationMutation,
} from '../../../queries/users'
import { Element } from 'react-bulma-components'
import { Organization } from '../../../api/organizations'
import { getUserLabel } from '../../../utils/users'

interface DiscontinueAssociationButtonProps {
  user: User
  roleKind: UserRoleKind
  organization: Organization
}

const DiscontinueAssociationButton: React.FC<DiscontinueAssociationButtonProps> = ({
  user,
  roleKind,
  organization,
}) => {
  const modal = useModal()
  const discontinueUserOrganizationRoleMutation = useDiscontinueUserOrganizationRoleMutation()
  const discontinueWorkerAssociationMutation = useDiscontinueWorkerAssociationMutation()

  return (
    <>
      <RequestButton
        size={'small'}
        variant="danger"
        onClick={() => modal.setIsDisplayed(true)}
        mutation={discontinueUserOrganizationRoleMutation}
      >
        Interrompre
      </RequestButton>
      <ConfirmationModal
        show={modal.isDisplayed}
        onCancel={() => modal.setIsDisplayed(false)}
        onConfirm={() => {
          if (roleKind === 'worker')
            discontinueWorkerAssociationMutation.mutate({ workerId: user._id })
          else discontinueUserOrganizationRoleMutation.mutate({ userId: user._id })
          modal.setIsDisplayed(false)
        }}
      >
        <Element renderAs="p" mb={3}>
          Êtes-vous certain de vouloir arrêter la collaboration avec{' '}
          <Element renderAs="strong">
            {user.firstName && user.lastName ? getUserLabel(user) : user.email}
          </Element>
          ?
        </Element>
        {roleKind === 'worker' ? (
          <Element>Vous ne pourrez plus lui attribuer de Missions.</Element>
        ) : (
          <Element>Il ne pourra plus accéder à l'espace TeamTim de {organization.name}.</Element>
        )}
      </ConfirmationModal>
    </>
  )
}

export default DiscontinueAssociationButton
