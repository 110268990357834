import { Formik } from 'formik'
import React, { FC } from 'react'
import useFilters from '../../hooks/filters'
import { FiltersResourcesEnum } from '../../store/filters'
import AutoSubmit from '../form/utils/auto-submit'
import Protected from '../protected/protected'
import WorkersFilterField from '../filters/fields/workers'
import OrganizationFilterField from '../filters/fields/organization'
import { OrganizationTypeEnum } from '../../api/organizations'
import JobTitleFilterField from '../filters/fields/job-title'
import IsCancelledFilterField from '../filters/fields/is-cancelled'
import DateFilterField from '../filters/fields/date'
import { Columns } from 'react-bulma-components'
import IsAbandonedFilterField from '../filters/fields/is-abandoned'
import IsValidatedFilterField from '../filters/fields/is-validated'

export const WorkPeriodsFilters: FC = () => {
  const { setFilters, filters } = useFilters(FiltersResourcesEnum.workPeriods)

  const queriesStaleTime = 1000 * 60 * 2 // 2 min

  return (
    <Formik
      initialValues={{
        start: filters.start,
        interimAgency: filters.interimAgency || [],
        employer: filters.employer || [],
        jobTitle: filters.jobTitle || [],
        user: filters.user || [],
        isValidated: filters.isValidated,
        isAbandoned: filters.isAbandoned,
        isCancelled: filters.isCancelled,
      }}
      onSubmit={values => {
        setFilters(FiltersResourcesEnum.workPeriods, values)
      }}
    >
      {props => {
        return (
          <Columns>
            <DateFilterField name="start" label="Date" />
            <Protected roles={['employerMember', 'interimAgencyMember']}>
              <WorkersFilterField />
            </Protected>
            <Protected roles={['employerMember']}>
              <OrganizationFilterField
                queriesStaleTime={queriesStaleTime}
                type={OrganizationTypeEnum.interimAgency}
              />
            </Protected>
            <Protected roles={['interimAgencyMember']}>
              <OrganizationFilterField
                queriesStaleTime={queriesStaleTime}
                type={OrganizationTypeEnum.employer}
              />
            </Protected>
            <Protected roles={['employerMember']}>
              <JobTitleFilterField
                queriesStaleTime={queriesStaleTime}
                employerIds={filters.employer}
              />
            </Protected>
            <Protected roles={['employerMember', 'interimAgencyMember']}>
              <IsCancelledFilterField />
            </Protected>
            <Protected roles={['employerMember', 'interimAgencyMember']}>
              <IsAbandonedFilterField />
            </Protected>
            <Protected roles={['employerMember', 'interimAgencyMember']}>
              <IsValidatedFilterField />
            </Protected>
            <AutoSubmit values={props.values} submitForm={props.submitForm} />
          </Columns>
        )
      }}
    </Formik>
  )
}
