import React from 'react'
import { useMissionsCountQuery } from '../../queries/missions'
import { DashboardCard } from './dashboard-card'
import { endOfWeek, startOfWeek } from '../../utils/date'

export const MissionsDashboardCard: React.FC = () => {
  const queriesCommonParams = {
    start: startOfWeek(new Date()),
    end: endOfWeek(new Date()),
    isCancelled: [false],
  }
  const missionsCountQuery = useMissionsCountQuery(queriesCommonParams)
  const validatedMissionsCountQuery = useMissionsCountQuery({
    ...queriesCommonParams,
    isValidated: true,
  })

  const totalMissions = missionsCountQuery?.data ?? 0
  const totalMissionsValidated = validatedMissionsCountQuery?.data ?? 0
  return (
    <DashboardCard
      title={'CETTE SEMAINE'}
      mainStat={{ value: totalMissions, label: 'Missions' }}
      secondaryStat={totalMissionsValidated + ' / ' + totalMissions + ' Validées'}
      to={'/missions'}
      queryStatus={validatedMissionsCountQuery.status}
    />
  )
}
