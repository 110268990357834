import React from 'react'
import { Mission } from '../../../api/missions'
import { WorkPeriod } from '../../../api/work-periods'
import { localDate } from '../../../utils/date'
import { findNextWorkPeriod, findPreviousWorkPeriod } from '../../../utils/missions'
import { PageNavigation } from '../../pages/page-navigation'

interface WorkPeriodPageNavigationProps {
  mission: Mission
  workPeriodId: WorkPeriod['_id']
}

export const WorkPeriodPageNavigation: React.FC<WorkPeriodPageNavigationProps> = ({
  mission,
  workPeriodId,
}) => {
  const previousWorkPeriod =
    mission && workPeriodId ? findPreviousWorkPeriod(mission, workPeriodId) : undefined
  const nextWorkPeriod =
    mission && workPeriodId ? findNextWorkPeriod(mission, workPeriodId) : undefined
  return (
    <PageNavigation
      items={[previousWorkPeriod, nextWorkPeriod]}
      labelBuilder={wp => localDate(wp.start.date)}
      linkBuilder={wp => `/missions/${mission._id}/work-periods/${wp._id}`}
    />
  )
}
