import { addDays, differenceInDays, isAfter, isBefore, isWithinInterval, parseISO } from 'date-fns'
import { Field, FieldArray, Formik, Form, FormikErrors, useFormikContext } from 'formik'
import React from 'react'
import {
  Card,
  Columns,
  Form as BulmaForm,
  Element,
  Button,
  Heading,
  Message,
  Icon,
  Level,
} from 'react-bulma-components'
import { useJobTitlesQuery } from '../../queries/job-titles'
import { useAssociatedOrganizationsQuery } from '../../queries/organizations'
import { formatCompleteDate, formatISODate } from '../../utils/date'
import { buildListAsInputOptions } from '../../utils/forms'
import InputField from '../form/fields/input'
import SelectField from '../form/fields/select'
import { RequestButton, RequestMessage } from '../request-components/request-components'
import {
  useCreateMutation,
  useCreatePost,
  useDeletePost,
  useUpdateMutation,
  useUpdatePost,
  useWorkersRequestsServicesQuery,
} from '../../queries/workers-requests'
import { isEqual, cloneDeep, get as _get } from 'lodash'
import AutocompleteField from '../form/fields/autocomplete'
import { WorkersRequest, WorkersRequestPost } from '../../api/workers-requests'
import { Employer, InterimAgency } from '../../api/organizations'
import { Mission } from '../../api/missions'
import config from '../../../../backend/src/config'
import { useWorkersQuery } from '../../queries/users'
import { User } from '../../api/users'
import ListField from '../form/fields/list-field'
import { AddResourceIcon, RemoveIcon } from '../icons'
import FormField from '../form/fields/form-field'
import useStore from '../../store'
import { getUserLabel, getWorkerLaborCostCoefficientKind } from '../../utils/users'
import WorkerLabel from '../texts/worker-label'
import TextareaField from '../form/fields/textarea'
import { WorkPeriodsEditor } from '../work-periods/work-periods-editor/work-periods-editor'
import { WorkPeriod } from '../../api/work-periods'
import { WorkPeriodEditorFormErrors } from '../work-periods/work-periods-editor/work-period-editor'
import { JobTitle } from '../../api/job-titles'
import { getDocInArray } from '../../utils/documents'
import { getJobTitleLaborCoefficientByInterimAgency } from '../../utils/job-titles'
import WorkerLaborCostCoefficientKindLabel from '../users/worker-labor-cost-coefficient-kind-label'
import {
  getWorkersRequestFormInitialValues,
  newPostTemplate,
} from './utils/workers-request-form-get-initial-values'
import FormikObserver from '../form/formik-observer'

const allRecourseCasesReasons = config.workersRequests.recourseCases.reasons
const allRecourseCasesReasonsAsInputOptions = Object.entries(allRecourseCasesReasons).map(
  ([key, value]) => ({
    value: key,
    label: value,
  }),
)

type WorkersRequestCreatePostValues = {
  recourseCase: WorkersRequestPost['recourseCase']
  desiredWorkers: Array<WorkersRequestPost['desiredWorkers'][number]['_id']>
  jobTitle: WorkersRequestPost['_id']
  count: number
  description?: string
}

export type WorkersRequestCreateFormValues = {
  meta: {
    startDate: string // YYYY-mm-dd
    endDate: string // YYYY-mm-dd
    resource?: WorkersRequest
  }
  interimAgency: InterimAgency['_id']
  missionData: {
    workPeriods: Array<
      {
        start?: { date: Date }
        end?: { date: Date }
      } & Partial<Omit<WorkPeriod, 'start' | 'end'>>
    >
    service: Mission['service']
    address: Mission['address']
    complementaryInformations?: Mission['complementaryInformations']
  }
  posts: Array<WorkersRequestCreatePostValues>
}

export interface WorkersRequestUpdateFormValues extends WorkersRequestCreateFormValues {
  posts:
    | WorkersRequestCreateFormValues['posts']
    | Array<
        {
          desiredWorkers: Array<WorkersRequestPost['desiredWorkers'][0]['_id']>
          jobTitle: JobTitle['_id']
          count: number
        } & Omit<WorkersRequestPost, 'desiredWorkers'>
      >
}

export type WorkersRequestCreateUpdateFormValues =
  | WorkersRequestCreateFormValues
  | WorkersRequestUpdateFormValues

type WorkersRequestFormErrors = FormikErrors<WorkersRequestCreateUpdateFormValues> &
  WorkPeriodEditorFormErrors

export type WorkersRequestFormProps =
  // When creating a blank workers request
  // When updating an existing workers request
  | {
      workersRequest?: WorkersRequest
      fromTemplate?: undefined
    }
  // When creating a new workers request and
  // using an existing mission as template
  | {
      workersRequest?: undefined
      fromTemplate: {
        mission: Mission & {
          workersRequestOfMission: WorkersRequest & { postOfMission: WorkersRequestPost }
        }
        workersRequest?: undefined
      }
    }
  // When creating a new workers request and
  // using an existing workers request as template
  | {
      workersRequest?: undefined
      fromTemplate: {
        mission?: undefined
        workersRequest: WorkersRequest
      }
    }

const WorkersRequestForm: React.FC<WorkersRequestFormProps> = props => {
  const { workersRequest } = props
  const currentOrganization = useStore(state => state.session.currentOrganization)
  // Local states
  const [currentJobTitle, setCurrentJobTitle] = React.useState<JobTitle | undefined>(undefined)

  // Resources queries
  const jobTitlesQuery = useJobTitlesQuery({})
  const missionsServicesQuery = useWorkersRequestsServicesQuery({})
  const interimAgenciesQuery = useAssociatedOrganizationsQuery()

  // Workers Request Mutations
  const createWorkersRequestMutation = useCreateMutation()
  const updateWorkersRequestMutation = useUpdateMutation()

  // Posts Mutations
  const createPostMutation = useCreatePost()
  const updatePostMutation = useUpdatePost()
  const deletePostMutation = useDeletePost()

  // Derived states
  const isNewWorkersRequest = workersRequest === undefined
  const isExistingWorkersRequest = !isNewWorkersRequest
  const isSubmitDisabled =
    workersRequest &&
    !workersRequest.__actions.canBeUpdated &&
    !workersRequest.__actions.requestedWorkersCanBeUpdated
  const isPostValuesUpdated: Record<WorkersRequestPost['_id'], boolean> = {} // Will be updated on each render

  const initialValues: WorkersRequestCreateUpdateFormValues =
    getWorkersRequestFormInitialValues(props)

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validate={values => {
        const errors: WorkersRequestFormErrors = {}

        // Interim Agency
        if (!values.interimAgency) errors['interimAgency'] = 'Champ requis'

        // Mission Data : Service
        if (!values.missionData?.service) {
          if (!errors.missionData) errors.missionData = {}
          errors.missionData.service = 'Champ requis'
        }

        // Mission Data : Address
        if (!values.missionData?.address) {
          if (!errors.missionData) errors.missionData = {}
          errors.missionData.address = 'Champ requis'
        }

        // Mission Data : WorkPeriods
        const startDate = parseISO(values.meta.startDate)
        const endDate = parseISO(values.meta.endDate)

        // If no workPeriod at all
        if (!values.missionData.workPeriods || values.missionData.workPeriods.length === 0) {
          if (!errors.missionData) errors.missionData = {}
          errors.missionData.workPeriods = "Pas d'horaire de mission"
        }

        // If startDate is after endDate, this is not gonna work..
        if (isAfter(startDate, endDate)) {
          if (errors.meta === undefined) errors.meta = {}
          errors.meta['startDate'] = 'La date de début doit être avant la date de fin'
        }

        // If startDate<>endDate length is > 365 days, this is not allowed
        if (Math.abs(differenceInDays(startDate, endDate)) > 365) {
          if (errors.meta === undefined) errors.meta = {}
          errors.meta['endDate'] = 'Une mission doit durer maximum 1 an'
        }

        for (let index = 0; index <= values.missionData.workPeriods.length; index++) {
          const workPeriod = values.missionData.workPeriods[index]
          const nextWorkPeriod = values.missionData.workPeriods[index + 1]
          const firstWorkPeriodStartDate =
            workPeriod && workPeriod.start ? workPeriod.start.date : undefined
          const firstWorkPeriodEndDate =
            workPeriod && workPeriod.end ? workPeriod.end.date : undefined
          const nextWorkPeriodStartDate =
            nextWorkPeriod && nextWorkPeriod.start ? nextWorkPeriod.start.date : undefined

          // If the end of a workPeriod overlaps the start of the next workPeriod
          if (firstWorkPeriodStartDate && firstWorkPeriodEndDate && nextWorkPeriodStartDate) {
            if (
              isBefore(nextWorkPeriodStartDate, firstWorkPeriodEndDate) ||
              isEqual(firstWorkPeriodEndDate, nextWorkPeriodStartDate)
            ) {
              const niceDate = formatCompleteDate(firstWorkPeriodStartDate)
              const isoDate = formatISODate(firstWorkPeriodStartDate)
              const error = `${niceDate} : L'heure de fin ne peut être plus grande ou égale à l'heure du début du jour suivant`
              if (!errors.missionData) errors.missionData = {}
              errors.missionData.workPeriods = error
              ;(errors[`workPeriod-${isoDate}`] ??= []).push(error)
            }
          }

          // If a workPeriod has no start date set
          if (firstWorkPeriodStartDate === undefined && firstWorkPeriodEndDate) {
            const date = firstWorkPeriodStartDate ?? firstWorkPeriodEndDate
            const niceDate = formatCompleteDate(date)
            const isoDate = formatISODate(date)
            const error = `${niceDate} : L'heure de début n'est pas précisée`
            if (!errors.missionData) errors.missionData = {}
            errors.missionData.workPeriods = error
            ;(errors[`workPeriod-${isoDate}`] ??= []).push(error)
          }
          // If a workPeriod has no end date set
          if (firstWorkPeriodStartDate && firstWorkPeriodEndDate === undefined) {
            const date = firstWorkPeriodStartDate ?? firstWorkPeriodEndDate
            const niceDate = formatCompleteDate(date)
            const isoDate = formatISODate(date!)
            const error = `${niceDate} : L'heure de fin n'est pas précisée`
            if (!errors.missionData) errors.missionData = {}
            errors.missionData.workPeriods = error
            ;(errors[`workPeriod-${isoDate}`] ??= []).push(error)
          }
        }

        // Posts
        if (values.posts.length === 0) {
          errors['posts'] = 'Merci de remplir au moins un poste à pourvoir'
        }
        for (let index = 0; index < values.posts.length; index++) {
          const post = values.posts[index]
          // Post Job Title
          if (!post?.jobTitle) {
            //@ts-expect-error
            errors[`posts.${index}.jobTitle`] = 'Veuillez choisir un intitulé de poste'
          } else if (post?.jobTitle) {
            const jobTitle = getDocInArray<JobTitle>(jobTitlesQuery.data || [], post.jobTitle)
            if (jobTitle && values.interimAgency) {
              const laborCoefficient = getJobTitleLaborCoefficientByInterimAgency(
                jobTitle,
                values.interimAgency,
              )
              if (
                !laborCoefficient ||
                laborCoefficient.delegationValue === null ||
                laborCoefficient.managementValue === null
              )
                //@ts-expect-error
                errors[
                  `posts.${index}.jobTitle`
                ] = `Le coefficient main d'oeuvre n'est pas configuré entre ce poste et l'agence.`
            }
          }
          // Post Recourse Case Reason
          if (!post?.recourseCase.reason) {
            //@ts-expect-error
            errors[`posts.${index}.recourseCase.reason`] = 'Veuillez choisir un Cas de Recours'
          }
          // Post Recourse Case Justification
          if (!post?.recourseCase.justification) {
            //@ts-expect-error
            errors[`posts.${index}.recourseCase.justification`] =
              'Veuillez remplir une Justification pour le Cas de Recours'
          }
        }

        return errors
      }}
      onSubmit={values => {
        const { startDate, endDate } = values.meta
        const missionStartDate = parseISO(startDate)
        const missionEndDate = addDays(parseISO(endDate), 1)

        // The form is validated, we know that each workPeriod is complete and valid
        const workPeriods = cloneDeep(
          values.missionData.workPeriods as Array<
            Required<WorkersRequestCreateUpdateFormValues['missionData']['workPeriods'][number]>
          >,
        )
          // We remove all existing work-periods before and after startDate/endDate
          .filter(wP =>
            isWithinInterval(wP.start.date, { start: missionStartDate, end: missionEndDate }),
          )
          // only start and end dates are needed
          .map(wP => {
            return {
              start: { date: wP.start.date },
              end: { date: wP.end.date },
            }
          })
        if (isExistingWorkersRequest) {
          if (workersRequest && workersRequest.__actions.canBeUpdated) {
            // Workers Requests global values have been updated
            const { missionData } = values

            updateWorkersRequestMutation.mutate({
              id: workersRequest._id,
              missionData: {
                workPeriods,
                service: missionData.service,
              },
            })
          }
          if (workersRequest && workersRequest.__actions.requestedWorkersCanBeUpdated) {
            // Maybe a post has been updated
            Object.entries(isPostValuesUpdated).forEach(([postId, isUpdated]) => {
              if (isUpdated) {
                const updatedPost = values.posts.find(post => '_id' in post && post._id === postId)
                if (updatedPost) {
                  const { desiredWorkers, recourseCase, jobTitle, description } = updatedPost
                  updatePostMutation.mutate({
                    workersRequestId: workersRequest._id.toString(),
                    postId: postId,
                    desiredWorkers,
                    recourseCase,
                    jobTitleId: jobTitle,
                    description,
                  })
                } else {
                  console.error("Couldn't find updated post, should not happen")
                }
              }
            })
          }
          if (workersRequest && workersRequest.__actions.requestedWorkersCanBeUpdated) {
            const draftPosts = values.posts.filter(post => !('_id' in post))
            // Maybe a post has been created
            const postsPayload = draftPosts.reduce((all, current) => {
              const { count, ...post } = current
              for (let i = 0; i < count; i++) {
                all.push(post)
              }
              return all
            }, [] as Array<Omit<WorkersRequestCreatePostValues, 'count'>>)
            postsPayload.forEach(post => {
              const { desiredWorkers, recourseCase, jobTitle, description } = post
              createPostMutation.mutate({
                workersRequestId: workersRequest._id.toString(),
                desiredWorkers,
                recourseCase,
                jobTitle: jobTitle,
                description,
              })
            })
          }
        } else if (isNewWorkersRequest) {
          const { posts, interimAgency, missionData } = values as WorkersRequestCreateFormValues

          const postsPayload = posts.reduce((acc, curr) => {
            const updated = acc
            const { count, ...post } = curr
            for (let i = 0; i < count; i++) {
              updated.push(post)
            }
            return updated
          }, [] as Array<Omit<WorkersRequestCreatePostValues, 'count'>>)
          // We create a new workers request
          createWorkersRequestMutation.mutate({
            interimAgency,
            missionData: {
              ...missionData,
              workPeriods,
            },
            posts: postsPayload,
          })
        }
      }}
    >
      {props => {
        return (
          <Form>
            <Card mb={6}>
              <Card.Content>
                <Columns>
                  <Columns.Column size={6}>
                    <Field
                      label="Agence d'intérim concernée"
                      name="interimAgency"
                      component={SelectField}
                      required
                      fullwidth="true"
                      options={buildListAsInputOptions(interimAgenciesQuery.data || [])}
                      selectDefaultChooseOptionLabel="Agence d'Intérim..."
                      disabled={Boolean(workersRequest)}
                    />
                  </Columns.Column>
                  <Columns.Column size={6}>
                    <Field
                      label="Service"
                      name="missionData.service"
                      component={AutocompleteField}
                      placeholder="Service..."
                      required
                      items={buildListAsInputOptions(missionsServicesQuery.data || [])}
                      fullwidth="true"
                      selectDefaultChooseOptionLabel={'Choisir le Service'}
                      disabled={workersRequest && !workersRequest.__actions.canBeUpdated}
                    />
                  </Columns.Column>
                </Columns>
                <Columns>
                  <Columns.Column>
                    <Element>
                      <Field
                        name="missionData.address"
                        label="Adresse de la prestation"
                        component={AutocompleteField}
                        required
                        items={buildListAsInputOptions(currentOrganization?.addresses ?? [])}
                        disabled={workersRequest && !workersRequest.__actions.canBeUpdated}
                      />
                    </Element>
                  </Columns.Column>
                </Columns>
                <Columns>
                  <Columns.Column>
                    <Element>
                      <Field
                        name="missionData.complementaryInformations"
                        label="Informations complémentaires pour l'Intérimaire"
                        component={InputField}
                        disabled={workersRequest}
                      />
                    </Element>
                  </Columns.Column>
                </Columns>
              </Card.Content>
            </Card>
            <Heading size={4}>Horaire type de la prestation</Heading>
            <Card>
              <Card.Content>
                <WorkPeriodsEditor
                  workPeriods={props.values.missionData.workPeriods}
                  initialWorkPeriods={props.initialValues.missionData.workPeriods}
                  resource={workersRequest}
                  setWorkPeriods={wPs => props.setFieldValue('missionData.workPeriods', wPs)}
                  errors={{ ...props.errors, workPeriods: props.errors.missionData?.workPeriods }}
                  startDate={props.values.meta.startDate}
                  endDate={props.values.meta.endDate}
                  jobTitle={currentJobTitle?._id.toString()}
                />
                <Level mt={5}>
                  <Level.Side></Level.Side>
                  <Level.Side>
                    <FormField>
                      <Element renderAs="span" textWeight="bold">
                        Simuler le calcul d'horaire pour
                      </Element>
                      <br />
                      <BulmaForm.Select
                        onChange={e => {
                          setCurrentJobTitle(
                            jobTitlesQuery.data?.find(jT => jT._id === e.target.value),
                          )
                        }}
                      >
                        <option style={{ display: 'none' }} value={undefined}>
                          {'Choisir un Intitulé de Poste'}
                        </option>
                        {jobTitlesQuery.data?.map(jobTitle => (
                          <option key={jobTitle._id} value={jobTitle._id}>
                            {jobTitlesQuery.data?.find(jT => jobTitle._id === jT._id)?.name}
                          </option>
                        ))}
                      </BulmaForm.Select>
                    </FormField>
                  </Level.Side>
                </Level>
              </Card.Content>
              {isExistingWorkersRequest && (
                <Card.Footer>
                  <Card.Footer.Item>
                    <Element>
                      <Element>
                        <RequestMessage
                          mutation={updateWorkersRequestMutation}
                          displayOn={{ success: 'La demande a été mise à jour' }}
                        />
                      </Element>
                      <RequestButton
                        mutation={updateWorkersRequestMutation}
                        color="primary"
                        type="submit"
                        disabled={isSubmitDisabled}
                        data-test="submit-workers-request-form"
                      >
                        {'Mettre à jour la Demande'}
                      </RequestButton>
                    </Element>
                  </Card.Footer.Item>
                </Card.Footer>
              )}
            </Card>

            <Heading size={4} mt={6}>
              Postes à Pourvoir
            </Heading>
            {props.errors.posts && typeof props.errors.posts === 'string' && (
              <Element textColor="danger">{props.errors.posts}</Element>
            )}
            <FieldArray
              name="posts"
              render={arrayHelpers => {
                return (
                  <>
                    {props.values.posts.map((post, index, allPosts) => {
                      const isExistingPost = '_id' in post
                      const isDraftPost = !('_id' in post)
                      const existingPost = isExistingPost ? post : undefined
                      const draftPost = isDraftPost ? post : undefined
                      const key = isExistingPost ? post._id : index
                      const existingPostInitialValues =
                        existingPost &&
                        initialValues.posts.find(p => '_id' in p && p._id === existingPost._id)
                      const isPostUpdated =
                        existingPostInitialValues &&
                        !isEqual(existingPostInitialValues, existingPost)
                      if (existingPost) {
                        isPostValuesUpdated[existingPost._id] = Boolean(isPostUpdated)
                      }

                      return (
                        <React.Fragment key={key}>
                          <Card mb={5}>
                            <Card.Header p={3}>
                              <Card.Header.Title>
                                <Element renderAs="span" textColor="primary" textSize={5}>
                                  {post.count && post.jobTitle
                                    ? post.count +
                                      ' x ' +
                                      jobTitlesQuery.data?.find(
                                        jT => post.jobTitle === jT._id.toString(),
                                      )?.name
                                    : undefined}
                                </Element>
                                {post.recourseCase.reason ? (
                                  //@ts-expect-error
                                  <>&nbsp; | {allRecourseCasesReasons[post.recourseCase.reason]}</>
                                ) : (
                                  ''
                                )}
                                {post.recourseCase.justification ? (
                                  <>&nbsp; | {post.recourseCase.justification}</>
                                ) : (
                                  ''
                                )}
                              </Card.Header.Title>
                              <Card.Header.Icon>
                                {
                                  // Display "delete post" button only IF
                                  // The post is already created (existing workers request) BUT empty
                                  ((existingPost &&
                                    existingPost.missions.length === 0 &&
                                    allPosts.length > 1) ||
                                    // OR
                                    // The post is added (draft post) on an existing workers request
                                    (isDraftPost && !isNewWorkersRequest) ||
                                    // OR
                                    // The post is from a workers request which is not created (draft)
                                    (isNewWorkersRequest && allPosts.length > 1)) && (
                                    <div className="field">
                                      <Button
                                        color="danger"
                                        size={'small'}
                                        outlined
                                        onClick={() => {
                                          if (isExistingWorkersRequest && isExistingPost) {
                                            post
                                            // We are updating an existing workers request, the post already exists
                                            deletePostMutation.mutate({
                                              workersRequestId: workersRequest._id,
                                              postId: post._id,
                                            })
                                          } else {
                                            // We are creating a new workers request, the post doesn't already exist
                                            // We only remove it from the form state
                                            arrayHelpers.remove(index)
                                          }
                                        }}
                                        type="button"
                                      >
                                        <Icon>
                                          <RemoveIcon />
                                        </Icon>
                                        <Element renderAs="span">Supprimer ce Poste</Element>
                                      </Button>
                                    </div>
                                  )
                                }
                              </Card.Header.Icon>
                            </Card.Header>
                            <Card.Content pt={1}>
                              <Columns>
                                {isDraftPost && (
                                  <Columns.Column size={2}>
                                    {isDraftPost && (
                                      <Field
                                        label={'Nombre'}
                                        name={`posts.${index}.count`}
                                        component={InputField}
                                        min={1}
                                        type="number"
                                        required
                                        fullwidth="true"
                                      />
                                    )}
                                  </Columns.Column>
                                )}
                                <Columns.Column>
                                  <Field
                                    label="Intitulé de Poste"
                                    name={`posts.${index}.jobTitle`}
                                    component={SelectField}
                                    required
                                    fullwidth="true"
                                    options={buildListAsInputOptions(jobTitlesQuery.data || [])}
                                    selectDefaultChooseOptionLabel={'Intitulé de Poste...'}
                                    disabled={
                                      workersRequest &&
                                      !workersRequest.__actions.requestedWorkersCanBeUpdated
                                    }
                                  />
                                  {props.values.posts[index]?.jobTitle &&
                                  jobTitlesQuery.data?.find(
                                    jT => jT._id.toString() === props.values.posts[index]?.jobTitle,
                                  )?.complementaryInformations ? (
                                    <Card>
                                      <Card.Header>
                                        <Card.Header.Title>Infos transmises</Card.Header.Title>
                                      </Card.Header>
                                      <Card.Content>
                                        {
                                          jobTitlesQuery.data.find(
                                            jT =>
                                              jT._id.toString() ===
                                              props.values.posts[index]?.jobTitle,
                                          )?.complementaryInformations
                                        }
                                      </Card.Content>
                                    </Card>
                                  ) : undefined}
                                </Columns.Column>
                                <Columns.Column>
                                  <Field
                                    label={'Cas de Recours'}
                                    name={`posts.${index}.recourseCase.reason`}
                                    component={SelectField}
                                    required
                                    fullwidth="true"
                                    // The actual value of the field is part of the available options
                                    options={allRecourseCasesReasonsAsInputOptions}
                                    selectDefaultChooseOptionLabel="Choisir un Cas de Recours"
                                    disabled={
                                      workersRequest &&
                                      !workersRequest.__actions.requestedWorkersCanBeUpdated
                                    }
                                  />
                                </Columns.Column>
                                <Columns.Column>
                                  <Field
                                    label={'Justification'}
                                    component={InputField}
                                    name={`posts.${index}.recourseCase.justification`}
                                    required
                                    fullwidth="true"
                                    disabled={
                                      workersRequest &&
                                      !workersRequest.__actions.requestedWorkersCanBeUpdated
                                    }
                                  />
                                </Columns.Column>
                              </Columns>
                              <Columns>
                                <Columns.Column>
                                  <Field
                                    label={
                                      <Element>
                                        Description du Poste pour l'Agence d'Intérim
                                      </Element>
                                    }
                                    component={TextareaField}
                                    name={`posts.${index}.description`}
                                    fullwidth="true"
                                    disabled={
                                      workersRequest &&
                                      !workersRequest.__actions.requestedWorkersCanBeUpdated
                                    }
                                  />
                                </Columns.Column>
                              </Columns>
                              <Columns>
                                <Columns.Column>
                                  <DesiredWorkersField
                                    index={index}
                                    fieldName={`posts.${index}.desiredWorkers`}
                                    fieldValue={props.values.posts[index]?.desiredWorkers ?? []}
                                    employer={currentOrganization as Employer}
                                  />
                                </Columns.Column>
                              </Columns>
                            </Card.Content>
                            {isExistingPost && (
                              <Card.Footer>
                                <Card.Footer.Item>
                                  <Element>
                                    <Element>
                                      <RequestMessage
                                        mutation={updatePostMutation}
                                        displayOn={{ success: 'Le poste a été mis à jour' }}
                                      />
                                    </Element>
                                    <RequestButton
                                      mutation={updatePostMutation}
                                      color="primary"
                                      type="submit"
                                      disabled={isSubmitDisabled || !isPostUpdated}
                                      data-test={`submit-workers-request-post-update-${index}-form`}
                                    >
                                      {'Mettre à jour le Poste'}
                                    </RequestButton>
                                  </Element>
                                </Card.Footer.Item>
                              </Card.Footer>
                            )}
                            {isExistingWorkersRequest && isDraftPost && (
                              <Card.Footer>
                                <Card.Footer.Item>
                                  <Element>
                                    <Element>
                                      <RequestMessage
                                        mutation={createPostMutation}
                                        displayOn={{ success: 'Le poste a été créé' }}
                                      />
                                    </Element>
                                    <RequestButton
                                      mutation={createPostMutation}
                                      color="primary"
                                      type="submit"
                                      disabled={isSubmitDisabled}
                                      data-test={`submit-workers-request-post-create-${index}-form`}
                                    >
                                      {'Créer le Poste'}
                                    </RequestButton>
                                  </Element>
                                </Card.Footer.Item>
                              </Card.Footer>
                            )}
                          </Card>
                          {index === allPosts.length - 1 && (
                            <Card mb={6}>
                              <Card.Content>
                                <Button
                                  textAlign="right"
                                  color="primary"
                                  outlined
                                  onClick={() => {
                                    arrayHelpers.push(structuredClone(newPostTemplate))
                                  }}
                                  type="button"
                                >
                                  <Icon>
                                    <AddResourceIcon />
                                  </Icon>
                                  <Element renderAs="span">Ajouter un Poste à Pourvoir</Element>
                                </Button>
                              </Card.Content>
                            </Card>
                          )}
                        </React.Fragment>
                      )
                    })}
                  </>
                )
              }}
            />
            {isNewWorkersRequest && (
              <Element>
                <Element mb={3}>
                  <RequestMessage mutation={createWorkersRequestMutation} />
                </Element>
                <RequestButton
                  mutation={createWorkersRequestMutation}
                  color="primary"
                  type="submit"
                  mr={3}
                  style={{ width: '100%' }}
                  disabled={isSubmitDisabled}
                  data-test="submit-workers-request-form"
                >
                  {'Envoyer la Demande'}
                </RequestButton>
              </Element>
            )}
          </Form>
        )
      }}
    </Formik>
  )
}

export default WorkersRequestForm

const DesiredWorkersField: React.FC<{
  index: number
  fieldName: string
  fieldValue: WorkersRequestCreateUpdateFormValues['posts'][number]['desiredWorkers']
  employer: Employer
}> = ({ index, fieldName, fieldValue, employer }) => {
  const {
    values: {
      interimAgency,
      meta: { resource: workersRequest },
    },
    setFieldValue,
  } = useFormikContext<WorkersRequestCreateUpdateFormValues>()
  const workersQuery = useWorkersQuery(
    { interimAgency },
    { enabled: Boolean(interimAgency) }, //enabled only when a interim  agency is selected
  )

  const allWorkers = workersQuery.data

  const selectedWorkers = fieldValue
    .map(dWId => allWorkers?.find(w => w._id === dWId))
    .filter((w): w is User => !!w)
  const selectedItems = buildListAsInputOptions(selectedWorkers ?? [], {
    labelBuilder: user => getUserLabel(user),
    renderLabel: user => (
      <InputUserLabel user={user} employer={employer} interimAgencyId={interimAgency} />
    ),
    sortBy: null,
  })
  const selectableWorkers = allWorkers?.filter(w => !fieldValue.some(dWId => dWId === w._id))
  const selectableItems = buildListAsInputOptions(selectableWorkers ?? [], {
    labelBuilder: user => getUserLabel(user),
    renderLabel: user => (
      <InputUserLabel user={user} employer={employer} interimAgencyId={interimAgency} />
    ),
  })

  if (!interimAgency)
    return (
      <Message color="warning">
        <Message.Body>Vous n'avez pas encore sélectionné une Agence d'Intérim.</Message.Body>
      </Message>
    )

  if (workersQuery.isFetched && !workersQuery.data?.[0])
    return (
      <Message color="warning">
        <Message.Body>
          <b>
            Aucun <WorkerLabel /> à suggérer
          </b>
          <br />
          Vous n'avez pas encore collaboré avec des <WorkerLabel plural /> via l'Agence d'Intérim
          sélectionnée
        </Message.Body>
      </Message>
    )

  return (
    <>
      <Field
        component={ListField}
        label="Intérimaires suggérées pour ce poste"
        required
        name={fieldName}
        items={selectedItems}
        fullwidth="true"
        disabled={workersRequest && !workersRequest.__actions.canBeUpdated}
      />
      <Formik<{
        posts: Array<{ desiredWorkers: User['_id'] | undefined }>
      }>
        initialValues={{
          posts: Array.from({ length: index + 1 }).map(p => ({ desiredWorkers: undefined })),
        }}
        onSubmit={() => undefined}
      >
        {props => (
          <>
            <FormikObserver
              values={props.values.posts[index]!.desiredWorkers}
              onChange={desiredWorker => {
                if (desiredWorker) {
                  setFieldValue(fieldName, [...fieldValue, desiredWorker])
                }
              }}
            />
            <Field
              component={AutocompleteField}
              items={selectableItems}
              onlySelect
              name={fieldName}
              clearOnSelect
              showItemsAfterSelect
              placeholder="Choisir..."
            />
          </>
        )}
      </Formik>
    </>
  )
}

const InputUserLabel: React.FC<{
  user: User
  employer: Employer
  interimAgencyId?: string
}> = ({ user, employer, interimAgencyId }) => {
  const laborCostCoefficientKind =
    interimAgencyId && getWorkerLaborCostCoefficientKind(user, interimAgencyId, employer)

  return (
    <>
      {getUserLabel(user)}
      {laborCostCoefficientKind && (
        <Element renderAs="small">
          {' '}
          -{' '}
          <WorkerLaborCostCoefficientKindLabel
            laborCostCoefficientKind={laborCostCoefficientKind}
          />
        </Element>
      )}
    </>
  )
}
