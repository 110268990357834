import React from 'react'
import { SummaryXlsExportData } from '../../../backend/src/services/resources/missions/missions.summary.service.types'

const useXlsxDownload = (fileName?: string, data?: SummaryXlsExportData): void => {
  React.useEffect(() => {
    if (data) {
      const run = async () => {
        const XLSX = await import('xlsx')
        const worksheet = XLSX.utils.json_to_sheet(data.content)
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, data.sheet)
        XLSX.writeFile(workbook, fileName || `${new Date()}.xlsx`)
      }
      run()
    }
  }, [data])
}

export default useXlsxDownload
