import React from 'react'
import { Columns, Element, Form, Heading } from 'react-bulma-components'
import { NotificationSettings } from '../../../../backend/src/services/resources/users/user.model'

interface NotificationsSettingsProps {
  resource: string
  title: string
  settings: { [key: string]: NotificationSettings }
  locales: { [key: string]: string }
  onItemChange(actionName: string, value: boolean, type: 'email'): void
  disabledFields?: string[]
}

const NotificationsSettings: React.FC<NotificationsSettingsProps> = ({
  resource,
  settings,
  title,
  locales,
  onItemChange,
  disabledFields,
}) => {
  if (!settings) return <></>
  return (
    <Element>
      <Heading size={6}>{title}</Heading>
      {Object.keys(settings).map(actionName => (
        <Columns key={`${resource}_${actionName}`} my={1}>
          <Columns.Column size={4} py={1}>
            {locales[actionName]}
          </Columns.Column>
          <Columns.Column size={4} py={1}>
            <Form.Field>
              <Form.Control>
                <Form.Checkbox
                  checked={settings[actionName]?.email}
                  onChange={() => onItemChange(actionName, !settings[actionName]?.email, 'email')}
                  disabled={disabledFields && disabledFields.includes(actionName)}
                >
                  Email
                </Form.Checkbox>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
        </Columns>
      ))}
    </Element>
  )
}

export default NotificationsSettings
