import React from 'react'
import { Section, Button, Container, Form, Icon } from 'react-bulma-components'
import Layout from '../../components/layout/layout'
import Table from '../../components/table'
import PageHeading from '../../components/sections/page-heading'
import { Link } from 'react-router-dom'
import ComponentHeader from '../../components/sections/component-header'
import { useJobTitlesQuery } from '../../queries/job-titles'
import { Column } from 'react-table'
import { JobTitle } from '../../api/job-titles'
import { trimTextIfLongerThan } from '../../utils/texts'
import { ResourceLinkCell } from '../../components/table/cells'
import { DocumentAddIcon } from '@heroicons/react/solid'
import { sortByIgnoreCase } from '../../utils/arrays'

const JobTitlesList: React.FunctionComponent = () => {
  const jobTitlesQuery = useJobTitlesQuery()
  const tableColumns = useListTableColumns()

  return (
    <Layout>
      <PageHeading title="Liste des Intitulés de Poste" />
      <Section>
        <Container>
          <ComponentHeader>
            <ComponentHeader.Right>
              <Form.Field>
                <Form.Label>&nbsp;</Form.Label>
                <Form.Control>
                  <Button renderAs={Link} to="/job-titles/new" outlined color="primary">
                    <Icon>
                      <DocumentAddIcon />
                    </Icon>
                    <span>Nouvel Intitulé de Poste</span>
                  </Button>
                </Form.Control>
              </Form.Field>
            </ComponentHeader.Right>
          </ComponentHeader>
          <Table
            columns={tableColumns}
            data={sortByIgnoreCase<JobTitle>(jobTitlesQuery.data || [], 'name')}
            noDataMessage="Aucun Intitulé de Poste à afficher"
          />
        </Container>
      </Section>
    </Layout>
  )
}

export default JobTitlesList

const useListTableColumns = (): Column<JobTitle>[] => {
  return React.useMemo(
    () => [
      {
        Header: 'Nom',
        accessor: 'name',
        Cell: data => (
          <ResourceLinkCell
            resource={data.row.original}
            path="/job-titles/"
            label={data.row.original.name}
          />
        ),
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: ({ value: description }) => (
          <>{description ? trimTextIfLongerThan(description, 120) : ''}</>
        ),
      },
      {
        Header: 'Informations Complémentaires',
        accessor: 'complementaryInformations',
        Cell: ({ value: complementaryInformations }) => (
          <>
            {complementaryInformations ? trimTextIfLongerThan(complementaryInformations, 120) : ''}
          </>
        ),
      },
      {
        Header: 'Règles de pointage',
        accessor: 'clockingRule',
        Cell: ({ value }) => (
          <ResourceLinkCell resource={value} path="/clocking-rules/" label={value.name} />
        ),
      },
    ],
    [],
  )
}
