import React from 'react'
import { WorkPeriodEvent } from '../../api/work-period-events'
import { useCreateMutation, useUpdateMutation } from '../../queries/work-period-events'
import { Field, Form, Formik } from 'formik'
import InputField from '../form/fields/input'
import { RequestButton, RequestMessage } from '../request-components/request-components'
import SelectField from '../form/fields/select'
import locales from '../../../../locales/work-period-events'
import { Element, Icon, Message } from 'react-bulma-components'
import { HelpIcon } from '../icons'
import WorkPeriodEventInformativeDescription from './work-period-event-informative-description'
import WorkPeriodEventBenefitDescription from './work-period-event-benefit-description'

export interface WorkPeriodEventFormProps {
  workPeriodEvent?: WorkPeriodEvent
}

const WorkPeriodEventForm: React.FC<WorkPeriodEventFormProps> = ({ workPeriodEvent }) => {
  const createMutation = useCreateMutation()
  const updateMutation = useUpdateMutation()

  return (
    <Formik
      initialValues={workPeriodEvent || { code: '', title: '', type: '' }}
      enableReinitialize
      validate={values => {
        const errors: any = {}
        if (!values.code) errors.code = 'Champ requis'
        if (!values.title) errors.title = 'Champ requis'
        if (!values.type) errors.type = 'Champ requis'
        return errors
      }}
      onSubmit={values => {
        workPeriodEvent
          ? updateMutation.mutate({ ...values, id: workPeriodEvent._id.toString() })
          : createMutation.mutate(values)
      }}
    >
      {() => {
        return (
          <Form autoComplete="off">
            <Field label="Code" name="code" component={InputField} />
            <Field label="Intitulé" name="title" component={InputField} />
            <Field
              label="Type"
              name="type"
              component={SelectField}
              options={[
                {
                  value: 'informative',
                  label: locales.types.informative,
                },
                {
                  value: 'benefit',
                  label: locales.types.benefit,
                },
              ]}
            />
            <Message color={'info'} textSize={7}>
              <Message.Body>
                <Icon>
                  <HelpIcon />
                </Icon>
                <Element>
                  <WorkPeriodEventInformativeDescription />
                </Element>
                <Element>
                  <WorkPeriodEventBenefitDescription />
                </Element>
              </Message.Body>
            </Message>
            <RequestButton
              color="primary"
              type="submit"
              mutation={workPeriodEvent ? updateMutation : createMutation}
            >
              {workPeriodEvent ? "Mettre à jour l'Évènement" : 'Nouvel Évènement'}
            </RequestButton>
            <RequestMessage mutation={workPeriodEvent ? updateMutation : createMutation} />
          </Form>
        )
      }}
    </Formik>
  )
}

export default WorkPeriodEventForm
