import React from 'react'
import { EmployerRule } from '../../api/employer-rules'
import { Column } from 'react-table'
import { ResourceLinkCell } from '../../components/table/cells'
import Layout from '../../components/layout/layout'
import PageHeading from '../../components/sections/page-heading'
import { Container, Icon, Section } from 'react-bulma-components'
import Table from '../../components/table'
import { sortByIgnoreCase } from '../../utils/arrays'
import { useEmployerRulesQuery } from '../../queries/employer-rules'
import EmployerRuleDescription from '../../components/employer-rules/employer-rule-description'
import useStore from '../../store'
import Protected from '../../components/protected/protected'
import { CheckIcon } from '../../components/icons'

const EmployerRulesList: React.FC = ({}) => {
  const currentOrganization = useStore(state => state.session.currentOrganization)
  const tableColumns = useRulesListTableColumns(currentOrganization?._id)

  const employerRulesQuery = useEmployerRulesQuery({})
  return (
    <Layout>
      <PageHeading title="Liste des Règles Financières" />
      <Section>
        <Container>
          <Table
            columns={tableColumns}
            data={sortByIgnoreCase<EmployerRule>(employerRulesQuery.data || [], 'name')}
            noDataMessage="Aucune Règle Financière à afficher"
          />
        </Container>
      </Section>
    </Layout>
  )
}

export default EmployerRulesList

const useRulesListTableColumns = (employerId?: string): Column<EmployerRule>[] => {
  return React.useMemo(
    () => [
      {
        Header: 'Nom',
        accessor: 'name',
        Cell: data => (
          <>
            <Protected roles={['superAdmin']}>
              <ResourceLinkCell
                resource={data.row.original}
                path={`/employers/${employerId}/rules/`}
                label={data.row.original.name}
              />
            </Protected>
            <Protected roles={['employerMember']}>{data.row.original.name}</Protected>
          </>
        ),
      },
      {
        Header: 'Nom court',
        accessor: 'shortName',
      },
      {
        Header: 'Description',
        accessor: 'interval.period',
        Cell: data => <EmployerRuleDescription employerRule={data.row.original} />,
      },
      {
        Header: 'Récap.',
        accessor: 'display.summary',
        Cell: data => {
          const employerRule = data.row.original as EmployerRule
          if (employerRule.display.summary)
            return (
              <Icon>
                <CheckIcon />
              </Icon>
            )
          return null
        },
      },
      {
        Header: 'Paie',
        accessor: 'display.payroll',
        Cell: data => {
          const employerRule = data.row.original as EmployerRule
          if (employerRule.display.payroll)
            return (
              <Icon>
                <CheckIcon />
              </Icon>
            )
          return null
        },
      },
    ],
    [],
  )
}
