import React from 'react'
import { ClockingRules } from '../../api/clocking-rules'
import { Element } from 'react-bulma-components'

interface ClockingsRulesClockedBreaksDescriptionProps {
  rules: ClockingRules['break']
}

const ClockingsRulesClockedBreaksDescription: React.FC<
  ClockingsRulesClockedBreaksDescriptionProps
> = ({ rules }) => {
  if (!rules.clockingsTolerance.early && !rules.clockingsTolerance.late) return null
  return (
    <Element>
      La durée totale de pause sera de{' '}
      <Element renderAs="strong">{rules.breakDuration} minutes</Element> si le temps total des
      pauses pointées est entre{' '}
      <Element renderAs="strong">
        {rules.breakDuration - rules.clockingsTolerance.early} et{' '}
        {rules.breakDuration + rules.clockingsTolerance.late} minutes
      </Element>
      .
    </Element>
  )
}

export default ClockingsRulesClockedBreaksDescription
