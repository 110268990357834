import { JobTitleSchema } from '../../../backend/src/services/resources/job-titles/job-title.model'
import { JobTitleRepositoryCreationPayload } from '../../../backend/src/services/resources/job-titles/job-titles.repository.service.types'
import api, { ApiResponse } from '.'
import { ClockingRules } from './clocking-rules'

export interface JobTitle extends JobTitleSchema {
  _id: string
  clockingRule: ClockingRules
}

const POPULATE = ['employer', 'clockingRule']

export const fetch = async (params?: any): Promise<ApiResponse<JobTitle[]>> =>
  await api({
    endpoint: '/job-titles',
    method: 'get',
    params: {
      sort: '-createdAt',
      populate: POPULATE,
      ...(params || {}),
    },
  })

export const get = async (id: string): Promise<ApiResponse<JobTitle>> =>
  await api({
    endpoint: `/job-titles/${id}`,
    method: 'get',
  })

export type JobTitleCreationPayload = Omit<JobTitleRepositoryCreationPayload, 'employer'>

export const create = async (params: JobTitleCreationPayload): Promise<ApiResponse<JobTitle>> =>
  await api({
    endpoint: '/job-titles',
    method: 'post',
    params,
  })

export interface JobTitleUpdatePayload extends Partial<JobTitleCreationPayload> {
  id: string
}

export const update = async (params: JobTitleUpdatePayload): Promise<ApiResponse<JobTitle>> => {
  const { id, ...payload } = params
  return await api({
    endpoint: `/job-titles/${id}`,
    method: 'put',
    params: payload,
  })
}
