import { create, SetState, GetState } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import filtersSlice, { FiltersSlice } from './filters'
import sessionSlice, { SessionSlice } from './session'
import { merge } from 'lodash'
import appSlice, { AppSlice } from './app'

export interface StoreState {
  filters: FiltersSlice
  session: SessionSlice
  app: AppSlice
}

export type StoreSlice<T> = (set: SetState<StoreState>, get: GetState<StoreState>) => T

const store = (set: SetState<StoreState>, get: GetState<StoreState>) => ({
  filters: { ...filtersSlice(set, get) },
  session: { ...sessionSlice(set, get) },
  app: { ...appSlice(set, get) },
})

const useStore = create<StoreState>()(
  persist(process.env.NODE_ENV === 'production' ? store : devtools(store), {
    name: 'session-state',
    partialize: (state: StoreState) => ({ session: state.session /*, filters: state.filters */ }),
    merge: (persistedState, currentState) => merge(currentState, persistedState),
  }),
)

export default useStore
