import React from 'react'
import { Element } from 'react-bulma-components'
import { Mission } from '../../../api/missions'
import { useUpdateMutation } from '../../../queries/missions'
import Avatar from '../../avatar/avatar'
import ConfirmationModal, {
  ConfirmationModalBaseProps,
} from '../../confirmation-modal/confirmation-modal'

interface CancelAbandonConfirmationProps extends ConfirmationModalBaseProps {
  mission: Mission
}

const CancelAbandonConfirmation: React.FC<CancelAbandonConfirmationProps> = ({
  show,
  hide,
  mission,
}) => {
  const missionUpdateMutation = useUpdateMutation()

  const onConfirm = () => {
    missionUpdateMutation.mutate({
      _id: mission._id,
      isAbandoned: false,
    })
    hide()
  }

  return (
    <ConfirmationModal onConfirm={onConfirm} show={show} onCancel={hide}>
      <Element>
        Êtes vous certain de vouloir annuler l'abandon de la mission de&nbsp;
        <strong>
          <Avatar user={mission.user} />
        </strong>
        ? <br />
        <Element mt={1}>
          <Element renderAs="strong">Toutes les journées de travail</Element> de la mission seront{' '}
          <Element renderAs="strong">à nouveau actives.</Element>
        </Element>
      </Element>
    </ConfirmationModal>
  )
}

export default CancelAbandonConfirmation
