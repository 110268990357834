import { endOfDay, isWithinInterval } from 'date-fns'
import { Mission } from '../api/missions'
import { WorkPeriod } from '../api/work-periods'
import { getWorkPeriodFromDayDate } from './work-periods'

const findWorkPeriodByDay = (mission: Mission, day: Date): WorkPeriod | undefined =>
  getWorkPeriodFromDayDate(mission.workPeriods, day)

export const findWorkPeriodById = (
  mission: Mission,
  workPeriodId: string,
): WorkPeriod | undefined => mission.workPeriods.find(workPeriod => workPeriod._id === workPeriodId)

export const findPreviousWorkPeriod = (
  mission: Mission,
  workPeriodId: string,
): WorkPeriod | undefined => {
  const currentWorkPeriodIndex = mission.workPeriods.findIndex(
    workPeriod => workPeriod._id === workPeriodId,
  )
  if (currentWorkPeriodIndex === 0) return undefined
  return mission.workPeriods[currentWorkPeriodIndex - 1]
}

export const getWorkPeriodsInInterval = (mission: Mission, start: Date, end: Date): WorkPeriod[] =>
  mission.workPeriods.filter(workPeriod =>
    isWithinInterval(new Date(workPeriod.start.date), {
      start,
      end: endOfDay(end),
    }),
  )

export const findNextWorkPeriod = (
  mission: Mission,
  workPeriodId: string,
): WorkPeriod | undefined => {
  const currentWorkPeriodIndex = mission.workPeriods.findIndex(
    workPeriod => workPeriod._id === workPeriodId,
  )
  if (currentWorkPeriodIndex === mission.workPeriods.length - 1) return undefined
  return mission.workPeriods[currentWorkPeriodIndex + 1]
}

export const getLastWorkPeriod = (mission: Mission): WorkPeriod =>
  mission.workPeriods[mission.workPeriods.length - 1] as WorkPeriod

export const hasAllWorkPeriodsClocked = (mission: Mission): boolean =>
  mission.workPeriods.every(
    workPeriod =>
      (workPeriod.start.manualClocking || workPeriod.start.clocking) &&
      (workPeriod.end.manualClocking || workPeriod.end.clocking),
  )
