import React, { ReactElement } from 'react'
import { Columns, Element, Heading, Icon } from 'react-bulma-components'
import { Mission } from '../../api/missions'
import { useUpdateMutation } from '../../queries/work-periods'
import ButtonWithTooltip from '../button-with-tooltip/button-with-tooltip'
import { MissionCancelledIcon, MissionValidatedIcon } from '../icons'
import { useModal } from '../modal'
import Protected, { isAuthorizedFor } from '../protected/protected'
import { MissionIsCancelledMessage, MissionIsValidatedMessage } from './mission-messages'
import MissionCancelConfirmation from './modals/cancel-confirmation'
import MissionValidateConfirmation from './modals/validate-mission-confirmation'
import WorkerLabel from '../texts/worker-label'

interface MissionActionsProps {
  mission: Mission
}

const MissionActions: React.FC<MissionActionsProps> = ({ mission }) => {
  const actions = useActions(mission)

  return (
    <>
      {actions.map(
        (
          action,
          index, //TODO: Component?
        ) => (
          <Element key={action.title}>
            <Heading size={5} mb={2}>
              {action.title}
            </Heading>
            <Element mb={2}>{action.description}</Element>
            {action.content}
            {index !== actions.length - 1 && <hr />}
          </Element>
        ),
      )}
    </>
  )
}

export default MissionActions

interface Action {
  //TODO : refactor (same code in work-periods-actions.tsx)
  title: string
  description?: string | ReactElement
  content: React.ReactNode
}

const useActions = (mission: Mission): Action[] => {
  const modal = useModal()
  //Validate mission confirmation state
  const [currentMissionToValidate, setCurrentMissionToValidate] = React.useState<Mission>()

  //Mutations
  const updateWorkPeriodMutation = useUpdateMutation()
  const firstCancellableWorkPeriod = mission.workPeriods.find(
    workPeriod => workPeriod.__actions.canBeCancelled,
  )
  const missionIsCancellable = firstCancellableWorkPeriod !== undefined

  // TODO: refactor using workPeriod.__action.canBeCancelled
  return React.useMemo(
    () =>
      [
        isAuthorizedFor(['employerMember'], { resource: 'missions', name: 'validate' }) && {
          title: "Valider l'entièreté de la Mission",
          description: (
            <>
              Vous pouvez valider l'entièreté d'une mission si l'ensemble de ses Journées de Travail
              sont validables :
              <ul>
                <li>- Soit elles sont complètement vides</li>
                <li>- Soit elles ont un pointage de début et de fin</li>
              </ul>
            </>
          ),
          content: (
            <Protected roles={['employerMember']}>
              <Columns>
                {mission.isValidated && (
                  <Columns.Column>
                    <MissionIsValidatedMessage mission={mission} />
                  </Columns.Column>
                )}
                {!mission.isValidated && (
                  <Columns.Column narrow>
                    <ButtonWithTooltip
                      type="button"
                      color={'primary'}
                      onClick={() => setCurrentMissionToValidate(mission)}
                      disabled={!Boolean(mission.__actions.canBeValidated)}
                      tooltip={{
                        text: 'Cette Mission ne peut être validée',
                        show: !mission.__actions.canBeValidated,
                      }}
                      data-test="validate-mission"
                    >
                      <Icon>
                        <MissionValidatedIcon />
                      </Icon>
                      <span>Valider</span>
                    </ButtonWithTooltip>
                    {mission.__actions.canBeValidated && currentMissionToValidate && (
                      <MissionValidateConfirmation
                        show={modal.isDisplayed}
                        hide={() => modal.setIsDisplayed(false)}
                        mission={mission}
                      />
                    )}
                  </Columns.Column>
                )}
              </Columns>
            </Protected>
          ),
        },
        isAuthorizedFor(['employerMember', 'interimAgencyMember'], {
          resource: 'missions',
          name: 'cancel',
        }) && {
          title: 'Interrompre le reste de la Mission',
          description: (
            <>
              L'interruption d'une Mission a pour effet de
              <ul>
                <li>- Préserver les Journées de Travail passées et en cours</li>
                <li>- Annuler toutes les Journées de Travail à venir</li>
                <li>
                  - Le <WorkerLabel /> sera disponible à l'issue de sa dernière Journée de Travail
                  préservée
                </li>
              </ul>
            </>
          ),
          content: (
            <Protected roles={['interimAgencyMember', 'employerMember']}>
              <Columns>
                {mission.isCancelled && (
                  <Columns.Column>
                    <MissionIsCancelledMessage mission={mission} />
                  </Columns.Column>
                )}
                {!mission.isCancelled && (
                  <Columns.Column narrow>
                    <ButtonWithTooltip
                      type="button"
                      color={'danger'}
                      onClick={() => firstCancellableWorkPeriod && modal.setIsDisplayed(true)}
                      disabled={!Boolean(firstCancellableWorkPeriod)}
                      tooltip={{
                        text: "Cette Mission n'est pas interruptible",
                        show: !missionIsCancellable,
                      }}
                      data-test="cancel-mission"
                    >
                      <Icon>
                        <MissionCancelledIcon />
                      </Icon>
                      <span>Interrompre</span>
                    </ButtonWithTooltip>
                    {missionIsCancellable && (
                      <MissionCancelConfirmation
                        show={modal.isDisplayed}
                        hide={() => modal.setIsDisplayed(false)}
                        mission={mission}
                        workPeriod={firstCancellableWorkPeriod}
                      />
                    )}
                  </Columns.Column>
                )}
              </Columns>
            </Protected>
          ),
        },
      ].filter(v => v) as Action[],
    [mission, firstCancellableWorkPeriod, updateWorkPeriodMutation, modal],
  )
}
