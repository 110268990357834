import { shallow } from 'zustand/shallow'
import useStore from '../store'

export const useAppNeedsUpdate = () => {
  const appNeedsUpdateHelpers = useStore(
    state => ({
      appNeedsUpdate: state.app.appNeedsUpdate,
      setAppNeedsUpdateUpdate: state.app.setAppNeedsUpdate,
    }),
    shallow,
  )
  return appNeedsUpdateHelpers
}
