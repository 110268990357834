import React from 'react'
import { ClockingRules } from '../../api/clocking-rules'
import locales from '../../../../locales'
import { toleranceInMillisecondsToMinutes } from '../../utils/clocking-rules'

interface ClockingRulesDescriptionProps {
  clockingRules: ClockingRules
  type: 'start' | 'end'
}

const ClockingRulesDescription: React.FC<ClockingRulesDescriptionProps> = ({
  clockingRules,
  type,
}) => {
  return (
    <ul style={{ whiteSpace: 'nowrap' }}>
      <li>
        | Je suis en avance, on prend en compte :{' '}
        <b>{locales.clockings[clockingRules.rules[type].early.mode]}</b>
        <br />
        {clockingRules.rules[type].early.tolerance !== 0 && (
          <>
            ⤷ Sauf si avance de moins de{' '}
            <b>{toleranceInMillisecondsToMinutes(clockingRules.rules[type].early.tolerance)} min</b>
          </>
        )}
      </li>
      <li>
        | Je suis en retard, on prend en compte :{' '}
        <b>{locales.clockings[clockingRules.rules[type].late.mode]}</b>
        <br />
        {clockingRules.rules[type].late.tolerance !== 0 && (
          <>
            ⤷ Sauf si retard de moins de{' '}
            <b>{toleranceInMillisecondsToMinutes(clockingRules.rules[type].late.tolerance)} min</b>
          </>
        )}
      </li>
    </ul>
  )
}

export default ClockingRulesDescription
