import React from 'react'
import { Columns, Container, Section } from 'react-bulma-components'
import { OrganizationTypeEnum } from '../../api/organizations'
import { OrganizationsDashBoardCard } from '../../components/dashboards/organizations-dashboard-card'
import { UsersDashBoardCard } from '../../components/dashboards/users-dashboard-card'
import Layout from '../../components/layout/layout'
import PageHeading from '../../components/sections/page-heading'
import useStore from '../../store'
import { getUserLabel } from '../../utils/users'

const SuperAdminDashboard: React.FC = () => {
  const currentUser = useStore(state => state.session.currentUser)

  return (
    <Layout>
      <PageHeading
        title={currentUser ? getUserLabel(currentUser) : '...'}
        subtitle="Super Admin"
      ></PageHeading>
      <Section>
        <Container>
          <Columns>
            <Columns.Column size={3}>
              <UsersDashBoardCard userRoleKind="superAdmin" />
            </Columns.Column>
            <Columns.Column size={3}>
              <UsersDashBoardCard />
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column size={3}>
              <OrganizationsDashBoardCard type={OrganizationTypeEnum.employer} />
            </Columns.Column>
            <Columns.Column size={3}>
              <UsersDashBoardCard userRoleKind="employerMember" />
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column size={3}>
              <OrganizationsDashBoardCard type={OrganizationTypeEnum.interimAgency} />
            </Columns.Column>
            <Columns.Column size={3}>
              <UsersDashBoardCard userRoleKind="interimAgencyMember" />
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column size={3}>
              <UsersDashBoardCard userRoleKind="worker" />
            </Columns.Column>
          </Columns>
        </Container>
      </Section>
    </Layout>
  )
}
export default SuperAdminDashboard
