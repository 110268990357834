import React from 'react'
import { Element } from 'react-bulma-components'
import { WorkersRequest } from '../../api/workers-requests'
import {
  WorkersRequestCompleteIcon,
  WorkersRequestClosedIcon,
  WorkersRequestCancelIcon,
} from '../icons'
import Message, { CommonMessageProps } from '../message/message'

export const WorkersRequestCompleteMessage: React.FC<
  CommonMessageProps & { workersRequest: { isComplete: WorkersRequest['isComplete'] } }
> = ({ workersRequest, iconColor = 'success', ...rest }) => {
  if (!workersRequest.isComplete) return <></>
  return (
    <Message
      iconColor={iconColor}
      text="Demande complète"
      icon={<WorkersRequestCompleteIcon />}
      {...rest}
    />
  )
}
export const WorkersRequestClosedMessage: React.FC<
  CommonMessageProps & { workersRequest: { isClosed: WorkersRequest['isClosed'] } }
> = ({ workersRequest, ...rest }) => {
  if (!workersRequest.isClosed) return <></>
  return <Message text="Demande cloturée" icon={<WorkersRequestClosedIcon />} {...rest} />
}

export const WorkersRequestCancelledMessage: React.FC<
  CommonMessageProps & {
    workersRequest: {
      isCancelled: WorkersRequest['isCancelled']
    }
  }
> = ({ workersRequest, ...rest }) => {
  if (!workersRequest.isCancelled) return <></>
  return <Message text="Demande annulée" icon={<WorkersRequestCancelIcon />} {...rest} />
}

interface WorkersRequestsMessagesProps extends CommonMessageProps {
  workersRequest: WorkersRequest
}

const WorkersRequestsMessages: React.FC<WorkersRequestsMessagesProps> = ({
  workersRequest,
  ...rest
}) => {
  return (
    <Element display="flex" flexWrap="wrap">
      <WorkersRequestCompleteMessage workersRequest={workersRequest} {...rest} />
      <WorkersRequestClosedMessage workersRequest={workersRequest} {...rest} />
      <WorkersRequestCancelledMessage workersRequest={workersRequest} {...rest} />
    </Element>
  )
}

export default WorkersRequestsMessages
