import React from 'react'
import FieldColumn from '../field-column'
import { Field } from 'formik'
import MultiSelectField from '../../form/fields/multi-select'
import { useJobTitlesQuery } from '../../../queries/job-titles'
import useStore from '../../../store'
import { buildListAsInputOptions } from '../../../utils/forms'

interface JobTitleFilterFieldProps {
  disabledQueries?: boolean
  queriesStaleTime?: number
  employerIds?: string[]
}

const JobTitleFilterField: React.FC<JobTitleFilterFieldProps> = ({
  disabledQueries,
  queriesStaleTime,
  employerIds,
}) => {
  const currentUserRole = useStore(state => state.session.currentUserRole)
  const jobTitlesQuery = useJobTitlesQuery(
    currentUserRole === 'interimAgencyMember'
      ? {
          employer: employerIds,
        }
      : {},
    {
      enabled: !disabledQueries && (currentUserRole === 'employerMember' || Boolean(employerIds)),
      staleTime: queriesStaleTime || 1000 * 60 * 2,
    },
  )
  return (
    <FieldColumn>
      <Field
        label="Poste"
        name="jobTitle"
        component={MultiSelectField}
        disabled={!jobTitlesQuery.data || !jobTitlesQuery.data[0]}
        options={buildListAsInputOptions(jobTitlesQuery.data || [])}
      />
    </FieldColumn>
  )
}

export default JobTitleFilterField
