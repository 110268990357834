import React from 'react'
import { WorkPeriod } from '../../api/work-periods'
import { useClockingsQuery } from '../../queries/clockings'
import { sortBy } from 'lodash'
import { Box, Element, Heading, Icon } from 'react-bulma-components'
import { formatTime } from '../../utils/date'
import { Clocking } from '../../api/clockings'
import { ArrowRightIcon } from '../icons'

interface WorkPeriodClockingsProps {
  workPeriod: WorkPeriod
}

const WorkPeriodClockings: React.FC<WorkPeriodClockingsProps> = ({ workPeriod }) => {
  const clockingsQuery = useClockingsQuery({ workPeriod: workPeriod._id })
  const clockings = sortBy(clockingsQuery.data || [], 'createdAt')

  return (
    <>
      {clockings.map((clocking, index) => (
        <WorkPeriodClocking
          clocking={clocking}
          workPeriod={workPeriod}
          key={clocking._id}
          isFirst={index === 0}
          isLast={index === clockings.length - 1}
        />
      ))}
    </>
  )
}

const WorkPeriodClocking: React.FC<{
  workPeriod: WorkPeriod
  clocking: Clocking
  isFirst?: boolean
  isLast?: boolean
}> = ({ workPeriod, clocking, isFirst, isLast }) => {
  const isEnd = clocking._id === workPeriod.end.clocking?.item
  let label = isFirst ? 'Entrée' : isEnd ? 'Sortie' : 'Intermédiaire'
  if (workPeriod.clockingRules.break.clocked && !isFirst) {
    const indexOfBreakClocking = workPeriod.breaksClockings.findIndex(
      bc => bc.item === clocking._id,
    )
    if (indexOfBreakClocking !== -1 && indexOfBreakClocking % 2 === 0) {
      label = 'Début pause'
    } else if (indexOfBreakClocking !== -1) {
      label = 'Fin pause'
    }
  }
  return (
    <Element display="inline-block" mr={2}>
      <Icon.Text>
        <Element renderAs="span">
          {label} à&nbsp;
          {formatTime(clocking.date)}
        </Element>
        {!isEnd && (
          <>
            <Icon>
              <ArrowRightIcon />
            </Icon>
            {isLast && <>&nbsp;&nbsp;...</>}
          </>
        )}
      </Icon.Text>
    </Element>
  )
}

export default WorkPeriodClockings
