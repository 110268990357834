import React, { ReactNode } from 'react'
import { Columns } from 'react-bulma-components'
import { KPIItem } from './item'
import { EmployerStats } from '../../hooks/use-employer-stats'

export const EmployerKpis = ({ stats }: { stats: EmployerStats }): ReactNode => {
  const workPeriods = stats.workPeriods
  const combine = stats.workPeriods.combine
  return (
    <>
      <Columns vCentered>
        <Columns.Column size={4}>
          <KPIItem
            data={[
              {
                id: 'Validation',
                value: stats.workPeriods.validated.length,
                color: 'var(--bulma-info)',
              },
              {
                id: 'Non Validées',
                value: stats.workPeriods.notValidated.length,
                color: 'orange',
              },
            ]}
            detailIndex={0}
          />
        </Columns.Column>
        <Columns.Column size={4}>
          <KPIItem
            data={[
              {
                id: 'Présence',
                value: combine(workPeriods, ['validated', 'expected', 'clocked']).length,
                color: 'var(--bulma-info)',
              },
              {
                id: 'Absents',
                value: combine(workPeriods, ['validated', 'expected', 'notClocked']).length,
                color: 'orange',
              },
            ]}
            detailIndex={1}
          />
        </Columns.Column>
        <Columns.Column size={4}>
          <KPIItem
            data={[
              {
                id: 'À Temps',
                value: combine(workPeriods, ['validated', 'expected', 'clocked', 'notLate']).length,
                color: 'var(--bulma-info)',
              },
              {
                id: 'Retard',
                value: combine(workPeriods, ['validated', 'expected', 'clocked', 'late']).length,
                color: 'orange',
              },
            ]}
            detailIndex={1}
          />
        </Columns.Column>
        <Columns.Column size={4} offset={1}>
          <KPIItem
            data={[
              {
                id: 'Sans Abandon',
                value: combine(workPeriods, ['validated', 'notAbandoned']).length,
                color: 'var(--bulma-info)',
              },
              {
                id: 'Abandons',
                value: combine(workPeriods, ['validated', 'abandoned']).length,
                color: 'orange',
              },
            ]}
            detailIndex={1}
          />
        </Columns.Column>
        <Columns.Column size={4} offset={1}>
          <KPIItem
            data={[
              {
                id: 'Pas interrompus',
                value: combine(workPeriods, ['validated', 'notCancelled']).length,
                color: 'var(--bulma-info)',
              },
              {
                id: 'Interrompus',
                value: combine(workPeriods, ['validated', 'cancelled']).length,
                color: 'orange',
              },
            ]}
            detailIndex={1}
          />
        </Columns.Column>
      </Columns>
    </>
  )
}
