import { Field, Form, Formik } from 'formik'
import React from 'react'
import { Container, Element } from 'react-bulma-components'
import InputField from '../form/fields/input'
import { RequestButton } from '../request-components/request-components'
import FormWrapper from '../form/form-wrapper'
import { PasswordFieldWithValidation } from '../form/fields/password'
import { useCompleteProfile } from '../../queries/users'
import { useFormWrapperPropsWithMutationData } from '../../hooks/use-form-wrapper-props-with-mutation-data'
import { useSearchParamsToken } from '../../hooks/use-search-params-token'
import CheckboxField from '../form/fields/checkbox'
import { Link } from 'react-router-dom'

const CompleteProfileform: React.FC = ({}) => {
  const { token, decoded } = useSearchParamsToken()
  const mutation = useCompleteProfile()

  const formWrapperProps = useFormWrapperPropsWithMutationData(formWrapperBaseProps, mutation)
  if (!decoded || !token) return <div>Le lien que vous avez utilisé est expiré ou erroné.</div>
  return (
    <Formik
      initialValues={{
        email: decoded.data.email as string,
        id: decoded._id as string,
        token: token as string,
        firstName: decoded.data?.firstName ?? '',
        lastName: decoded.data?.lastName ?? '',
        newPassword: '',
        confirmNewPassword: '',
        confirmPrivacyPolicy: false,
        confirmTermsOfUse: false,
        confirmAssociations: false,
      }}
      enableReinitialize
      validate={values => {
        const errors: any = {}
        if (!values.email) errors.email = 'Champ requis'
        if (!values.firstName) errors.firstName = 'Champ requis'
        if (!values.lastName) errors.lastName = 'Champ requis'
        if (!values.newPassword) errors.newPassword = 'Champ requis'
        if (!values.confirmNewPassword) errors.confirmNewPassword = 'Champ requis'
        if (values.newPassword !== values.confirmNewPassword)
          errors.confirmNewPassword = 'Les deux mots de passe doivent être similaires'
        if (values.confirmPrivacyPolicy === false)
          errors.confirmPrivacyPolicy = 'Vous devez accepter la Politique de Confidentialité'
        if (values.confirmTermsOfUse === false)
          errors.confirmTermsOfUse = "Vous devez accepter les Conditions Générales d'Utilisation"
        if (values.confirmAssociations === false)
          errors.confirmAssociations =
            'Vous devez accepter de travailler avec les employeurs vous ayant invité sur la plateforme'
        return errors
      }}
      onSubmit={values => {
        // No need for confirmNewPassword, we already know it's similar to newPassword with validation
        // No need for confirmPrivacyPolicy, by completing the profile we know it is accepted
        // No need for confirmTermsOfUse, by completing the profile we know it is accepted
        const {
          newPassword: password,
          confirmNewPassword,
          confirmPrivacyPolicy,
          confirmTermsOfUse,
          confirmAssociations,
          ...data
        } = values
        mutation.mutate({ ...data, password })
      }}
    >
      {() => {
        return (
          <FormWrapper {...formWrapperProps}>
            <Form>
              <Element renderAs="p" mb={6}>
                Bienvenue !<br />
                Complétez votre profil pour pouvoir vous connecter à la plateforme.
              </Element>
              <Element mb={6}>
                <Field
                  label="Email"
                  name="email"
                  type="email"
                  component={InputField}
                  required
                  disabled
                />
                <Field label="Prénom" name="firstName" component={InputField} required />
                <Field label="Nom" name="lastName" component={InputField} required />
              </Element>
              <Element mb={5}>
                <PasswordFieldWithValidation
                  label="Nouveau mot de passe"
                  name="newPassword"
                  required
                />
              </Element>
              <Element mb={5}>
                <PasswordFieldWithValidation
                  label="Confirmez le mot de passe"
                  name="confirmNewPassword"
                  required
                />
              </Element>
              <Element mb={6} mt={6}>
                <Field
                  component={CheckboxField}
                  label="Conditions Générales d'Utilisation"
                  name="confirmTermsOfUse"
                  data-test="confirmTermsOfUse"
                  required
                  text={
                    <>
                      J'accepte les{' '}
                      <Link to={'/terms-of-use'} target="_blank">
                        Conditions Générales d'Utilisation de la plateforme
                      </Link>
                    </>
                  }
                />
              </Element>
              <Element mb={6} mt={6}>
                <Field
                  component={CheckboxField}
                  label="Politique de Confidentialité"
                  name="confirmPrivacyPolicy"
                  data-test="confirmPrivacyPolicy"
                  required
                  text={
                    <>
                      J'accepte la{' '}
                      <Link to={'/privacy-policy'} target="_blank">
                        Politique de Confidentialité
                      </Link>
                    </>
                  }
                />
              </Element>
              <Element mb={6} mt={6}>
                <Field
                  component={CheckboxField}
                  label="Employeurs m'ayant invité sur la plateforme"
                  name="confirmAssociations"
                  data-test="confirmAssociations"
                  required
                  text={
                    <>
                      J'accepte de travailler avec les employeurs m'ayant invité sur la plateforme
                    </>
                  }
                />
              </Element>
              <Container mb={6}>
                <Element mt={6}>
                  <RequestButton color="primary" type="submit" mutation={mutation}>
                    Rejoindre
                  </RequestButton>
                </Element>
              </Container>
            </Form>
          </FormWrapper>
        )
      }}
    </Formik>
  )
}

const formWrapperBaseProps = {
  loadingProps: { body: 'Enregistrement de vos données...' },
  successProps: {
    title: 'Compte créé et validé',
    body: {
      description: 'Vous pouvez maintenant vous connecter à la plateforme',
      actions: [
        {
          label: 'Se connecter',
          to: '/login',
        },
      ],
    },
  },
}

export default CompleteProfileform
