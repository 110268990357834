import React from 'react'
import Layout from '../../components/layout/layout'
import {
  Box,
  Button,
  Columns,
  Container,
  Heading,
  Icon,
  Level,
  Section,
} from 'react-bulma-components'
import PageTitle from '../../components/pages/page-title'
import { ViewListIcon } from '@heroicons/react/outline'
import { Link } from 'react-router-dom'
import useUrlId from '../../hooks/use-url-id'
import { useOrganizationQuery } from '../../queries/organizations'
import { Employer, InterimAgency } from '../../api/organizations'
import useStore from '../../store'
import { compareDocsById } from '../../utils/documents'
import RoleStatus from '../../components/users/role-status'
import { useJobTitlesQuery } from '../../queries/job-titles'
import JobTitleLaborCoefficientForm from '../../components/job-titles/job-title-labor-coefficient-form'
import { sortByIgnoreCase } from '../../utils/arrays'
import { JobTitle } from '../../api/job-titles'

const AgencyDetailsForEmployer: React.FC = ({}) => {
  const { id } = useUrlId()
  const currentOrganization = useStore(state => state.session.currentOrganization) as Employer
  const { data: interimAgency } = useOrganizationQuery<InterimAgency>(id, 'interimAgency')
  const association = currentOrganization.associations.find(a =>
    compareDocsById(a.interimAgency, id),
  )
  const jobTitlesQuery = useJobTitlesQuery()

  const jobTitles = sortByIgnoreCase<JobTitle>(jobTitlesQuery.data || [], 'name')

  if (!interimAgency) return <></>
  return (
    <Layout>
      <Section>
        <Container max={true} breakpoint="desktop">
          <Level alignItems="flex-start" mb={6}>
            <Level.Side>
              <PageTitle
                title={interimAgency.name}
                subTitle={
                  association && (
                    <RoleStatus
                      role={association}
                      text={
                        association.status === 'accepted'
                          ? `Vous collaborez avec ${interimAgency.name}`
                          : association.status === 'pending'
                          ? 'Invitation en attente de réponse'
                          : 'Collaboration refusée'
                      }
                    />
                  )
                }
              />
            </Level.Side>
            <Level.Side>
              <Button
                renderAs={Link}
                to={`/employers/${currentOrganization._id}`}
                outlined
                color="primary"
                size="small"
              >
                <Icon>
                  <ViewListIcon />
                </Icon>
                <span>Liste des Agences partenaires</span>
              </Button>
            </Level.Side>
          </Level>
          <Heading size={4}>Coefficients main d'oeuvre par Intitulés de Poste</Heading>
          <Columns>
            {jobTitles.map(jobTitle => (
              <Columns.Column size={4} my={2} key={jobTitle._id}>
                <Box>
                  <JobTitleLaborCoefficientForm interimAgency={interimAgency} jobTitle={jobTitle} />
                </Box>
              </Columns.Column>
            ))}
          </Columns>
        </Container>
      </Section>
    </Layout>
  )
}

export default AgencyDetailsForEmployer
