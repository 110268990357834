import React from 'react'
import { useOutletContext } from 'react-router-dom'
import { InterimAgency } from '../../api/organizations'
import AgencySubscriptionForm from '../organizations/agency-subscription-form'

interface AppSubscriptionOptionsProps {
  interimAgency?: InterimAgency
}

const AppSubscriptionOptions: React.FC = () => {
  const { interimAgency } = useOutletContext<AppSubscriptionOptionsProps>()

  return <AgencySubscriptionForm interimAgency={interimAgency} />
}

export default AppSubscriptionOptions
