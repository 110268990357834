import jwtDecode from 'jwt-decode'
import { useSearchParams } from 'react-router-dom'

/*
 * Get and decode JWT token in url params
 * Returns empty if unable to decode the token
 */
export const useSearchParamsToken = (
  searchParamName?: string,
): { token: string | undefined; decoded: any | undefined } => {
  const [searchParams] = useSearchParams()
  const token = searchParams.get(searchParamName || 'token') || undefined
  try {
    const decoded = token && jwtDecode(token)
    return { token, decoded }
  } catch (err) {
    return { token: undefined, decoded: undefined }
  }
}
