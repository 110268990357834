import missionsHistoryLocales from './missions'

export default {
  history: {
    title: {
      workersRequestCreated: 'Demande créée',
      workersRequestUpdated: 'Demande mise à jour',
      workersRequestCancelled: 'Demande annulée',
      missionCreated: missionsHistoryLocales.history.title.missionCreated,
      missionCancelled: missionsHistoryLocales.history.title.missionCancelled,
      missionReplaced: 'Mission remplacée',
      postUpdated: 'Poste mis à jour',
      postDeleted: 'Poste supprimé',
      postCreated: 'Poste ajouté'
    },
    changesTitle: {
      'missionData.workPeriods': 'Horaire modifié',
      'missionData.service': 'Service modifié',
      'missionData.jobTitle': 'Intitulé de poste modifié',
      'jobTitle': 'Intitulé de poste modifié',
      requestedWorkers: 'Travailleurs demandés',
      recourseCases: 'Cas de Recours',
      recourseCase: 'Cas de Recours',
      'recourseCase.justification': 'Cas de Recours - justification',
      'recourseCase.reason': 'Cas de Recours',
      'posts': 'Poste(s) créé(s)'
    },
  },
}
