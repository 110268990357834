import React from 'react'
import { ModalProps } from '../../modal'
import Modal from '../../modal'
import CreatePreBilling, { CreatePreBillingProps } from '../create-pre-billing'

interface CreatePreBillingModalProps extends CreatePreBillingProps {
  isDisplayed: ModalProps['actions']['isDisplayed']
  setIsDisplayed: ModalProps['actions']['setIsDisplayed']
}

const CreatePreBillingModal: React.FC<CreatePreBillingModalProps> = ({
  isDisplayed,
  setIsDisplayed,
  ...rest
}) => {
  return (
    <Modal actions={{ isDisplayed, setIsDisplayed }} title="Générer une pré-facturation">
      <CreatePreBilling {...rest} />
    </Modal>
  )
}

export default CreatePreBillingModal
