import React from 'react'
import { useOutletContext } from 'react-router-dom'
import { InterimAgency } from '../../api/organizations'
import AgencyInfosForm from '../../components/organizations/agency-infos-form'

interface AgencyInfoProps {
  interimAgency?: InterimAgency
}

const AgencyInfo: React.FC = () => {
  const { interimAgency } = useOutletContext<AgencyInfoProps>()

  return <AgencyInfosForm interimAgency={interimAgency} />
}

export default AgencyInfo
