import React from 'react'
import Modal, { ModalActions } from '../../modal'
import { Content } from 'react-bulma-components'
import UserForm from '../user-form'

interface OfflineUserFormModalProps {
  modalActions: ModalActions
}

const OfflineUserFormModal: React.FC<OfflineUserFormModalProps> = ({ modalActions }) => {
  return (
    <Modal title="Créer un Utilisateur Offline" actions={modalActions}>
      <Content className="mb-3" renderAs="p">
        <details>
          <summary>Afficher les détails d'un Utilisateur Offline</summary>
          Un <b>Utilisateur Offline</b> a les restrictions suivantes :<dialog></dialog>
          <ul>
            <li>Ne peut pas se connecter à la plateforme</li>
            <li>Est exclusivement lié à l'Agence d'Intérim qui l'a créé</li>
            <li>Ne reçoit pas de notifications</li>
            <li>Pointe via le QR Code fourni par son Agence d'Intérim</li>
          </ul>
        </details>
      </Content>
      <UserForm role="worker" noAppAccess />
    </Modal>
  )
}

export default OfflineUserFormModal
