import React from 'react'
import useFilters from '../../hooks/filters'
import { FiltersResourcesEnum, PreBillingsFilters } from '../../store/filters'
import { Field, Formik } from 'formik'
import { dateInputToDateValue, dateToDateInputValue } from '../../utils/date'
import { Columns } from 'react-bulma-components'
import AutoSubmit from '../form/utils/auto-submit'
import OrganizationFilterField from '../filters/fields/organization'
import { OrganizationTypeEnum } from '../../api/organizations'
import FieldColumn from '../filters/field-column'
import MultiSelectField from '../form/fields/multi-select'
import { cloneDeep } from 'lodash'
import DateFilterField from '../filters/fields/date'

const PreBillingsFilters: React.FC = ({}) => {
  const { setFilters, filters } = useFilters(FiltersResourcesEnum.preBillings)

  return (
    <Formik<
      Omit<PreBillingsFilters, 'start' | 'end'> & {
        start: string | undefined
        end: string | undefined
      }
    >
      initialValues={{
        ...filters,
        start: filters.start ? dateToDateInputValue(new Date(filters.start)) : undefined,
        end: filters.end ? dateToDateInputValue(new Date(filters.end)) : undefined,
      }}
      onSubmit={values => {
        setFilters(FiltersResourcesEnum.preBillings, {
          ...values,
          start: values.start ? dateInputToDateValue(values.start) : undefined,
          end: values.end ? dateInputToDateValue(values.end) : undefined,
        })
      }}
    >
      {props => {
        return (
          <>
            <Columns pb={1}>
              <DateFilterField name="start" label="Du" />
              <DateFilterField name="end" label="Au" />
              <OrganizationFilterField type={OrganizationTypeEnum.interimAgency} />
              <FieldColumn>
                <Field
                  label="Références de Facturation"
                  name="invoiceRef"
                  component={MultiSelectField}
                  options={[
                    {
                      label: 'Oui',
                      value: true,
                    },
                    {
                      label: 'Non',
                      value: false,
                    },
                  ]}
                />
              </FieldColumn>
            </Columns>
            <AutoSubmit values={props.values} submitForm={props.submitForm} />
          </>
        )
      }}
    </Formik>
  )
}

export default PreBillingsFilters
