import React, { ReactNode, useState } from 'react'
import {
  Button,
  Columns,
  Container,
  Element,
  Form,
  Icon,
  Message,
  Table,
} from 'react-bulma-components'
import { useUsersQuery } from '../../queries/users'
import _, { get } from 'lodash'
import {
  EmployerWorkerFormValue,
  InterimAgencyWorkerFormValue,
} from '../../components/users/user-form'
import { PropertiesToString } from '../../types/utils'
import useStore from '../../store'
import { WarningIcon } from '../../components/icons'

// Function to convert dotted keys to nested objects
function convertToNestedObject<
  Source extends Array<{ [key: string]: any }>,
  Target extends Array<{ [key: string]: any }>,
>(data: Source): Target {
  return data.map(row => {
    const result = {}
    for (const key in row) {
      //@ts-ignore
      _.set(result, key, row[key])
    }
    return result
  }) as Target
}

type _FixArr<T> = T extends readonly any[] ? Omit<T, Exclude<keyof any[], number>> : T
type _DropInitDot<T> = T extends `.${infer U}` ? U : T
type _DeepKeys<T> = T extends object
  ? {
      [K in (string | number) & keyof T]: `${
        | `.${K}`
        | (`${K}` extends `${number}` ? `[${K}]` : never)}${'' | _DeepKeys<_FixArr<T[K]>>}`
    }[(string | number) & keyof T]
  : never
type DeepKeys<T> = _DropInitDot<_DeepKeys<_FixArr<T>>>

type CombinedUserModels = InterimAgencyWorkerFormValue & EmployerWorkerFormValue

type JSON_from_CSV = Array<
  {
    status: 'invalid' | 'existing' | 'valid'
  } & Partial<Record<DeepKeys<CombinedUserModels>, string>>
>

type Model = Array<
  {
    status: 'invalid' | 'existing' | 'valid'
    errors: { [key in DeepKeys<CombinedUserModels>]?: string }
  } & Partial<CombinedUserModels>
>

function validateEmail(value: string) {
  const input = document.createElement('input')

  input.type = 'email'
  input.required = true
  input.value = value

  return typeof input.checkValidity === 'function'
    ? input.checkValidity()
    : /\S+@\S+\.\S+/.test(value)
}

interface MassImportProps {
  onSuccess: (validUsers: Model) => void
  model: Array<DeepKeys<CombinedUserModels>>
}

export const MassImport = ({ onSuccess, model }: MassImportProps): ReactNode => {
  const [fileList, setFileList] = useState<FileList | null>(null)
  const [list, setList] = useState<Model>()

  const [error, setError] = useState('')
  const currentOrganization = useStore(s => s.session.currentOrganization)

  const existingWorkersQuery = useUsersQuery({ 'roles.kind': 'worker', appAccess: 'full' })

  const fileReader = new FileReader()

  const file = fileList?.[0]
  const step = fileList === null ? 1 : list ? 3 : 2

  const validList = list?.filter(u => u.status === 'valid') ?? []
  const validTotal = list ? validList.length : undefined
  const existingTotal = list ? list.filter(u => u.status === 'existing').length : undefined
  const invalidTotal = list ? list.filter(u => u.status === 'invalid').length : undefined

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError('')
    setList(undefined)
    setFileList(e.target.files)
  }

  const handleFileProcessing = async () => {
    const { read, utils } = await import('xlsx')
    if (file) {
      fileReader.onload = function (e: ProgressEvent<FileReader>) {
        if (!e.target) {
          setError('#1 - Lecture du fichier impossible')
          return
        }
        const csvFile = e.target.result

        // Convert CSV to JSON array
        const workbook = read(csvFile, { type: 'string', raw: true })
        const firstSheetName = workbook.SheetNames[0]
        if (!firstSheetName) {
          setError('#2 - Lecture du fichier impossible')
          return
        }
        const worksheet = workbook.Sheets[firstSheetName]
        if (!worksheet) {
          setError('#3 - Lecture du fichier impossible')
          return
        }
        const dottedJsonArray = utils.sheet_to_json<JSON_from_CSV[number]>(worksheet)
        const jsonArray = convertToNestedObject<JSON_from_CSV, Model>(dottedJsonArray)
        if (!jsonArray[0]) {
          setError('#4 - Le fichier est vide')
          return
        }

        const missingHeaders: string[] = []
        const requiredHeaders: ['email', 'firstName', 'lastName'] = [
          'email',
          'firstName',
          'lastName',
        ]
        requiredHeaders.forEach(requiredHeader => {
          const hasHeaderData = jsonArray.some(item => item[requiredHeader])
          if (!hasHeaderData) {
            missingHeaders.push(requiredHeader)
          }
        })
        if (missingHeaders[0]) {
          setError(`#5 - en-tête(s) '${missingHeaders.join(' & ')}' absent(s)`)
          return
        }

        if (jsonArray.length < 1) {
          setError("#6 - Pas d'entrées dans le fichier")
          return
        }
        if (existingWorkersQuery.data === undefined) {
          setError("#7 - Impossible de charger la liste d'uilitsateurs existants")
          return
        }
        const internalRefs: string[] = existingWorkersQuery.data
          .map(
            u =>
              u.associations.find(
                a => a.organization.toString() === currentOrganization?._id.toString(),
              )?.internalRef,
          )
          .filter((v): v is string => v !== undefined)
        const emails: string[] = []
        const validated: Model = jsonArray.map(_user => {
          const user = _user
          user.status = 'invalid'
          user.errors = {}
          const { email, firstName, lastName, internalRef } = user
          if (!email) user.errors.email = 'Adresse email manquante'
          else if (!validateEmail(email)) user.errors.email = 'Adresse email incorrect'
          else if (emails.some(_email => _email === email))
            user.errors.email = 'Email déjà présent dans une autre ligne'
          else emails.push(email)
          if (!firstName) user.errors.firstName = 'Prénom manquant'
          if (!lastName) user.errors.lastName = 'Nom manquant'
          if (internalRef) {
            if (internalRefs.some(ref => ref === internalRef))
              user.errors.internalRef = 'Matricule interne déjà présent dans une autre ligne'
            else internalRefs.push(internalRef)
          }

          const validateScheduleData = (attribute: 'monthlyHoursWorked' | 'weeklyHoursWorked') => {
            const hoursWorked = user.schedule?.[attribute].toString()
            const messageLabel = attribute === 'monthlyHoursWorked' ? 'mois' : 'semaine'
            if (hoursWorked?.includes(','))
              user.errors[
                `schedule.${attribute}`
              ] = `Heures à prester par ${messageLabel} incorrect - les décimals doivent utiliser le point comme séparateur`
            else if (hoursWorked && typeof parseFloat(hoursWorked) !== 'number') {
              user.errors[
                `schedule.${attribute}`
              ] = `Heures à prester par ${messageLabel} incorrect`
            } else if (hoursWorked && user.schedule)
              user.schedule[attribute] = parseFloat(hoursWorked)
          }

          if (user?.schedule?.monthlyHoursWorked) validateScheduleData('monthlyHoursWorked')
          if (user?.schedule?.weeklyHoursWorked) validateScheduleData('weeklyHoursWorked')

          // All provided data is valid
          const userAlreadyInvited = existingWorkersQuery.data.some(
            existingWorker => existingWorker.email === email,
          )
          if (userAlreadyInvited) {
            user.status = 'existing'
            user.errors.email = 'Adresse email déjà utilisée'
            return user
          }
          // This worker is valid to create
          if (!Object.keys(user.errors)[0]) user.status = 'valid'
          return user
        })
        setList(validated)
      }

      fileReader.readAsText(file)
    }
  }

  const csvKeys: PropertiesToString<Record<DeepKeys<CombinedUserModels>, string | undefined>> = {
    email: 'Adresse email -> obligatoire',
    firstName: 'Prénom -> obligatoire',
    lastName: 'Nom de Naissance -> obligatoire',
    internalRef: 'Matricule interne -> généré automatiquement si absent',
    'schedule.monthlyHoursWorked':
      'Heures à prester par semaine, utiliser un point pour la virgule -> 0 si absent',
    'schedule.weeklyHoursWorked':
      'Heures à prester par mois, utiliser un point pour la virgule -> 0 si absent',
    schedule: '',
    internalComment: 'Commentaire -> optionnel',
    internalInformation: 'Information -> optionnel',
  }

  const csvTemplateFileData =
    'data:text/csv;charset=utf-8,' +
    Object.keys(csvKeys)
      .filter(key => key !== 'schedule' && model.includes(key))
      .join(',') +
    '\n'

  return (
    <>
      <div>
        <Container>
          <Columns mb={0}>
            <Columns.Column>Le fichier CSV doit reprendre les en-têtes suivants :</Columns.Column>
            <Columns.Column display="flex" justifyContent="end">
              <Button
                size="small"
                color={'success'}
                renderAs="a"
                href={csvTemplateFileData}
                download={'import-teamtim-workers.csv'}
              >
                Télécharger le masque
              </Button>
            </Columns.Column>
          </Columns>
          <table className="table">
            <thead>
              <tr>
                <th>En-tête</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {model.flatMap(key => {
                const value = csvKeys[key]
                return (
                  <tr key={key}>
                    <td>
                      <b>{key}</b>
                    </td>
                    <td>{value}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          <br />
        </Container>
        <Form.InputFile
          onChange={handleUpload}
          //@ts-expect-error fuck react-bulma-components
          inputProps={{ accept: '.csv' }}
          id={'csvFileInput'}
          accept={'.csv'}
          label={'1. Choisir un fichier'}
          value={fileList ?? undefined}
          filename={file?.name ?? undefined}
          fullwidth
          data-test="upload-file-action"
        />
        <br />
        <Button
          type="button"
          disabled={step === 1}
          onClick={handleFileProcessing}
          data-test="process-file-action"
        >
          2. Controler le fichier
        </Button>
        <br />
        <br />
        {error && (
          <Message color="danger">
            <Message.Body>Erreur : {error}</Message.Body>
          </Message>
        )}
        {list && (
          <>
            <Message color="success">
              <Message.Body>Entrées Valides : {validTotal}</Message.Body>
            </Message>
            <Message color="warning">
              <Message.Body>Entrées Existantes : {existingTotal}</Message.Body>
            </Message>
            <Message color="danger">
              <Message.Body>Entrées Invalides : {invalidTotal}</Message.Body>
            </Message>
          </>
        )}
        <Button
          type="button"
          disabled={step < 3 || Boolean(error) || validList.length === 0}
          onClick={() => {
            onSuccess(validList)
          }}
          data-test="submit-list-action"
        >
          3. Préparer {validList.length} invitations
        </Button>
      </div>

      <hr />
      {list && (
        <Table.Container>
          <Table size="narrow">
            <thead>
              <tr>
                <th>#</th>
                <th>Statut</th>
                <th>Email</th>
                <th>Prénom</th>
                <th>Nom</th>
                <th>Matricule</th>
                {model.includes('schedule.weeklyHoursWorked') && <th>H/Sem</th>}
                {model.includes('schedule.monthlyHoursWorked') && <th>H/Mois</th>}
                <th>Commentaire</th>
                <th>Information</th>
              </tr>
            </thead>
            {list.map((item, index) => {
              return (
                <Element
                  key={index}
                  renderAs="tr"
                  backgroundColor={
                    item.status === 'valid'
                      ? 'success-light'
                      : item.status === 'existing'
                      ? 'warning-light'
                      : 'danger-light'
                  }
                >
                  <td>{index + 2}</td>
                  <td>
                    {item.status === 'valid'
                      ? 'Valide'
                      : item.status === 'existing'
                      ? 'Existant'
                      : 'Invalide'}
                  </td>
                  <UserCell item={item} attribute="email" />
                  <UserCell item={item} attribute="firstName" />
                  <UserCell item={item} attribute="lastName" />
                  <UserCell item={item} attribute="internalRef" />
                  {model.includes('schedule.weeklyHoursWorked') && (
                    <UserCell item={item} attribute="schedule.weeklyHoursWorked" />
                  )}
                  {model.includes('schedule.monthlyHoursWorked') && (
                    <UserCell item={item} attribute="schedule.monthlyHoursWorked" />
                  )}
                  <UserCell item={item} attribute="internalComment" />
                  <UserCell item={item} attribute="internalInformation" />
                </Element>
              )
            })}
          </Table>
        </Table.Container>
      )}
    </>
  )
}

const UserCell: React.FC<{ item: Model[0]; attribute: DeepKeys<CombinedUserModels> }> = ({
  item,
  attribute,
}) => {
  if (!item) return <td></td>
  return (
    <td>
      <Element display="inline-flex" data-tooltip={item.errors[attribute]}>
        <Element mr={1}>
          {item.errors[attribute] && (
            <Icon>
              <WarningIcon />
            </Icon>
          )}
        </Element>
        <Element>{get(item, attribute) ?? '[vide]'}</Element>
      </Element>
    </td>
  )
}
