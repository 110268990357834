import React from 'react'
import { Icon, Message as MessageBulma, Tag } from 'react-bulma-components'
import { Color } from 'react-bulma-components/src/components'

export interface CommonMessageProps {
  small?: boolean
  type?: 'message' | 'tag' | 'icon'
  iconColor?: Color
  color?: Color
  'data-test'?: string
}

interface MessageProps extends CommonMessageProps {
  icon: React.ReactNode
  text: React.ReactNode
}

const Message: React.FC<MessageProps> = ({
  color,
  icon,
  iconColor,
  text,
  'data-test': dataTest,
  small = false,
  type = 'message',
}) => {
  const bulmaSize = small ? 'small' : undefined
  if (type === 'icon') {
    return (
      <Icon
        color={iconColor}
        data-tooltip={text}
        size={bulmaSize}
        data-test={dataTest}
        className="has-tooltip-left"
      >
        {icon}
      </Icon>
    )
  }
  if (type === 'tag') {
    return (
      <>
        <Tag
          color={color}
          size={small ? 'small' : 'medium'}
          data-tooltip={small ? text : undefined}
          data-test={dataTest}
        >
          <Icon color={iconColor}>{icon}</Icon>
          {!small && <span>{text}</span>}
        </Tag>
      </>
    )
  }
  return (
    <MessageBulma
      color={iconColor ?? color}
      mr={3}
      mb={3}
      display={small ? 'inline-block' : 'block'}
      data-test={dataTest}
    >
      <MessageBulma.Body data-tooltip={small ? text : undefined} className="has-tooltip-left">
        <Icon.Text flexWrap="nowrap">
          <Icon color={iconColor}> {icon}</Icon>
          {!small && <span>{text}</span>}
        </Icon.Text>
      </MessageBulma.Body>
    </MessageBulma>
  )
}

export default Message
