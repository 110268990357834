import React from 'react'
import { Element } from 'react-bulma-components'
import { User } from '../../api/users'
import { getUserLabel } from '../../utils/users'

interface AvatarProps {
  user: User
  small?: boolean
  backgroundColor?: string
}

const Avatar: React.FC<AvatarProps> = ({ user, small, backgroundColor }) => {
  const { firstName, lastName } = user
  const [initials, fullName] = React.useMemo(
    () => [`${firstName.charAt(0)}${lastName.charAt(0)}`, getUserLabel(user)],
    [firstName, lastName],
  )

  if (small) {
    return (
      <Element
        renderAs="span"
        backgroundColor={backgroundColor || `primary-dark`}
        textColor="primary-light"
        style={{ borderRadius: 999, padding: '0.5rem' }}
        data-tooltip={fullName}
        className="has-tooltip-bottom"
      >
        {initials}
      </Element>
    )
  }
  return <>{fullName}</>
}

export default Avatar
