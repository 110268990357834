import React from 'react'
import { Card, Element, Heading, Loader } from 'react-bulma-components'
import { Link } from 'react-router-dom'
import { LinkProps } from 'react-router-dom'
import { UserRoleKind } from '../../api/users'
import { UserRoleKindThemeNames } from '../../hooks/use-theme-switcher'
import { ConditionalWrapper } from '../layout/conditional-wrapper'
import { UseQueryResult } from '@tanstack/react-query'

export interface DashboardCardProps {
  title?: string | React.ReactElement
  mainStat: {
    value: string | number
    label: string | React.ReactElement
  }
  secondaryStat?: string | React.ReactElement
  color?: UserRoleKindThemeNames[UserRoleKind]
  to?: LinkProps['to']
  queryStatus: UseQueryResult['status']
}

export const DashboardCard: React.FC<DashboardCardProps> = ({
  title,
  mainStat: { value, label },
  secondaryStat,
  color,
  to,
  queryStatus,
}) => {
  return (
    <Card>
      <ConditionalWrapper
        condition={Boolean(to)}
        // We know that 'to' is defined in the wrapper because it's status of the condition Boolean(to)
        wrapper={children => <Link to={to!}>{children}</Link>}
      >
        <Card.Content>
          {title !== undefined && (
            <Heading textColor="grey" renderAs="h3" size={6} textWeight="bold" mb={3}>
              {title}
            </Heading>
          )}
          {queryStatus === 'success' ? (
            <Element textAlign={'center'}>
              <Element
                renderAs="span"
                textSize={1}
                textWeight="bold"
                textColor={color ? color : 'primary'}
              >
                {value}{' '}
                <Element renderAs="span" textSize={3} style={{ whiteSpace: 'nowrap' }}>
                  {label}
                </Element>
              </Element>
              <br />
              <Element
                textColor="grey"
                renderAs="span"
                textSize={6}
                textWeight="bold"
                style={{ whiteSpace: 'nowrap' }}
              >
                {secondaryStat ?? <>&nbsp;</>}
              </Element>
            </Element>
          ) : (
            <Loader />
          )}
        </Card.Content>
      </ConditionalWrapper>
    </Card>
  )
}
