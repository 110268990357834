import { Formik, Form, Field } from 'formik'
import React from 'react'
import { Button, Container, Element } from 'react-bulma-components'
import { useResetPasswordMutation } from '../../queries/users'
import { PasswordFieldWithValidation } from '../form/fields/password'
import InputField from '../form/fields/input'

interface ResetPasswordFormProps {
  token?: string
}

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({ token }) => {
  const mutation = useResetPasswordMutation()

  return (
    <Formik
      initialValues={{
        newPassword: '',
        confirmNewPassword: '',
        validationCode: '',
      }}
      enableReinitialize
      validate={values => {
        const errors: any = {}
        if (!values.newPassword) errors.password = 'Champ requis'
        if (!values.validationCode) errors.password = 'Champ requis'
        if (!values.confirmNewPassword) errors.password = 'Champ requis'
        if (values.newPassword !== values.confirmNewPassword)
          errors.confirmNewPassword = 'Les deux mots de passe doivent être similaires'
        return errors
      }}
      onSubmit={values => {
        const { newPassword: password, validationCode } = values
        token && mutation.mutate({ password, token, validationCode: Number(validationCode) })
      }}
    >
      {() => {
        return (
          <Form>
            <Container>
              <Field
                label="Code de vérification"
                help="Entrez ici le code à 4 chiffres que vous avez reçu par email."
                name="validationCode"
                component={InputField}
                required
              />
              <Element mb={5}>
                <PasswordFieldWithValidation
                  label="Votre nouveau mot de passe"
                  name="newPassword"
                />
              </Element>
              <Element mb={5}>
                <PasswordFieldWithValidation
                  label="Confirmez le mot de passe"
                  name="confirmNewPassword"
                />
              </Element>
              <Button color="primary" type="submit">
                Mettre à jour
              </Button>
            </Container>
          </Form>
        )
      }}
    </Formik>
  )
}

export default ResetPasswordForm
