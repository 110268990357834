import { WorkPeriodSchema } from '../services/resources/work-periods/work-period.model'
import isEqualWith from 'lodash/isEqualWith'

interface WorkPeriodDates {
  start: { date: Date; [key: string]: any }
  end: { date: Date; [key: string]: any }
  [key: string]: any
}

export const extractDates = (workPeriods: WorkPeriodDates[]): { start: Date; end: Date }[] =>
  workPeriods.map(wp => ({ start: wp.start.date, end: wp.end.date }))

export const datesAreEquals = (
  workPeriods: WorkPeriodDates[],
  workPeriodsToCheck: WorkPeriodDates[],
): boolean => isEqualWith(extractDates(workPeriods), extractDates(workPeriodsToCheck))

export const datesToString = (workPeriods: WorkPeriodDates[]): string =>
  workPeriods.reduce(
    (result, workPeriod) =>
      (result += `${new Date(workPeriod.start.date).toLocaleDateString('fr-FR', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'Europe/Paris',
      })} de ${new Date(workPeriod.start.date).toLocaleTimeString('fr-FR', {
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'Europe/Paris',
      })} à ${new Date(workPeriod.end.date).toLocaleTimeString('fr-FR', {
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'Europe/Paris',
      })}\n`),
    '',
  )

export const hasClockingInProgress = (workPeriod: WorkPeriodSchema): boolean => {
  const startClocking = workPeriod.start.clocking || workPeriod.start.manualClocking
  const endClocking = workPeriod.end.clocking || workPeriod.end.manualClocking
  return !Boolean((!startClocking && !endClocking) || (startClocking && endClocking))
}

interface ClockedBreak {
  start: WorkPeriodSchema['breaksClockings'][0]
  end?: WorkPeriodSchema['breaksClockings'][0]
}

export const buildClockedBreaks = (
  breaksClockings: WorkPeriodSchema['breaksClockings'],
): ClockedBreak[] => {
  const sanitizedBreaks: ClockedBreak[] = []
  breaksClockings.forEach((breakClocking, index) => {
    if (index % 2 === 0) {
      sanitizedBreaks.push({
        start: breakClocking,
        end: breaksClockings[index + 1],
      })
    }
  })
  return sanitizedBreaks
}
