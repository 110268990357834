import React from 'react'
import { RequestButton } from '../../request-components/request-components'
import { useCreateMutation } from '../../../queries/users'
import { CreateUserParams } from '../../../../../backend/src/services/resources/users/users.controller.service.types'
import { UserRoleKind } from '../../../api/users'
import { Organization } from '../../../api/organizations'

interface ResendUserInvitationButtonProps {
  userEmail: string
  roleKind: UserRoleKind
  organization: Organization
}

const ResendUserInvitationButton: React.FC<ResendUserInvitationButtonProps> = ({
  userEmail,
  roleKind,
  organization,
}) => {
  const createUserMutation = useCreateMutation()

  const mutate = () => {
    const mutationPayload: CreateUserParams = {
      email: userEmail,
      appAccess: 'full',
      role: {
        kind: roleKind,
      },
    }
    if (roleKind === 'employerMember' || roleKind === 'interimAgencyMember') {
      mutationPayload.role.organizationRole = 'admin'
      mutationPayload.role.organization = organization._id
    } else if (roleKind === 'worker') {
      mutationPayload.association = organization._id
    }
    createUserMutation.mutate(mutationPayload)
  }

  return (
    <RequestButton
      size={'small'}
      onClick={mutate}
      disabled={createUserMutation.isSuccess}
      mr={'2'}
      mutation={createUserMutation}
    >
      Ré-envoyer l'invitation
    </RequestButton>
  )
}

export default ResendUserInvitationButton
