import React from 'react'
import {
  Card,
  Container,
  Element,
  Heading,
  Level,
  Section,
  Table,
  Tag,
} from 'react-bulma-components'
import Layout from '../../components/layout/layout'
import { useNavigate } from 'react-router-dom'
import useUrlId from '../../hooks/use-url-id'
import { useMissionQuery } from '../../queries/missions'
import MissionMessages from '../../components/missions/mission-messages'
import Breadcrumbs from '../../components/breadcrumbs/breadcrumbs'
import MissionActions from '../../components/missions/mission-actions'
import MissionPageTitle, { EditActions } from '../../components/missions/titles/mission-page-title'
import { dateToTimeInputValue, formatCompleteDate, localDate } from '../../utils/date'
import Protected, { isAuthorizedFor } from '../../components/protected/protected'
import { Link } from 'react-router-dom'
import { Mission } from '../../api/missions'
import { sortBy, upperFirst } from 'lodash'
import {
  WorkPeriodAbandonedMessage,
  WorkPeriodCancelledMessage,
  WorkPeriodCannotBeValidated,
  WorkPeriodValidatedMessage,
} from '../../components/work-periods/work-period-messages'
import { useWorkersRequestsQuery } from '../../queries/workers-requests'
import { ConditionalWrapper } from '../../components/layout/conditional-wrapper'
import History from '../../components/history/history'
import locales from '../../../../locales/missions'

const MissionDetails: React.FunctionComponent = () => {
  const navigate = useNavigate()
  const { id, isNew } = useUrlId()
  const { data: mission } = useMissionQuery(id)
  const isInterimAgencyMission = Boolean(mission !== undefined && 'interimAgency' in mission)
  const { data: workersRequest } = useWorkersRequestsQuery(
    { 'posts.missions': mission?._id },
    { enabled: Boolean(isInterimAgencyMission) },
  )
  const linkedWorkersRequestId = workersRequest?.[0]?._id

  const breadcrumbs = useBreadcrumbs(id)
  React.useEffect(() => {
    if (isNew) navigate('/') // TODO: TEMP quickfix to prevent going to the mission creation page
  }, [isNew])

  const historyItems = React.useMemo(() => {
    return sortBy(mission?.history || [], 'createdAt').reverse()
  }, [mission])

  if (!mission) return <></>

  return (
    <Layout>
      <Section>
        <Container max={true} breakpoint="desktop">
          <Level>
            <Level.Side>
              <Breadcrumbs items={breadcrumbs} />
            </Level.Side>
            <Level.Side align="right">{!isNew && <EditActions mission={mission} />}</Level.Side>
          </Level>
          <Level alignItems="flex-start" mb={6}>
            <Level.Side>
              <MissionPageTitle
                mission={mission}
                organizationType={isInterimAgencyMission ? 'interimAgency' : 'employer'}
              ></MissionPageTitle>
            </Level.Side>
            <Level.Side align="right">
              <Tag.Group>
                <MissionMessages mission={mission} type="tag" />
              </Tag.Group>
            </Level.Side>
          </Level>
          <Protected roles={['interimAgencyMember', 'employerMember']}>
            <Heading size={4}>Actions</Heading>
            <Card>
              <Card.Content>
                <Element>
                  <MissionActions mission={mission} />
                </Element>
              </Card.Content>
            </Card>
          </Protected>
          <Card mt={6}>
            <Card.Content>
              <Heading size={5}>
                {`Du ${localDate(mission.start)} au ${localDate(mission.end)}`}
              </Heading>
              <WorkPeriodsList mission={mission} />
            </Card.Content>
          </Card>
          <Element textAlign={'right'} mt={2}>
            {linkedWorkersRequestId && (
              <Link to={`/workers-requests/${linkedWorkersRequestId}`}>Voir la demande</Link>
            )}
          </Element>
        </Container>
      </Section>
      <Section>
        <Container max={true} breakpoint="desktop">
          <Heading size={4} mt={5}>
            Historique
          </Heading>
          <History items={historyItems} locales={locales.history} />
        </Container>
      </Section>
    </Layout>
  )
}

export default MissionDetails

const useBreadcrumbs = (missionId?: string) =>
  React.useMemo(
    () => [
      {
        label: 'Liste des missions',
        to: '/missions',
      },
      {
        label: `Mission`,
        to: `/missions/${missionId}`,
      },
    ],
    [missionId],
  )

interface WorkPeriodsListProps {
  mission: Mission
}

const WorkPeriodsList: React.FC<WorkPeriodsListProps> = ({ mission }) => {
  return (
    <Table.Container>
      <Table size="fullwidth">
        <thead>
          <tr>
            <th>Jour</th>
            <th>Début</th>
            <th>Fin</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {mission.workPeriods.map(workPeriod => {
            return (
              <tr key={workPeriod._id}>
                <td>
                  <ConditionalWrapper
                    condition={isAuthorizedFor(['employerMember'])}
                    wrapper={children => (
                      <Link to={`/missions/${mission._id}/work-periods/${workPeriod._id}`}>
                        {children}
                      </Link>
                    )}
                  >
                    <>{upperFirst(formatCompleteDate(workPeriod.start.date))}</>
                  </ConditionalWrapper>
                </td>
                <td>{dateToTimeInputValue(workPeriod.start.date)}</td>
                <td>{dateToTimeInputValue(workPeriod.end.date)}</td>
                <td>
                  <Tag.Group>
                    <WorkPeriodValidatedMessage type="icon" workPeriod={workPeriod} />
                    <WorkPeriodAbandonedMessage type="icon" workPeriod={workPeriod} />
                    <WorkPeriodCancelledMessage type="icon" workPeriod={workPeriod} />
                    <WorkPeriodCannotBeValidated type="icon" workPeriod={workPeriod} />
                  </Tag.Group>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </Table.Container>
  )
}
