import React, { useEffect, useState } from 'react'
import { Columns, Container, Form, Heading, Loader, Section } from 'react-bulma-components'
import Layout from '../../components/layout/layout'
import LoginForm from '../../components/session/login-form'
import { useOrganizationsQuery } from '../../queries/organizations'
import { Employer } from '../../api/organizations'
import useStore from '../../store'
import { Navigate } from 'react-router-dom'

const LoginAsQrScannerUserPage: React.FC = () => {
  const currentUser = useStore(s => s.session.currentUser)
  const allEmployers = useOrganizationsQuery()?.data?.filter(o => o.type === 'employer')
  const [selectedEmployerId, setSelectedEmployerId] = useState<Employer['_id'] | undefined>(
    undefined,
  )

  useEffect(() => {
    const firstEmployer = allEmployers?.[0]
    if (firstEmployer) {
      setSelectedEmployerId(firstEmployer._id)
    }
  }, [allEmployers?.length])

  const notAuthorized = allEmployers?.length === 0
  const isSystemUser = currentUser?.appAccess === 'system'

  if (isSystemUser) return <Navigate to="/laser-scanner" />
  if (allEmployers === undefined) return <Loader />

  const onChange = e => {
    return setSelectedEmployerId(e.target.value)
  }

  return (
    <Layout>
      <Section size={'large'}>
        <Container>
          <Columns centered>
            <Columns.Column size={4}>
              <Heading>Créer une Borne de Scan</Heading>
              <>
                {notAuthorized ? (
                  <>Vous n'êtes pas autorisé à créer une Borne de Scan</>
                ) : (
                  <>
                    <Container my={5} mb={6}>
                      <Form.Control mt={6}>
                        <Form.Label mb={5}>
                          Pour quel Employeur souhaitez-vous créer une borne ?
                        </Form.Label>
                        {allEmployers?.map(e => {
                          return (
                            <>
                              <Form.Radio
                                key={e._id}
                                name={e._id}
                                checked={selectedEmployerId === e._id}
                                value={e._id}
                                onChange={onChange}
                              >
                                {e.name}
                              </Form.Radio>
                              <br />
                            </>
                          )
                        })}
                      </Form.Control>
                    </Container>
                    <Form.Label mb={5}>
                      Confirmez la création de Borne avec vos identifiants
                    </Form.Label>
                    <LoginForm loginAsQrScannerForEmployerId={selectedEmployerId} />
                  </>
                )}
              </>
            </Columns.Column>
          </Columns>
        </Container>
      </Section>
    </Layout>
  )
}

export default LoginAsQrScannerUserPage
