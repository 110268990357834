import React from 'react'
import { useOutletContext } from 'react-router-dom'
import { OutletContext } from './interim-agency-wrapper'
import { useAppSubscriptionPeriodsByAgencyQuery } from '../../queries/app-subscription-periods'
import AppSubscriptionPeriodsAgencyList from '../../components/app-subscription-periods/periods-list'

/**
 * Component for managing agency subscription settings
 * Receives interimAgency from outlet context and passes it to the form
 */
type AppSubscriptionPeriodsProps = OutletContext

const AppSubscriptionPeriods: React.FC = () => {
  const { interimAgency } = useOutletContext<AppSubscriptionPeriodsProps>()
  const { data: allPeriods } = useAppSubscriptionPeriodsByAgencyQuery(interimAgency._id, {
    enabled: Boolean(interimAgency),
  })

  return (
    <AppSubscriptionPeriodsAgencyList
      data={allPeriods || []}
      itemPath={`/agencies/${interimAgency._id}/app-subscription-periods/`}
    />
  )
}

export default AppSubscriptionPeriods
