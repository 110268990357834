import React from 'react'
import { Icon, Section, Button, Container, Form } from 'react-bulma-components'
import { Column } from 'react-table'
import Layout from '../../components/layout/layout'
import Table from '../../components/table'
import PageHeading from '../../components/sections/page-heading'
import useFilters from '../../hooks/filters'
import { Clocking } from '../../api/clockings'
import { DocumentTextIcon, BanIcon, CalendarIcon, QrcodeIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'
import { getStatus } from '../../utils/clockings'
import ComponentHeader from '../../components/sections/component-header'
import Protected from '../../components/protected/protected'
import useStore from '../../store'
import { useClockingsQuery } from '../../queries/clockings'
import { AppUserRole } from '../../store/session'
import { User } from '../../api/users'
import { getUserLabel } from '../../utils/users'
import ClockingsFilters from '../../components/clockings/filters'
import { FiltersResourcesEnum } from '../../store/filters'
import WorkerLabel from '../../components/texts/worker-label'

const ClockingsList: React.FunctionComponent = () => {
  const { filters } = useFilters(FiltersResourcesEnum.clockings)
  const currentUserRole = useStore(state => state.session.currentUserRole)

  const clockingsQuery = useClockingsQuery(
    {
      limit: 300,
      start: filters.start,
      end: filters.end,
      user: filters.user?.[0] ? filters.user : undefined,
    },
    { refetchInterval: 10000, enabled: Boolean(filters?.start) },
  )

  const tableColumns = useListTableColumns(clockingsQuery.data || [], currentUserRole)

  return (
    <Layout>
      <PageHeading
        title="Liste des derniers pointages"
        subtitle="Affiche les pointages les plus récents"
      />
      <Section>
        <Container>
          <ComponentHeader>
            <ComponentHeader.Left>
              <ClockingsFilters />
            </ComponentHeader.Left>
            <ComponentHeader.Right>
              <Protected
                roles={['employerMember']}
                action={{ resource: 'clockings', name: 'create' }}
              >
                <Form.Field>
                  <Form.Label>&nbsp;</Form.Label>
                  <Form.Control>
                    <Button renderAs={Link} to="/qr-scanner" outlined color="primary">
                      <Icon>
                        <QrcodeIcon />
                      </Icon>
                      <span>Pointer</span>
                    </Button>
                  </Form.Control>
                </Form.Field>
              </Protected>
            </ComponentHeader.Right>
          </ComponentHeader>
          <Table
            columns={tableColumns}
            data={clockingsQuery.data || []}
            noDataMessage="Aucun pointage à afficher"
          />
        </Container>
      </Section>
    </Layout>
  )
}

export default ClockingsList

const useListTableColumns = (data: Clocking[], currentUserRole: AppUserRole): Column[] => {
  return React.useMemo(
    () =>
      [
        currentUserRole !== 'worker' && {
          Header: <WorkerLabel />,
          accessor: 'user',
          Cell: (data: any) => {
            const clocking: Clocking = data.cell.row.original
            return getUserLabel(clocking.user as User)
          },
        },
        {
          Header: 'Pointage',
          accessor: 'createdAt',
          Cell: (data: any) => {
            const clocking: Clocking = data.cell.row.original
            return new Date(clocking.createdAt).toLocaleString()
          },
        },
        currentUserRole !== 'interimAgencyMember' && {
          Header: 'Agence',
          accessor: 'interimAgency',
          Cell: (data: any) => {
            const clocking: Clocking = data.cell.row.original
            return clocking.mission?.interimAgency?.name || ''
          },
        },
        currentUserRole !== 'employerMember' && {
          Header: 'Employeur',
          accessor: 'employer',
          Cell: (data: any) => {
            const clocking: Clocking = data.cell.row.original
            return clocking?.employer?.name || ''
          },
        },
        {
          Header: 'Type',
          accessor: 'entry',
          Cell: (data: any) => {
            const clocking: Clocking = data.cell.row.original
            const status = getStatus(clocking)

            return status
          },
        },
        currentUserRole !== 'worker' && {
          Header: 'Journée',
          accessor: 'workPeriod',
          Cell: (data: any) => {
            const clocking: Clocking = data.cell.row.original

            //TODO: helper
            return clocking.type && clocking.mission && clocking.workPeriod ? (
              <Button
                size="small"
                renderAs={Link}
                to={`/missions/${clocking.mission._id}/work-periods/${clocking.workPeriod}`}
              >
                <Icon>
                  <CalendarIcon />
                </Icon>
              </Button>
            ) : (
              <Button disabled color="ghost" size="small">
                <Icon>
                  <BanIcon />
                </Icon>
              </Button>
            )
          },
        },
        currentUserRole !== 'worker' && {
          Header: 'Mission',
          accessor: 'mission',
          Cell: (data: any) => {
            const clocking: Clocking = data.cell.row.original
            return clocking.mission ? (
              <Button size="small" renderAs={Link} to={`/missions/${clocking.mission._id}`}>
                <Icon>
                  <DocumentTextIcon />
                </Icon>
              </Button>
            ) : (
              <Button disabled color="ghost" size="small">
                <Icon>
                  <BanIcon />
                </Icon>
              </Button>
            )
          },
        },
      ].filter(c => c) as Column[],
    [data, currentUserRole],
  )
}
