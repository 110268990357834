import React from 'react'
import { Container, Section } from 'react-bulma-components'
import Layout from '../../components/layout/layout'
import PageHeading from '../../components/sections/page-heading'
import { useWorkPeriodsQuery } from '../../queries/work-periods'
import { endOfMonth, startOfMonth, subMonths } from 'date-fns'
import { formatCompleteDate } from '../../utils/date'
import { useOrganizationsQuery } from '../../queries/organizations'
import Protected from '../../components/protected/protected'

const BillingDashboard: React.FC = () => {
  const thisMonth = startOfMonth(new Date())
  const thisMonthWortkPeriods = useWorkPeriodsCount(thisMonth, endOfMonth(thisMonth))
  const previousMonth = subMonths(thisMonth, 1)
  const previousMonthWorkPeriods = useWorkPeriodsCount(previousMonth, endOfMonth(previousMonth))
  const employers = useOrganizationsQuery({ type: 'employer' })?.data

  return (
    <Layout>
      <PageHeading title={'Billing'}></PageHeading>
      <Protected roles={['superAdmin']}>
        <Section>
          <Container>
            <br />
            <table className="table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Total</th>
                  {employers?.map(e => (
                    <th>{e.name}</th>
                  ))}
                </tr>
              </thead>
              <tr>
                <td>{formatCompleteDate(previousMonth)}</td>
                <td>{previousMonthWorkPeriods?.length}</td>
                {employers?.map(e => (
                  <td>
                    {
                      previousMonthWorkPeriods?.filter(wP => wP.mission.employer._id === e._id)
                        .length
                    }
                  </td>
                ))}
              </tr>
              <tr>
                <td>{formatCompleteDate(thisMonth)}</td>
                <td>{thisMonthWortkPeriods?.length}</td>
                {employers?.map(e => (
                  <td>
                    {thisMonthWortkPeriods?.filter(wP => wP.mission.employer._id === e._id).length}
                  </td>
                ))}
              </tr>
            </table>
            <br />
          </Container>
        </Section>
      </Protected>
    </Layout>
  )
}
export default BillingDashboard

const useWorkPeriodsCount = (start: Date, end: Date) => {
  const workPeriodsQuery = useWorkPeriodsQuery({
    populate: [],
    start,
    end,
    fields: ['_id'],
  })
  return workPeriodsQuery.data
}
