import { QueryKey, useMutation, UseQueryResult } from '@tanstack/react-query'
import { queryClient, QueryOptions, UseMutationResult, useResource, useResources } from '.'
import {
  create,
  fetch,
  get,
  EmployerRule,
  EmployerRuleCreationPayload,
  EmployerRuleUpdatePayload,
  update,
} from '../api/employer-rules'

//Queries
export const useEmployerRuleQuery = (id?: string): UseQueryResult<EmployerRule, any> =>
  useResource<EmployerRule>('employer-rules', get, id)

export const useEmployerRulesQuery = (
  filters?: {
    employer?: string
    'display.summary'?: boolean
    'display.payroll'?: boolean
    'interval.period'?: EmployerRule['interval']['period']
  },
  options?: QueryOptions,
): UseQueryResult<EmployerRule[], any> =>
  useResources<EmployerRule[], any>('employer-rules', fetch, filters, options)

//Mutations
const onMutationSuccess = () => {
  queryClient.invalidateQueries(['employer-rules'])
}

export const useCreateMutationKey = (): QueryKey => ['employer-rules', 'create']
export const useCreateMutation = (): UseMutationResult<
  EmployerRule,
  EmployerRuleCreationPayload,
  any
> => useMutation(create, { mutationKey: useCreateMutationKey(), onSuccess: onMutationSuccess })

export const useUpdateMutationKey = (): QueryKey => ['employer-rules', 'update']
export const useUpdateMutation = (): UseMutationResult<
  EmployerRule,
  EmployerRuleUpdatePayload,
  any
> =>
  useMutation(update, {
    mutationKey: useUpdateMutationKey(),
    onSuccess: onMutationSuccess,
  })
