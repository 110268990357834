import React from 'react'
import FieldColumn from '../field-column'
import { Field } from 'formik'
import MultiSelectField from '../../form/fields/multi-select'

const IsValidatedFilterField: React.FC = ({}) => {
  return (
    <FieldColumn>
      <Field
        label="Validée"
        name="isValidated"
        component={MultiSelectField}
        options={[
          {
            label: 'Oui',
            value: true,
          },
          {
            label: 'Non',
            value: false,
          },
        ]}
      />
    </FieldColumn>
  )
}

export default IsValidatedFilterField
