import React from 'react'
import { Button, Container, Element, Section } from 'react-bulma-components'
import { Cell, Column } from 'react-table'
import { UserAssociation, UserRole } from '../../api/users'
import Layout from '../../components/layout/layout'
import PageHeading from '../../components/sections/page-heading'
import Table from '../../components/table'
import RoleStatus from '../../components/users/role-status'
import locales from '../../../../locales'
import useStore from '../../store'
import { Organization } from '../../api/organizations'
import { User } from '../../api/users'
import {
  useUpdateUserAssociationMutation,
  useUpdateUserRoleMutation,
  useUserQuery,
} from '../../queries/users'
import ConfirmationModal from '../../components/confirmation-modal/confirmation-modal'
import { getUserLabel } from '../../utils/users'

const UserOrganizations: React.FC = () => {
  const currentUser = useStore(state => state.session.currentUser) as User

  // Query - get user
  const userQuery = useUserQuery(currentUser._id)
  const user = userQuery.data

  // Mutations
  const updateRoleMutation = useUpdateUserRoleMutation()
  const updateAssociationMutation = useUpdateUserAssociationMutation()

  // Discontinue associaiton confirmation modal state
  const [currentAssociationToDiscontinue, setCurrentAssociationToDiscontinue] = React.useState<
    UserAssociation | undefined
  >()

  // Build columns
  const rolesColumns = useRolesListTable(
    user?.roles || [],
    params =>
      updateRoleMutation.mutate({
        roleId: params.itemId,
        status: params.status,
      }),
    'roles',
  )
  const associationsColumns = useRolesListTable(
    user?.associations || [],
    params =>
      updateAssociationMutation.mutate({
        associationId: params.itemId,
        status: params.status,
      }),
    'associations',
    setCurrentAssociationToDiscontinue,
  )

  // Check if user is worker (usefull to display or not associations)
  const isWorker = React.useMemo((): boolean => {
    return user?.roles.some(role => role.kind === 'worker') || false
  }, [user])
  const isOnlyWorker = isWorker && user?.roles.length === 1

  return (
    <Layout>
      {!isOnlyWorker && user && (
        <>
          <PageHeading title="Mes Profils" subtitle="" />
          <Section>
            <Container>
              <Table
                columns={rolesColumns}
                data={user?.roles}
                noDataMessage="Aucune relation à afficher"
              />
            </Container>
          </Section>
        </>
      )}
      {isWorker && user && (
        <Element mt={5}>
          <PageHeading
            title="Mes Relations"
            subtitle="Liste de mes partenaires avec qui je travaille"
          />
          <Section>
            <Container>
              <Table
                columns={associationsColumns}
                data={user.associations}
                noDataMessage="Aucune relation à afficher"
              />
            </Container>
          </Section>
        </Element>
      )}
      <ConfirmationModal
        onConfirm={() => {
          if (currentAssociationToDiscontinue) {
            updateAssociationMutation.mutate({
              associationId: currentAssociationToDiscontinue._id,
              status: 'discontinued',
            })
            setCurrentAssociationToDiscontinue(undefined)
          }
        }}
        show={Boolean(currentAssociationToDiscontinue)}
        onCancel={() => setCurrentAssociationToDiscontinue(undefined)}
      >
        <Element>
          Êtes vous certain de vouloir interrompre votre collaboration avec{' '}
          <Element renderAs="span" textWeight="bold">
            {currentAssociationToDiscontinue?.organization.name}
          </Element>
          ? <br />
          L'agence ne pourra plus entrer vous attribuer des missions avec vous.
        </Element>
      </ConfirmationModal>
    </Layout>
  )
}

export default UserOrganizations

const useRolesListTable = (
  roles: User['roles'] | User['associations'],
  respondToInvitation: (data: { itemId: string; status: 'accepted' | 'rejected' }) => void,
  type: 'roles' | 'associations',
  setCurrentAssociationToDiscontinue?: (association?: UserAssociation) => void,
): Column[] =>
  React.useMemo(
    () =>
      [
        type === 'roles'
          ? {
              Header: 'Type',
              accessor: 'kind',
              Cell: (cell: Cell<UserRole>) => {
                const role = cell.row.original
                return (
                  <Element>
                    {locales.users.roles[role.kind]}{' '}
                    {role.organization && `- ${role.organization.name}`}
                  </Element> //Todo: Refactor ... i18N?
                )
              },
            }
          : {
              Header: 'Agence',
              accessor: 'organization',
              Cell: ({ value }: { value: Organization }) => <Element>{value.name}</Element>,
            },
        {
          Header: "Statut de l'invitation",
          accessor: 'status',
          Cell: (data: any) => {
            return <RoleStatus role={data.cell.row.original} showDate />
          },
        },
        {
          Header: 'Invité par',
          accessor: 'invitedBy',
          Cell: ({ value }: { value: User }) => {
            return value ? getUserLabel(value) : ''
          },
        },
        {
          Header: 'Action',
          accessor: '__actions',
          Cell: (data: any) => {
            const role: UserRole | UserAssociation = data.cell.row.original
            //TODO: Improve
            //@ts-ignore
            if (role.status === 'pending' && role.kind !== 'worker')
              return (
                <>
                  <Button
                    size={'small'}
                    color="success"
                    data-test="accept-invitation"
                    mr={1}
                    onClick={() =>
                      respondToInvitation({
                        itemId: role._id,
                        status: 'accepted',
                      })
                    }
                  >
                    Accepter
                  </Button>
                  <Button
                    size={'small'}
                    color="danger"
                    data-test="reject-invitation"
                    onClick={() =>
                      respondToInvitation({
                        itemId: role._id,
                        status: 'rejected',
                      })
                    }
                  >
                    Refuser
                  </Button>
                </>
              )

            if (role.status === 'accepted' && setCurrentAssociationToDiscontinue)
              return (
                <Button
                  size={'small'}
                  color="danger"
                  data-test="discontinue-association"
                  onClick={() => setCurrentAssociationToDiscontinue(role as UserAssociation)}
                >
                  Se retirer
                </Button>
              )
            return ''
          },
        },
      ] as Column[],
    [roles, respondToInvitation, type],
  )
