import React from 'react'
import { Button, Container, Heading, Section } from 'react-bulma-components'
import { Link } from 'react-router-dom'
import Layout from '../layout/layout'

interface Error404Props {
  redirect?: {
    to: string
    label: string
  }
}

const Error404: React.FunctionComponent<Error404Props> = ({ redirect }) => (
  <Layout>
    <Section>
      <Container textAlign="center" className="content is-vcentered has-text-centered">
        <Heading size={2} mt={6}>
          404
        </Heading>

        <Heading subtitle renderAs="p">
          La resource demandée n'existe pas.
        </Heading>
        <Button renderAs={Link} to={'/'}>
          Retour à l'écran d'accueil
        </Button>
        {redirect && (
          <Button renderAs={Link} to={redirect.to} ml={2}>
            {redirect.label}
          </Button>
        )}
      </Container>
    </Section>
  </Layout>
)

export default Error404
