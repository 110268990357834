import { QueryKey, useMutation, useQuery, UseQueryResult } from '@tanstack/react-query'
import { queryClient, UseMutationResult, useResources } from '.'
import {
  Clocking,
  ClockingCreationPayload,
  create,
  fetch,
  FetchClockingsParams,
} from '../api/clockings'
import api from '../api'

//Queries
export const useClockingsQuery = (
  filters?: FetchClockingsParams,
  options?: { refetchInterval?: number; enabled?: boolean },
): UseQueryResult<Clocking[], FetchClockingsParams> =>
  useResources('clockings', fetch, filters || {}, options)

//Mutations
const useCreateMutationKey = (): QueryKey => ['clockings', 'create']
export const useCreateMutation = (): UseMutationResult<Clocking, ClockingCreationPayload, any> =>
  useMutation(create, {
    mutationKey: useCreateMutationKey(),
    onSuccess: () => {
      queryClient.invalidateQueries(['clockings'])
    },
  })

export const useCheckAppVersionQuery = (interval: number) =>
  useQuery({
    queryFn: async () =>
      api<Record<string, never>, Record<string, never>>({
        endpoint: '/version',
        method: 'head',
      }),
    refetchInterval: () => interval,
    refetchIntervalInBackground: true,
  })
