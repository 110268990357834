import React from 'react'
import { Element } from 'react-bulma-components'

const WorkPeriodEventInformativeDescription: React.FC = () => {
  return (
    <>
      <Element renderAs="strong">Evénement Informatif</Element> : il est indicatif, il n’a pas
      d’incidence sur des calculs. <br />
      <Element renderAs="i">Exemple : Une Absence Autorisée, un Grand Déplacement</Element>
    </>
  )
}

export default WorkPeriodEventInformativeDescription
