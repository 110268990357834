import React from 'react'
import { Element } from 'react-bulma-components'
import Avatar from '../avatar/avatar'
import { User } from '../../api/users'
import { WorkersRequestPostDesiredWorkersProps } from './desired-workers'

interface WorkersRequestDesiredWorkerProps extends WorkersRequestPostDesiredWorkersProps {
  worker: User
  onClick?(worker: User): void
}

const WorkersRequestPostDesiredWorker: React.FC<WorkersRequestDesiredWorkerProps> = ({
  workersRequest,
  worker,
  availableWorkers,
  onClick,
}) => {
  const isAlreadyAssigned = React.useMemo(() => {
    return workersRequest.posts.find(
      post =>
        !post.isReplaceable && post.missions[post.missions.length - 1]?.user._id === worker._id,
    )
  }, [workersRequest, worker])

  const isAvailable = React.useMemo(() => {
    return availableWorkers && availableWorkers.some(w => w._id === worker._id)
  }, [worker, availableWorkers])

  const tooltipText = React.useMemo<string | undefined>(() => {
    if (isAlreadyAssigned) return 'Déjà assigné à la demande'
    else if (isAvailable) return 'Disponible'
    else if (availableWorkers) return 'Non disponible'
    return
  }, [isAlreadyAssigned, isAvailable])

  return (
    <Element
      renderAs={isAvailable && onClick ? 'a' : 'span'}
      onClick={() => isAvailable && onClick?.(worker)}
      data-tooltip={tooltipText}
    >
      <Avatar user={worker} />
    </Element>
  )
}

export default WorkersRequestPostDesiredWorker
