import React from 'react'
import { useLocation, useParams } from 'react-router-dom'

interface Options {
  idName?: string
}

// Get resource id in url and check if id is equal to know if we are on a resource creation page

const useUrlId = (
  options?: Options,
): { id: string | undefined; isNew: boolean; isEdit: boolean } => {
  const { pathname } = useLocation()
  const urlId = useParams()[(options || {}).idName || 'id']
  const isNew = Boolean(pathname.match(/new$/))
  const isEdit = Boolean(pathname.match(/edit$/))

  return React.useMemo(
    () => ({
      id: urlId === 'new' ? undefined : urlId,
      isNew,
      isEdit,
    }),
    [urlId, pathname, options],
  )
}

export default useUrlId
