import React from 'react'
import { FormWrapperProps } from '../components/form/form-wrapper'
import { UseMutationResult } from '../queries'

export const useFormWrapperPropsWithMutationData = (
  props: FormWrapperProps,
  mutation?: UseMutationResult<any, any>,
  disabled?: boolean,
): FormWrapperProps =>
  React.useMemo(
    () => ({
      status: mutation?.status,
      ...props,
      disabled,
      successProps: { ...(props.successProps || {}), data: mutation?.data },
      errorProps: { ...(props.errorProps || {}), data: mutation?.error?.response?.data },
    }),
    [props, mutation],
  )
