import React from 'react'
import { Element } from 'react-bulma-components'
import { Mission } from '../../api/missions'
import {
  MissionAbandonedIcon,
  MissionCancelledIcon,
  MissionNotEditableIcon,
  MissionValidatedIcon,
  MissionWeekValidatedIcon,
  PreBillingIcon,
} from '../icons'
import Message, { CommonMessageProps } from '../message/message'
import { Link } from 'react-router-dom'
import { isAllWeekWorkPeriodsPreBillied } from '../../utils/work-periods'

export const MissionIsCancelledMessage: React.FC<
  CommonMessageProps & { mission: { isCancelled: Mission['isCancelled'] } }
> = ({ mission, ...rest }) => {
  if (!mission) return <></>
  if (!mission.isCancelled) return <></>
  return (
    mission.isCancelled && (
      <Message
        iconColor="danger"
        text="Mission Interrompue"
        data-test="mission-cancelled"
        icon={<MissionCancelledIcon />}
        {...rest}
      />
    )
  )
}

export const MissionIsValidatedMessage: React.FC<
  CommonMessageProps & { mission: { isValidated: Mission['isValidated'] } }
> = ({ mission, ...rest }) => {
  if (!mission) return <></>
  if (!mission.isValidated) return <></>
  return (
    <Message
      text="Mission Validée"
      data-test="mission-validated"
      icon={<MissionValidatedIcon />}
      iconColor="success"
      {...rest}
    />
  )
}

export const MissionWeekIsValidatedMessage: React.FC<
  CommonMessageProps & { mission: { weekIsValidated: boolean } }
> = ({ mission, ...rest }) => {
  if (!mission) return <></>
  if (!mission.weekIsValidated) return <></>
  return (
    <Message
      text="Semaine Validée"
      icon={<MissionWeekValidatedIcon />}
      iconColor="success"
      data-test="week-validated"
      {...rest}
    />
  )
}

export const MissionIsPreBilledMessage: React.FC<
  CommonMessageProps & {
    mission?: Mission
    week?: number
    year?: number
    force?: boolean
  }
> = ({ mission, force, week, year, ...rest }) => {
  const preBillingId =
    mission && week && year && isAllWeekWorkPeriodsPreBillied(mission, week, year)
  if (!force && (!mission || !preBillingId)) return <></>
  return (
    <Element renderAs={mission && Link} to={`/pre-billings/${preBillingId}`}>
      <Message
        text="Semaine Pré-facturée"
        icon={<PreBillingIcon />}
        iconColor="success"
        data-test="week-pre-billed"
        {...rest}
      />
    </Element>
  )
}

const MissionCannotBeUpdatedMessage: React.FC<MissionMessagesProps> = ({
  mission,
  ...rest
}) => {
  if (!mission) return <></>
  if (mission.__actions.canBeUpdated) return <></>
  return (
    <Message
      text="Mission ne peut plus être mise à jour"
      icon={<MissionNotEditableIcon />}
      {...rest}
    />
  )
}

export const MissionAbandonedMessage: React.FC<
  CommonMessageProps & { mission: { isAbandoned: Mission['isAbandoned'] } }
> = ({ mission, ...rest }) => {
  if (!mission) return <></>
  if (!mission.isAbandoned) return <></>
  return (
    <Message
      iconColor={'danger'}
      text="Mission en Abandon de Poste"
      data-test="mission-abandoned"
      icon={<MissionAbandonedIcon />}
      {...rest}
    />
  )
}

/* export const MissionIsEndedMessage: React.FC<
  CommonMessageProps & { mission: { isEnded: Mission['isEnded'] } }
> = ({ mission, ...rest }) => {
  if (!mission) return <></>
  if (!mission.isEnded) return <></>
  return <Message text="Mission terminée" icon={<MissionAbandonedIcon />} {...rest} />
}
 */
interface MissionMessagesProps extends CommonMessageProps {
  mission: Mission
}

const MissionMessages: React.FC<MissionMessagesProps> = props => {
  if (!props.mission) return <></>
  return (
    <Element display="flex" flexWrap="wrap">
      <MissionIsValidatedMessage {...props} />
      <MissionAbandonedMessage {...props} />
      <MissionIsCancelledMessage {...props} />
      {/*<MissionIsPreBilledMessage {...props} /> */}
    </Element>
  )
}

export default MissionMessages
