import React from 'react'
import { Button, Container, Form, Icon, Section } from 'react-bulma-components'
import { CellProps, CellValue, Column } from 'react-table'
import Layout from '../../components/layout/layout'
import Table from '../../components/table'
import PageHeading from '../../components/sections/page-heading'
import { Mission, MissionWithSummary } from '../../api/missions'
import { Link } from 'react-router-dom'
import MissionsFilters from '../../components/missions/filters'
import { localDate } from '../../utils/date'
import ComponentHeader from '../../components/sections/component-header'
import Protected from '../../components/protected/protected'
import useStore from '../../store'
import { SessionSlice } from '../../store/session'
import { useMissionsQuery } from '../../queries/missions'
import {
  MissionAbandonedMessage,
  MissionIsCancelledMessage,
  MissionIsValidatedMessage,
} from '../../components/missions/mission-messages'
import { MissionAddIcon } from '../../components/icons'
import { getUserLabel } from '../../utils/users'
import WorkerLabel from '../../components/texts/worker-label'
import OrganizationOptionProtected from '../../components/protected/organization-option-protected'
import useFilters from '../../hooks/filters'
import { FiltersResourcesEnum } from '../../store/filters'

const MissionsList: React.FunctionComponent = () => {
  const currentUserRole = useStore(state => state.session.currentUserRole)
  const { filters } = useFilters(FiltersResourcesEnum.missions)
  const query = useMissionsQuery({ ...filters, unSelectedFields: ['workPeriods'] })
  const tableColumns = useListTableColumns(query.data || [], currentUserRole)

  return (
    <Layout>
      <PageHeading
        title="Liste des missions"
        subtitle="Liste de toutes les missions triées par semaine"
      />
      <Section>
        <Container>
          <ComponentHeader>
            <ComponentHeader.Left>
              <MissionsFilters />
            </ComponentHeader.Left>
            <ComponentHeader.Right>
              <Protected roles={['employerMember']}>
                <OrganizationOptionProtected
                  options={[{ organizationType: 'employer', option: 'biPart' }]}
                >
                  <Form.Field>
                    <Form.Label>&nbsp;</Form.Label>
                    <Form.Control>
                      <Button renderAs={Link} to="/missions/new" color="primary">
                        <Icon>
                          <MissionAddIcon />
                        </Icon>
                        <span>Nouvelle Mission Interne</span>
                      </Button>
                    </Form.Control>
                  </Form.Field>
                </OrganizationOptionProtected>
              </Protected>
            </ComponentHeader.Right>
          </ComponentHeader>
          <Table
            columns={tableColumns}
            data={query.data || []}
            noDataMessage="Aucune mission à afficher"
          />
        </Container>
      </Section>
    </Layout>
  )
}

export default MissionsList

const useListTableColumns = (
  missions: MissionWithSummary[],
  currentUserRole: SessionSlice['currentUserRole'],
): Column<MissionWithSummary>[] =>
  React.useMemo(() => {
    const columns: (Column<MissionWithSummary> | false)[] = [
      {
        Header: () => <WorkerLabel />,
        id: 'user',
        accessor: (mission): CellValue<string> => mission.user.firstName,
        Cell: (data: CellProps<MissionWithSummary>) => {
          const mission = data.cell.row.original
          return (
            <>
              <Protected roles={['interimAgencyMember', 'employerMember']}>
                <Link to={`/missions/${mission._id}`}>{getUserLabel(mission.user)}</Link>
              </Protected>
              <Protected roles={['worker']}>{getUserLabel(mission.user)}</Protected>
            </>
          )
        },
      },
      currentUserRole !== 'interimAgencyMember' && {
        Header: 'Agence',
        accessor: 'interimAgency',
        Cell: (data: CellProps<MissionWithSummary>) => {
          const mission = data.cell.row.original
          if ('interimAgency' in mission) {
            return mission.interimAgency?.name
          } else return <>Non</>
        },
      },
      currentUserRole !== 'employerMember' && {
        Header: 'Employeur',
        accessor: 'employer',
        Cell: (data: CellProps<MissionWithSummary>) => {
          const mission: Mission = data.cell.row.original
          return mission.employer?.name || ' '
        },
      },
      {
        Header: 'Service',
        accessor: 'service',
      },
      {
        Header: 'Poste',
        accessor: (mission): CellValue<string> => mission.jobTitle.name,
      },
      {
        Header: 'Début',
        accessor: 'start',
        Cell: (data: CellProps<MissionWithSummary>) => {
          const mission = data.cell.row.original
          return localDate(mission.start)
        },
      },
      {
        Header: 'Fin',
        accessor: 'end',
        Cell: (data: CellProps<MissionWithSummary>) => {
          const mission = data.cell.row.original
          return localDate(mission.end)
        },
      },
      currentUserRole !== 'worker' && {
        Header: (
          <MissionIsValidatedMessage
            mission={{ isValidated: true }}
            type="icon"
            small
            iconColor={'dark'}
          />
        ),
        accessor: 'isValidated',
        Cell: (data: CellProps<MissionWithSummary>) => {
          const mission = data.cell.row.original
          return <MissionIsValidatedMessage mission={mission} type="icon" small />
        },
        width: 1,
      },
      currentUserRole !== 'worker' && {
        Header: (
          <MissionAbandonedMessage
            mission={{ isAbandoned: true }}
            type="icon"
            small
            iconColor={'dark'}
          />
        ),
        accessor: 'isAbandoned',
        Cell: (data: CellProps<MissionWithSummary>) => {
          const mission = data.cell.row.original
          return <MissionAbandonedMessage mission={mission} type="icon" small />
        },
        width: 1,
      },
      currentUserRole !== 'worker' && {
        Header: (
          <MissionIsCancelledMessage
            mission={{ isCancelled: true }}
            type="icon"
            small
            iconColor={'dark'}
          />
        ),
        accessor: 'isCancelled',
        Cell: (data: CellProps<MissionWithSummary>) => {
          const mission = data.cell.row.original
          return <MissionIsCancelledMessage mission={mission} type="icon" small />
        },
        width: 1,
      },
    ]
    // .filter(c => c) not working with TS inference
    return columns.flatMap(c => (c ? [c] : []))
  }, [missions, currentUserRole])
