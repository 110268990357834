import { Field, FieldArray, Form, Formik, useFormikContext } from 'formik'
import React from 'react'
import { Button, Columns, Container, Element, Heading, Icon } from 'react-bulma-components'
import { useCreateMutation, useUpdateMutation } from '../../queries/organizations'
import { InterimAgency, OrganizationTypeEnum } from '../../api/organizations'
import InputField from '../form/fields/input'
import { RequestButton, RequestMessage } from '../request-components/request-components'
import { AddressesForm } from './addresses-form'
import Protected from '../protected/protected'
import { PlusIcon } from '@heroicons/react/outline'
import { TrashIcon } from '@heroicons/react/outline'

interface AgencyFormProps {
  organization?: InterimAgency
}

const AgencyForm: React.FunctionComponent<AgencyFormProps> = ({ organization }) => {
  const createMutation = useCreateMutation()
  const updateMutation = useUpdateMutation()
  const currentMutation = organization ? updateMutation : createMutation
  return (
    <Formik
      initialValues={{
        name: organization?.name || '',
        type: OrganizationTypeEnum.interimAgency,
        addresses: organization?.addresses ?? [],
        appSubscriptionOptions: organization?.appSubscriptionOptions || {
          licenseFee: 0,
          discountPercentage: 0,
          tiers: [
            {
              workersRange: {
                min: 1,
                max: null,
              },
              workPeriodCost: 10,
            },
          ],
        },
      }}
      enableReinitialize
      validate={values => {
        const errors: any = {}
        if (!values.name) errors.name = 'Champ requis'
        if (values.addresses.length === 0)
          errors.addresses = 'Au moins une adresse doit être renseignée'
        if (values.appSubscriptionOptions.licenseFee < 0) {
          errors.appSubscriptionOptions = { licenseFee: 'Le montant doit être positif' }
        }
        if (
          values.appSubscriptionOptions.discountPercentage < 0 ||
          values.appSubscriptionOptions.discountPercentage > 100
        ) {
          errors.appSubscriptionOptions = {
            ...errors.appSubscriptionOptions,
            discountPercentage: 'Le pourcentage doit être entre 0 et 100',
          }
        }
        // Validation des options de base
        if (values.appSubscriptionOptions.licenseFee < 0) {
          errors['appSubscriptionOptions.licenseFee'] = 'Le montant doit être positif'
        }

        if (
          values.appSubscriptionOptions.discountPercentage < 0 ||
          values.appSubscriptionOptions.discountPercentage > 100
        ) {
          errors['appSubscriptionOptions.discountPercentage'] =
            'Le pourcentage doit être entre 0 et 100'
        }

        // Validation des tiers
        const { tiers } = values.appSubscriptionOptions
        if (tiers.length === 0) {
          errors['appSubscriptionOptions.tiers'] = 'Au moins un palier est requis'
        } else {
          tiers.forEach((tier, index) => {
            // Validation du min
            if (tier.workersRange.min < 0) {
              errors[`appSubscriptionOptions.tiers.${index}.workersRange.min`] =
                'Le nombre minimum doit être positif'
            }

            // Validation de la continuité des tiers
            if (index > 0) {
              const previousTier = tiers[index - 1]
              if (tier.workersRange.min !== (previousTier?.workersRange.max || 0) + 1) {
                errors[`appSubscriptionOptions.tiers.${index}.workersRange.min`] =
                  'Le minimum doit être égal au maximum du palier précédent + 1'
              }
            }

            // Validation du max (sauf pour le dernier tier)
            if (index < tiers.length - 1) {
              if (!tier.workersRange.max) {
                errors[`appSubscriptionOptions.tiers.${index}.workersRange.max`] =
                  'Seul le dernier palier doit avoir une valeur maximum non définie'
              } else if (tier.workersRange.max <= tier.workersRange.min) {
                errors[`appSubscriptionOptions.tiers.${index}.workersRange.max`] =
                  'Le maximum doit être supérieur au minimum'
              }
            }
            // Validation du coût
            if (tier.workPeriodCost < 0) {
              errors[`appSubscriptionOptions.tiers.${index}.workPeriodCost`] =
                'Le coût doit être positif'
            }
            // Validation pour discountPercentage
            if (
              tier.discountPercentage &&
              (tier.discountPercentage < 0 || tier.discountPercentage > 100)
            ) {
              errors[`appSubscriptionOptions.tiers.${index}.discountPercentage`] =
                'Le pourcentage de remise doit être entre 0 et 100'
            }
          })
        }
        return errors
      }}
      onSubmit={values => {
        const tiers = values.appSubscriptionOptions.tiers!
        if (tiers.length > 0) {
          tiers[tiers.length - 1].workersRange.max = undefined
        }
        if (!organization) createMutation.mutate(values)
        else updateMutation.mutate({ id: organization._id as string, ...values })
      }}
    >
      {() => {
        return (
          <Form>
            <Columns>
              <Columns.Column size={12}>
                <Heading renderAs="h2" size={4}>
                  Données de l'agence
                </Heading>
                <Field label="Nom" name="name" component={InputField} required />
                <AddressesForm />

                <Protected roles={['superAdmin']}>
                  <Heading renderAs="h2" size={4} mt={5}>
                    Options d'abonnement
                  </Heading>
                  <Columns>
                    <Columns.Column size={6}>
                      <Field
                        label="Frais de licence de base"
                        name="appSubscriptionOptions.licenseFee"
                        help="Cout licence par semaine"
                        component={InputField}
                        type="number"
                        min={0}
                        required
                        step={0.01}
                      />
                    </Columns.Column>
                    <Columns.Column size={6}>
                      <Field
                        label="Pourcentage de remise"
                        name="appSubscriptionOptions.discountPercentage"
                        help="Remise en % appliquée sur le coût de licence de base"
                        component={InputField}
                        type="number"
                        min={0}
                        max={100}
                        required
                      />
                    </Columns.Column>
                  </Columns>
                  <Heading renderAs="h3" size={5} mt={4}>
                    Paliers coûts travailleurs / jour
                  </Heading>
                  <SubscriptionTiersForm />
                </Protected>
              </Columns.Column>
            </Columns>
            <Container>
              <Element mt={6}>
                <Element mb={1}>
                  <RequestMessage mutation={currentMutation} />
                </Element>
                <RequestButton color="primary" type="submit" mutation={currentMutation}>
                  {organization
                    ? "Mettre à jour les données de l'Agence"
                    : 'Créer une Nouvelle Agence'}
                </RequestButton>
              </Element>
            </Container>
          </Form>
        )
      }}
    </Formik>
  )
}

export default AgencyForm

const SubscriptionTiersForm: React.FC = () => {
  const { values } = useFormikContext<{
    appSubscriptionOptions: {
      tiers: Array<{
        workersRange: {
          min: number
          max: number
        }
        workPeriodCost: number
        discountPercentage: number
      }>
    }
  }>()

  return (
    <FieldArray
      name="appSubscriptionOptions.tiers"
      render={arrayHelpers => (
        <div>
          {values.appSubscriptionOptions.tiers.map((tier, index) => (
            <Columns key={index} vCentered>
              <Columns.Column>
                <Field
                  label="Travailleurs min"
                  name={`appSubscriptionOptions.tiers.${index}.workersRange.min`}
                  component={InputField}
                  type="number"
                  min={0}
                />
              </Columns.Column>
              <Columns.Column>
                <Field
                  label="Travailleurs max"
                  name={`appSubscriptionOptions.tiers.${index}.workersRange.max`}
                  component={InputField}
                  type="number"
                  min={0}
                />
              </Columns.Column>
              <Columns.Column>
                <Field
                  label="Prix par travailleur"
                  name={`appSubscriptionOptions.tiers.${index}.workPeriodCost`}
                  component={InputField}
                  type="number"
                  min={0}
                  step={0.0001}
                />
              </Columns.Column>
              <Columns.Column>
                <Field
                  label="Remise spécifique (%)"
                  name={`appSubscriptionOptions.tiers.${index}.discountPercentage`}
                  component={InputField}
                  type="number"
                  min={0}
                  max={100}
                />
              </Columns.Column>
              <Columns.Column narrow>
                <Button
                  onClick={() => arrayHelpers.remove(index)}
                  style={{ marginTop: '1.8rem' }}
                  size={'small'}
                  type="button"
                >
                  <Icon>
                    <TrashIcon />
                  </Icon>
                </Button>
              </Columns.Column>
            </Columns>
          ))}
          <Button
            outlined
            color="primary"
            size="small"
            type="button"
            onClick={() =>
              arrayHelpers.push({
                workersRange: {
                  min:
                    (values.appSubscriptionOptions.tiers[
                      values.appSubscriptionOptions.tiers.length - 1
                    ]?.workersRange.max || 0) + 1,
                  max: 0,
                },
                workPeriodCost: 0,
                discountPercentage: 0,
              })
            }
          >
            <Icon>
              <PlusIcon />
            </Icon>
            <span>Ajouter un palier</span>
          </Button>
        </div>
      )}
    />
  )
}
