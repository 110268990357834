import React, { FC, ReactNode } from 'react'
import { shallow } from 'zustand/shallow'
import { useIsAuthQuery } from '../queries/users'
import useRoute from '../hooks/use-route'
import NetworkErrorPage from '../pages/errors/network'
import useStore from '../store'
import useThemeSwitcher from '../hooks/use-theme-switcher'
import { Loader } from 'react-bulma-components'

const App: React.FunctionComponent = () => {
  // Get route by pathname
  const route = useRoute()
  return (
    <NetworkError>
      <CheckIsAuth>
        <ThemeSwitcher />
        {route}
      </CheckIsAuth>
    </NetworkError>
  )
}

export default App

const CheckIsAuth: FC<{ children: ReactNode }> = ({ children }): ReactNode => {
  const session = useStore(state => state.session)
  useIsAuthQuery()

  if (session.isInitialized) {
    return children
  } else {
    return <Loader />
  }
}

const ThemeSwitcher = () => {
  const currentUserRole = useStore(state => state.session.currentUserRole)
  useThemeSwitcher(currentUserRole)
  return null
}

const NetworkError: FC<{ children: ReactNode }> = ({ children }): ReactNode => {
  const [isNetworkOnError] = useStore(state => [state.session.isNetworkOnError], shallow)

  if (isNetworkOnError) return <NetworkErrorPage></NetworkErrorPage>
  return children
}
