import React from 'react'
import InputField from '../../form/fields/input'
import FieldColumn from '../field-column'
import { Field } from 'formik'

const WeekFilterField: React.FC = ({}) => {
  return (
    <FieldColumn>
      <Field label="Semaine" name="week" type="date" component={InputField} />
    </FieldColumn>
  )
}

export default WeekFilterField
