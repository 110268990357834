import { isEqual } from 'date-fns'
import { Field, Form, Formik } from 'formik'
import React from 'react'
import { Columns, Heading } from 'react-bulma-components'
import { Mission } from '../../api/missions'
import { WorkPeriod, WorkPeriodUpdatePayload, WorkPeriodWithSummary } from '../../api/work-periods'
import { useUpdateMutation } from '../../queries/work-periods'
import {
  dateToTimeInputValue,
  formatDuration,
  fromToTimeInputsValueToDates,
} from '../../utils/date'
import DescriptionList from '../description-list/description-list'
import InputField from '../form/fields/input'
import TextareaField from '../form/fields/textarea'
import Quote from '../quote/quote'
import { RequestButton, RequestMessage } from '../request-components/request-components'
import { isAuthorizedFor } from '../protected/protected'

interface WorkPeriodDatesFormProps {
  mission: Mission
  workPeriod: WorkPeriod
}

const WorkPeriodDatesForm: React.FC<WorkPeriodDatesFormProps> = ({ mission, workPeriod }) => {
  //Mutations
  const updateWorkPeriodMutation = useUpdateMutation()
  return (
    <Formik
      initialValues={{
        user: {
          ...mission.user,
          email: mission.user.appAccess === 'none' ? 'offline' : mission.user.email,
        },
        workPeriod: {
          start: {
            date: dateToTimeInputValue(workPeriod.start.date),
            clocking: dateToTimeInputValue(
              workPeriod.start.clocking && workPeriod.start.clocking.date,
            ),
            manualClocking: dateToTimeInputValue(
              workPeriod.start.manualClocking && workPeriod.start.manualClocking,
            ),
            comment: workPeriod.start.comments[workPeriod.start.comments.length - 1]?.message || '',
          },
          end: {
            date: dateToTimeInputValue(workPeriod.end.date),
            clocking: dateToTimeInputValue(workPeriod.end.clocking && workPeriod.end.clocking.date),
            manualClocking: dateToTimeInputValue(
              workPeriod.end.manualClocking && workPeriod.end.manualClocking,
            ),
            comment: workPeriod.end.comments[workPeriod.end.comments.length - 1]?.message || '',
          },
          manualBreak: workPeriod.manualBreak,
        },
      }}
      enableReinitialize
      validate={values => {
        const errors: any = {}
        if (!values.workPeriod.start.date) errors['workPeriod.start.date'] = 'Champ requis'
        else if (!values.workPeriod.end.date) errors['workPeriod.end.date'] = 'Champ requis'

        const [manualClockingStartDate, manualClockingEndDate] = fromToTimeInputsValueToDates(
          workPeriod.start.date,
          values.workPeriod.start.manualClocking,
          values.workPeriod.end.manualClocking,
        )

        if (
          //TODO: refactor manualClocking is updated check
          !isEqual(
            new Date(manualClockingStartDate || Date.now()),
            new Date(workPeriod.start.manualClocking),
          ) &&
          values.workPeriod.start.manualClocking &&
          !values.workPeriod.start.comment
        )
          errors['workPeriod.start.comment'] = 'Champ requis'

        if (
          !isEqual(
            new Date(manualClockingEndDate || Date.now()),
            new Date(workPeriod.end.manualClocking),
          ) &&
          values.workPeriod.end.manualClocking &&
          !values.workPeriod.end.comment
        )
          errors['workPeriod.end.comment'] = 'Champ requis'

        return errors
      }}
      onSubmit={values => {
        const [manualClockingStartDate, manualClockingEndDate] = fromToTimeInputsValueToDates(
          workPeriod.start.date,
          values.workPeriod.start.manualClocking,
          values.workPeriod.end.manualClocking,
        )

        const newStartDate: WorkPeriodUpdatePayload['start'] = { date: workPeriod.start.date }
        if (manualClockingStartDate !== undefined) {
          // manualClockingStartDate can be null when input field is empty ("")
          newStartDate.manualClocking = manualClockingStartDate
          newStartDate.comment = values.workPeriod.start.comment
        }
        const newEndDate: WorkPeriodUpdatePayload['end'] = { date: workPeriod.end.date }
        if (manualClockingEndDate !== undefined) {
          // manualClockingEndDate can be null when input field is empty ("")
          newEndDate.manualClocking = manualClockingEndDate
          newEndDate.comment = values.workPeriod.end.comment
        }
        const manualBreak =
          //@ts-expect-error when the field is cleared it's value is empty string ""
          values.workPeriod.manualBreak === '' ? null : values.workPeriod.manualBreak

        updateWorkPeriodMutation.mutate({
          missionId: mission._id,
          workPeriodId: workPeriod._id,
          start: newStartDate,
          end: newEndDate,
          manualBreak,
        })
      }}
    >
      {props => {
        return (
          <Form>
            <Columns>
              <Columns.Column size={4}>
                <WorkPeriodClockingTimeDetails
                  workPeriod={workPeriod}
                  clockingTime="start"
                  formManualClockingValue={props.values?.workPeriod.start.manualClocking}
                />
              </Columns.Column>
              <Columns.Column size={4}>
                <WorkPeriodClockingTimeDetails
                  workPeriod={workPeriod}
                  clockingTime="end"
                  formManualClockingValue={props.values?.workPeriod.end.manualClocking}
                />
              </Columns.Column>
              <Columns.Column size={4}>
                <WorkPeriodManualBreak workPeriod={workPeriod} mission={mission} />
              </Columns.Column>
              <Columns.Column>
                {props.dirty && workPeriod.__actions.canBeManualClocked && (
                  <>
                    <RequestButton
                      mutation={updateWorkPeriodMutation}
                      color="primary"
                      type="submit"
                      mr={3}
                      style={{ width: '100%' }}
                    >
                      Modifier la journée {props.status}
                    </RequestButton>
                    <RequestMessage mutation={updateWorkPeriodMutation} />
                  </>
                )}
              </Columns.Column>
            </Columns>
          </Form>
        )
      }}
    </Formik>
  )
}

interface WorkPeriodClockingTimeDetailsProps {
  workPeriod: WorkPeriod
  clockingTime: 'start' | 'end'
  formManualClockingValue?: any
}

const WorkPeriodClockingTimeDetails: React.FC<WorkPeriodClockingTimeDetailsProps> = ({
  workPeriod,
  clockingTime,
  formManualClockingValue,
}) => {
  const clockingData = workPeriod[clockingTime]
  const comment =
    clockingData &&
    clockingData.manualClocking &&
    clockingData.comments[clockingData.comments.length - 1]

  const inputFieldDisabled =
    !workPeriod.__actions.canBeManualClocked ||
    !isAuthorizedFor(['employerMember'], {
      resource: 'missions',
      name: 'updateWorkPeriodManualClocking',
    })

  return (
    <DescriptionList
      labelColumnWidth={'20%'}
      items={[
        {
          label: <Heading size={4}>{clockingTime === 'start' ? 'Début' : 'Fin'}</Heading>,
          value: ' ',
        },
        {
          label: 'Théorique',
          value: dateToTimeInputValue(clockingData.date),
        },
        {
          label: 'Pointé à',
          value: clockingData.clocking ? dateToTimeInputValue(clockingData.clocking.date) : '/',
        },
        {
          label: 'Corrigé',
          value: (
            <Field
              type="time"
              name={`workPeriod.${clockingTime}.manualClocking`}
              component={InputField}
              disabled={inputFieldDisabled}
            />
          ),
        },
        {
          label: 'Commentaire',
          value:
            // Show field when textarea field value is modified
            formManualClockingValue &&
            formManualClockingValue !== dateToTimeInputValue(clockingData.manualClocking) ? (
              <Field component={TextareaField} name={`workPeriod.${clockingTime}.comment`} />
            ) : comment ? (
              <Quote message={comment.message} sender={comment.user} />
            ) : (
              '/'
            ),
        },
      ]}
    />
  )
}

interface WorkPeriodManualBreakProps {
  workPeriod: WorkPeriodWithSummary
  mission: Mission
}

const WorkPeriodManualBreak: React.FC<WorkPeriodManualBreakProps> = ({ workPeriod, mission }) => {
  const { breakDuration: defaultBreakDuration, breakAllowedAfter } = workPeriod.clockingRules.break

  const inputFieldDisabled =
    !workPeriod.__actions.canBeManualClocked ||
    !isAuthorizedFor(['employerMember'], {
      resource: 'missions',
      name: 'updateWorkPeriodManualBreak',
    })

  return (
    <DescriptionList
      labelColumnWidth={'30%'}
      items={[
        {
          label: <Heading size={4}>Pause</Heading>,
          value: ' ',
        },
        {
          label: 'Théorique',
          value: `${formatDuration(workPeriod.summary.durations.theoreticalBreaks)}`,
        },
        {
          label: 'Après',
          value: formatDuration(breakAllowedAfter * 60 * 1000),
        },
        workPeriod.clockingRules.break.clocked && {
          label: 'Pointées',
          value: formatDuration(workPeriod.summary.durations.clockedBreaks),
        },
        workPeriod.clockingRules.break.clocked && {
          label: 'Comptabilisé',
          value: formatDuration(workPeriod.summary.durations.breaks),
        },
        {
          label: 'Corrigé',
          value: (
            <Field
              type="number"
              min={0}
              max={60}
              step={1}
              name={`workPeriod.manualBreak`}
              component={InputField}
              disabled={inputFieldDisabled}
            />
          ),
        },
      ].filter(v => v)}
    />
  )
}

export default WorkPeriodDatesForm
