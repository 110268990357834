import { FieldProps } from 'formik'
import React from 'react'
import QrReader from 'react-qr-reader-es6'
import FormField, { FormFieldProps } from './form-field'

interface QrScannerFieldProps extends FormFieldProps, FieldProps {
  facingMode?: 'environment' | 'user'
  setQrReaderError: React.Dispatch<React.SetStateAction<string | undefined>>
  hideVideo?: boolean
}

const QrScannerField: React.FunctionComponent<QrScannerFieldProps> = props => {
  const { field, form, label, facingMode, hideVideo, setQrReaderError } = props
  const [qrData, setQrData] = React.useState<string>()
  const [lastScan, setLastScan] = React.useState<number>(0)

  const handleChange = (value: string | null) => {
    if (value === null) return
    if (!qrData || qrData !== value || new Date().getTime() > lastScan + 5000) {
      setQrData(value)
      setLastScan(new Date().getTime())
      props.form.setFieldValue(field.name, value || '')
    }
  }

  return (
    <FormField label={label} error={form.errors[field.name] as string}>
      <QrReader
        delay={500}
        onError={errorData =>
          setQrReaderError('Error loading and enabling video device : ' + JSON.stringify(errorData))
        }
        onScan={handleChange}
        resolution={1200}
        facingMode={facingMode}
        style={hideVideo ? { display: 'none' } : {}}
      />
    </FormField>
  )
}

export default QrScannerField
