import { UserRoleKind } from '../../../backend/src/services/resources/users/user.model'
import { OrganizationTypeEnum } from '../api/organizations'

type RoleColorHandles = {
  [key in UserRoleKind | 'guest' | OrganizationTypeEnum]?: string
}
const roleColorHandles: RoleColorHandles = {
  superAdmin: 'super-admin',
  interimAgencyMember: 'interim-agency',
  interimAgency: 'interim-agency',
  worker: 'worker',
  employerMember: 'employer',
  employer: 'employer',
}

export const getRoleColorHandle = (
  role: keyof RoleColorHandles,
): RoleColorHandles[keyof RoleColorHandles] => {
  return roleColorHandles[role]
}
