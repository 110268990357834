import React from 'react'
import { Button, Container, Icon, Level, Section } from 'react-bulma-components'
import Layout from '../../components/layout/layout'
import {
  useCreateMutationKey,
  useJobTitleQuery,
  useUpdateMutationKey,
} from '../../queries/job-titles'
import useUrlId from '../../hooks/use-url-id'
import JobTitleForm from '../../components/job-titles/job-title-form'
import FormWrapper from '../../components/form/form-wrapper'
import { useFormWrapperPropsWithMutationData } from '../../hooks/use-form-wrapper-props-with-mutation-data'
import { useMutationState } from '../../queries'
import { ViewListIcon } from '@heroicons/react/outline'
import { Link } from 'react-router-dom'
import PageTitle from '../../components/pages/page-title'

const JobTitleDetails: React.FC = () => {
  const { id, isNew } = useUrlId()
  const jobTitleQuery = useJobTitleQuery(id)
  const mutationState = useMutationState(isNew ? useCreateMutationKey() : useUpdateMutationKey())
  const formWrapperProps = useFormWrapperPropsWithMutationData(
    formWrapperBaseProps,
    mutationState,
    !isNew,
  )

  const jobTitle = jobTitleQuery.data

  return (
    <Layout>
      <>
        <Section>
          <Container max={true} breakpoint="desktop">
            <Level alignItems="flex-start" mb={6}>
              <Level.Side>
                <PageTitle
                  title={isNew ? 'Nouvel Intitulé de Poste' : jobTitle?.name || ''}
                  subTitle={
                    isNew
                      ? 'Créer un nouvel intitulé de poste'
                      : `Modification de l\'intitulé de poste`
                  }
                />
              </Level.Side>
              <Level.Side>
                <Button renderAs={Link} to="/job-titles" outlined color="primary" size={'small'}>
                  <Icon>
                    <ViewListIcon />
                  </Icon>
                  <span>Liste des Intitulés de Poste</span>
                </Button>
              </Level.Side>
            </Level>
            <FormWrapper {...formWrapperProps}>
              <JobTitleForm jobTitle={jobTitleQuery.data} />
            </FormWrapper>
          </Container>
        </Section>
      </>
    </Layout>
  )
}

const formWrapperBaseProps = {
  loadingProps: { body: "Enregistrement de l'Intitulé de Poste..." },
  successProps: {
    title: 'Intitulé de Poste créé',
    body: {
      description: "L'Intitulé de Poste a été créé",
      actions: [
        {
          label: 'Nouvel Intitulé de Poste',
        },
        {
          label: 'Retourner à la liste',
          to: '/job-titles',
        },
      ],
    },
  },
}

export default JobTitleDetails
