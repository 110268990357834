import React from 'react'
import { Element, Form } from 'react-bulma-components'
import { Mission } from '../../../api/missions'
import { WorkPeriod } from '../../../api/work-periods'
import { useUpdateMutation } from '../../../queries/work-periods'
import { formatDayName, localDate } from '../../../utils/date'
import Avatar from '../../avatar/avatar'
import ConfirmationModal, {
  ConfirmationModalBaseProps,
} from '../../confirmation-modal/confirmation-modal'
import Protected from '../../protected/protected'

interface MissionCancelConfirmationProps extends ConfirmationModalBaseProps {
  mission: Mission
  workPeriod: WorkPeriod
}

const MissionCancelConfirmation: React.FC<MissionCancelConfirmationProps> = ({
  show,
  hide,
  mission,
  workPeriod,
}) => {
  const updateWorkPeriodMutation = useUpdateMutation()
  const [replaceWorkersRequestPost, setReplaceWorkersRequestPost] = React.useState(false)

  const onConfirm = async () => {
    await updateWorkPeriodMutation.mutateAsync({
      missionId: mission._id,
      workPeriodId: workPeriod._id,
      isCancelled: true,
      isReplaceable: replaceWorkersRequestPost,
    })
    hide()
  }

  const canBeReplaced = mission.workPeriods.some(w => w.__actions.canBeReplaced)

  return (
    <ConfirmationModal onConfirm={onConfirm} show={show} onCancel={hide}>
      <Element>
        Êtes vous certain de vouloir interrompre la mission de{' '}
        <strong>
          <Avatar user={mission.user} />
        </strong>{' '}
        ? <br />
        Les Journées de Travail à partir du {formatDayName(workPeriod.start.date)}&nbsp;
        {localDate(workPeriod.start.date)} seront annulées.
      </Element>
      {canBeReplaced && (
        <Protected roles={['employerMember']}>
          <Element mt={3}>
            <Element renderAs="hr" />
            <Element renderAs="ul">
              <Element renderAs="li">
                Au moins une Journée de Travail n'a pas été prestée et est remplaçable.
              </Element>
              <Element renderAs="li">
                Vous pouvez demander à l'Agence <b> {mission.interimAgency?.name}</b> un remplaçant.
              </Element>
            </Element>
            <Form.Checkbox
              mt={3}
              checked={replaceWorkersRequestPost}
              onChange={() => setReplaceWorkersRequestPost(!replaceWorkersRequestPost)}
              data-test="cancel-and-ask-for-a-replacement"
            >
              Demander un remplaçant
            </Form.Checkbox>
          </Element>
        </Protected>
      )}
    </ConfirmationModal>
  )
}

export default MissionCancelConfirmation
