import React from 'react'
import { Element, Icon } from 'react-bulma-components'
import { BreakRule } from '../../../../backend/src/services/resources/clocking-rules/clocking-rule.model'
import { User } from '../../api/users'
import Duration from '../missions/duration'
import { useWorkPeriodsDuration } from '../../hooks/use-work-period-duration'

interface WorkPeriodsDurationProps {
  workPeriods: {
    start?:
      | {
          date: Date
        }
      | undefined
    end?:
      | {
          date: Date
        }
      | undefined
  }[]
  breakRule?: BreakRule | undefined
  userId?: User['_id']
  period?: string // IsoWeek or IsoMonth
}

export const WorkPeriodsDuration: React.FC<WorkPeriodsDurationProps> = ({
  workPeriods,
  breakRule,
  userId,
  period,
}) => {
  const { total, expected } = useWorkPeriodsDuration(workPeriods, breakRule, userId, period)
  const durationMismatch = total && expected && total !== expected

  return (
    <>
      <Element
        renderAs="span"
        textColor={total === 0 ? 'grey' : durationMismatch ? 'danger' : undefined}
        data-tooltip={durationMismatch ? 'La durée ne respecte pas le contrat.' : undefined}
      >
        <Duration duration={total} />
      </Element>
      {expected && durationMismatch ? (
        <>
          &nbsp;/&nbsp;
          <Duration duration={expected} />
        </>
      ) : undefined}
    </>
  )
}
