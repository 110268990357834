import {
  Mutation,
  QueryClient,
  QueryKey,
  UseMutationResult as QRUseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { isEmpty, isEqual, xorWith } from 'lodash'
import React from 'react'
import { ApiErrorData, ApiResponse } from '../api'

export type QueryOptions<Data = any, ApiResData = Data> = Omit<
  UseQueryOptions<ApiResponse<ApiResData>, any, Data, any>,
  'queryKey' | 'queryFn' | 'initialData'
>

export type UseMutationResult<
  TData = unknown,
  TVariables = unknown,
  TError = AxiosError<ApiErrorData, any>,
  TContext = unknown,
> = QRUseMutationResult<ApiResponse<TData>, TError, TVariables, TContext>

// Query client
export const queryClient = new QueryClient({
  defaultOptions: {
    //TODO: mutations clear cache on Success (by first string in key)
    queries: {
      select(data): any {
        const d = data as AxiosResponse //TODO: try to improve types
        return d.data
      },
      retry(failureCount, error: any) {
        if (error?.isAxiosError) {
          const err: any = (error as AxiosError).toJSON()
          if (err.status >= 400)
            //TODO: improve codes to check
            return false
        }
        return failureCount <= 2
      },
    },
  },
})

// useQuery wrapper to get one resource doc
// Build the cache key like ['resourceName', '_ID']
// Is disabled when id is undefined
export const useResource = <T, P = any>(
  resourceName: string,
  apiFn: (id: string | any) => any,
  id?: string,
  params?: P,
  options?: QueryOptions<T>,
): UseQueryResult<T, any> =>
  useQuery<any, unknown, T, [string, string | undefined]>(
    [resourceName, id],
    async () => apiFn(params ? { id, ...params } : (id as string)),
    {
      enabled: Boolean(id),
      ...options,
    },
  )

// useQuery wrapper to fetch resource list docs
// Build the cache key like ['resourceName', { ...params } ]
export const useResources = <T, P = any, ApiResData = T>(
  resourceName: string,
  apiFn: (params: P) => any,
  params: P,
  options?: QueryOptions<T, ApiResData>,
): UseQueryResult<T, any> => {
  return useQuery<any, unknown, T, [string, any]>(
    [resourceName, params || {}],
    async () => apiFn(params),
    options,
  )
}

export type MutationState = Mutation<any, AxiosError<any, any>>['state']

// Get mutation cache state by key
export const useMutationState = (key: QueryKey): MutationState | undefined => {
  const [mutationState, setMutationState] = React.useState<MutationState>()
  queryClient.getMutationCache().subscribe(data => {
    if (data.mutation && isEmpty(xorWith(key, data.mutation?.options.mutationKey, isEqual)))
      setMutationState(data.mutation.state)
  })
  return mutationState
}
