import { orderBy } from 'lodash'

export const moveItemInArray = (arr: any[], fromIndex: number, toIndex: number): any[] => {
  const element = arr[fromIndex]
  arr.splice(fromIndex, 1)
  arr.splice(toIndex, 0, element)
  return arr
}

const enumToArrayOfStrings = (enumeration: { [key: string]: any }): string[] => {
  return Object.keys(enumeration).map((key: any) => enumeration[key])
}

export const sortByIgnoreCase = <T>(array: T[], key: keyof T): T[] =>
  orderBy(array, [item => (item[key] as string).toLowerCase()])
