import React from 'react'
import fileDownload from 'js-file-download'

// Trigger file browser file download when data exist & change

const useFileDownload = (fileName: string, data?: string): void => {
  React.useEffect(() => {
    data && fileDownload(data, fileName)
  }, [data])
}

export default useFileDownload
