import { upperFirst } from 'lodash'
import React from 'react'
import { formatCompleteDate, formatTime } from '../../utils/date'
import DescriptionList from '../description-list/description-list'

interface WorkPeriodsDatesDescriptionListProps {
  workPeriods: {
    start: { date: Date }
    end: { date: Date }
  }[]
}

const WorkPeriodsDatesDescriptionList: React.FC<WorkPeriodsDatesDescriptionListProps> = ({
  workPeriods,
}) => {
  return (
    <DescriptionList
      items={[
        ...workPeriods.map(workPeriod => ({
          label: upperFirst(formatCompleteDate(workPeriod.start.date)),
          value: `${formatTime(workPeriod.start.date)} - ${formatTime(workPeriod.end.date)}`,
        })),
      ]}
    />
  )
}

export default WorkPeriodsDatesDescriptionList
