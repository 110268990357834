import React, { ReactNode } from 'react'
import { useCalendarContext } from '../../../../hooks/use-calendar'
import { MonthDelimiter } from './month-delimiter'
import { Element } from 'react-bulma-components'

interface WeekProps<T, M> {
  week: number
  weekYear: number
  children?: ReactNode
}

export const Week = <T, M>({ week, weekYear, children }: WeekProps<T, M>): JSX.Element => {
  const { calendar } = useCalendarContext<T, M>()
  const calendarWeek = calendar[weekYear]![week]!

  return (
    <>
      <MonthDelimiter calendarWeek={calendarWeek} />
      <tr>{children}</tr>
    </>
  )
}

interface WeekHeaderProps<T, M> extends WeekProps<T, M> {
  left?: ReactNode | string
  right?: ReactNode | string
}

const Header = <T, M>({ week, weekYear, left, right, children }: WeekHeaderProps<T, M>) => {
  return (
    <td>
      <Element display="flex" justifyContent="space-between">
        <Element>
          {left ? (
            left
          ) : (
            <Element renderAs="span" textWeight="bold">
              S{week}
            </Element>
          )}
        </Element>
        <Element>{right}</Element>
      </Element>
      <Element>{children}</Element>
    </td>
  )
}
Week.Header = Header
