import React from 'react'
import { Button, Element, Icon } from 'react-bulma-components'
import { Size } from 'react-bulma-components/src/components'
import { Mission, MissionWithSummary } from '../../api/missions'
import ReactTooltip from 'react-tooltip'
import { WorkPeriodValidatedIcon } from '../icons'

interface MissionWeekValidateButtonProps {
  mission: MissionWithSummary
  label?: string
  size?: Size | 'smallest'
  type?: 'button' | 'submit' | 'reset'
  onClick(mission: Mission): void
}

const MissionWeekValidateButton: React.FunctionComponent<MissionWeekValidateButtonProps> = ({
  label,
  onClick,
  mission,
  size,
  type,
}) => {
  const { _id } = mission
  const tooltipId = `validate-mission-${_id}`

  const weekCanBeValidated = mission.__actions.weekCanBeValidated

  if (weekCanBeValidated) {
    return (
      <Element renderAs="span" data-tip={!weekCanBeValidated} data-for={tooltipId}>
        {!weekCanBeValidated && (
          <ReactTooltip id={tooltipId} effect="solid">
            <Element textAlign={'center'}>
              Validation impossible
              <br />
              Une journée avec pointage d'entrée
              <br />
              ne contient pas de pointage de sortie.
            </Element>
          </ReactTooltip>
        )}
        <Button
          style={size === 'smallest' ? { height: '1.5rem' } : {}}
          size={size === 'smallest' ? 'small' : size}
          type={type}
          color={weekCanBeValidated ? 'link' : ''}
          onClick={() => onClick(mission)}
          disabled={!weekCanBeValidated}
          data-test="validate-week"
        >
          <Icon>
            <WorkPeriodValidatedIcon />
          </Icon>
          {label && <span>{label}</span>}
        </Button>
      </Element>
    )
  } else {
    return <></>
  }
}

export default MissionWeekValidateButton
