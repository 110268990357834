import React from 'react'
import { Columns, Container, Level, Section, Tag } from 'react-bulma-components'
import Layout from '../../components/layout/layout'
import useUrlId from '../../hooks/use-url-id'
import { useMissionQuery } from '../../queries/missions'
import MissionMessages from '../../components/missions/mission-messages'
import Breadcrumbs from '../../components/breadcrumbs/breadcrumbs'
import MissionPageTitle from '../../components/missions/titles/mission-page-title'
import MissionForm from '../../components/missions/mission-form'

const MissionEdition: React.FunctionComponent = () => {
  const { id, isNew } = useUrlId()
  const { data: mission } = useMissionQuery(id)

  const breadcrumbs = useBreadcrumbs()

  return (
    <Layout>
      <Section>
        <Container max={true} breakpoint="desktop">
          <Level>
            <Level.Side>
              <Breadcrumbs items={breadcrumbs} />
            </Level.Side>
            <Level.Side align="right"></Level.Side>
          </Level>
          <Level alignItems="flex-start" mb={6}>
            <Level.Side>
              <MissionPageTitle mission={mission} organizationType={'employer'}></MissionPageTitle>
            </Level.Side>
            <Level.Side align="right">
              <Tag.Group>
                {!isNew && mission !== undefined && (
                  <MissionMessages mission={mission} type="tag" />
                )}
              </Tag.Group>
            </Level.Side>
          </Level>
          <Columns>
            <Columns.Column>
              <MissionForm mission={mission} />
            </Columns.Column>
          </Columns>
        </Container>
      </Section>
    </Layout>
  )
}

export default MissionEdition

const useBreadcrumbs = () => {
  const { id, isNew } = useUrlId()

  const baseCrumb = Object.freeze([
    {
      label: 'Liste des missions',
      to: '/missions',
    },
  ])

  if (isNew) {
    return [
      ...baseCrumb,
      {
        label: 'Nouvelle Mission Interne',
      },
    ]
  } else {
    return [
      ...baseCrumb,
      {
        label: `Mission`,
        to: `/missions/${id}`,
      },
      {
        label: `Modifier`,
      },
    ]
  }
}
