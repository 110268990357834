import { Field, Form, Formik } from 'formik'
import React, { useRef } from 'react'
import { User } from '../../api/users'
import _ from 'lodash'
import { Columns, Heading, Form as BulmaForm, Element } from 'react-bulma-components'
import InputField from '../form/fields/input'
import ComponentFooter from '../sections/component-footer'
import { RequestButton, RequestMessage } from '../request-components/request-components'
import { useUpdateEmailMutation } from '../../queries/users'

interface UpdateEmailProps {
  user: User
}

const UpdateEmail: React.FC<UpdateEmailProps> = ({ user }) => {
  const mutation = useUpdateEmailMutation()
  const newEmail = useRef('')

  const initialValues = { email: user.email }

  return (
    <Formik
      initialValues={initialValues}
      //TODO: validation?!
      onSubmit={values => {
        newEmail.current = values.email
        mutation.mutate(values.email)
      }}
    >
      {({ values }) => {
        const submitDisabled = _.isEqual(initialValues, values)
        return (
          <Form autoComplete="off">
            <Columns>
              <Columns.Column>
                <Heading renderAs="h2" size={4}>
                  Mettre à jour l'adresse email
                </Heading>
                <Columns>
                  <Columns.Column>
                    <Field
                      label="Adresse email"
                      name="email"
                      component={InputField}
                      type="email"
                      required
                    />
                  </Columns.Column>
                  <Columns.Column>
                    <BulmaForm.Field>
                      <BulmaForm.Label>&nbsp;</BulmaForm.Label>
                      <BulmaForm.Control>
                        <RequestButton
                          color="primary"
                          type="submit"
                          mutation={mutation}
                          disabled={submitDisabled}
                        >
                          {'Mettre à jour les Informations'}
                        </RequestButton>
                      </BulmaForm.Control>
                    </BulmaForm.Field>
                  </Columns.Column>
                  <Columns.Column>
                    <Element textWeight="bold">
                      <RequestMessage
                        mutation={mutation}
                        displayOn={{
                          success: `Votre demande a été envoyée, merci de vérifier votre boite mail à l\'adresse ${newEmail?.current}`,
                        }}
                      />
                    </Element>
                  </Columns.Column>
                </Columns>
              </Columns.Column>
            </Columns>
            <ComponentFooter>
              <ComponentFooter.Left></ComponentFooter.Left>
              <ComponentFooter.Right></ComponentFooter.Right>
            </ComponentFooter>
          </Form>
        )
      }}
    </Formik>
  )
}
export default UpdateEmail
