import React from 'react'
import { Button, Icon } from 'react-bulma-components'
import { EditIcon } from '../../icons'
import { useModal } from '../../modal'
import UpdateUserModal from '../modals/update-user'
import { User, UserRoleKind } from '../../../api/users'

interface EditUserButtonProps {
  user: User
  userRoleKind: UserRoleKind
}

const EditUserButton: React.FC<EditUserButtonProps> = ({ user, userRoleKind }) => {
  const modal = useModal()
  return (
    <>
      <Button
        size="small"
        mr={'2'}
        onClick={() => {
          modal.setIsDisplayed(true)
        }}
        data-test="edit-worker-btn"
      >
        <Icon>
          <EditIcon />
        </Icon>
      </Button>
      <UpdateUserModal user={user} modalActions={modal} userRole={userRoleKind} />
    </>
  )
}

export default EditUserButton
