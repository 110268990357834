import { CheckCircleIcon, DotsHorizontalIcon, ExclamationCircleIcon } from '@heroicons/react/solid'
import { MutationStatus } from '@tanstack/react-query'
import React, { useState } from 'react'
import { Button, Element, Icon } from 'react-bulma-components'
import { Color } from 'react-bulma-components/src/components'
import { UseMutationResult } from '../../queries'

const defaultDelay = 5000

const getRequestColor = (status?: MutationStatus): Color => {
  let color = 'info'
  if (status === 'error') color = 'danger'
  else if (status === 'success') color = 'success'
  return color
}

interface RequestButtonProps {
  mutation?: UseMutationResult<any, any, any>
  children: React.ReactNode
  delay?: number
  [key: string]: any
}

//TODO: Move to new file
export const RequestButton = ({
  mutation,
  children,
  delay,
  ...rest
}: RequestButtonProps): JSX.Element => {
  return (
    <Button {...rest}>
      <span>{children}</span>
      <RequestIcon mutation={mutation} delay={delay} />
    </Button>
  )
}

const useRequestComponent = (
  delay: number,
  mutation?: UseMutationResult<any, any, any>,
): MutationStatus => {
  const [displayRequestStatus, setDisplayRequestStatus] = useState<MutationStatus>('idle')
  React.useEffect(() => {
    if (mutation) {
      let timeout: ReturnType<typeof setTimeout>
      if (delay !== 0) {
        setDisplayRequestStatus(mutation.status)
        timeout = setTimeout(() => {
          setDisplayRequestStatus('idle')
        }, delay)
      }
      return () => {
        if (timeout) {
          clearTimeout(timeout)
        }
      }
    }
  }, [mutation?.status])

  return displayRequestStatus
}

const RequestIcon = ({
  mutation,
  delay = defaultDelay,
}: {
  mutation?: UseMutationResult<any, any, any>
  delay?: number
}): any => {
  const status = useRequestComponent(delay, mutation)
  const color = React.useMemo(() => getRequestColor(status), [status])
  if (status === 'idle') return <></>

  return (
    <Icon color={color}>
      {status === 'error' && <ExclamationCircleIcon />}
      {status === 'success' && <CheckCircleIcon />}
      {status === 'loading' && <DotsHorizontalIcon />}
    </Icon>
  )
}

interface DisplayRequestResultOn {
  error?: string | boolean
  success?: string | boolean
  inProgress?: string | boolean
}

interface RequestMessageProps {
  mutation?: UseMutationResult<any, any, any>
  delay?: number
  displayOn?: DisplayRequestResultOn
}

export const RequestMessage: React.FC<RequestMessageProps> = ({
  mutation,
  delay = defaultDelay,
  displayOn,
}) => {
  const displayOnDefaultConfig = { error: true, success: false, inProgress: false }
  const on = Object.assign(displayOnDefaultConfig, displayOn)
  const status = useRequestComponent(delay, mutation)
  const color = React.useMemo(() => getRequestColor(status), [status])
  if (!status) return <></>
  const isError = status === 'error' && on.error
  const isInProgress = status === 'loading' && on.inProgress
  const isSuccess = status === 'success' && on.success
  const displayMessage = isError || isInProgress || isSuccess
  const errorMessage = isError && on.error && (
    <p>
      {mutation?.error?.response?.data?.message}
      {mutation?.error?.response?.data?.data?.length > 0 && (
        <ul>
          {mutation?.error?.response?.data?.data?.map(errorDetail => (
            <li>{errorDetail?.message}</li>
          ))}
        </ul>
      )}
    </p>
  )

  if (displayMessage)
    return (
      <Element renderAs="span" textColor={color as string}>
        {isError && (typeof isError === 'string' ? isError : errorMessage)}
        {isInProgress && (typeof isInProgress === 'string' ? isInProgress : 'Envoi en cours...')}
        {isSuccess &&
          (typeof isSuccess === 'string' ? isSuccess : 'Opération réalisée avec succès')}
      </Element>
    )
  return <></>
}

export const RequestIconWithMessage = ({
  mutation,
  delay = defaultDelay,
  displayOn,
}: {
  mutation?: UseMutationResult<any, any, any>
  delay?: number
  displayOn?: DisplayRequestResultOn
}): any => {
  const status = useRequestComponent(delay, mutation)
  if (status === 'idle' || !mutation) return <></>

  return (
    <Icon.Text>
      <RequestIcon mutation={mutation} />
      <RequestMessage mutation={mutation} displayOn={displayOn} />
    </Icon.Text>
  )
}
