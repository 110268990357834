import { QueryKey, useMutation, UseQueryResult } from '@tanstack/react-query'
import { queryClient, QueryOptions, UseMutationResult, useResource, useResources } from '.'
import {
  fetch,
  get,
  create,
  AppSubscriptionPeriod,
  AppSubscriptionPeriodCreationPayload,
  GetByPeriodParams,
  getByPeriod,
  getAllPeriods,
  AppSubscriptionPeriodsInterimAgencyTotal,
  calculatePeriodTotals,
  CalculatePeriodTotalsParams,
  AppSubscriptionPeriodsInterimAgencyPeriod,
} from '../api/app-subscription-periods'
import { getDocIdAsString } from '../utils/documents'

// Queries
export const useAppSubscriptionPeriodQuery = (
  id?: string,
  options?: QueryOptions,
): UseQueryResult<AppSubscriptionPeriod, any> =>
  useResource<AppSubscriptionPeriod>('app-subscription-periods', get, id, undefined, options)

export const useAppSubscriptionPeriodsQuery = (
  filters?: any,
  options?: QueryOptions,
): UseQueryResult<AppSubscriptionPeriod[], any> =>
  useResources<AppSubscriptionPeriod[], any>('app-subscription-periods', fetch, filters, options)

export const useAppSubscriptionPeriodByPeriodQuery = <T = AppSubscriptionPeriod>(
  params?: GetByPeriodParams,
  options?: QueryOptions,
): UseQueryResult<T, any> =>
  useResource<T>('app-subscription-periods', getByPeriod, undefined, params, options)

export const useAppSubscriptionPeriodTotalsQuery = (
  params?: CalculatePeriodTotalsParams,
  options?: QueryOptions,
): UseQueryResult<AppSubscriptionPeriodsInterimAgencyTotal, any> =>
  useResource<AppSubscriptionPeriodsInterimAgencyTotal>(
    'app-subscription-periods-totals',
    calculatePeriodTotals,
    undefined,
    params,
    {
      ...options,
      enabled: !!params?.interimAgencyId,
    },
  )

/**
 * Hook pour récupérer toutes les périodes d'abonnement d'une agence d'intérim
 */
export const useAppSubscriptionPeriodsByAgencyQuery = (
  interimAgencyId?: string,
  options?: QueryOptions,
): UseQueryResult<AppSubscriptionPeriodsInterimAgencyPeriod[], any> =>
  useResource<AppSubscriptionPeriodsInterimAgencyPeriod[]>(
    `app-subscription-periods-all-periods`,
    getAllPeriods,
    interimAgencyId,
    undefined,
    {
      ...options,
      enabled: !!interimAgencyId,
    },
  )

// Mutations
const onMutationSuccess = () => {
  queryClient.invalidateQueries(['app-subscription-periods'])
}

export const useCreateAppSubscriptionPeriodMutationKey = (): QueryKey => [
  'app-subscription-periods',
  'create',
]
export const useCreateAppSubscriptionPeriodMutation = (): UseMutationResult<
  AppSubscriptionPeriod,
  AppSubscriptionPeriodCreationPayload,
  any
> =>
  useMutation(create, {
    mutationKey: useCreateAppSubscriptionPeriodMutationKey(),
    onSuccess: onMutationSuccess,
  })

export const useCreateAppSubscriptionPeriodMutationFromTotal = (
  appSubscriptionPeriodsInterimAgencyTotal: AppSubscriptionPeriodsInterimAgencyTotal,
): (() => Promise<AppSubscriptionPeriod[]>) => {
  const mutation = useCreateAppSubscriptionPeriodMutation()
  const create = async () => {
    const res = await Promise.all(
      appSubscriptionPeriodsInterimAgencyTotal.employerDetails.map(
        async employer =>
          (
            await mutation.mutateAsync({
              employer: getDocIdAsString(employer.employer),
              periodNumber: appSubscriptionPeriodsInterimAgencyTotal.period.periodNumber,
              periodYear: appSubscriptionPeriodsInterimAgencyTotal.period.year,
              interimAgency: getDocIdAsString(
                appSubscriptionPeriodsInterimAgencyTotal.interimAgency,
              ),
            })
          ).data,
      ),
    )

    queryClient.invalidateQueries(['app-subscription-periods-totals'])
    return res
  }

  return create
}
