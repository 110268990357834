import React, { ReactNode } from 'react'
import { Element, Heading } from 'react-bulma-components'

interface PageTitleProps {
  title?: ReactNode
  subTitle?: ReactNode
  children?: ReactNode
}

const PageTitle: React.FC<PageTitleProps> = ({ title, subTitle, children }) => {
  return (
    <Element>
      {title && (
        <>
          <Heading size={3} textWeight="bold">
            {title}
          </Heading>
          <Heading subtitle size={6}>
            {subTitle}
          </Heading>
        </>
      )}
      {children && <Element mt={2}>{children}</Element>}
    </Element>
  )
}

export default PageTitle
