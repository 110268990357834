import React from 'react'
import { Columns, Container, Element, Heading, Section } from 'react-bulma-components'
import Layout from '../../components/layout/layout'
import PageHeading from '../../components/sections/page-heading'
import ComponentHeader from '../../components/sections/component-header'
import UpdateEmail from '../../components/users/update-email'
import UpdateCommonInformations from '../../components/users/update-common-informations'
import UpdatePassword from '../../components/users/update-password'
import useStore from '../../store'
import UserQrCode from '../../components/users/user-qr-code'
import { useUserQuery } from '../../queries/users'
import Avatar from '../../components/avatar/avatar'
import { DeleteMyAccount } from '../../components/users/delete-my-account'

const UserDetails: React.FC = () => {
  const user = useStore(state => state.session.currentUser)

  const userQuery = useUserQuery(user?._id)
  if (!user || !userQuery.data) return <></>

  return (
    <Layout>
      <>
        <PageHeading title={<Avatar user={user} />} subtitle={`Détail pour l\'Utilisateur`} />
        <Section>
          <Container>
            <ComponentHeader>
              <ComponentHeader.Left></ComponentHeader.Left>
              <ComponentHeader.Right></ComponentHeader.Right>
            </ComponentHeader>
            <Columns>
              <Columns.Column>
                <UpdateCommonInformations user={userQuery.data} />
                <UpdateEmail user={userQuery.data} />
                <UpdatePassword user={userQuery.data} />
                <Heading renderAs="h2" size={4}>
                  Mon QR Code
                </Heading>
                <UserQrCode user={user} canRegenerate print />
                <Element textAlign={'center'} backgroundColor="danger-light" p={3} py={5} mt={6}>
                  <DeleteMyAccount user={user} />
                </Element>
              </Columns.Column>
            </Columns>
          </Container>
        </Section>
      </>
    </Layout>
  )
}

export default UserDetails
