import React from 'react'
import { Columns, Element, Icon, Section } from 'react-bulma-components'
import { useSearchParams } from 'react-router-dom'
import FormWrapper, { FormWrapperProps } from '../../components/form/form-wrapper'
import Layout from '../../components/layout/layout'
import PageHeading from '../../components/sections/page-heading'
import ResetPasswordForm from '../../components/users/reset-password-form'
import { useFormWrapperPropsWithMutationData } from '../../hooks/use-form-wrapper-props-with-mutation-data'
import { useMutationState } from '../../queries'
import { useResetPasswordMutationKey } from '../../queries/users'
import { CheckIcon, WarningIcon } from '../../components/icons'
import { useSearchParamsToken } from '../../hooks/use-search-params-token'

const UserResetPassword: React.FC = ({}) => {
  const mutationState = useMutationState(useResetPasswordMutationKey())
  const [searchParams] = useSearchParams()
  const isMobileRequest = Boolean(searchParams.get('isMobileRequest'))
  const formWrapperProps = useFormWrapperPropsWithMutationData(
    formWrapperBaseProps(isMobileRequest),
    mutationState,
  )

  const { token, decoded } = useSearchParamsToken()

  return (
    <Layout>
      <Section>
        <Columns centered>
          <Columns.Column size={6} textAlign="center">
            <PageHeading title="Modifier votre mot de passe" />
          </Columns.Column>
        </Columns>
        <Columns centered>
          <Columns.Column size={4}>
            {!decoded.data.isCompleted ? (
              <>
                <Element textAlign={'center'}>
                  <Icon color="warning" size={'large'}>
                    <WarningIcon color="warning" />
                  </Icon>
                </Element>
                <Element renderAs="p" mb={5} textAlign={'center'}>
                  Votre compte n'a pas encore été complété et validé. <br />
                  Un email vous a été envoyé avec un lien vers le formulaire de validation de
                  compte.
                </Element>
              </>
            ) : (
              <>
                <Element textAlign={'center'}>
                  <Icon color="success" size={'large'}>
                    <CheckIcon color="success" />
                  </Icon>
                </Element>
                <Element renderAs="p" mb={5} textAlign={'center'}>
                  Un email vous a été envoyé avec un code vérification. <br />
                  Merci d'utiliser ce code ici. 👇
                </Element>
                <FormWrapper {...formWrapperProps}>
                  {isMobileRequest && (
                    <Element mb={3}>
                      Vous avez fait une demande de nouveau mot de passe depuis l'application
                      mobile. Entrez ici votre nouveau mot de passe.
                    </Element>
                  )}
                  <ResetPasswordForm token={token} />
                </FormWrapper>
              </>
            )}
          </Columns.Column>
        </Columns>
      </Section>
    </Layout>
  )
}

export default UserResetPassword

const formWrapperBaseProps = (isMobileRequest: boolean): FormWrapperProps => ({
  loadingProps: { body: 'Vérification en cours...' },
  successProps: {
    title: 'Mot de passe modifié',
    body: {
      description: isMobileRequest
        ? "Vous pouvez retourner dans l'application mobile et utiliser votre nouveau mot de passe pour vous connecter."
        : 'Vous pouvez dès à présent utiliser votre nouveau mot de passe pour vous connecter.',
      actions: !isMobileRequest ? [{ label: 'Se connecter', to: '/login' }] : [],
    },
  },
  errorProps: {
    title: 'Une erreur est survenue',
    message:
      "Le code de vérification n'existe pas, a expiré ou est incorrect. Veuillez consulter vos emails pour obtenir le code ou recommencer la procédure.",
  },
})
