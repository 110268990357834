import { Form, Formik } from 'formik'
import React from 'react'
import { User } from '../../api/users'
import { Columns, Heading, Form as BulmaForm, Element } from 'react-bulma-components'
import ComponentFooter from '../sections/component-footer'
import { RequestButton, RequestMessage } from '../request-components/request-components'
import {
  isPasswordValid,
  PasswordField,
  PasswordFieldWithValidation,
} from '../form/fields/password'
import { useUpdateMutation } from '../../queries/users'
import { useNavigate } from 'react-router-dom'
import useStore from '../../store'

interface UpdatePasswordProps {
  user: User
}

const UpdatePassword: React.FC<UpdatePasswordProps> = ({ user }) => {
  const mutation = useUpdateMutation()
  const navigate = useNavigate()
  const logout = useStore(state => state.session.logout)
  
  if (mutation.isSuccess) {
    logout()
    navigate('/login', { state: { message: { color: 'success', header: 'Mot de passe mis à jour', body: 'Vouss pouvez maintenant vous reconnecter avec votre nouveau mot de passe' } } })
  }

  return (
    <Formik
      initialValues={{
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      }}
      validate={values => {
        const errors: any = {}
        if (!values.currentPassword) errors.currentPassword = 'Champ requis'
        if (!values.newPassword) errors.newPassword = 'Champ requis'
        if (!values.confirmNewPassword) errors.confirmNewPassword = 'Champ requis'
        if (values.newPassword !== values.confirmNewPassword)
          errors.confirmNewPassword = 'Les deux mots de passe doivent être similaires'
        return errors
      }}
      onSubmit={values => {
        const { currentPassword: oldPassword, newPassword: password } = values

        mutation.mutate({ oldPassword, password, id: user._id })
      }}
    >
      {({ values }) => {
        const submitDisabled =
          values.currentPassword.length === 0 ||
          values.newPassword.length === 0 ||
          values.confirmNewPassword.length === 0 ||
          isPasswordValid(values.newPassword) ||
          isPasswordValid(values.confirmNewPassword)
        return (
          <Form autoComplete="off">
            <Columns>
              <Columns.Column>
                <Heading renderAs="h2" size={4}>
                  Changer le mot de passe
                </Heading>
                <Columns>
                  <Columns.Column>
                    <PasswordField label="Ancien mot de passe" name="currentPassword" />
                  </Columns.Column>
                  <Columns.Column>
                    <Element mb={5}>
                      <PasswordFieldWithValidation
                        label="Nouveau mot de passe"
                        name="newPassword"
                      />
                    </Element>
                    <PasswordFieldWithValidation
                      label="Confirmez le nouveau mot de passe"
                      name="confirmNewPassword"
                    />
                  </Columns.Column>
                  <Columns.Column>
                    <BulmaForm.Field>
                      <BulmaForm.Label>
                        &nbsp;
                        <RequestMessage
                          mutation={mutation}
                          displayOn={{
                            error: 'Ancien mot de passe incorrect',
                          }}
                        />
                      </BulmaForm.Label>
                      <BulmaForm.Control>
                        <RequestButton
                          color="primary"
                          type="submit"
                          mutation={mutation}
                          disabled={submitDisabled}
                        >
                          {'Mettre à jour les Informations'}
                        </RequestButton>
                      </BulmaForm.Control>
                    </BulmaForm.Field>
                  </Columns.Column>
                </Columns>
              </Columns.Column>
            </Columns>
            <ComponentFooter>
              <ComponentFooter.Left></ComponentFooter.Left>
              <ComponentFooter.Right></ComponentFooter.Right>
            </ComponentFooter>
          </Form>
        )
      }}
    </Formik>
  )
}
export default UpdatePassword
