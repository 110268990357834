import { ResourceRef, ResourceRefOrSchema } from '../types/common.types'

export const compareDocsById = (doc: any, docToCompare: any): boolean => {
  const docId = doc?._id ? doc?._id.toString() : doc?.toString()
  const docIdToCompare = docToCompare?._id ? docToCompare?._id.toString() : docToCompare?.toString()
  return docId === docIdToCompare
}

export const getDocIdAsString = (
  docOrId: ResourceRef | ResourceRefOrSchema<{ _id: ResourceRef }>,
): string => {
  if (typeof docOrId === 'string') return docOrId
  if ('_id' in docOrId && docOrId._id) return docOrId._id.toString()
  return docOrId.toString()
}

export const getEmbeddedDocById = <T = any>(
  embeddedDocs: T[],
  docOrId: ResourceRef | ResourceRefOrSchema<{ _id: ResourceRef }>,
): T | undefined => embeddedDocs.find(doc => compareDocsById(doc, docOrId))
