import { round } from 'lodash'

export const formatCost = (cost: number | undefined): string =>
  (cost ? Math.round(cost * 100) / 100 : '0') + '€'

/**
 * Formate un nombre en devise avec 4 décimales
 * @param value - Montant à formater
 * @param currency - Symbole de la devise (par défaut '€')
 * @returns Chaîne formatée (ex: "123.4567 €")
 */
export const formatPreciseCost = (value: number | undefined, currency = '€'): string => {
  if (value === undefined) return `0${currency}`
  return `${round(value, 4)}${currency}`
}
