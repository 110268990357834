import { Clocking } from '../api/clockings'

enum ClockingStatusEnum {
  noMission = 'Pas de Mission',
  entry = 'Entrée',
  exit = 'Sortie',
  workPeriodDeleted = 'Journée supprimée',
  replaced = 'Intermédiaire',
}

export const getStatus = (clocking: Clocking): ClockingStatusEnum => {
  if (!clocking.mission || !clocking.workPeriod || !clocking.type)
    return ClockingStatusEnum.noMission
  const workPeriod = clocking.workPeriod
  return workPeriod
    ? workPeriod[clocking.type].clocking?.item === clocking._id
      ? clocking.type === 'start'
        ? // Work period exist and clocking was registered as start
          ClockingStatusEnum.entry
        : // Work period exist and clocking was registered as end
          ClockingStatusEnum.exit
      : // Work period exist but clocking no longer used
        ClockingStatusEnum.replaced
    : // Work period has been deleted
      ClockingStatusEnum.workPeriodDeleted
}
