import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { ApiConfig, get } from '../api/config'

//Queries
export const useConfigQuery = (): UseQueryResult<ApiConfig, any> =>
  useQuery<any, unknown, ApiConfig, [string, string | undefined]>(
    ['app-config', 'get'],
    async () => get(),
    { staleTime: 60 * 1000 * 10 },
  )
