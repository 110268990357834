import React from 'react'
import {
  Button,
  Card,
  Columns,
  Container,
  Element,
  Heading,
  Level,
  Section,
  Tag,
} from 'react-bulma-components'
import FormWrapper from '../../components/form/form-wrapper'
import Layout from '../../components/layout/layout'
import { useFormWrapperPropsWithMutationData } from '../../hooks/use-form-wrapper-props-with-mutation-data'
import useUrlId from '../../hooks/use-url-id'
import { useMutationState } from '../../queries'
import { useCreateMutationKey, useWorkersRequestQuery } from '../../queries/workers-requests'
import { localDate } from '../../utils/date'
import Breadcrumbs from '../../components/breadcrumbs/breadcrumbs'
import WorkersRequestsMessages from '../../components/workers-requests/workers-requests-messages'
import useStore from '../../store'
import WorkersRequestMissionListItem from '../../components/workers-requests/posts-list-item'
import WorkersRequestPageTitle, {
  EditActions,
} from '../../components/workers-requests/titles/workers-request-page-title'
import { sortBy } from 'lodash'
import locales from '../../../../locales/workers-requests'
import History from '../../components/history/history'
import WorkersRequestHistoryItemCustomContent from '../../components/workers-requests/history/history-item-custom-content'
import WorkPeriodsDatesDescriptionList from '../../components/work-periods/work-periods-dates-description-list'

const WorkersRequestsDetails: React.FC = ({}) => {
  const currentUserRole = useStore(state => state.session.currentUserRole)
  const mutationState = useMutationState(useCreateMutationKey())
  const { id, isNew } = useUrlId()
  const { data: workersRequest } = useWorkersRequestQuery(id)
  const formWrapperProps = useFormWrapperPropsWithMutationData(
    formWrapperBaseProps,
    mutationState,
    !isNew,
  )

  const breadcrumbs = useBreadcrumbs()

  const historyItems = React.useMemo(() => {
    return sortBy(workersRequest?.history || [], 'createdAt').reverse()
  }, [workersRequest])

  if (!workersRequest) return <></>

  return (
    <Layout>
      <Section>
        <FormWrapper {...formWrapperProps}>
          <Container breakpoint="desktop" max={true}>
            <Level>
              <Level.Side>
                <Breadcrumbs items={breadcrumbs} />
              </Level.Side>
              <Level.Side align="right">
                {!isNew && <EditActions workersRequest={workersRequest} />}
              </Level.Side>
            </Level>
            <Level alignItems="flex-start" mb={6}>
              <Level.Side>
                <WorkersRequestPageTitle
                  workersRequest={workersRequest}
                  organizationType={
                    currentUserRole === 'interimAgencyMember' ? 'employer' : 'interimAgency'
                  }
                />
              </Level.Side>
              <Level.Side align="right">
                <Tag.Group>
                  <WorkersRequestsMessages workersRequest={workersRequest} type="tag" />
                </Tag.Group>
              </Level.Side>
            </Level>
            <Container>
              <Columns>
                <Columns.Column mb={5}>
                  <Heading
                    size={5}
                    onClick={() => setShowDetails(!showDetails)}
                    style={{ cursor: 'pointer' }}
                  >
                    {`Du ${localDate(workersRequest.missionsStart)} au ${localDate(
                      workersRequest.missionsEnd,
                    )}`}
                  </Heading>
                  <Card mt={5} mb={6}>
                    <Card.Content>
                      <p>
                        <Element mb={2} textWeight="bold">
                          Adresse
                        </Element>
                        {workersRequest.missionData.address}
                      </p>
                      <br />
                      <b>Horaire</b>
                      <WorkPeriodsDatesDescriptionList
                        workPeriods={workersRequest.missionData.workPeriods}
                      />
                    </Card.Content>
                  </Card>
                </Columns.Column>

                <Columns.Column>
                  <Heading size={5}>
                    Postes demandés ({workersRequest.stats.assigned}&nbsp;/&nbsp;
                    {workersRequest.stats.assigned + workersRequest.stats.notAssigned})
                  </Heading>
                  <Element>
                    {workersRequest.posts.map(post => (
                      <Element key={post._id} mt={5}>
                        <WorkersRequestMissionListItem
                          post={post}
                          workersRequest={workersRequest}
                        />
                      </Element>
                    ))}
                  </Element>
                </Columns.Column>
              </Columns>
            </Container>
            <Columns mb={5}></Columns>
          </Container>
          <Container breakpoint="desktop" max={true} pt={5}>
            <Heading size={5} pt={5}>
              Historique
            </Heading>
            <History
              items={historyItems}
              locales={locales.history}
              renderCustomContent={item => (
                <WorkersRequestHistoryItemCustomContent
                  key={item._id}
                  historyItem={historyItems.find(i => i._id === item._id)}
                />
              )}
            />
          </Container>
        </FormWrapper>
      </Section>
    </Layout>
  )
}

export default WorkersRequestsDetails

const formWrapperBaseProps = {
  loadingProps: { body: 'Enregistrement de la Demande...' },
  successProps: {
    title: 'Demande créée',
    body: {
      description: "La Demande a été créée, l'Agence d'Intérim a été notifiée.",
      actions: [
        {
          label: 'Nouvelle Demande',
        },
        {
          label: 'Retourner à la liste',
          to: '/workers-requests',
        },
      ],
    },
  },
}

const useBreadcrumbs = () =>
  React.useMemo(
    () => [
      {
        label: 'Demandes de Personnel',
        to: '/workers-requests',
      },
      {
        label: `Demande de Personnel`,
      },
    ],
    [],
  )
