import React from 'react'
import { User } from '../../../api/users'
import Modal, { ModalActions } from '../../modal'
import { Element } from 'react-bulma-components'
import UserQrCode from '../user-qr-code'

interface UserQrCodeModalProps {
  user: User
  modalActions: ModalActions
}

const UserQrCodeModal: React.FC<UserQrCodeModalProps> = ({ user, modalActions }) => {
  return (
    <Modal title={`QR Code de  ${user?.firstName} ${user?.lastName}`} actions={modalActions}>
      <Element textAlign="center">
        {user?._id && <UserQrCode user={user} print canRegenerate />}
      </Element>
    </Modal>
  )
}

export default UserQrCodeModal
