import React from 'react'
import { Field, Form, Formik } from 'formik'
import { Container, Element } from 'react-bulma-components'
import { useCreateMutation, useUpdateMutation } from '../../queries/organizations'
import {
  InterimAgency,
  OrganizationCreationPayload,
  OrganizationTypeEnum,
} from '../../api/organizations'
import InputField from '../form/fields/input'
import { RequestButton, RequestMessage } from '../request-components/request-components'
import { AddressesForm } from './addresses-form'
import { useOutletContext } from 'react-router-dom'

interface AgencyInfosFormProps {
  interimAgency?: InterimAgency
}

const AgencyInfosForm: React.FC<AgencyInfosFormProps> = ({ interimAgency }) => {
  const createMutation = useCreateMutation()
  const updateMutation = useUpdateMutation()
  const currentMutation = interimAgency ? updateMutation : createMutation

  return (
    <Formik
      initialValues={{
        name: interimAgency?.name || '',
        addresses: interimAgency?.addresses ?? [],
      }}
      enableReinitialize
      validate={values => {
        const errors: any = {}
        if (!values.name) errors.name = 'Champ requis'
        if (values.addresses.length === 0)
          errors.addresses = 'Au moins une adresse doit être renseignée'
        return errors
      }}
      onSubmit={values => {
        const payload: OrganizationCreationPayload = {
          ...values,
          type: OrganizationTypeEnum.interimAgency,
        }
        if (!interimAgency) createMutation.mutate(payload)
        else
          updateMutation.mutate({
            id: interimAgency._id as string,
            ...payload,
          })
      }}
    >
      <Form>
        <Field label="Nom" name="name" component={InputField} required />
        <AddressesForm />

        <Container>
          <Element mt={6}>
            <Element mb={1}>
              <RequestMessage mutation={currentMutation} />
            </Element>
            <RequestButton color="primary" type="submit" mutation={currentMutation}>
              {interimAgency
                ? "Mettre à jour les informations de l'Agence"
                : 'Créer une Nouvelle Agence'}
            </RequestButton>
          </Element>
        </Container>
      </Form>
    </Formik>
  )
}

export default AgencyInfosForm
