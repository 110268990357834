import React from 'react'
import { Element, Icon } from 'react-bulma-components'
import { Link } from 'react-router-dom'
import { ArrowLeftIcon, ArrowRightIcon } from '../icons'

type PageNavigationProps<ResourceType extends { _id: string }> = {
  items: [ResourceType | undefined, ResourceType | undefined]
  linkBuilder: (item: ResourceType) => string
  labelBuilder: (item: ResourceType) => string
}

export const PageNavigation = <ResourceType extends { _id: string }>({
  items: [previous, next],
  linkBuilder,
  labelBuilder,
}: PageNavigationProps<ResourceType>): JSX.Element => {
  const previousLink = previous ? linkBuilder(previous) : undefined
  const nextLink = next ? linkBuilder(next) : undefined
  const previousLabel = previous ? labelBuilder(previous) : undefined
  const nextLabel = next ? labelBuilder(next) : undefined

  return (
    <Element display="flex" justifyContent="space-between">
      {previousLink ? (
        // previous is defined, why the hell could previousLink be undefined ?
        <Link to={previousLink}>
          <Icon.Text flexWrap="nowrap">
            <Icon>
              <ArrowLeftIcon />
            </Icon>
            &nbsp;
            {previousLabel}
          </Icon.Text>
        </Link>
      ) : (
        // Placeholder if no previous but next !
        <Element />
      )}
      {previousLink && nextLink && <>&bull;</>}
      {nextLink && (
        // next is defined, why the hell could nextLink be undefined ?
        <Link to={nextLink}>
          <Icon.Text flexWrap="nowrap">
            {nextLabel}
            &nbsp;
            <Icon>
              <ArrowRightIcon />
            </Icon>
          </Icon.Text>
        </Link>
      )}
    </Element>
  )
}
