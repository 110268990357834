import {
  differenceInDays,
  eachDayOfInterval,
  formatISO,
  getDate,
  getDay,
  getMonth,
  getWeek,
  getWeekYear,
  getYear,
  isBefore,
  isEqual,
  isFriday,
  isMonday,
  isSaturday,
  isSunday,
  isThursday,
  isTuesday,
  isWednesday,
  parseISO,
} from 'date-fns'
import { once, setWith } from 'lodash'
import React, { ReactElement, ReactNode, useContext, useMemo, useRef } from 'react'
import { formatISODate } from '../utils/date'
import { Tagged } from 'type-fest'

export type IsoDate = Tagged<string, 'ISODate'>
export type IsoTime = string
type IsoFullDate = string

type CalendarYearValue = number
type CalendarWeekYearValue = number

export type Calendar<T = null, M = null> = {
  calendar: CalendarYears<T, M>
  startDate: string
  endDate: string
  getDay: (isoDate: string) => CalendarDay<T, M> | null
  getWeek: (isoWeek: string) => CalendarWeek<T, M> | null
  getWeekDays: (isoWeekDay: 0 | 1 | 2 | 3 | 4 | 5 | 6) => NonNullable<CalendarDay<T, M>>[]
}
type CalendarYears<T, M> = Record<CalendarYearValue, CalendarWeeks<T, M>>
type CalendarWeeks<T, M> = Record<CalendarWeekYearValue, CalendarWeek<T, M>>
export type CalendarWeek<T, M> = [
  CalendarDay<T, M>,
  CalendarDay<T, M>,
  CalendarDay<T, M>,
  CalendarDay<T, M>,
  CalendarDay<T, M>,
  CalendarDay<T, M>,
  CalendarDay<T, M>,
]
export type CalendarDay<T, M> = {
  weekDay: 0 | 1 | 2 | 3 | 4 | 5 | 6
  week: number
  weekYear: number
  month: number
  year: number
  isoDate: string
  isoTime: string
  isoFullDate: string
  day: number
  data: T | null
  metadata: M | null
} | null

const getCalendarInitialState = <T, M>(): Calendar<T, M> => ({
  calendar: {},
  startDate: '',
  endDate: '',
  getDay: () => null,
  getWeek: () => null,
  getWeekDays: () => [],
})

const createCalendarContext = once(<T, M>() =>
  React.createContext<Calendar<T, M>>(getCalendarInitialState()),
)

const context = getCalendarInitialState()

export const useCalendarContext = <T, M>(): Calendar<T, M> =>
  useContext(createCalendarContext<T, M>())

export const CalendarProvider = <T, M>({
  children,
  startDateISO,
  endDateISO,
  select,
  metadata,
  data,
}: {
  children: ReactNode
  startDateISO: string
  endDateISO: string
  select: {
    day: (data: T[], isoDate: string) => T | null
  }
  metadata: {
    day: (data: T, isoDate: string) => M | null
  }
  data: T[]
}): React.ReactElement => {
  const StateContext = createCalendarContext<T, M>()
  const lastCalendar = useRef<Calendar<T, M>>()

  const getCalendarDay = (isoDate: string): CalendarDay<T, M> => {
    const date = new Date(isoDate)
    return (
      fullCalendar.calendar?.[getWeekYear(date)]?.[getWeek(date)]?.find(
        d => d?.isoDate === isoDate,
      ) ?? null
    )
  }

  const getCalendarWeek = (isoDate: string): CalendarWeek<T, M> | null => {
    const date = new Date(isoDate)
    return fullCalendar.calendar?.[getWeekYear(date)]?.[getWeek(date)] ?? null
  }

  const getCalendarWeekDays = (
    isoWeekDay: 0 | 1 | 2 | 3 | 4 | 5 | 6,
  ): NonNullable<CalendarDay<T, M>>[] => {
    const days = eachDayOfInterval({
      start: new Date(startDateISO),
      end: new Date(endDateISO),
    })
      .filter(isWeekDayMethods[isoWeekDay])
      .map(day => formatISODate(day))
      .map(isoDate => getCalendarDay(isoDate))
      .filter((d): d is NonNullable<CalendarDay<T, M>> => !!d)
    return days
  }

  const fullCalendar = useMemo<Calendar<T, M>>(() => {
    try {
      const startDate = parseISO(startDateISO)
      const endDate = parseISO(endDateISO)
      if (
        (isBefore(startDate, endDate) || isEqual(startDate, endDate)) &&
        differenceInDays(endDate, startDate) <= 400
      ) {
        const calendar = {
          calendar: getWeeksListCalendar<T, M>(startDateISO, endDateISO, data, select, metadata),
          startDate: startDateISO,
          endDate: endDateISO,
          getDay: getCalendarDay,
          getWeek: getCalendarWeek,
          getWeekDays: getCalendarWeekDays,
        }
        lastCalendar.current = calendar
        return calendar
      } else {
        return lastCalendar.current
      }
    } catch (err) {
      console.error(err)
    }
    return lastCalendar.current
  }, [startDateISO, endDateISO, data, select, metadata])

  return <StateContext.Provider value={fullCalendar}>{children}</StateContext.Provider>
}

export const UseCalendar = <T, M>({
  children,
}: {
  children: (calendar: Calendar<T, M>) => ReactElement<Calendar<T, M>>
}): ReactElement<Calendar<T, M>> => {
  const calendar = useCalendarContext<T, M>()

  return children(calendar)
}

/**
 * Generates a calendar on a date given interval
 * @param {string} startDate - date format YYYY-mm-dd, lower than or equal to @endDate
 * @param {string} endDate - date format YYYY--mm-dd, higher than or equal to @endDate
 * @returns {{year: {week: Day}}} - an object keyed by year / week / CalendarDay
 */
const getWeeksListCalendar = <T, M>(
  startDateISO: string,
  endDateISO: string,
  data: T[],
  select: {
    day: (data: T[], isoDate: string) => T | null
  },
  metadata: {
    day: (data: T, isoDate: string) => M | null
  },
): Calendar<T, M>['calendar'] => {
  if (!startDateISO) return {}
  if (!endDateISO) return {}
  try {
    // eachDayOfInterval will fail with a RangeError if endDate < startDate
    // form validation prevents this too
    const startDate = parseISO(startDateISO)
    const endDate = parseISO(endDateISO)

    const years = eachDayOfInterval({ start: startDate, end: endDate })
      .map(date => {
        const isoDate = formatISODate(date)
        const dayData = select.day(data, formatISODate(date))
        const calendarDay: CalendarDay<T, M> = {
          weekDay: getDay(date),
          week: getWeek(date),
          weekYear: getWeekYear(date),
          month: getMonth(date),
          year: getYear(date),
          isoDate: formatISODate(date),
          isoTime: formatISO(date, { representation: 'time' }),
          isoFullDate: formatISO(date),
          day: getDate(date),
          data: dayData,
          metadata: dayData ? metadata.day(dayData, isoDate) : null,
        }
        return calendarDay
      })
      .reduce<Calendar<T, M>['calendar']>((years, day) => {
        setWith(
          years,
          [day.weekYear, day.week],
          [...(years?.[day.weekYear]?.[day.week] ?? []), day],
          Object,
        )
        return years
      }, {})

    Object.entries(years).map(([yearNumber, weeks]) =>
      Object.entries(weeks).map(([weekNumber, week]) => {
        if (week.length < 7 && week[0]) {
          const firstWeekDay = week[0].weekDay
          const lastWeekDay = (week[week.length - 1] ?? week[0]).weekDay
          // If firstWeekDay is sunday (=== 0) we convert it to 7 to handle counting back to 1
          for (let weekDay = firstWeekDay === 0 ? 7 : firstWeekDay; weekDay !== 1; weekDay--) {
            week.unshift(null)
          }
          if (lastWeekDay !== 0) {
            for (let weekDay = lastWeekDay; weekDay <= 6; weekDay++) {
              week.push(null)
            }
          }
        }
      }),
    )

    return years
  } catch (error) {
    console.error(error)
    return {}
  }
}

const isWeekDayMethods = [
  isSunday,
  isMonday,
  isTuesday,
  isWednesday,
  isThursday,
  isFriday,
  isSaturday,
] as const
