import React from 'react'
import Layout from '../../components/layout/layout'
import FormWrapper from '../../components/form/form-wrapper'
import { Button, Container, Form, Icon, Level, Section } from 'react-bulma-components'
import { ViewListIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'
import Protected from '../../components/protected/protected'
import useUrlId from '../../hooks/use-url-id'
import { useMutationState } from '../../queries'
import {
  useCreateMutationKey,
  useOneClockingRulesQuery,
  useUpdateMutationKey,
} from '../../queries/clocking-rules'
import { useFormWrapperPropsWithMutationData } from '../../hooks/use-form-wrapper-props-with-mutation-data'
import ClockingRulesForm from '../../components/clocking-rules/clocking-rules-form'
import PageTitle from '../../components/pages/page-title'

const ClockingRulesDetails: React.FunctionComponent = () => {
  const { id, isNew } = useUrlId({ idName: 'clockingRulesId' })
  const mutationState = useMutationState(isNew ? useCreateMutationKey() : useUpdateMutationKey())
  const clockingRules = useOneClockingRulesQuery(id)
  const formWrapperProps = useFormWrapperPropsWithMutationData(
    formWrapperBaseProps,
    mutationState,
    !isNew,
  )

  return (
    <Layout>
      <Section>
        <Container max={true} breakpoint="desktop">
          <Level alignItems="flex-start" mb={6}>
            <Level.Side>
              <PageTitle
                title={isNew ? 'Nouvelles Règles de Pointage' : clockingRules?.data?.name}
                subTitle={
                  isNew
                    ? 'Créer des Nouvelles Règles de Pointage'
                    : 'Modification des Règles de Pointage existantes'
                }
              />
            </Level.Side>
            <Level.Side>
              <Protected roles={['employerMember']}>
                <Button
                  renderAs={Link}
                  to="/clocking-rules"
                  outlined
                  color="primary"
                  size={'small'}
                >
                  <Icon>
                    <ViewListIcon />
                  </Icon>
                  <span>Liste des Règles de Pointage</span>
                </Button>
              </Protected>
            </Level.Side>
          </Level>
          <FormWrapper {...formWrapperProps}>
            <ClockingRulesForm clockingRules={clockingRules.data} />
          </FormWrapper>
        </Container>
      </Section>
    </Layout>
  )
}

export default ClockingRulesDetails

const formWrapperBaseProps = {
  loadingProps: { body: 'Enregistrement des Règles de Pointage...' },
  successProps: {
    title: 'Règles de Pointages créées',
    body: {
      description: 'Les Règles de Pointage ont été créées',
      actions: [
        {
          label: 'Nouvelles Règles de Pointage',
        },
        {
          label: 'Retourner à la liste',
          to: '/clocking-rules',
        },
      ],
    },
  },
}
