import React from 'react'
import { Organization } from '../../api/organizations'
import { organizationTypeThemeNames } from '../../hooks/use-theme-switcher'
import { useOrganizationsQuery } from '../../queries/organizations'
import { DashboardCard, DashboardCardProps } from './dashboard-card'

const labels: { [key in Organization['type']]: Pick<DashboardCardProps, 'title' | 'mainStat'> } = {
  employer: {
    title: 'EMPLOYEURS',
    mainStat: {
      label: 'Employeurs',
      value: 0,
    },
  },
  interimAgency: {
    title: "AGENCES D'INTÉRIM",
    mainStat: {
      label: 'Agences',
      value: 0,
    },
  },
}

export const OrganizationsDashBoardCard: React.FC<{ type: Organization['type'] }> = ({ type }) => {
  const organizationsQuery = useOrganizationsQuery()
  const organizationsOfType = organizationsQuery.data?.filter(o => o.type === type).length ?? 0
  return (
    <>
      <DashboardCard
        title={labels[type].title}
        mainStat={{ value: organizationsOfType, label: labels[type].mainStat.label }}
        color={organizationTypeThemeNames[type]}
        queryStatus={organizationsQuery.status}
      />
    </>
  )
}
