import React from 'react'
import { WorkPeriod } from '../../api/work-periods'
import { useWorkPeriodEventsQuery } from '../../queries/work-period-events'
import { Field, FieldArray, Formik } from 'formik'
import { Button, Columns, Element, Icon } from 'react-bulma-components'
import SelectField from '../form/fields/select'
import { buildListAsInputOptions } from '../../utils/forms'
import InputField from '../form/fields/input'
import { isEqual } from 'lodash'
import { RemoveIcon } from '../icons'
import { useUpdateMutation } from '../../queries/work-periods'
import { Mission } from '../../api/missions'
import { RequestButton } from '../request-components/request-components'
import WorkPeriodEventModalForm from '../work-period-events/work-period-event-modal-form'
import { buildWorkPeriodEventLabel } from '../../utils/work-period-events'

interface WorkPeriodEventsFormProps {
  workPeriod: WorkPeriod
  mission: Mission
}

const WorkPeriodEventsForm: React.FC<WorkPeriodEventsFormProps> = ({ workPeriod, mission }) => {
  const workPeriodEventsQuery = useWorkPeriodEventsQuery()
  const updateWorkPeriodMutation = useUpdateMutation()
  const [showWorkPeriodEventForm, setShowWorkPeriodEventForm] = React.useState(false)

  const initialEvents = (workPeriod.events || []).map(e => ({ ...e, item: e.item._id }))

  return (
    <>
      <WorkPeriodEventModalForm
        setIsDisplayed={setShowWorkPeriodEventForm}
        isDisplayed={showWorkPeriodEventForm}
      />
      <Formik
        initialValues={{
          events: initialEvents,
        }}
        enableReinitialize
        validate={values => {
          const errors: any = {}

          return errors
        }}
        onSubmit={values => {
          updateWorkPeriodMutation.mutate({
            workPeriodId: workPeriod._id,
            missionId: mission._id,
            events: values.events,
          })
        }}
      >
        {({ values, submitForm }) => {
          const isModified = !isEqual(
            values.events.filter((event: any) => event.item),
            initialEvents,
          )
          return (
            <>
              <FieldArray
                name="events"
                render={arrayHelpers => (
                  <Element>
                    {values.events?.[0] ? (
                      values.events.map((event, index) => (
                        <Columns key={index}>
                          <Columns.Column mb={3} size={4}>
                            <Field
                              name={`events.${index}.item`}
                              label="Évènement"
                              component={SelectField}
                              options={buildListAsInputOptions(workPeriodEventsQuery.data || [], {
                                labelBuilder: buildWorkPeriodEventLabel,
                                sortBy: 'code',
                              })}
                              help={
                                !values.events?.[index]?.item && (
                                  <Element
                                    renderAs="a"
                                    onClick={() => setShowWorkPeriodEventForm(true)}
                                  >
                                    Créer un nouvel évènement
                                  </Element>
                                )
                              }
                            />
                          </Columns.Column>
                          <Columns.Column size={7}>
                            <Field
                              name={`events.${index}.comment`}
                              component={InputField}
                              label="Commentaire"
                            />
                          </Columns.Column>
                          <Columns.Column key={index} mb={3} size={1} pt={5} textAlign={'center'}>
                            <Button
                              mt={5}
                              size={'small'}
                              onClick={arrayHelpers.handleRemove(index)}
                            >
                              <Icon>
                                <RemoveIcon />
                              </Icon>
                            </Button>
                          </Columns.Column>
                        </Columns>
                      ))
                    ) : (
                      <Element mb={3}>Aucun évènement lié à la Journée de Travail</Element>
                    )}
                    <Button
                      type="button"
                      onClick={() => arrayHelpers.push({ item: undefined, comment: '' })}
                      mr={2}
                    >
                      Ajouter un évènement
                    </Button>
                    <RequestButton
                      type="button"
                      color={'primary'}
                      onClick={() => submitForm()}
                      mutation={
                        updateWorkPeriodMutation?.variables?.events && updateWorkPeriodMutation
                      }
                      disabled={!isModified}
                    >
                      Enregistrer
                    </RequestButton>
                  </Element>
                )}
              />
            </>
          )
        }}
      </Formik>
    </>
  )
}

export default WorkPeriodEventsForm
