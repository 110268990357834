/**
 * Parse une chaîne de caractères au format "YYYY_WW" en objet contenant l'année et la semaine
 * @param periodString - Chaîne au format "YYYY_WW" (ex: "2024_12")
 * @returns {Object} Objet contenant year et week
 */
export const parsePeriodString = (
  periodString: string,
): { periodYear: number; periodNumber: number } => {
  const [yearStr, weekStr] = periodString.split('_')

  if (!yearStr || !weekStr) {
    throw new Error('Format de période invalide. Attendu: "YYYY_WW"')
  }

  const periodYear = parseInt(yearStr, 10)
  const periodNumber = parseInt(weekStr, 10)

  if (isNaN(periodYear) || isNaN(periodNumber)) {
    throw new Error('Format de période invalide. Attendu: "YYYY_WW"')
  }

  return {
    periodYear,
    periodNumber,
  }
}

/**
 * Crée une chaîne de caractères au format "YYYY_WW" à partir d'une année et d'une semaine
 * @param periodYear - Année
 * @param periodNumber - Numéro de semaine
 * @returns {string} Chaîne au format "YYYY_WW"
 */
export const formatPeriodString = (periodYear: number, periodNumber: number): string => {
  return `${periodYear}_${periodNumber.toString().padStart(2, '0')}`
}
