import React from 'react'
import { Container, Loader, Section } from 'react-bulma-components'
import FormWrapper from '../../components/form/form-wrapper'
import Layout from '../../components/layout/layout'
import PageHeading from '../../components/sections/page-heading'
import { useValidateNewEmailMutation } from '../../queries/users'
import { useSearchParamsToken } from '../../hooks/use-search-params-token'
import useStore from '../../store'
import { useFormWrapperPropsWithMutationData } from '../../hooks/use-form-wrapper-props-with-mutation-data'

const UserValidateNewEmail: React.FC = ({}) => {
  const { token } = useSearchParamsToken()
  const mutation = useValidateNewEmailMutation()
  const currentUser = useStore(state => state.session.currentUser)
  const formWrapperProps = useFormWrapperPropsWithMutationData(
    {
      loadingProps: { body: 'Vérification en cours...' },
      successProps: {
        title: 'Adresse email modifiée',
        body: {
          description: "Votre ancienne adresse email n'est plus utilisable sur la plateforme.",
          actions: [
            {
              label: 'Ok',
              to: currentUser ? '/' : '/login',
            },
          ],
        },
      },
    },
    mutation,
  )

  React.useEffect(() => {
    token && mutation.mutate(token)
  }, [])

  return (
    <Layout>
      <PageHeading title="Validation de votre nouvelle adresse email" />
      <Section>
        <Container>
          <FormWrapper {...formWrapperProps}>
            <Loader />
          </FormWrapper>
        </Container>
      </Section>
    </Layout>
  )
}

export default UserValidateNewEmail
