import React, { ReactNode } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import useStore from '../store'

const Guest: React.FC<{ children: ReactNode }> = ({ children }) => {
  const isAuthenticated = useStore(state => state.session.isAuthenticated)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const redirect = searchParams.get('redirect')

  // Listen session data to check if current user can access to current route
  React.useEffect(() => {
    //Redirect to / if current user is authenticated
    if (isAuthenticated) {
      if (redirect) {
        navigate(redirect)
      } else {
        navigate('/')
      }
    }
  }, [isAuthenticated])

  return <>{children}</>
}

export default Guest
