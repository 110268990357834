import { ReactNode, ReactElement } from 'react'

interface ConditionalWrapperProps {
  condition: boolean
  wrapper: (children: ReactNode) => ReactElement
  children: ReactElement
}

export const ConditionalWrapper: React.FC<ConditionalWrapperProps> = ({
  condition,
  wrapper,
  children,
}) => (condition ? wrapper(children) : children)
