import React from 'react'
import WorkPeriodEventModalForm from '../work-period-events/work-period-event-modal-form'
import { Field, Formik } from 'formik'
import { Mission } from '../../api/missions'
import { dateInputToDateValue, dateToDateInputValue } from '../../utils/date'
import { getWorkPeriodsInInterval } from '../../utils/missions'
import { useUpdateManyMutation } from '../../queries/work-periods'
import { RequestButton } from '../request-components/request-components'
import { Columns, Element } from 'react-bulma-components'
import InputField from '../form/fields/input'
import SelectField from '../form/fields/select'
import { buildListAsInputOptions } from '../../utils/forms'
import { useWorkPeriodEventsQuery } from '../../queries/work-period-events'
import { buildWorkPeriodEventLabel } from '../../utils/work-period-events'
import { WorkPeriod } from '../../api/work-periods'
import { getWorkPeriodsWithEvent } from '../../utils/work-periods'
import CheckboxField from '../form/fields/checkbox'

interface WorkPeriodDateToDateEventFormProps {
  defaultFromDate: Date
  mission: Mission
}

const WorkPeriodDateToDateEventForm: React.FC<WorkPeriodDateToDateEventFormProps> = ({
  defaultFromDate,
  mission,
}) => {
  const updateWorkPeriodsMutation = useUpdateManyMutation()
  const workPeriodEventsQuery = useWorkPeriodEventsQuery()
  const [showWorkPeriodEventForm, setShowWorkPeriodEventForm] = React.useState(false)
  return (
    <>
      <WorkPeriodEventModalForm
        setIsDisplayed={setShowWorkPeriodEventForm}
        isDisplayed={showWorkPeriodEventForm}
      />
      <Formik
        initialValues={{
          from: dateToDateInputValue(defaultFromDate),
          to: '',
          event: {},
          addDuplicate: false,
        }}
        enableReinitialize
        validate={values => {
          const errors: any = {}

          if (!values.from) errors.from = 'Champ requis'
          if (!values.to) errors.to = 'Champ requis'

          if (!values.event?.item) errors['event.item'] = 'Champs requis'

          return errors
        }}
        onSubmit={values => {
          const from = dateInputToDateValue(values.from)
          const to = dateInputToDateValue(values.to)
          const workPeriodsInInterval = getWorkPeriodsInInterval(mission, from, to)
          const workPeriodsToUpdate = values.addDuplicate
            ? workPeriodsInInterval
            : workPeriodsInInterval.filter(
                w => !w.events.some(e => e.item._id === values.event.item),
              )
          updateWorkPeriodsMutation.mutate({
            missionId: mission._id,
            workPeriodsIds: workPeriodsToUpdate.map(w => w._id),
            events: [values.event],
          })
        }}
      >
        {({ values, submitForm }) => {
          const workPeriodsInInterval =
            values.from &&
            values.to &&
            getWorkPeriodsInInterval(
              mission,
              dateInputToDateValue(values.from),
              dateInputToDateValue(values.to),
            )

          return (
            <>
              <Columns mb={1}>
                <Columns.Column>
                  <Field
                    name={`from`}
                    component={InputField}
                    min={dateToDateInputValue(new Date(mission.start))}
                    max={dateToDateInputValue(new Date(values.to || mission.end))}
                    label="Du"
                    type="date"
                  />
                </Columns.Column>
                <Columns.Column>
                  <Field
                    name={`to`}
                    component={InputField}
                    min={dateToDateInputValue(new Date(values.from || mission.start))}
                    max={dateToDateInputValue(new Date(mission.end))}
                    label="Au"
                    type="date"
                  />
                </Columns.Column>
              </Columns>
              <Columns>
                <Columns.Column mb={3} size={5}>
                  <Field
                    name={`event.item`}
                    label="Évènement"
                    component={SelectField}
                    options={buildListAsInputOptions(workPeriodEventsQuery.data || [], {
                      labelBuilder: buildWorkPeriodEventLabel,
                      sortBy: 'code',
                    })}
                    help={
                      !values.event?.item && (
                        <Element renderAs="a" onClick={() => setShowWorkPeriodEventForm(true)}>
                          Créer un nouvel évènement
                        </Element>
                      )
                    }
                  />
                </Columns.Column>
                <Columns.Column size={7}>
                  <Field name={`event.comment`} component={InputField} label="Commentaire" />
                </Columns.Column>
              </Columns>
              {workPeriodsInInterval && values.event?.item && (
                <Columns>
                  <Columns.Column>
                    <WorkPeriodsHasAlreadyEventWarn
                      workPeriods={workPeriodsInInterval}
                      eventId={values.event?.item}
                    />
                  </Columns.Column>
                </Columns>
              )}
              <Columns>
                <Columns.Column>
                  <RequestButton
                    type="button"
                    color={'primary'}
                    onClick={() => submitForm()}
                    mutation={updateWorkPeriodsMutation}
                  >
                    Enregistrer
                  </RequestButton>
                </Columns.Column>
              </Columns>
            </>
          )
        }}
      </Formik>
    </>
  )
}

export default WorkPeriodDateToDateEventForm

const WorkPeriodsHasAlreadyEventWarn: React.FC<{
  workPeriods: WorkPeriod[]
  eventId: string
}> = ({ workPeriods, eventId }) => {
  const workPeriodsWithEvent = getWorkPeriodsWithEvent(workPeriods, eventId)
  if (!workPeriodsWithEvent[0]) return ''

  return (
    <Field
      name={`addDuplicate`}
      component={CheckboxField}
      text={"Oui, je veux dupliquer l'énènement"}
      label={`Cet évènement est déjà attribué à ${workPeriodsWithEvent.length} journée(s) de travail dans la
        plage sélectionnée. Voulez-vous dupliquer l'èvènement pour ces journée(s) de travail?`}
    />
  )
}
