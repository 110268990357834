import React from 'react'
import useStore from '../../store'
import { OrganizationTypeEnum } from '../../api/organizations'

interface WorkerLabelProps {
  plural?: boolean
  potentialPlural?: boolean
}

const WorkerLabel: React.FC<WorkerLabelProps> = ({ plural, potentialPlural }) => {
  const currentOrganization = useStore(state => state.session.currentOrganization)
  let label =
    currentOrganization?.type === OrganizationTypeEnum.interimAgency ? 'Intérimaire' : 'Travailleur'
  if (plural) label += 's'
  else if (potentialPlural) label += '(s)'

  return <>{label}</>
}

export default WorkerLabel
