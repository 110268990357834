import React from 'react'
import { Icon } from 'react-bulma-components'
import { ClipboardCheckIcon } from '@heroicons/react/outline'
import { ClipboardBaseButton, IClipboardBaseButton } from './clipboard-base-button'

type IPasteButton = IClipboardBaseButton

export const PasteButton: IPasteButton = props => {
  return (
    <ClipboardBaseButton data-tooltip="Coller" {...props}>
      <Icon color="primary">
        <ClipboardCheckIcon />
      </Icon>
    </ClipboardBaseButton>
  )
}
