import React from 'react'
import { Field, FieldArray, Form, Formik, useFormikContext } from 'formik'
import { Button, Columns, Container, Element, Icon } from 'react-bulma-components'
import { useUpdateMutation } from '../../queries/organizations'
import { InterimAgency } from '../../api/organizations'
import {
  RequestButton,
  RequestMessage,
} from '../../components/request-components/request-components'
import InputField from '../../components/form/fields/input'
import { PlusIcon } from '@heroicons/react/outline'
import { TrashIcon } from '@heroicons/react/outline'
import { useOutletContext } from 'react-router-dom'
import { isNumber } from 'lodash'

interface AgencySubscriptionFormProps {
  interimAgency?: InterimAgency
}

const AgencySubscriptionForm: React.FC<AgencySubscriptionFormProps> = ({ interimAgency }) => {
  const updateMutation = useUpdateMutation()

  return (
    <Formik
      initialValues={{
        appSubscriptionOptions: interimAgency?.appSubscriptionOptions || {
          licenseFee: 0,
          discountPercentage: 0,
          tiers: [
            {
              workersRange: {
                min: 1,
                max: null,
              },
              workPeriodCost: 10,
            },
          ],
        },
      }}
      enableReinitialize
      validate={values => {
        const errors: any = {}

        if (values.appSubscriptionOptions.licenseFee < 0) {
          errors.appSubscriptionOptions = { licenseFee: 'Le montant doit être positif' }
        }
        if (
          values.appSubscriptionOptions.discountPercentage < 0 ||
          values.appSubscriptionOptions.discountPercentage > 100
        ) {
          errors.appSubscriptionOptions = {
            ...errors.appSubscriptionOptions,
            discountPercentage: 'Le pourcentage doit être entre 0 et 100',
          }
        }

        // Validation des tiers
        const { tiers } = values.appSubscriptionOptions
        if (tiers.length === 0) {
          errors['appSubscriptionOptions.tiers'] = 'Au moins un palier est requis'
        } else {
          tiers.forEach((tier, index) => {
            // Validation du min
            if (tier.workersRange.min < 0) {
              errors[`appSubscriptionOptions.tiers.${index}.workersRange.min`] =
                'Le nombre minimum doit être positif'
            }

            // Validation de la continuité des tiers
            if (index > 0) {
              const previousTier = tiers[index - 1]
              if (tier.workersRange.min !== (previousTier?.workersRange.max || 0) + 1) {
                errors[`appSubscriptionOptions.tiers.${index}.workersRange.min`] =
                  'Le minimum doit être égal au maximum du palier précédent + 1'
              }
            }

            // Validation du max (sauf pour le dernier tier)
            if (index < tiers.length - 1) {
              if (!tier.workersRange.max) {
                errors[`appSubscriptionOptions.tiers.${index}.workersRange.max`] =
                  'Seul le dernier palier doit avoir une valeur maximum non définie'
              } else if (tier.workersRange.max <= tier.workersRange.min) {
                errors[`appSubscriptionOptions.tiers.${index}.workersRange.max`] =
                  'Le maximum doit être supérieur au minimum'
              }
            }
            // Validation du coût
            if (tier.workPeriodCost < 0) {
              errors[`appSubscriptionOptions.tiers.${index}.workPeriodCost`] =
                'Le coût doit être positif'
            }
            // Validation pour discountPercentage
            if (
              tier.discountPercentage &&
              (tier.discountPercentage < 0 || tier.discountPercentage > 100)
            ) {
              errors[`appSubscriptionOptions.tiers.${index}.discountPercentage`] =
                'Le pourcentage de remise doit être entre 0 et 100'
            }
          })
        }
        return errors
      }}
      onSubmit={values => {
        const tiers = values.appSubscriptionOptions.tiers
        if (tiers.length) {
          tiers[tiers.length - 1]!.workersRange.max = undefined
        }
        if (interimAgency) {
          updateMutation.mutate({
            id: interimAgency._id as string,
            appSubscriptionOptions: values.appSubscriptionOptions,
          })
        }
      }}
    >
      <Form>
        <Columns>
          <Columns.Column size={6}>
            <Field
              label="Frais de licence de base"
              name="appSubscriptionOptions.licenseFee"
              help="Cout licence par semaine"
              component={InputField}
              type="number"
              min={0}
              required
              step={0.01}
            />
          </Columns.Column>
          <Columns.Column size={6}>
            <Field
              label="Pourcentage de remise"
              name="appSubscriptionOptions.discountPercentage"
              help="Remise en % appliquée sur le coût de licence de base"
              component={InputField}
              type="number"
              min={0}
              max={100}
              required
            />
          </Columns.Column>
        </Columns>

        <SubscriptionTiersForm />

        <Container>
          <Element mt={6}>
            <Element mb={1}>
              <RequestMessage mutation={updateMutation} />
            </Element>
            <RequestButton color="primary" type="submit" mutation={updateMutation}>
              Mettre à jour les options d'abonnement
            </RequestButton>
          </Element>
        </Container>
      </Form>
    </Formik>
  )
}

export default AgencySubscriptionForm

const SubscriptionTiersForm: React.FC = () => {
  const { values } = useFormikContext<{
    appSubscriptionOptions: {
      tiers: Array<{
        workersRange: {
          min: number
          max: number
        }
        workPeriodCost: number
        discountPercentage: number
      }>
    }
  }>()

  return (
    <FieldArray
      name="appSubscriptionOptions.tiers"
      render={arrayHelpers => (
        <div>
          {values.appSubscriptionOptions.tiers.map((tier, index) => (
            <Columns key={index} vCentered>
              <Columns.Column>
                <Field
                  label="Travailleurs min"
                  name={`appSubscriptionOptions.tiers.${index}.workersRange.min`}
                  component={InputField}
                  type="number"
                  min={0}
                />
              </Columns.Column>
              <Columns.Column>
                <Field
                  label="Travailleurs max"
                  name={`appSubscriptionOptions.tiers.${index}.workersRange.max`}
                  component={InputField}
                  type="number"
                  min={0}
                />
              </Columns.Column>
              <Columns.Column>
                <Field
                  label="Prix par travailleur"
                  name={`appSubscriptionOptions.tiers.${index}.workPeriodCost`}
                  component={InputField}
                  type="number"
                  min={0}
                  step={0.0001}
                />
              </Columns.Column>
              <Columns.Column>
                <Field
                  label="Remise spécifique (%)"
                  name={`appSubscriptionOptions.tiers.${index}.discountPercentage`}
                  component={InputField}
                  type="number"
                  min={0}
                  max={100}
                />
              </Columns.Column>
              <Columns.Column narrow>
                <Button
                  onClick={() => arrayHelpers.remove(index)}
                  style={{ marginTop: '1.8rem' }}
                  size={'small'}
                  type="button"
                >
                  <Icon>
                    <TrashIcon />
                  </Icon>
                </Button>
              </Columns.Column>
            </Columns>
          ))}
          <Button
            outlined
            color="primary"
            size="small"
            type="button"
            onClick={() =>
              arrayHelpers.push({
                workersRange: {
                  min:
                    (values.appSubscriptionOptions.tiers[
                      values.appSubscriptionOptions.tiers.length - 1
                    ]?.workersRange.max || 0) + 1,
                  max: 0,
                },
                workPeriodCost: 0,
                discountPercentage: 0,
              })
            }
          >
            <Icon>
              <PlusIcon />
            </Icon>
            <span>Ajouter un palier</span>
          </Button>
        </div>
      )}
    />
  )
}
