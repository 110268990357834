import { FieldProps } from 'formik'
import React from 'react'
import { Form } from 'react-bulma-components'
import FormField, { FormFieldProps } from './form-field'

interface CheckboxFieldProps extends FormFieldProps, FieldProps {
  type: string
  text?: string
}

const CheckboxField: React.FunctionComponent<CheckboxFieldProps> = props => {
  const { field, form, label, help, ...rest } = props
  if (props.field.value === undefined) props.field.value = ''
  return (
    <FormField label={label} help={help} error={form.errors[field.name] as string}>
      <Form.Checkbox {...field} {...rest} checked={props.field.value}>
        {props.text}
      </Form.Checkbox>
    </FormField>
  )
}

export default CheckboxField
