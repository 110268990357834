import {
  addDays,
  addWeeks,
  endOfWeek,
  isBefore,
  isToday,
  parseISO,
  startOfDay,
  startOfWeek,
  subWeeks,
} from 'date-fns'
import { endOfDay } from 'date-fns/esm'
import React, { ReactNode, useState } from 'react'
import {
  Button,
  Columns,
  Container,
  Element,
  Heading,
  Icon,
  Level,
  Message,
  Progress,
  Section,
  Table,
} from 'react-bulma-components'
import Layout from '../../components/layout/layout'
import useStore from '../../store'
import useFilters from '../../hooks/filters'
import { dateRangeDisplayHelper, formatISODate } from '../../utils/date'
import { CalendarIcon, CheckIcon, KpiIcon, WarningIcon } from '../../components/icons'
import { useNavigate } from 'react-router-dom'
import { useEmployerStats } from '../../hooks/use-employer-stats'
import { FiltersResourcesEnum } from '../../store/filters'
import { EmployerKpis } from '../../components/kpi/employer-kpis'
import { IsoDate } from '../../hooks/use-calendar'
import { DashboardCard } from '../../components/dashboards/dashboard-card'
import { Size } from 'react-bulma-components/src/components'
import ReactTooltip from 'react-tooltip'

const NewEmployerDashboard: React.FC = () => {
  const currentOrganizationRole = useStore(state => state.session.currentOrganizationRole)
  const navigate = useNavigate()
  const [filters, setFilters] = useState({
    start: formatISODate(new Date()),
    end: formatISODate(new Date()),
  })
  const { start, end } = filters
  const setWorkersRequestsFilters = useFilters(FiltersResourcesEnum.workersRequests).setFilters
  const setWorkPeriodsFilters = useFilters(FiltersResourcesEnum.workPeriods).setFilters
  const stats = useEmployerStats(start, end)

  const startDate = parseISO(start)
  const endDate = parseISO(end)

  const contextualDateRange = dateRangeDisplayHelper(start, end)
  const dateRange = dateRangeDisplayHelper(start, end, false)
  return (
    <Layout>
      {currentOrganizationRole === 'clocker' ? (
        <></>
      ) : (
        <Section>
          <Container>
            <Columns centered>
              <Columns.Column size={6}>
                <Heading size={2} textAlign={'center'}>
                  {contextualDateRange}{' '}
                </Heading>
                <Heading
                  size={5}
                  textAlign={'center'}
                  subtitle
                  textColor="primary-40"
                  textWeight="bold"
                >
                  {dateRange}
                </Heading>
                <ValidationProgress start={start} end={end} size={'large'} />
                {stats.workPeriods.notValidated.length > 0 && (
                  <Message color="warning" mt={5}>
                    <Message.Body>
                      <Level>
                        <Level.Side>
                          <Icon.Text>
                            <Icon>
                              <WarningIcon />
                            </Icon>
                            Il vous reste {stats.workPeriods.notValidated.length} Journées de
                            Travail à Valider{' '}
                          </Icon.Text>
                        </Level.Side>
                        <Level.Side>
                          <Button
                            size="small"
                            display="inline-block"
                            onClick={() => {
                              setWorkPeriodsFilters(FiltersResourcesEnum.workPeriods, {
                                start: formatISODate(startOfDay(startDate)),
                                end: formatISODate(endOfDay(endDate)),
                                isValidated: [false],
                              })
                              navigate('/work-periods')
                            }}
                          >
                            Valider des Journées de Travail
                          </Button>
                        </Level.Side>
                      </Level>
                    </Message.Body>
                  </Message>
                )}
                {stats.posts.missing.length > 0 && (
                  <Message color="warning" mt={5}>
                    <Message.Body>
                      <Level>
                        <Level.Side>
                          <Icon.Text>
                            <Icon>
                              <WarningIcon />
                            </Icon>
                            Vous avez encore {stats.posts.missing.length} Demandes en cours
                          </Icon.Text>
                        </Level.Side>
                        <Level.Side>
                          <Button
                            size="small"
                            display="inline-block"
                            onClick={() => {
                              setWorkersRequestsFilters(FiltersResourcesEnum.workersRequests, {
                                start: startOfWeek(parseISO(start)),
                                end: endOfWeek(parseISO(start)),
                                isExpired: [],
                                isComplete: [false],
                                isCancelled: [false],
                              })
                              navigate('/workers-requests')
                            }}
                          >
                            Voir les Demandes
                          </Button>
                        </Level.Side>
                      </Level>
                    </Message.Body>
                  </Message>
                )}
              </Columns.Column>
            </Columns>
            <Columns gap={8} mt={5}>
              <Columns.Column>
                <Heading size={3}>Tableau de Bord</Heading>
                <EmployerStat start={start} end={end} />
              </Columns.Column>
              <Columns.Column>
                <Level>
                  <Level.Side>
                    <Heading size={3}>Statistiques</Heading>
                  </Level.Side>
                  <Level.Side>
                    <Button
                      color="primary"
                      outlined
                      onClick={() => navigate('/kpi')}
                      textWeight="bold"
                    >
                      <Icon>
                        <KpiIcon />
                      </Icon>
                      <span>Explorer les Statistiques</span>
                    </Button>
                  </Level.Side>
                </Level>

                <EmployerKpis stats={stats} />
              </Columns.Column>
            </Columns>
            <Heading size={3}>Planning</Heading>
            <Planning filters={filters} setFilters={setFilters} />
          </Container>
        </Section>
      )}
    </Layout>
  )
}
export default NewEmployerDashboard

export const Planning = ({
  filters,
  setFilters,
}: {
  filters: { start: IsoDate; end: IsoDate }
  setFilters: React.Dispatch<
    React.SetStateAction<{
      start: IsoDate
      end: IsoDate
    }>
  >
}): ReactNode => {
  const todayStart = startOfDay(new Date())
  const todayEnd = endOfDay(new Date())
  const thisWeekStart = startOfWeek(todayStart)
  const thisWeekEnd = endOfWeek(todayEnd)
  const pastWeekStart = subWeeks(thisWeekStart, 1)
  const pastWeekEnd = subWeeks(thisWeekEnd, 1)
  const nextWeekStart = addWeeks(thisWeekStart, 1)
  const nextWeekEnd = addWeeks(thisWeekEnd, 1)
  const thisWeek = [0, 1, 2, 3, 4, 5, 6].map(day => ({
    start: formatISODate(addDays(thisWeekStart, day)),
    end: formatISODate(addDays(endOfDay(thisWeekStart), day)),
  }))
  const pastWeek = [0, 1, 2, 3, 4, 5, 6].map(day => ({
    start: formatISODate(addDays(pastWeekStart, day)),
    end: formatISODate(addDays(endOfDay(pastWeekStart), day)),
  }))
  const nextWeek = [0, 1, 2, 3, 4, 5, 6].map(day => ({
    start: formatISODate(addDays(nextWeekStart, day)),
    end: formatISODate(addDays(endOfDay(nextWeekStart), day)),
  }))

  return (
    <Table size="fullwidth">
      <thead>
        <tr>
          <th></th>
          <th>Lundi</th>
          <th>Mardi</th>
          <th>Mercredi</th>
          <th>Jeudi</th>
          <th>Vendredi</th>
          <th>Samedi</th>
          <th>Dimanche</th>
        </tr>
      </thead>
      <tbody>
        <tr
          style={{
            backgroundColor:
              filters.start === formatISODate(nextWeekStart) &&
              filters.end === formatISODate(nextWeekEnd)
                ? 'lavender'
                : undefined,
          }}
        >
          <th>
            Semaine Prochaine
            <br />
            <Button
              size={'small'}
              data-test="see-next-week-button"
              mt={3}
              onClick={() =>
                setFilters({
                  start: formatISODate(nextWeekStart),
                  end: formatISODate(nextWeekEnd),
                })
              }
            >
              Détail
            </Button>
          </th>
          {nextWeek.map(({ start, end }) => (
            <td
              key={start}
              style={{
                backgroundColor:
                  filters.start === start && filters.end === end ? 'lavender' : undefined,
              }}
            >
              <PlanningCardItem isoDate={start} setFilters={setFilters} />
            </td>
          ))}
        </tr>
        <tr
          style={{
            backgroundColor:
              filters.start === formatISODate(thisWeekStart) &&
              filters.end === formatISODate(thisWeekEnd)
                ? 'lavender'
                : undefined,
          }}
        >
          <th>
            Cette Semaine
            <br />
            <Button
              size={'small'}
              data-test="see-this-week-button"
              mt={3}
              onClick={() =>
                setFilters({
                  start: formatISODate(thisWeekStart),
                  end: formatISODate(thisWeekEnd),
                })
              }
            >
              Détail
            </Button>
          </th>
          {thisWeek.map(({ start, end }) => (
            <td
              key={start}
              style={{
                backgroundColor:
                  filters.start === start && filters.end === end ? 'lavender' : undefined,
              }}
            >
              <PlanningCardItem isoDate={start} setFilters={setFilters} />
            </td>
          ))}
        </tr>
        <tr
          style={{
            backgroundColor:
              filters.start === formatISODate(pastWeekStart) &&
              filters.end === formatISODate(pastWeekEnd)
                ? 'lavender'
                : undefined,
          }}
        >
          <th>
            Semaine Passée
            <br />
            <Button
              size={'small'}
              data-test="see-previous-week-button"
              mt={3}
              onClick={() =>
                setFilters({
                  start: formatISODate(pastWeekStart),
                  end: formatISODate(pastWeekEnd),
                })
              }
            >
              Détail
            </Button>
          </th>
          {pastWeek.map(({ start, end }) => (
            <td
              key={start}
              style={{
                backgroundColor:
                  filters.start === start && filters.end === end ? 'lavender' : undefined,
              }}
            >
              <PlanningCardItem isoDate={start} setFilters={setFilters} />
            </td>
          ))}
        </tr>
      </tbody>
    </Table>
  )
}

const PlanningCardItem = ({
  isoDate,
  setFilters,
}: {
  isoDate: IsoDate
  setFilters: React.Dispatch<
    React.SetStateAction<{
      start: IsoDate
      end: IsoDate
    }>
  >
}): ReactNode => {
  const stats = useEmployerStats(isoDate, isoDate)
  const date = parseISO(isoDate)
  const isInThePast = isBefore(date, startOfDay(new Date()))
  const isInThePastOrToday = isInThePast || isToday(date)
  const isInTheFuture = !isInThePast
  const isInTheFutureOrToday = isInTheFuture || isToday(date)
  const isPastWeek = isBefore(date, startOfWeek(new Date()))
  const workPeriodsNotValidated = stats.workPeriods.notValidated.length > 0
  const ongoingDemands = stats.posts.missing.length > 0
  const displayWorkPeriodsNotValidated = isInThePastOrToday && workPeriodsNotValidated
  const displayOngoingDemands = isInTheFutureOrToday && ongoingDemands
  const allOk = !displayOngoingDemands && !displayWorkPeriodsNotValidated
  const warningColor = isPastWeek ? 'danger-80' : 'warning-80'

  return (
    <>
      <Element renderAs="ul" p={2}>
        <li>{stats.workPeriods.expected.length} Travailleurs</li>
        {allOk ? (
          <>
            <li>
              <Element p={1} mt={1}>
                <Icon.Text>
                  <Icon color="success-70">
                    <CheckIcon />
                  </Icon>
                  OK
                </Icon.Text>
              </Element>
            </li>
            <li>
              <Element p={1} mt={1}>
                &nbsp;
              </Element>
            </li>
          </>
        ) : (
          <>
            <li>
              {displayWorkPeriodsNotValidated ? (
                <Element p={1} mt={1} backgroundColor={warningColor}>
                  {stats.workPeriods.notValidated.length} à Valider !
                </Element>
              ) : (
                <Element p={1} mt={1}>
                  &nbsp;
                </Element>
              )}
            </li>
            <li>
              {displayOngoingDemands ? (
                <Element p={1} mt={1} backgroundColor={warningColor}>
                  {stats.posts.missing.length} Demandes !
                </Element>
              ) : (
                <Element p={1} mt={1}>
                  &nbsp;
                </Element>
              )}
            </li>
          </>
        )}
        <Level mt={3}>
          <Level.Side>
            <Button size={'small'} onClick={() => setFilters({ start: isoDate, end: isoDate })}>
              Détail
            </Button>
          </Level.Side>
          <Level.Side>
            {isToday(date) ? (
              <Icon size="small" color={'dark'} data-tooltip="Aujourd'hui">
                <CalendarIcon />
              </Icon>
            ) : null}
          </Level.Side>
        </Level>
      </Element>
    </>
  )
}

const EmployerStat = ({ start, end }: { start: IsoDate; end: IsoDate }): ReactNode => {
  const stats = useEmployerStats(start, end)

  return (
    <>
      <Columns>
        <Columns.Column size={6}>
          <DashboardCard
            mainStat={{
              value: stats.workPeriods.combine(stats.workPeriods, ['expected']).length,
              label: 'Missions',
            }}
            secondaryStat={
              <>
                + {stats.workPeriods.combine(stats.workPeriods, ['notExpected']).length}{' '}
                interruptions ou abandons
              </>
            }
            queryStatus={'success'}
          />
        </Columns.Column>
        <Columns.Column size={6}>
          <DashboardCard
            mainStat={{
              value: stats.workPeriods.combine(stats.workPeriods, ['expected', 'clockedInNotOut'])
                .length,
              label: 'Présents',
            }}
            secondaryStat={'ont pointé leur entrée'}
            queryStatus={'success'}
          />
        </Columns.Column>
        <Columns.Column size={6}>
          <DashboardCard
            mainStat={{
              value: stats.workPeriods.combine(stats.workPeriods, ['expected', 'late']).length,
              label: 'En retard',
            }}
            secondaryStat={`dont ${
              stats.workPeriods.combine(stats.workPeriods, ['expected', 'late', 'notClocked'])
                .length
            } pas encore arrivés`}
            queryStatus={'success'}
          />
        </Columns.Column>
        <Columns.Column size={6}>
          <DashboardCard
            mainStat={{
              value: stats.workPeriods.combine(stats.workPeriods, ['expected', 'clockedOut'])
                .length,
              label: 'Terminés',
            }}
            secondaryStat={'ont pointé leur sortie'}
            queryStatus={'success'}
          />
        </Columns.Column>
      </Columns>
    </>
  )
}

export const ValidationProgress = ({
  start,
  end,
  size = 'normal',
}: {
  start: IsoDate
  end: IsoDate
  size?: Size
}): ReactNode => {
  const stats = useEmployerStats(start, end)
  const value = (stats.workPeriods.validated.length / stats.workPeriods.total.length) * 100

  let color: string
  switch (true) {
    case value < 50:
      color = 'danger'
      break
    case value >= 50 && value < 100:
      color = 'warning'
      break
    case value === 100:
      color = 'success'
      break
    default:
      color = 'info'
  }

  return (
    <>
      <ReactTooltip id="validation-progress" backgroundColor={`var(--bulma-${color}-dark)`}>
        <Element textWeight="bold">État de la Validation : {Math.floor(value)}%</Element>
      </ReactTooltip>
      <Element data-tip data-for="validation-progress">
        <Progress size={size} value={value} max={100} color={color} />
      </Element>
    </>
  )
}
