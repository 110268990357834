import { ExclamationIcon } from '@heroicons/react/outline'
import React from 'react'
import { Element, Icon } from 'react-bulma-components'
import ErrorLayout from '../../components/layout/error-layout'

const NetworkError: React.FC = () => {
  return (
    <ErrorLayout>
      <Element textAlign="center">
        <Icon size={'large'}>
          <ExclamationIcon />
        </Icon>
        <Element textSize={3} textAlign="center">
          Service temporairement indisponible
        </Element>
        Merci de réessayer dans une minute ou de contacter le service technique si le problème
        persiste
      </Element>
    </ErrorLayout>
  )
}

export default NetworkError
