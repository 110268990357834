import React from 'react'
import ReactTooltip from 'react-tooltip'
import { Button, Element } from 'react-bulma-components'

interface ButtonWithTooltipProps extends React.ComponentProps<typeof Button> {
  tooltip: {
    text: string
    show?: boolean
    id?: string
  }
}

const ButtonWithTooltip: React.FC<ButtonWithTooltipProps> = props => {
  const { tooltip, children, ...buttonProps } = props
  const id = React.useMemo(() => tooltip.id || (Math.random() * 1e32).toString(36), [tooltip.id])
  return (
    <Element renderAs="span" data-for={id} data-tip>
      {tooltip.show !== false && (
        <ReactTooltip id={id} effect="solid">
          <Element textAlign={'center'}>{tooltip.text}</Element>
        </ReactTooltip>
      )}
      <Button {...buttonProps}>{children}</Button>
    </Element>
  )
}

export default ButtonWithTooltip
