import React from 'react'
import { Container, Section } from 'react-bulma-components'
import Layout from '../../components/layout/layout'
import PageHeading from '../../components/sections/page-heading'
import CompleteProfileform from '../../components/users/complete-profile-form'

const UserCompleteProfile: React.FC = ({}) => {
  return (
    <Layout>
      <PageHeading title="Complétez votre profil" />
      <Section>
        <Container max={true} breakpoint={'desktop'}>
          <CompleteProfileform />
        </Container>
      </Section>
    </Layout>
  )
}

export default UserCompleteProfile
