import { Field, Form, Formik } from 'formik'
import React from 'react'
import { Columns, Container, Element, Heading } from 'react-bulma-components'
import { useCreateMutation, useUpdateMutation } from '../../queries/organizations'
import { Employer, OrganizationTypeEnum } from '../../api/organizations'
import InputField from '../form/fields/input'
import { RequestButton, RequestMessage } from '../request-components/request-components'
import SelectField from '../form/fields/select'
import { AddressesForm } from './addresses-form'
import Protected from '../protected/protected'
import CheckboxField from '../form/fields/checkbox'
import { hoursAsNumberToTimeInputValue, timeInputValueToHoursAsNumber } from '../../utils/date'

interface EmployerFormProps {
  organization?: Employer
}

const EmployerForm: React.FunctionComponent<EmployerFormProps> = ({ organization }) => {
  const createMutation = useCreateMutation()
  const updateMutation = useUpdateMutation()

  return (
    <Formik
      initialValues={{
        name: organization?.name || '',
        type: OrganizationTypeEnum.employer,
        rules: {
          missions: {
            abandonmentPayments: Boolean(organization?.rules?.missions.abandonmentPayments),
            dayStartHour: organization
              ? hoursAsNumberToTimeInputValue(organization.rules.missions.dayStartHour)
              : '',
            nightStartHour: organization
              ? hoursAsNumberToTimeInputValue(organization.rules.missions.nightStartHour)
              : '',
          },
        },
        addresses: organization?.addresses || [],
        appOptions: organization?.appOptions,
      }}
      enableReinitialize
      validate={values => {
        const errors: any = {}
        if (!values.name) errors.name = 'Champ requis'
        if (values.addresses.length === 0)
          errors.addresses = 'Au moins une adresse doit être renseignée'
        if (!values.rules?.missions.nightStartHour)
          errors['rules.missions.nightStartHour'] = 'Champ requis'
        if (!values.rules?.missions.dayStartHour)
          errors['rules.missions.dayStartHour'] = 'Champ requis'
        return errors
      }}
      onSubmit={values => {
        if (values.rules?.missions.dayStartHour)
          values.rules.missions.dayStartHour = timeInputValueToHoursAsNumber(
            values.rules.missions.dayStartHour,
          )
        if (values.rules?.missions.nightStartHour)
          values.rules.missions.nightStartHour = timeInputValueToHoursAsNumber(
            values.rules.missions.nightStartHour,
          )
        if (!organization) createMutation.mutate(values)
        else updateMutation.mutate({ id: organization._id.toString(), ...values })
      }}
    >
      {() => {
        return (
          <Form>
            <Columns>
              <Columns.Column size={12}>
                <Field label="Nom de l'Employeur" name="name" component={InputField} required />
                <AddressesForm />
              </Columns.Column>

              <Protected roles={['superAdmin']}>
                <>
                  <Columns.Column size={12}>
                    <Field
                      label="Les Abandons de Poste sont"
                      name="rules.missions.abandonmentPayments"
                      component={SelectField}
                      options={[
                        { value: true, label: 'Payés' },
                        { value: false, label: 'Non Payés' },
                      ]}
                    />
                  </Columns.Column>
                  <Columns.Column size={3}>
                    <Field
                      label="Heure début jour"
                      name="rules.missions.dayStartHour"
                      type="time"
                      component={InputField}
                      help="La modification ne sera appliquée qu'aux nouvelles missions"
                    />
                  </Columns.Column>
                  <Columns.Column size={3}>
                    <Field
                      label="Heure début nuit"
                      name="rules.missions.nightStartHour"
                      type="time"
                      component={InputField}
                    />
                  </Columns.Column>
                  <Protected roles={['superAdmin']}>
                    <Columns.Column size={12}>
                      <Heading size={4} mt={5}>
                        Fonctionnalités disponnibles
                      </Heading>
                      <Field label="Bi-parti" name="appOptions.biPart" component={CheckboxField} />
                    </Columns.Column>
                  </Protected>
                </>
              </Protected>
            </Columns>
            <Container>
              <Element mb={1}>
                <RequestMessage mutation={updateMutation} />
              </Element>
              <RequestButton color="primary" type="submit" mutation={updateMutation}>
                {organization
                  ? "Mettre à jour les données de l'Employeur"
                  : 'Créer un Nouvel Employeur'}
              </RequestButton>
            </Container>
          </Form>
        )
      }}
    </Formik>
  )
}

export default EmployerForm
