import React, { useState } from 'react'
import { Button, Container, Content, Element, Form, Icon, Section } from 'react-bulma-components'
import { User } from '../../api/users'
import ConfirmationModal from '../confirmation-modal/confirmation-modal'
import { useDeleteAccountMutation } from '../../queries/users'
import useStore from '../../store'
import { ExclamationIcon } from '@heroicons/react/solid'

export const DeleteMyAccount: React.FC<{ user: User }> = ({ user }) => {
  const logout = useStore(state => state.session.logout)
  const deleteAccountMutation = useDeleteAccountMutation()
  const [displayModal, setDisplayModal] = useState(false)
  const [confirmTextInputValue, setConfirmTextInputValue] = useState('')
  const confirmText = user.email
  const confirmTextIsValid = confirmTextInputValue === confirmText

  if (deleteAccountMutation.isSuccess) {
    logout()
  }
  return (
    <Container>
      <Button
        onClick={() => setDisplayModal(true)}
        color="danger"
        data-test="delete-my-account"
        size={'small'}
      >
        <Icon>
          <ExclamationIcon />
        </Icon>
        <span>Supprimer définitivement mon compte</span>
      </Button>
      <ConfirmationModal
        onConfirm={() => {
          if (!confirmTextIsValid) {
            console.error('oops, button should not be clickable')
          } else {
            deleteAccountMutation.mutate(user._id)
          }
        }}
        onCancel={() => setDisplayModal(false)}
        title="Supprimer définitivement mon compte"
        show={displayModal}
        confirmButtonLabel="Supprimer définitivement mon compte"
        confirmButtonProps={{ disabled: confirmTextIsValid ? false : true }}
      >
        Vous allez <u>supprimer définitivement</u> le compte lié à l'adresse :<br />
        <Element m={3} p={3} textAlign={'center'} style={{ fontFamily: 'monospace' }}>
          {user.email}
        </Element>
        <Content>
          <ul>
            <li>Vous ne pourrez plus vous connecter</li>
            <li>Vos données seront définitivement effacées</li>
            <li>Vous ne recevrez plus de notifications</li>
            <li>
              Cette action est <u>irréversible</u>
            </li>
          </ul>
        </Content>
        <Form.Field>
          <Form.Control>
            <Form.Label>
              Veuillez retaper votre adresse email ci-dessous pour confirmer la suppression
              définitive de votre compte
            </Form.Label>
            <Form.Input
              name="confirm-delete-my-account"
              value={confirmTextInputValue}
              onChange={e => setConfirmTextInputValue(e.target.value)}
              placeholder={confirmText}
              color={confirmTextIsValid ? 'success' : 'danger'}
            />
          </Form.Control>
        </Form.Field>
      </ConfirmationModal>
    </Container>
  )
}
