import React from 'react'
import { Button, Element, Icon } from 'react-bulma-components'
import { Size } from 'react-bulma-components/src/components'
import { WorkPeriod } from '../../api/work-periods'
import ReactTooltip from 'react-tooltip'
import { MissionNotEditableIcon, MissionValidatedIcon } from '../icons'

interface WorkPeriodValidateButtonProps {
  workPeriod: WorkPeriod
  label?: string
  cancelLabel?: string
  size?: Size | 'smallest'
  type?: 'button' | 'submit' | 'reset'
  onClick(workPeriod: WorkPeriod): void
}

const WorkPeriodValidateButton: React.FunctionComponent<WorkPeriodValidateButtonProps> = ({
  label,
  cancelLabel,
  onClick,
  workPeriod,
  size,
  type,
}) => {
  const id = `validate-work-period-${workPeriod._id}`
  if (!workPeriod.isValidated) {
    const actionDisabled = !workPeriod.__actions.canBeValidated
    return (
      <Element renderAs="span" data-tip={actionDisabled} data-for={id}>
        {actionDisabled && (
          <ReactTooltip id={id} effect="solid">
            <Element textAlign={'center'}>Impossible de valider la journée de travail</Element>
          </ReactTooltip>
        )}
        <Button
          style={size === 'smallest' ? { height: '1.5rem' } : {}}
          size={size === 'smallest' ? 'small' : size}
          type={type}
          color={!actionDisabled ? 'link' : ''}
          onClick={() => onClick(workPeriod)}
          disabled={actionDisabled}
          data-test="work-period-validate-action"
        >
          <Icon>
            <MissionValidatedIcon />
          </Icon>
          {label && <span>{label}</span>}
        </Button>
      </Element>
    )
  } else if (workPeriod.__actions.canBeInvalidated) {
    return (
      <Element renderAs="span">
        <Button
          style={size === 'smallest' ? { height: '1.5rem' } : {}}
          size={size === 'smallest' ? 'small' : size}
          type={type}
          onClick={() => onClick(workPeriod)}
          data-test="work-period-invalidate-action"
        >
          {cancelLabel ?? (
            <Icon size={'small'} data-tooltip="Journée de travail validée" color="link">
              <MissionNotEditableIcon />
            </Icon>
          )}
        </Button>
      </Element>
    )
  }
  return <></>
}

export default WorkPeriodValidateButton
