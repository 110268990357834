import React, { FC, ReactNode, useEffect, useRef, useState } from 'react'
import {
  Button,
  Columns,
  Element,
  Hero,
  Icon,
  Level,
  Message,
  Section,
  Tabs,
  Tag,
} from 'react-bulma-components'
import Layout from '../../components/layout/layout'
import { Field, Form, Formik, FormikProps } from 'formik'
import AutoSubmit from '../../components/form/utils/auto-submit'
import { RefreshIcon } from '@heroicons/react/solid'
import Clock from '../../components/clock/clock'
import { useCheckAppVersionQuery, useCreateMutation } from '../../queries/clockings'
import { CheckCircleIcon } from '@heroicons/react/outline'
import QrScannerField from '../../components/form/fields/qr-scanner'
import { CountBack, CountBackAndHide } from '../../hooks/use-count-back'
//@ts-expect-error -> this is an mp3 file !
import * as BeepFile from '../../images/beep.mp3'
import useStore from '../../store'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import { Organization } from '../../api/organizations'
import { differenceInSeconds } from 'date-fns'

const beep = new Audio(BeepFile)
const usePlayBeep = () => {
  useEffect(() => {
    beep.play()
    return () => {
      beep.pause()
    }
  }, [])
}

const Beep = () => {
  usePlayBeep()
  return <></>
}

const StatusTag = () => {
  const checkAppVersionQuery = useCheckAppVersionQuery(60000)

  const isOnline = checkAppVersionQuery.dataUpdatedAt > 0 && checkAppVersionQuery.isFetched

  const statusText = isOnline ? 'online' : 'offline'
  const statusColor = isOnline ? 'success' : 'danger'

  return (
    <Tag size="small" color={statusColor}>
      {statusText}
    </Tag>
  )
}

const QrScannerPage: FC<{ scanner: 'camera' | 'laser' }> = ({ scanner }) => {
  const navigate = useNavigate()
  const mutation = useCreateMutation()
  const [qrReaderError, setQrReaderError] = useState<string>()
  const currentOrganization = useStore(s => s.session.currentOrganization)
  const currentUser = useStore(s => s.session.currentUser)
  const currentUserRole = useStore(s => s.session.currentUserRole)
  const logout = useStore(s => s.session.logout)
  const [searchParams] = useSearchParams()
  // @ts-expect-error -> fix this
  const isSystemUser = currentUser?.appAccess === 'system'

  const [camera, setCamera] = React.useState<'user' | 'environment' | undefined>('environment')
  const [mode, setMode] = React.useState<'kiosk' | 'mobile' | undefined>('kiosk')
  const [lastQrSent, setLastQrSent] = React.useState<{
    value: string
    time: number
  }>({
    value: '',
    time: 0,
  })

  if (currentUserRole === 'superAdmin') {
    return (
      <Layout>
        <Section>Vous devez être connecté en tant qu'Employeur pour pouvoir scanner</Section>
      </Layout>
    )
  }

  // Remove this later, we still support /qr-scanner?laser=true url scheme
  // Has been replaced with /kiosk and /laser-scanner
  if (scanner === 'camera' && searchParams.get('laser')) {
    return <Navigate to="/laser-scanner" />
  }

  const clockingResultColor =
    mutation.isSuccess && mutation.data.data.mission
      ? 'success'
      : mutation.isIdle
      ? 'white'
      : mutation.isError || (mutation.isSuccess && !mutation.data.data.mission) || qrReaderError
      ? 'danger'
      : 'white'

  return (
    <Layout hideHeader hideFooter>
      <Formik<{ qr: string }>
        initialValues={{
          qr: '',
        }}
        onSubmit={async (values, { resetForm }) => {
          const now = new Date().getTime()
          if (values.qr) {
            console.log(values.qr, lastQrSent.value, differenceInSeconds(now, lastQrSent.time))
            if (
              JSON.stringify(values.qr) === JSON.stringify(lastQrSent.value) &&
              differenceInSeconds(now, lastQrSent.time) < 5
            ) {
              return
            }
            try {
              const hash = JSON.parse(values.qr)
              if (hash.content && hash.iv)
                await mutation
                  .mutateAsync(
                    {
                      hash: JSON.parse(values.qr),
                    },
                    {
                      onSuccess: () => {
                        setLastQrSent({
                          value: values.qr,
                          time: now,
                        })
                      },
                    },
                  )
                  .catch(e => e)
            } catch (err) {
              setQrReaderError("Ce QR Code n'est pas valide")
            }
            resetForm({
              values: { qr: '' },
            })
          }
        }}
      >
        {props => {
          return (
            <Hero size={'fullheight'} color={clockingResultColor}>
              <Hero.Header>
                <Level m={5}>
                  <Level.Side>
                    <label className="label has-text-centered">{currentOrganization?.name}</label>
                  </Level.Side>
                  <Level.Side>
                    <StatusTag />
                  </Level.Side>
                </Level>
              </Hero.Header>
              <Hero.Body justifyContent="center" className="p-0">
                <Columns centered className="is-mobile" style={{ flexBasis: 'min(70vw, 70vh)' }}>
                  <Columns.Column textSize={4} textWeight="bold">
                    {qrReaderError && (
                      <CountBackAndHide cb={() => setQrReaderError(undefined)}>
                        <Beep />
                        <Message color={clockingResultColor} mb={3} textSize={5}>
                          <Message.Body py={3}>
                            {qrReaderError} (<CountBack />)
                          </Message.Body>
                        </Message>
                      </CountBackAndHide>
                    )}
                    {mutation.isSuccess && (
                      <CountBackAndHide cb={() => mutation.reset()}>
                        <Beep />
                        <Message color={clockingResultColor} mb={3}>
                          <Message.Body py={3} textSize={5}>
                            {mutation.data.data.mission
                              ? 'Pointage enregistré'
                              : 'Aucune mission en cours'}
                            &nbsp;(
                            <CountBack />)
                          </Message.Body>
                        </Message>
                      </CountBackAndHide>
                    )}
                    {mutation.isError && (
                      <CountBackAndHide cb={() => mutation.reset()}>
                        <Beep />
                        <Message color={clockingResultColor} mb={3}>
                          <Message.Body py={3} textSize={5}>
                            Pas autorisé (<CountBack />)
                          </Message.Body>
                        </Message>
                      </CountBackAndHide>
                    )}
                    {!qrReaderError && !mutation.isSuccess && !mutation.isError && (
                      <Message color={clockingResultColor} mb={3}>
                        <Message.Body textAlign={'center'} py={3} textSize={5}>
                          Scannez votre QR à <Clock />
                        </Message.Body>
                      </Message>
                    )}
                    {scanner === 'laser' ? (
                      <LaserScannerForm props={props} currentOrganization={currentOrganization} />
                    ) : (
                      <QRScannerForm
                        camera={camera}
                        setCamera={setCamera}
                        mode={mode}
                        setMode={setMode}
                        setQrReaderError={setQrReaderError}
                        props={props}
                      />
                    )}
                  </Columns.Column>
                </Columns>
              </Hero.Body>
              <Hero.Footer>
                <Level m={5} breakpoint="mobile">
                  <Level.Side>
                    <Element>
                      {isSystemUser ? (
                        <Element textColor="grey">Mode kiosque</Element>
                      ) : (
                        <Button
                          size="small"
                          onClick={() => navigate('/kiosk')}
                          outlined
                          textColor="grey"
                          pull="right"
                          backgroundColor="white"
                        >
                          Créer un kiosque
                        </Button>
                      )}
                    </Element>
                  </Level.Side>
                  <Level.Side>
                    <Button
                      size="small"
                      onClick={isSystemUser ? logout : () => navigate('/')}
                      color={'ghost'}
                      textColor="grey-light"
                      data-test="exit-qr-scanner"
                    >
                      x
                    </Button>
                  </Level.Side>
                </Level>
              </Hero.Footer>
            </Hero>
          )
        }}
      </Formik>
    </Layout>
  )
}

export default QrScannerPage

export const LaserScannerForm = ({
  props,
}: {
  props: FormikProps<{
    qr: string
  }>
  currentOrganization: Organization | undefined
}): ReactNode => {
  const keyboardInputRef = useRef<HTMLInputElement | null>(null)

  return (
    <Form>
      <div className="field">
        <div className="control">
          <input
            type="text"
            className="input"
            ref={keyboardInputRef}
            name="qr"
            autoFocus={true}
            onBlur={() => setTimeout(() => keyboardInputRef.current?.focus(), 5)}
            value={props.values.qr}
            onChange={props.handleChange}
            style={{
              border: 0,
              boxShadow: 'none',
              color: 'transparent',
              backgroundColor: 'transparent',
            }}
            data-test="laser-scanner-input-field"
          />
        </div>
      </div>
    </Form>
  )
}

export const QRScannerForm = ({
  mode,
  setMode,
  setQrReaderError,
  camera,
  setCamera,
  props,
}: {
  mode: 'kiosk' | 'mobile' | undefined
  setMode: React.Dispatch<React.SetStateAction<'kiosk' | 'mobile' | undefined>>
  setQrReaderError: React.Dispatch<React.SetStateAction<string | undefined>>
  camera: 'user' | 'environment' | undefined
  setCamera: React.Dispatch<React.SetStateAction<'user' | 'environment' | undefined>>
  props: FormikProps<{
    qr: string
  }>
}): ReactNode => {
  return (
    <Form>
      <>
        {
          <>
            <Element style={{ transform: mode === 'kiosk' ? 'scaleX(-1)' : '' }}>
              <Field
                name="qr"
                component={QrScannerField}
                setQrReaderError={setQrReaderError}
                required
                facingMode={camera}
                data-test="camera-scanner-input-field"
              />
            </Element>
            <Element px={2} mt={5}>
              <Level>
                <Level.Side>
                  <Element>
                    <Tabs size={'small'} type="toggle-rounded" color="primary">
                      <Tabs.Tab
                        active={mode === 'kiosk'}
                        onClick={() => setMode('kiosk')}
                        backgroundColor={mode !== 'kiosk' ? 'light' : undefined}
                      >
                        {mode === 'kiosk' && (
                          <Icon>
                            <CheckCircleIcon color="success" />
                          </Icon>
                        )}
                        Kiosque
                      </Tabs.Tab>
                      <Tabs.Tab
                        active={mode === 'mobile'}
                        onClick={() => setMode('mobile')}
                        backgroundColor={mode !== 'mobile' ? 'light' : undefined}
                      >
                        {mode === 'mobile' && (
                          <Icon>
                            <CheckCircleIcon />
                          </Icon>
                        )}
                        Mobile
                      </Tabs.Tab>
                    </Tabs>
                  </Element>
                </Level.Side>
                <Level.Side>
                  <Tabs size="small" type="toggle-rounded">
                    <Tabs.Tab
                      backgroundColor="white"
                      onClick={() => setCamera(camera === 'user' ? 'environment' : 'user')}
                    >
                      <Icon color="primary">
                        <RefreshIcon />
                      </Icon>
                    </Tabs.Tab>
                  </Tabs>
                </Level.Side>
              </Level>
            </Element>
          </>
        }
      </>
      <input
        type="text"
        name="qr"
        onChange={props.handleChange}
        value={props.values.qr}
        style={{
          border: 0,
          boxShadow: 'none',
          color: 'transparent',
          backgroundColor: 'transparent',
        }}
      />
      <AutoSubmit values={props.values} submitForm={props.submitForm} />
    </Form>
  )
}
