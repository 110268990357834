import { Field, Form, Formik } from 'formik'
import React from 'react'
import { Button, Container } from 'react-bulma-components'
import { useRequestPasswordMutation } from '../../queries/users'
import InputField from '../form/fields/input'

const RequestNewPasswordForm: React.FC = ({}) => {
  const mutation = useRequestPasswordMutation()
  return (
    <Formik
      initialValues={{
        email: '',
      }}
      enableReinitialize
      validate={values => {
        const errors: any = {}
        if (!values.email) errors.email = 'Champ requis'
        return errors
      }}
      onSubmit={values => {
        mutation.mutate(values)
      }}
    >
      {() => {
        return (
          <Form>
            <Container>
              <Field
                label="Votre adresse email"
                name="email"
                type="email"
                component={InputField}
                required
              />
              <Button color="primary" type="submit">
                Envoyer
              </Button>
            </Container>
          </Form>
        )
      }}
    </Formik>
  )
}

export default RequestNewPasswordForm
