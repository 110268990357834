import React from 'react'
import { useFirstRender } from '../../../hooks/use-first-render'

interface AutoSubmitProps {
  values: any
  submitForm: () => void
}

const AutoSubmit: React.FunctionComponent<AutoSubmitProps> = ({ values, submitForm }) => {
  const firstRender = useFirstRender()

  React.useEffect(() => {
    if (!firstRender) submitForm()
  }, [values, submitForm])

  return null
}

export default AutoSubmit
