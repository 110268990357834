import React from 'react'
import { Section, Button, Container, Form, Icon } from 'react-bulma-components'
import Layout from '../../components/layout/layout'
import Table from '../../components/table'
import PageHeading from '../../components/sections/page-heading'
import { Link } from 'react-router-dom'
import ComponentHeader from '../../components/sections/component-header'
import { Column } from 'react-table'
import { ResourceLinkCell } from '../../components/table/cells'
import { DocumentAddIcon } from '@heroicons/react/solid'
import { useAppSubscriptionPeriodsQuery } from '../../queries/app-subscription-periods'
import { AppSubscriptionPeriod } from '../../api/app-subscription-periods'
import { localDate } from '../../utils/date'
import { formatPreciseCost } from '../../utils/finance'
import { BillIcon } from '../../components/icons'

/**
 * Component for listing subscription periods for an interim agency.
 */
const AppSubscriptionPeriodsList: React.FunctionComponent = () => {
  const subscriptionPeriodsQuery = useAppSubscriptionPeriodsQuery()
  const tableColumns = useListTableColumns()

  return (
    <Layout>
      <PageHeading title="Liste des Périodes d'Abonnement" />
      <Section>
        <Container>
          <ComponentHeader>
            <ComponentHeader.Left>
              <></>
            </ComponentHeader.Left>
            <ComponentHeader.Right>
              <Form.Field>
                <Form.Label>&nbsp;</Form.Label>
                <Form.Control>
                  <Button
                    renderAs={Link}
                    to="/app-subscription-periods/new"
                    outlined
                    color="primary"
                  >
                    <Icon>
                      <BillIcon />
                    </Icon>
                    <span>Voir la période en cours</span>
                  </Button>
                </Form.Control>
              </Form.Field>
            </ComponentHeader.Right>
          </ComponentHeader>
          <Table
            columns={tableColumns}
            data={subscriptionPeriodsQuery.data || []}
            noDataMessage="Aucune Période d'Abonnement à afficher"
          />
        </Container>
      </Section>
    </Layout>
  )
}

export default AppSubscriptionPeriodsList

/**
 * Hook to define table columns for subscription periods.
 */
const useListTableColumns = (): Column<AppSubscriptionPeriod>[] => {
  return React.useMemo(
    () => [
      {
        Header: 'Identifiant',
        accessor: 'identifier',
        Cell: data => (
          <ResourceLinkCell
            resource={data.row.original}
            path="/app-subscription-periods/"
            label={data.row.original.identifier}
          />
        ),
      },
      {
        Header: 'UE',
        accessor: 'employer.name',
      },
      {
        Header: 'Date de Début',
        accessor: 'periodStart',
        Cell: ({ value }) => <>{localDate(value)}</>,
      },
      {
        Header: 'Date de Fin',
        accessor: 'periodEnd',
        Cell: ({ value }) => <>{localDate(value)}</>,
      },
      {
        Header: 'Cout total',
        accessor: 'fess.total',
        Cell: ({ value }) => <>{formatPreciseCost(value)}</>,
      },
    ],
    [],
  )
}
