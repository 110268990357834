import { Field, Formik } from 'formik'
import React from 'react'
import { Columns } from 'react-bulma-components'
import MultiSelectField from '../form/fields/multi-select'
import AutoSubmit from '../form/utils/auto-submit'
import RoleStatus from './role-status'

// TODO: refactor filters in store and create/use users filters (do not use state & onChange)

interface UsersFiltersProps {
  onChange(values: UsersFiltersValues): void
  initialValues?: UsersFiltersValues
}

export interface UsersFiltersValues {
  status: string[]
}

const UsersFilters: React.FC<UsersFiltersProps> = ({ onChange, initialValues }) => {
  return (
    <Formik<UsersFiltersValues> initialValues={initialValues || { status: [] }} onSubmit={onChange}>
      {props => {
        return (
          <>
            <Columns mb="0">
              <FieldColumn>
                <Field
                  label="Statut"
                  component={MultiSelectField}
                  name="status"
                  options={[
                    { value: 'pending', label: <RoleStatus role={{ status: 'pending' }} /> },
                    { value: 'accepted', label: <RoleStatus role={{ status: 'accepted' }} /> },
                    { value: 'rejected', label: <RoleStatus role={{ status: 'rejected' }} /> },
                    {
                      value: 'discontinued',
                      label: <RoleStatus role={{ status: 'discontinued' }} />,
                    },
                  ]}
                  disableDefaultFilter
                />
              </FieldColumn>
            </Columns>
            <AutoSubmit values={props.values} submitForm={props.submitForm} />
          </>
        )
      }}
    </Formik>
  )
}

export default UsersFilters

const FieldColumn: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Columns.Column narrow style={{ minWidth: 220 }}>
    {children}
  </Columns.Column>
)
