import { once } from 'lodash'
import React, { ReactNode, useContext, useState } from 'react'

export interface Clipboard<T> {
  copy: (value: T) => void
  isEqualTo: (value: T) => boolean
  value: T | undefined
  isEmpty: boolean
}

const createClipboardContext = once(<T,>() =>
  React.createContext<Clipboard<T>>({
    copy: () => {
      return
    },
    isEqualTo: () => false,
    value: undefined,
    isEmpty: true,
  }),
)

export const useClipboardContext = <T,>(): Clipboard<T> => useContext(createClipboardContext<T>())

export const ClipboardProvider = <T,>({
  children,
}: {
  children?: ReactNode
}): React.ReactElement => {
  const [clipboard, setClipboard] = useState<T>()
  const StateContext = createClipboardContext<T>()

  const isEqualTo = (value: T) => {
    if (clipboard === undefined) return false
    return JSON.stringify(value) === JSON.stringify(clipboard)
  }

  return (
    <StateContext.Provider
      value={{
        copy: setClipboard,
        isEqualTo,
        value: clipboard,
        isEmpty: clipboard === undefined,
      }}
    >
      {children}
    </StateContext.Provider>
  )
}
