import React, { ReactNode } from 'react'
import { Element } from 'react-bulma-components'
import { CalendarDay, useCalendarContext } from '../../../../hooks/use-calendar'

interface DayProps<T, M> {
  isoDate: NonNullable<CalendarDay<T, M>>['isoDate'] | null
  children?: ReactNode
  left?: ReactNode | string
  right?: ReactNode | string
}

const EmptyTd: React.FC<{ children?: ReactNode }> = ({ children }) => (
  <Element renderAs="td">
    <Element renderAs="span" textSize={7}>
      {children}
    </Element>
  </Element>
)

export const Day = <T, M>({ isoDate, left, right, children }: DayProps<T, M>): JSX.Element => {
  const { getDay } = useCalendarContext<T, M>()

  if (isoDate === null) {
    return <EmptyTd />
  }

  return (
    <Element renderAs="td" className="px-2">
      <Element display="flex" justifyContent="space-between">
        <Element>
          {left ? (
            left
          ) : (
            <Element textSize={6} textWeight="bold" renderAs="span" mr={1}>
              {getDay(isoDate)?.day}
            </Element>
          )}
        </Element>
        <Element>{right}</Element>
      </Element>
      {children}
    </Element>
  )
}
