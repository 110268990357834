import { PreBillingSchema } from '../../../backend/src/services/resources/pre-billings/pre-billing.model'
import api, { ApiResponse } from '.'
import { ClockingRules } from './clocking-rules'
import {
  CreationInfos,
  PreBillingBusinessCreationPayload,
  PreBillingBusinessUpdatePayload,
} from '../../../backend/src/services/resources/pre-billings/pre-billings.business.service.types'
import { Employer, InterimAgency } from './organizations'
import { PreBillingsFilters } from '../store/filters'
import { User } from './users'

export interface PreBilling extends Omit<PreBillingSchema, 'createdBy'> {
  _id: string
  clockingRule: ClockingRules
  interimAgency: InterimAgency
  createdBy: User
  employer: Employer
}

const POPULATE = ['employer', 'interimAgency', 'createdBy']

export const fetch = async (params?: PreBillingsFilters): Promise<ApiResponse<PreBilling[]>> => {
  if (params?.invoiceRef?.length === 1 && params.invoiceRef[0]) {
    params.invoiceRef = ''
  } else if (params?.invoiceRef?.length === 1 && !params.invoiceRef[0]) {
    params['!invoiceRef'] = ''
  } else if (params?.invoiceRef) params.invoiceRef = undefined

  return await api({
    endpoint: '/pre-billings',
    method: 'get',
    params: {
      sort: '-createdAt',
      populate: POPULATE,
      ...(params || {}),
    },
  })
}

export const get = async (id: string): Promise<ApiResponse<PreBilling>> =>
  await api({
    endpoint: `/pre-billings/${id}`,
    method: 'get',
    params: { populate: POPULATE },
  })

export const simulate = async (
  params: PreBillingCreationPayload,
): Promise<ApiResponse<CreationInfos>> =>
  await api({
    endpoint: '/pre-billings/simulate',
    method: 'get',
    params,
  })

export type PreBillingCreationPayload = Omit<
  PreBillingBusinessCreationPayload,
  'employer' | 'createdBy'
>

export const create = async (params: PreBillingCreationPayload): Promise<ApiResponse<PreBilling>> =>
  await api({
    endpoint: '/pre-billings',
    method: 'post',
    params,
  })

export type PreBillingUpdatePayload = PreBillingBusinessUpdatePayload & { id: string }
export const update = async (data: PreBillingUpdatePayload): Promise<ApiResponse<PreBilling>> => {
  const { id, ...params } = data
  return await api({
    endpoint: `/pre-billings/${id}`,
    method: 'put',
    params,
  })
}
