import React from 'react'
import { Button, Element, Message, Modal } from 'react-bulma-components'
import { useAppNeedsUpdate } from '../../hooks/use-app-needs-update'

export const AppNeedsUpdateBanner: React.FC = () => {
  const { appNeedsUpdate } = useAppNeedsUpdate()
  if (!appNeedsUpdate) return <></>
  return (
    <Modal closeOnBlur={false} closeOnEsc={false} show={true} showClose={false}>
      <Modal.Content>
        <Message color={'warning'} backgroundColor="warning-light">
          <Message.Header>Nouvelle version disponible</Message.Header>
          <Message.Body m={3} pb={5}>
            <Element mb={3}>Une nouvelle version de la plateforme est disponible.</Element>
            <Button color={'warning'} onClick={forceHardRefresh}>
              Appliquer la mise à jour
            </Button>
          </Message.Body>
        </Message>
      </Modal.Content>
    </Modal>
  )
}

const forceHardRefresh = (): void => {
  // We first fetch the url in the background by explicitly not caching it in headers
  // Then do a "hard reload"
  // This is a combination of 2 methods talked in
  // https://stackoverflow.com/questions/2099201/javascript-hard-refresh-of-current-page
  const url = new URL(window.location.href)
  fetch(url, {
    headers: {
      Pragma: 'no-cache',
      Expires: '-1',
      'Cache-Control': 'no-cache',
    },
  })
    // @ts-expect-error
    // See https://developer.mozilla.org/en-US/docs/Web/API/Location/reload
    // Firefox accepts a forceReload boolean parameter
    // Other browsers will just discard the parameter
    .then(ok => window.location.reload(true))
    .catch(err => {
      console.error('Unexpected error when trying to prefetch before force hard refresh', err)
      // Refresh anyway
      // @ts-expect-error, see above
      window.location.reload(true)
    })
}
