import { Field, Form, Formik } from 'formik'
import React from 'react'
import InputField from '../form/fields/input'
import { useCreateMutation, useUpdateMutation } from '../../queries/job-titles'
import { JobTitle } from '../../api/job-titles'
import SelectField from '../form/fields/select'
import { buildListAsInputOptions } from '../../utils/forms'
import { RequestButton } from '../request-components/request-components'
import TextareaField from '../form/fields/textarea'
import { useClockingRulesQuery } from '../../queries/clocking-rules'
import ClockingRulesDescription from '../clocking-rules/clocking-rules-description'
import { Box, Card, Columns, Element, Heading, Icon, Message } from 'react-bulma-components'
import { useAssociatedOrganizationsQuery } from '../../queries/organizations'
import FormField from '../form/fields/form-field'
import { isEqual } from 'lodash'
import { WarningIcon } from '../icons'
import { compareDocsById } from '../../utils/documents'

interface JobTitleFormProps {
  jobTitle?: JobTitle
}

const JobTitleForm: React.FC<JobTitleFormProps> = ({ jobTitle }) => {
  const createMutation = useCreateMutation()
  const updateMutation = useUpdateMutation()
  const clockingRulesQuery = useClockingRulesQuery()
  const organizationsQuery = useAssociatedOrganizationsQuery()
  const interimAgencies = organizationsQuery.data || []

  if (!organizationsQuery.data) return

  const currentClockingRule =
    jobTitle && clockingRulesQuery.data?.find(c => compareDocsById(c._id, jobTitle?.clockingRule))

  return (
    <Formik
      initialValues={
        jobTitle || {
          name: '',
          description: '',
          complementaryInformations: '',
          clockingRule: '',
          laborCostCoefficients: interimAgencies.map(i => ({ interimAgency: i._id, value: null })),
        }
      }
      enableReinitialize
      validate={values => {
        const errors: any = {}
        if (!values.name) errors.name = 'Champ requis'
        if (!values.clockingRule) errors.clockingRule = 'Champ requis'
        values.laborCostCoefficients?.forEach((l, index) => {
          if (!l.delegationValue)
            errors[`laborCostCoefficients[${index}].delegationValue`] = 'Champ requis'
          if (!l.managementValue)
            errors[`laborCostCoefficients[${index}].managementValue`] = 'Champ requis'
        })
        return errors
      }}
      onSubmit={values => {
        jobTitle
          ? updateMutation.mutate({ ...values, id: jobTitle._id.toString() })
          : createMutation.mutate(values)
      }}
    >
      {({ values }) => {
        const clockingRules =
          values.clockingRule &&
          clockingRulesQuery.data?.find &&
          clockingRulesQuery.data.find(clockingRules => clockingRules._id === values.clockingRule)

        return (
          <Form autoComplete="off">
            <Heading size={4} mb={5}>
              Informations de base
            </Heading>
            <Field label="Nom " name="name" component={InputField} required />
            <Field name="description" component={TextareaField} label="Description interne" />
            <hr />
            <FormField
              label="Informations complémentaires pour les Travailleurs"
              help="Ces informations seront systématiquement transmises aux Travailleurs recevant une mission pour cet Intitulé de Poste"
            >
              <Field name="complementaryInformations" component={TextareaField} />
            </FormField>
            <Element mb={5} mt={6}>
              <Heading size={5} mb={5}>
                Coefficients de main d'oeuvre
              </Heading>
              <Columns>
                {values.laborCostCoefficients.map((laborCostCoefficient, index) => {
                  const interimAgency = interimAgencies.find(
                    i => i._id === laborCostCoefficient.interimAgency,
                  )
                  if (!interimAgency) return
                  return (
                    <Columns.Column key={interimAgency._id} size={4}>
                      <Box>
                        <Heading size={5} mb={3}>
                          {interimAgency.name}
                        </Heading>
                        <Columns>
                          <Columns.Column>
                            <Field
                              label={'Délégation'}
                              name={`laborCostCoefficients[${index}].delegationValue`}
                              component={InputField}
                              type="number"
                              required
                              step="0.1"
                            />
                          </Columns.Column>
                          <Columns.Column>
                            <Field
                              label={'Gestion'}
                              name={`laborCostCoefficients[${index}].managementValue`}
                              component={InputField}
                              type="number"
                              required
                              step="0.1"
                            />
                          </Columns.Column>
                        </Columns>
                      </Box>
                    </Columns.Column>
                  )
                })}
              </Columns>
            </Element>
            <Element mb={5} mt={6}>
              <Heading size={5} mb={5}>
                Règles de pointage
              </Heading>
              <Field
                name="clockingRule"
                component={SelectField}
                options={
                  clockingRulesQuery.data ? buildListAsInputOptions(clockingRulesQuery.data) : []
                }
              />
              {clockingRules && (
                <>
                  <Card mb={3}>
                    <Card.Content>
                      <Element textWeight="bold" mb={3}>
                        Règle à l'Entrée
                      </Element>
                      <ClockingRulesDescription clockingRules={clockingRules} type="start" />
                    </Card.Content>
                  </Card>
                  <Card mb={3}>
                    <Card.Content>
                      <Element textWeight="bold" mb={3}>
                        Règle à la Sortie
                      </Element>
                      <ClockingRulesDescription clockingRules={clockingRules} type="end" />
                    </Card.Content>
                  </Card>
                </>
              )}
            </Element>
            {jobTitle &&
              clockingRules &&
              currentClockingRule &&
              !isEqual(values.clockingRule, jobTitle.clockingRule) && (
                <>
                  <Heading size={4} mt={5}>
                    <Icon.Text>
                      <Icon mr={1}>
                        <WarningIcon color="red" />
                      </Icon>
                      Modification des journées de travail en cours{' '}
                    </Icon.Text>
                  </Heading>
                  <Box>
                    <Message color="warning" p={2}>
                      Vous avez modifié la règle de pointage de l'intitulé de poste{' '}
                      <Element renderAs="strong">{jobTitle.name}</Element> (anciennement{' '}
                      <Element renderAs="strong">{currentClockingRule.name}</Element>).
                      <br />
                      <br />
                      En sélectionnant une date, la nouvelle règle de pointage (
                      <Element renderAs="strong">{clockingRules.name}</Element>) sera appliquée à
                      toutes les journées de travail:
                      <Element className="content">
                        <ul>
                          <li>Non validées</li>
                          <li>Commençant à partir de la date sélectionnée</li>
                        </ul>
                      </Element>
                      <Element>
                        Sans date sélectionnée, la nouvelle règle ne s'appliquera qu'aux futures
                        missions. Les missions et journées de travail existantes conserveront
                        l'ancienne règle.
                      </Element>
                    </Message>

                    <Field
                      name="updateExistingWorkPeriodsFrom"
                      component={InputField}
                      type="date"
                      label="Modifier les journées de travail à partir du"
                      help="Si aucune date n'est choisie, aucune journée de travail ne sera modifiée"
                    />
                  </Box>
                </>
              )}
            <RequestButton color="primary" type="submit" mutation={updateMutation} mt={6}>
              {jobTitle ? "Mettre à jour l'Intitulé de Poste" : 'Nouvel Intitulé de Poste'}
            </RequestButton>
          </Form>
        )
      }}
    </Formik>
  )
}
export default JobTitleForm
