import React, { useState } from 'react'
import { Button, Element, Form, Message } from 'react-bulma-components'
import { PreBillingsFilters, MissionsFilters } from '../../store/filters'
import DescriptionList from '../description-list/description-list'
import { useCreateMutation, useSimulatePreBillingQuery } from '../../queries/pre-billings'
import { getWeek, getWeekYear, localDate } from '../../utils/date'
import { useNavigate } from 'react-router-dom'
import { formatCost } from '../../utils/finance'
import WorkerLabel from '../texts/worker-label'

export interface CreatePreBillingProps {
  interimAgencyId?: string
  service?: MissionsFilters['service']
  jobTitle?: MissionsFilters['jobTitle']
  start: PreBillingsFilters['start']
  end: PreBillingsFilters['end']
}

const CreatePreBilling: React.FC<CreatePreBillingProps> = ({
  interimAgencyId,
  jobTitle,
  service,
  start,
}) => {
  // Pre billing mutation
  const mutation = useCreateMutation()
  const navigate = useNavigate()
  const [isConfirmed, setIsConfirmed] = useState(false)

  const newPreBillingParams = {
    week: getWeek(new Date(start as Date)),
    year: getWeekYear(new Date(start as Date)),
    interimAgency: interimAgencyId,
    jobTitle,
    service,
  }

  const simulateQuery = useSimulatePreBillingQuery(newPreBillingParams)

  React.useEffect(() => {
    if (mutation.isSuccess) navigate(`/pre-billings/${mutation.data.data._id}`)
  }, [mutation.isSuccess])

  if (simulateQuery.isLoading || !simulateQuery.data) return <Element>Chargement en cours</Element>

  return (
    <Element>
      {simulateQuery.data?.missions.preBillable === 0 && (
        <Message color="danger">
          <Message.Body>
            <Element renderAs="p" textWeight="bold">
              Pas de Mission éligible pour la Pré-Facturation
            </Element>
          </Message.Body>
        </Message>
      )}
      {simulateQuery.data?.missions.count !== simulateQuery.data?.missions.preBillable && (
        <Message color="warning">
          <Message.Body>
            <Element renderAs="p" textWeight="bold" mb={3}>
              {simulateQuery.data?.missions.notPreBillable} Mission(s) non prises en compte
            </Element>
            <Element className="content">
              <Element renderAs="ul" textSize={7}>
                <Element renderAs="li">
                  Uniquement les missions validées sont prise en compte
                </Element>
                <Element renderAs="li">
                  Toutes les missions pour un même <WorkerLabel /> doivent être pré-facturées en
                  même temps
                </Element>
                <Element renderAs="li">
                  Uniquement les missions correspondantes aux filtres sélectionnés sont prise en
                  compte
                </Element>
              </Element>
            </Element>
          </Message.Body>
        </Message>
      )}
      <DescriptionList
        items={[
          {
            label: 'Missions concernées',
            value: `${simulateQuery.data?.missions.preBillable} / ${simulateQuery.data?.missions.count}`,
          },
          { label: 'Du', value: localDate(simulateQuery.data.from) },
          { label: 'Au', value: localDate(simulateQuery.data.to) },
          { label: 'Agence', value: simulateQuery.data.interimAgencyName },
          {
            label: 'Poste',
            value: simulateQuery.data.jobTitles?.[0]
              ? simulateQuery.data.jobTitles.join(', ')
              : 'Tous',
          },
          {
            label: 'Service',
            value: simulateQuery.data.services?.[0]
              ? simulateQuery.data.services.join(', ')
              : 'Tous',
          },
          { label: 'Montant total', value: formatCost(simulateQuery.data.totals?.costs?.total) },
        ]}
      />
      <Form.Field mt={5} mb={3}>
        <Form.Control>
          <Form.Checkbox
            required={true}
            textWeight="bold"
            checked={isConfirmed}
            onChange={() => setIsConfirmed(!isConfirmed)}
            name="confirm-create-pre-billing"
            data-test="confirm-create-pre-billing"
            disabled={!simulateQuery.data?.missions.preBillable}
          >
            <Element renderAs="span" textWeight="bold">
              Je comprends que cette action est irréversible
            </Element>
          </Form.Checkbox>
        </Form.Control>
      </Form.Field>
      <Button
        fullwidth
        color={'info'}
        onClick={() => {
          simulateQuery.data.missions.preBillable >= 1 && mutation.mutate(newPreBillingParams)
        }}
        disabled={
          !simulateQuery.data?.missions.preBillable ||
          mutation.isLoading ||
          mutation.isSuccess ||
          !isConfirmed
        }
      >
        Générer la pré-facturation de{' '}
        {`${simulateQuery.data?.missions.preBillable} / ${simulateQuery.data?.missions.count}`}{' '}
        Mission(s)
      </Button>
    </Element>
  )
}

export default CreatePreBilling
