import React from 'react'
import { Field, FieldAttributes } from 'formik'
import config from '../../../../../backend/src/config'
import InputField from './input'
import { Element, Form } from 'react-bulma-components'

const {
  passwordValidation: { rule, message },
} = config
const regex = new RegExp(rule)

export const PasswordField: React.FunctionComponent = props => {
  // State for password field: change type
  const [showPassword, setShowPassword] = React.useState(false)

  // Input type: password by default, text if password is visible to the user
  const inputType = showPassword ? 'text' : 'password'

  return (
    <>
      <Field
        component={InputField}
        required={true}
        autoComplete="current-password"
        {...props}
        type={inputType}
      />
      <Element>
        <Form.Checkbox
          checked={showPassword}
          onClick={() => setShowPassword(!showPassword)}
          tabIndex={-1} // Do not accidentally focus and toggle
        >
          Afficher le mot de passe
        </Form.Checkbox>
      </Element>
    </>
  )
}

export const PasswordFieldWithValidation: React.FunctionComponent<FieldAttributes<any>> = props => {
  return <PasswordField validate={isPasswordValid} autoComplete="new-password" {...props} />
}

export const isPasswordValid = (value: string): string | false => {
  if (value.length > 0 && !regex.test(value)) return message
  return false
}
