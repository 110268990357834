import { EmployerRuleSchema } from '../../../backend/src/services/resources/employer-rules/employer-rule.model'
import { EmployerRuleRepositoryCreationPayload } from '../../../backend/src/services/resources/employer-rules/employer-rules.repository.service.types'
import api, { ApiResponse } from '.'
import { ClockingRules } from './clocking-rules'

export interface EmployerRule extends EmployerRuleSchema {
  _id: string
}

const POPULATE = ['employer']

export const fetch = async (params?: any): Promise<ApiResponse<EmployerRule[]>> =>
  await api({
    endpoint: '/employer-rules',
    method: 'get',
    params: {
      sort: '-createdAt',
      populate: POPULATE,
      ...(params || {}),
    },
  })

export const get = async (id: string): Promise<ApiResponse<EmployerRule>> =>
  await api({
    endpoint: `/employer-rules/${id}`,
    method: 'get',
  })

export type EmployerRuleCreationPayload = EmployerRuleRepositoryCreationPayload

export const create = async (
  params: EmployerRuleCreationPayload,
): Promise<ApiResponse<EmployerRule>> =>
  await api({
    endpoint: '/employer-rules',
    method: 'post',
    params,
  })

export interface EmployerRuleUpdatePayload extends Partial<EmployerRuleCreationPayload> {
  id: string
}

export const update = async (
  params: EmployerRuleUpdatePayload,
): Promise<ApiResponse<EmployerRule>> => {
  const { id, ...payload } = params
  return await api({
    endpoint: `/employer-rules/${id}`,
    method: 'put',
    params: payload,
  })
}
