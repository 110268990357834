import { FieldProps } from 'formik'
import React from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import FormField, { FormFieldProps } from './form-field'

interface TextareaFieldProps extends FormFieldProps, FieldProps {
  maxRows?: number
  minRows?: number
}

const TextareaField: React.FunctionComponent<TextareaFieldProps> = props => {
  const { field, form, ...rest } = props
  if (props.field.value === undefined) props.field.value = ''
  return (
    <FormField label={props.label} error={form.errors[field.name] as string}>
      <TextareaAutosize {...field} {...rest} className="textarea" />
    </FormField>
  )
}

export default TextareaField
